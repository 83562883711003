import { useQueryParam, Modal } from '@maestro/components';
import React from 'react';
import { SeriesRpgConfiguration } from './properties/rpg/SeriesRpgConfiguration';

type Props = {
  onStudioFlowChange: () => void;
};

export const PropertiesModal: React.FC<Props> = ({ onStudioFlowChange }) => {
  const [paramValue, setParam] = useQueryParam({ param: 'prop' });
  const onClose = () => setParam(undefined, true);
  const isOpen = paramValue === 'open';

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Properties" borderDisabled>
      <SeriesRpgConfiguration onStudioFlowChange={onStudioFlowChange} />
    </Modal>
  );
};
