import {
  type SingleSelectNodeData,
  StudioNodeType,
  ValueType,
} from '@common/studio-types';
import type { SingleSelectOption } from '../game/messages.types';
import type { GameNode } from '../studioGameCreator.types';
import type { GameNodes } from './getGameNodes';
import type { StatefulGameConfig } from './statefulGame';

export const toSingleSelectOption = (
  option: GameNode<SingleSelectNodeData>['options'][0],
  gameNodes: GameNodes,
  config: StatefulGameConfig,
): SingleSelectOption => {
  const nextNode = gameNodes.nodeById(option.nextNodeId);

  if (nextNode?.type === StudioNodeType.CoinToss) {
    const prop =
      nextNode.property && nextNode.property.type === ValueType.Property
        ? config.getProperty(nextNode.property.ref)
        : undefined;

    return {
      id: option.id,
      text: option.text,
      addon: {
        type: 'coin-toss',
        attribute: prop && { name: prop.name, description: '' },
      },
    };
  }

  return { id: option.id, text: option.text };
};
