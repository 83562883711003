import type { PlayerInputNodeData } from '@common/studio-types';
import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { InterpolatedText } from '../../components/value/InterpolatedText';
import { ValueBadge } from '../../components/value/ValueBadge';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<PlayerInputNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      title="Player input"
      selected={selected}
      nodeId={id}
      node={data}
    >
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Text>
        <InterpolatedText text={data.prompt} />
      </Text>
      <Container>
        <ValueBadge value={data.property} />
      </Container>
      <Handle
        nodeId={id}
        type="source"
        position={Position.Bottom}
        id="source-bottom"
      />
    </BaseStudioNode>
  );
};

export const PlayerInputNode = memo(Node);

const Text = styled.div`
  white-space: pre-line;
`;

const Container = styled.div`
  margin-top: ${dimensions.size8};
`;
