import styled from 'styled-components';
import { Icon } from './Icon';

type Props = {
  width: number;
  height: number;
  iconSize: number;
  className?: string;
};

export const ImageFallback: React.FC<Props> = ({
  width,
  height,
  iconSize,
  className,
}) => (
  <ImageFallbackContainer $width={width} $height={height} className={className}>
    <Icon name="image" size={iconSize} color={'rgba(255, 255, 255, 0.4)'} />
  </ImageFallbackContainer>
);

const ImageFallbackContainer = styled.div<{ $width: number; $height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width}px;
  min-width: ${({ $width }) => $width}px;
  height: ${({ $height }) => `${$height}px` ?? 'auto'};
  min-height: ${({ $height }) => `${$height}px` ?? 'auto'};
  background-color: ${({ theme }) => theme.colors.background.shade};
`;
