/**
 * These are base dimension tokens, used for sizing items in Components (not extensive):
 *  - height (min/max/set)
 *  - width (min/max/set)
 *  - margin
 *  - padding
 *  - border-radius
 */
export const rawDimensions = {
  size0: 0,
  size1: 1,
  size2: 2,
  size3: 3,
  size4: 4,
  size6: 6,
  size8: 8,
  size10: 10,
  size12: 12,
  size14: 14,
  size16: 16,
  size20: 20,
  size24: 24,
  size28: 28,
  size32: 32,
  size36: 36,
  size40: 40,
  size44: 44,
  size48: 48,
  size56: 56,
  size64: 64,
  size72: 72,
  size80: 80,
  size96: 96,
  size112: 112,
  size120: 120,
  size128: 128,
  size160: 160,
  size180: 180,
  size200: 200,
  size240: 240,
  size256: 256,
  size280: 280,
  size300: 300,
  size320: 320,
  size360: 360,
  size400: 400,
  size480: 480,
  size560: 560,
  size600: 600,
  size640: 640,
  size720: 720,
  size800: 800,
  size880: 880,
  size960: 960,
  size999: 999,
  size1280: 1280,
} as const;

/**
 * These are base dimension tokens, used for sizing items in CSS (not extensive):
 *  - height (min/max/set)
 *  - width (min/max/set)
 *  - margin
 *  - padding
 *  - border-radius
 */
export const dimensions = {
  size0: `${rawDimensions.size0}`,
  size1: `${rawDimensions.size1}px`,
  size2: `${rawDimensions.size2}px`,
  size3: `${rawDimensions.size3}px`,
  size4: `${rawDimensions.size4}px`,
  size6: `${rawDimensions.size6}px`,
  size8: `${rawDimensions.size8}px`,
  size10: `${rawDimensions.size10}px`,
  size12: `${rawDimensions.size12}px`,
  size14: `${rawDimensions.size14}px`,
  size16: `${rawDimensions.size16}px`,
  size20: `${rawDimensions.size20}px`,
  size24: `${rawDimensions.size24}px`,
  size28: `${rawDimensions.size28}px`,
  size32: `${rawDimensions.size32}px`,
  size36: `${rawDimensions.size36}px`,
  size40: `${rawDimensions.size40}px`,
  size44: `${rawDimensions.size44}px`,
  size48: `${rawDimensions.size48}px`,
  size56: `${rawDimensions.size56}px`,
  size64: `${rawDimensions.size64}px`,
  size72: `${rawDimensions.size72}px`,
  size80: `${rawDimensions.size80}px`,
  size96: `${rawDimensions.size96}px`,
  size112: `${rawDimensions.size112}px`,
  size120: `${rawDimensions.size120}px`,
  size128: `${rawDimensions.size128}px`,
  size160: `${rawDimensions.size160}px`,
  siz180: `${rawDimensions.size180}px`,
  size200: `${rawDimensions.size200}px`,
  size240: `${rawDimensions.size240}px`,
  size256: `${rawDimensions.size256}px`,
  size280: `${rawDimensions.size280}px`,
  size300: `${rawDimensions.size300}px`,
  size320: `${rawDimensions.size320}px`,
  size360: `${rawDimensions.size360}px`,
  size400: `${rawDimensions.size400}px`,
  size480: `${rawDimensions.size480}px`,
  size560: `${rawDimensions.size560}px`,
  size600: `${rawDimensions.size600}px`,
  size640: `${rawDimensions.size640}px`,
  size720: `${rawDimensions.size720}px`,
  size800: `${rawDimensions.size800}px`,
  size880: `${rawDimensions.size880}px`,
  size960: `${rawDimensions.size960}px`,
  size999: `${rawDimensions.size999}px`,
  size1280: `${rawDimensions.size1280}px`,
} as const;
