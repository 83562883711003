/* cspell:words dagre, dagrejs, rankdir, nodesep, ranksep, edgesep, graphlib */
import dagre from '@dagrejs/dagre';
import { useMemo } from 'react';
import { type Node, type Edge } from 'reactflow';

// https://github.com/dagrejs/dagre/wiki#configuring-the-layout
const layoutOptions = {
  nodesep: 10, // separation for nodes
  ranksep: 80, // separation for ranks

  // 500px separation for edges (to not have edges behind other nodes)
  edgesep: 500,
};

export const useAutoLayout = () => {
  const graph = useMemo(() => {
    return new dagre.graphlib.Graph()
      .setDefaultEdgeLabel(() => ({}))
      .setGraph(layoutOptions);
  }, []);

  return (nodes: Node[], edges: Edge[]) => {
    for (const node of nodes) {
      graph.setNode(node.id, {
        width: node.width ?? 0,
        height: node.height ?? 0,
      });

      // Dagre currently has an open issue that prevents it from laying out sub-flows
      // correctly if any nodes in the sub-flow are connected to nodes outside the
      // sub-flow.
      //
      // See: https://github.com/dagrejs/dagre/issues/238

      // if (node.parentNode) {
      //   dagreGraph.setParent(node.id, node.parentNode);
      // }
    }

    for (const edge of edges) {
      graph.setEdge(edge.source, edge.target);
    }

    dagre.layout(graph);

    const nextNodes = nodes.map((node) => {
      const { x, y } = graph.node(node.id);
      const position = {
        x: x - (node.width ?? 0) / 2,
        y: y - (node.height ?? 0) / 2,
      };

      return { ...node, position };
    });

    return { nodes: nextNodes, edges };
  };
};
