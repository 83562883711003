import { splitVariables } from '@common/studio-types';
import { capitalize } from 'lodash';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import { getValue } from './getValue/getValue';
import type { ReturnedValue } from './getValue/types';

export { getValue } from './getValue/getValue';

export const convertToText = async (
  text: string,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<string> => {
  const values = await Promise.all(
    splitVariables(text, config.getPropertyByName).map(async (value) => {
      return typeof value === 'string'
        ? value
        : getValue(value, state, config, playerData).then(
            (returnedValue: ReturnedValue) =>
              value.isStartOfSentence
                ? capitalize(returnedValue.visualValue)
                : returnedValue.visualValue,
          );
    }),
  );

  return values.join('');
};
