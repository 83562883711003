import { Button, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Drawer } from '../Drawer';
import { PlayEpisodeItemsSimulator } from './PlayEpisodeItemsSimulator';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const PlayEpisodeDrawer: React.FC<Props> = (props) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const theme = useTheme();

  useEffect(onClose, [props.isOpen]);

  return (
    <>
      {props.isOpen && <Backdrop onClick={props.onClose} />}
      <Drawer
        isOpen={props.isOpen}
        onCancel={props.onClose}
        title="Play episode"
        headerContent={
          <Tooltip
            label="Properties menu"
            aria-label="Properties menu"
            placement="bottom-end"
          >
            <Button variant="drawerButton" onClick={onToggle}>
              <Icon
                name="property-value"
                size={rawDimensions.size24}
                color={
                  isOpen ? theme.colors.text.accent : theme.colors.text.header
                }
              />
            </Button>
          </Tooltip>
        }
      >
        {props.isOpen && (
          <PlayEpisodeItemsSimulator
            isPropertiesOpen={isOpen}
            onPropertiesClose={onClose}
          />
        )}
      </Drawer>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  z-index: 10;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.1);
`;
