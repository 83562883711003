export const GoogleIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5372 7.19431H14.0002V7.16665H8.00016V9.83331H11.7678C11.2182 11.3856 9.74116 12.5 8.00016 12.5C5.79116 12.5 4.00016 10.709 4.00016 8.49998C4.00016 6.29098 5.79116 4.49998 8.00016 4.49998C9.01983 4.49998 9.9475 4.88465 10.6538 5.51298L12.5395 3.62731C11.3488 2.51765 9.75616 1.83331 8.00016 1.83331C4.3185 1.83331 1.3335 4.81831 1.3335 8.49998C1.3335 12.1816 4.3185 15.1666 8.00016 15.1666C11.6818 15.1666 14.6668 12.1816 14.6668 8.49998C14.6668 8.05298 14.6208 7.61665 14.5372 7.19431Z"
        fill="#FFC107"
      />
      <path
        d="M2.10205 5.39698L4.29238 7.00331C4.88505 5.53598 6.32038 4.49998 8.00005 4.49998C9.01972 4.49998 9.94738 4.88465 10.6537 5.51298L12.5394 3.62731C11.3487 2.51765 9.75605 1.83331 8.00005 1.83331C5.43938 1.83331 3.21872 3.27898 2.10205 5.39698Z"
        fill="#FF3D00"
      />
      <path
        d="M7.99994 15.1667C9.72194 15.1667 11.2866 14.5077 12.4696 13.436L10.4063 11.69C9.71446 12.2161 8.86909 12.5007 7.99994 12.5C6.26594 12.5 4.79361 11.3943 4.23894 9.85132L2.06494 11.5263C3.16827 13.6853 5.40894 15.1667 7.99994 15.1667Z"
        fill="#4CAF50"
      />
      <path
        d="M14.537 7.19435H14V7.16669H8V9.83335H11.7677C11.5047 10.5722 11.0311 11.2177 10.4053 11.6904L10.4063 11.6897L12.4697 13.4357C12.3237 13.5684 14.6667 11.8334 14.6667 8.50002C14.6667 8.05302 14.6207 7.61669 14.537 7.19435Z"
        fill="#1976D2"
      />
    </svg>
  );
};
