import { StudioNodeData } from '@common/studio-types';
import { Node } from 'reactflow';
import {
  Suggestions,
  SuggestionsSection,
} from '../../components/suggestions/suggestion.types';
import { nodeConfigs } from '../../nodes';

export const getValidationSuggestion = (
  nodes: Node[],
  selectNode: (nodeId: string) => void,
): SuggestionsSection | undefined => {
  const suggestions: Suggestions[] = nodes
    .map((node: Node<StudioNodeData>): Suggestions[] => {
      const nodeConfig = nodeConfigs[node.data.type];
      const validationErrors = nodeConfig?.validateNodeData?.(node.data) ?? [];

      return validationErrors.map(
        (error): Suggestions => ({
          id: `${node.id}-validation-${error.field ?? ''}-${error.text}`,
          title: nodeConfig.name,
          subtitle: error.field,
          description: error.text,
          action: { title: 'Edit', onClick: () => selectNode(node.id) },
        }),
      );
    })
    .flat();

  return suggestions.length
    ? { title: 'Validation', suggestions, type: 'validation' }
    : undefined;
};
