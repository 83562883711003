import { useGetUserId } from '@maestro/components';
import { toSupabaseClient } from '@maestro/supabase';
import { useCallback, useEffect, useState } from 'react';
import { Item, ItemRow } from './useItems';

export const useItem = (itemId?: string) => {
  const userId = useGetUserId();
  const [item, setItem] = useState<Item>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchItem = useCallback(async () => {
    if (!itemId || !userId) {
      return;
    }

    try {
      const { data, error } = await toSupabaseClient()
        .from('item')
        .select('*, series(id, title)')
        .eq('id', itemId)
        .limit(1)
        .single<ItemRow>();

      if (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching item details', error);
      }

      setItem(
        data
          ? {
              id: data.id,
              name: data.name,
              category: data.category,
              series: { id: data.series.id, title: data.series.title },
              description: data.description,
              imagePath: data.image_path ?? '',
              fromAuthor: data.created_by_id === userId,
            }
          : undefined,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching item details', error);
    } finally {
      setIsLoading(false);
    }
  }, [itemId, userId]);

  useEffect(() => {
    fetchItem().catch(() => void 0);
  }, [fetchItem]);

  return {
    item,
    isLoading,
  };
};
