import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React, { PropsWithChildren, useRef } from 'react';

type Props = PropsWithChildren<{
  variant: string;
  isOpen: boolean;
  onClose: () => void;
  promptTitle: string;
  promptMessage: string;
  onDelete: () => void;
}>;

export const DeleteModal: React.FC<Props> = (props) => {
  const cancelRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{props.promptTitle}</AlertDialogHeader>
          <AlertDialogBody>{props.promptMessage}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={props.onClose} variant="ghost">
              Nevermind
            </Button>
            <Button onClick={props.onDelete} ml={3} variant="danger">
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
