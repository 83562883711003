import { pickBy, identity } from 'lodash';

type Options = {
  width?: number;
  height?: number;
  dpr?: number;
  resize?: 'cover' | 'contain' | 'fill';
};

export const toOptimizedImageUri = (uri?: string, options: Options = {}) => {
  if (!uri) return uri;

  try {
    const { origin } = new URL(uri);

    // Skip localhost images
    if (origin.includes('localhost')) {
      return uri;
    }

    // Skip non-supported formats
    // https://developers.cloudflare.com/images/transform-images/#supported-formats-and-limitations
    if (!/\.(jpe?g|png|gif|webp)$/i.test(uri)) {
      return uri;
    }

    const optimizedBase = 'https://img.downavenue.com/cdn-cgi/image';
    const optionParams = pickBy(
      {
        width: options.width,
        height: options.height,
        fit: options.resize,
        dpr: options.dpr,
        quality: 85,
      },
      identity,
    );

    return `${optimizedBase}/${Object.entries(optionParams)
      .map((a) => a.join('='))
      .join(',')}/${uri}`;
  } catch (e) {
    return uri;
  }
};
