import { ImageNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { ImageForm } from './ImageForm';
import { ImageNode } from './ImageNode';

const name = 'Image';

export const imageNode: NodeConfig<StudioNodeType.Image, ImageNodeData> = {
  type: StudioNodeType.Image,
  icon: 'image',
  name,
  node: ImageNode,
  form: ImageForm,
  cloneNodeData: ({ imageUrl, alt, width, height, generateImage }) => ({
    data: {
      id: toNodeId(),
      imageUrl,
      alt,
      width,
      height,
      generateImage,
    },
  }),
  getNodeData: (data) => ({ title: 'Image', description: data.alt }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.Image,
    imageUrl: '',
    alt: '',
    width: 0,
    height: 0,
  }),
  validateNodeData: (data) => (!data.imageUrl ? [{ text: '' }] : []),
};
