import * as amplitude from '@amplitude/analytics-browser';
import { env } from '@maestro/env';
import LogRocket from 'logrocket';
import { identify as sentryIdentify } from './sentry';

export const useAnalytics = () => {
  if (!env.VITE_LOGROCKET_APP_ID) {
    // eslint-disable-next-line no-console
    console.warn('LogRocket appId not provided: SDK not initialized');
  } else {
    LogRocket.init(env.VITE_LOGROCKET_APP_ID);
  }

  if (!env.VITE_AMPLITUDE_API_KEY) {
    // eslint-disable-next-line no-console
    console.warn('Amplitude apiKey not provided: SDK not initialized');
  } else {
    amplitude.init(env.VITE_AMPLITUDE_API_KEY, { defaultTracking: true });
    amplitude.track('App Loaded');
  }
};

export const identify = (userId: string, userTraits?: { email: string }) => {
  LogRocket.identify(userId, userTraits);
  amplitude.setUserId(userId);
  sentryIdentify(userId, userTraits?.email);

  if (userTraits?.email) {
    const identify = new amplitude.Identify();
    identify.setOnce('email', userTraits.email);
    amplitude.identify(identify);
  }
};
