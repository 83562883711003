import { Badge } from '@chakra-ui/react';
import { ValueType, type ChangeValueNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { ItemContainer } from '../../components/value/ItemContainer';
import { ValueBadge } from '../../components/value/ValueBadge';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<ChangeValueNodeData>;

const operators: Record<ChangeValueNodeData['action'], string> = {
  increase: '+',
  set: '=',
  reduce: '-',
};

const Node: React.FC<Props> = ({ id, data, selected }) => {
  const operator = operators[data.action];

  return (
    <BaseStudioNode
      nodeId={id}
      title="Set value"
      selected={selected}
      node={data}
    >
      <Container>
        <div>
          <ValueBadge
            value={data.changedValue}
            badgeProps={{
              variant: 'circle',
              fontFamily: 'mono',
              borderRadius: `${dimensions.size12} 0 0 ${dimensions.size12}`,
            }}
          />
          <Badge borderRadius={dimensions.size0} variant="value">
            {operator}
          </Badge>
          {data.value && (
            <ValueBadge
              value={data.value}
              badgeProps={{
                variant: 'value',
                borderRadius: `0 ${dimensions.size12} ${dimensions.size12} 0`,
              }}
            />
          )}
        </div>
        {data.changedValue.type === ValueType.Item && (
          <ItemContainer itemId={data.changedValue.ref} />
        )}
      </Container>

      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <Handle
        nodeId={data.id}
        label="Output"
        type="source"
        position={Position.Bottom}
        id={data.id}
      />
    </BaseStudioNode>
  );
};

export const ChangeValueNode = memo(Node);

const Container = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.header};
`;
