import { Button } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import { LoginFormTypes, useLogin } from '@refinedev/core';
import React from 'react';
import styled from 'styled-components';
import { AppleIcon } from './AppleIcon';
import { GoogleIcon } from './GoogleIcon';
import { OnboardingScreen } from './OnboardingScreen';

export const LoginScreen: React.FC = () => {
  const { mutate: login } = useLogin<LoginFormTypes>({});

  return (
    <OnboardingScreen
      titleText="Transform your stories into adventures"
      subtitleText="Let your readers' choices change the way your world unfolds to them"
    >
      <LoginContainer>
        <CTA>Sign in or sign up</CTA>
        <Button
          lineHeight={dimensions.size24}
          variant="default"
          height={dimensions.size44}
          width="100%"
          rightIcon={<GoogleIcon />}
          onClick={() => login({ providerName: 'google' })}
        >
          Continue with Google
        </Button>
        <Button
          lineHeight={dimensions.size24}
          variant="default"
          height={dimensions.size44}
          width="100%"
          rightIcon={<AppleIcon />}
          onClick={() => login({ providerName: 'apple' })}
        >
          Continue with Apple
        </Button>
        <Agreement>
          By signing up, you are agreeing to our{' '}
          <a href="https://downavenue.com/terms-of-service/" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="https://downavenue.com/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
        </Agreement>
      </LoginContainer>
    </OnboardingScreen>
  );
};

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: ${dimensions.size12};
  align-items: center;
  justify-content: center;
  max-width: ${dimensions.size400};
`;

const CTA = styled.div`
  ${textStyles.label.lb16sb};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const Agreement = styled.div`
  ${textStyles.body.b12m};
  color: rgba(255, 255, 255, 0.8);
  text-align: center;

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
  }
`;
