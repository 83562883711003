import {
  FormErrorMessage,
  FormControl as ChakraFormControl,
  FormLabel,
  FormControlProps,
} from '@chakra-ui/react';
import React, {
  PropsWithChildren,
  ReactNode,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

type Props<T> = PropsWithChildren<{
  isEnabled?: boolean;
  object?: T;
  field?: keyof T;
  label: string;
  noMargin?: boolean;
  formControlProps?: FormControlProps;
  errorMessage?: string;
}>;

export const FormControl: <T extends Record<string, unknown>>(
  p: Props<T>,
) => React.ReactElement<Props<T>> = (props) => {
  const isError =
    props.isEnabled &&
    !!props.object &&
    !!props.field &&
    !props.object[props.field];

  const injectErrorProp = (children: ReactNode): ReactNode => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cloneElement(child, { isInvalid: isError } as any);
      }

      return child;
    });
  };

  return (
    <ChakraFormControl
      mb={props.noMargin ? 0 : 6}
      isInvalid={isError}
      {...(props?.formControlProps ?? {})}
    >
      <FormLabel>{props.label}</FormLabel>
      {injectErrorProp(props.children)}
      <FormErrorMessage>
        {isError && (props?.errorMessage ?? 'This field is required')}
      </FormErrorMessage>
    </ChakraFormControl>
  );
};
