import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { PrePublishEpisodeVersionValidation } from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';

type Props = {
  label: string;
  isValid: boolean;
  withSeparator?: boolean;
  validations?: PrePublishEpisodeVersionValidation[];
  showNext?: boolean;
  onNextClick?: () => void;
  isCollapsed: boolean;
  onViewClick?: () => void;
};

export const VerificationStatusStep: FC<Props> = ({
  label,
  isValid,
  withSeparator,
  validations,
  showNext,
  onNextClick,
  isCollapsed,
  onViewClick,
}) => {
  const theme = useTheme();

  const validationMessage = () => {
    const fields = validations?.map((validation) => validation.field);
    const lastField = fields?.pop();

    return (validations?.length ?? 0) > 1
      ? `Needs to review ${fields?.join(', ')} and ${lastField}.`
      : `Needs to review ${lastField}.`;
  };

  return (
    <Step>
      <StepContainer>
        <StatusIndicatorContainer $isValid={isValid}>
          <Icon
            name={isValid ? 'checkmark' : 'close'}
            size={rawDimensions.size16}
            color={
              isValid
                ? theme.colors.base.light['1000']
                : theme.colors.base.yellow['1000']
            }
          />
        </StatusIndicatorContainer>
        <StepLabel>{label}</StepLabel>
      </StepContainer>
      {withSeparator && (
        <SpacerContainer>
          <SeparatorContainer>
            <Separator $isValid={isValid} />
          </SeparatorContainer>
          {!isCollapsed && (
            <StepCollapsibleContent>
              <ValidationDetails>
                <ValidationText>{validationMessage()}</ValidationText>
                <Button variant={'default'} onClick={onViewClick}>
                  View
                </Button>
              </ValidationDetails>
              {showNext && (
                <Button
                  onClick={onNextClick}
                  variant={'default'}
                  maxW={'fit-content'}
                  alignSelf={'flex-end'}
                  marginRight={dimensions.size16}
                >
                  Next
                </Button>
              )}
            </StepCollapsibleContent>
          )}
        </SpacerContainer>
      )}
    </Step>
  );
};

const StepCollapsibleContent = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  gap: ${dimensions.size12};
`;

const SpacerContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: stretch;
`;

const Step = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size12};
`;

const StatusIndicatorContainer = styled.div<{ $isValid: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: ${dimensions.size28};
  height: ${dimensions.size28};
  padding: ${dimensions.size6};
  background: ${({ theme, $isValid }) =>
    $isValid ? theme.colors.background.accent : theme.colors.base.yellow[200]};
  border-radius: 9999px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size12};
  align-items: center;
`;

const StepLabel = styled.label`
  ${textStyles.label.lb16b};
  color: ${({ theme }) => theme.colors.text.header};
`;

const SeparatorContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: ${dimensions.size28};
  height: 100%;
`;

const Separator = styled.div<{ $isValid: boolean }>`
  width: ${dimensions.size2};
  height: 100%;
  min-height: ${dimensions.size28};
  background: ${({ theme, $isValid }) =>
    $isValid
      ? theme.colors.background.accent
      : theme.colors.border.default[200]};
  border-radius: 9999px;
`;

const ValidationDetails = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size12};
  align-items: center;
  width: 100%;
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const ValidationText = styled.p`
  flex: 1;
  padding: 0;
  margin: 0;
  ${textStyles.body.b14m};
  color: ${({ theme }) => theme.colors.base.yellow[1000]};
`;
