import { dimensions } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useItems } from '../../hooks/useItems';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { FieldContainer } from '../Field';
import { ItemInput } from './ItemInput';

type Props = PropsWithChildren<{
  value: string;
  onChange(ref: string): void;
}>;

export const ValueItemInput: React.FC<Props> = (props) => {
  const { value, onChange, children } = props;
  const { seriesId } = useStudioFlowStore();
  const { items, refetch } = useItems({ seriesId });

  return (
    <Container>
      <SelectContainer>
        <ItemInput
          onItemCreated={refetch}
          value={value}
          items={items}
          onChange={(item) => onChange(item.id)}
        />
        {children}
      </SelectContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & ${FieldContainer} {
    margin-bottom: 0;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
`;
