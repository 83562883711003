import { TopBar } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

export const Maintenance: React.FC = () => {
  return (
    <Container>
      <GradientContainer>
        <StyledTopBar />
        <Title>Avenue is Under Maintenance</Title>
        <Body>Avenue is currently unavailable for maintenance.</Body>
      </GradientContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      0deg,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 0%,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 100%
    ),
    #21212c;
`;

const GradientContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(123, 128, 255, 0.4) 0%,
    rgba(52, 53, 88, 0.08) 41%,
    rgba(33, 33, 44, 0) 100%
  );
`;

const StyledTopBar = styled(TopBar)`
  position: fixed;
  top: 0%;
  background: transparent;
`;

const Title = styled.div`
  font-size: ${dimensions.size32};
  font-weight: 700;
  color: white;
`;

const Body = styled.div`
  max-width: ${dimensions.size560};
  margin-top: ${dimensions.size16};
  font-size: ${dimensions.size16};
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
`;
