import { ColorTheme, ColorWithOpacity } from '../types/theme';
import { baseColorMap } from './baseColors';

export type FillColorDesignSystem = {
  default: Pick<ColorWithOpacity, 1000 | 900 | 600 | 400 | 200 | 100 | 50>;
  accent: Pick<ColorWithOpacity, 1000 | 900 | 100>;
  light: Pick<ColorWithOpacity, 1000 | 600>;
  error: Pick<ColorWithOpacity, 1000>;
};

export const fillColorMap: ColorTheme<FillColorDesignSystem> = {
  dark: {
    default: {
      1000: baseColorMap.dark.light['1000'],
      900: baseColorMap.dark.light['900'],
      600: baseColorMap.dark.light['600'],
      400: baseColorMap.dark.light['400'],
      200: baseColorMap.dark.light['200'],
      100: baseColorMap.dark.light['100'],
      50: baseColorMap.dark.light['50'],
    },
    accent: {
      1000: baseColorMap.dark.accent['1000'],
      900: baseColorMap.dark.accent['900'],
      100: baseColorMap.dark.accent['100'],
    },
    light: {
      1000: baseColorMap.dark.light['1000'],
      600: baseColorMap.dark.light['600'],
    },
    error: {
      1000: baseColorMap.dark.red['1000'],
    },
  },
  light: {
    default: {
      1000: baseColorMap.light.dark['1000'],
      900: baseColorMap.light.dark['900'],
      600: baseColorMap.light.dark['600'],
      400: baseColorMap.light.dark['400'],
      200: baseColorMap.light.dark['200'],
      100: baseColorMap.light.dark['100'],
      50: baseColorMap.light.dark['50'],
    },
    accent: {
      1000: baseColorMap.light.accent['1000'],
      900: baseColorMap.light.accent['900'],
      100: baseColorMap.light.accent['100'],
    },
    light: {
      1000: baseColorMap.light.light['1000'],
      600: baseColorMap.light.light['600'],
    },
    error: {
      1000: baseColorMap.light.red['1000'],
    },
  },
};
