import type { GetValueNodeData } from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { getValue } from './utils';

export const getValueAction = async (
  node: GameNode<GetValueNodeData>,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ActionResult> => {
  const outputName = node.outputName || 'default';
  const value = await getValue(node.value, state, config, playerData);
  const integerValue = typeof value.value === 'number' ? value.value : 0;

  // we don't support output with string
  return { messages: [], state, output: { [outputName]: integerValue } };
};
