import { Button } from '@chakra-ui/react';
import {
  StartNodeData,
  StudioNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import { memo } from 'react';
import { Position, Node as ReactFlowNode, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { Handle } from '../Handle';
import { narratorTextNode } from '../narratorText';
import { StudioNodeComponent } from '../Node.types';

const Node: StudioNodeComponent<StartNodeData> = (props) => {
  const { id, data, xPos, yPos, selected } = props;
  const { selectNode } = useStudioFlowStore();
  const { addEdges, getNodes, setNodes } = useReactFlow();

  const onBeginClick = () => {
    const studioNodeData = narratorTextNode.createNodeData();

    const item: ReactFlowNode<StudioNodeData> = {
      data: studioNodeData,
      type: studioNodeData.type,
      id: studioNodeData.id,
      position: { x: xPos - 104, y: yPos + 180 },
      selected: true,
    };

    const nodes = getNodes();
    const updatedNodes = nodes.map((node) =>
      node.type === StudioNodeType.Start
        ? { ...node, data: { ...node.data, newEpisode: false } }
        : node,
    );

    setNodes([...updatedNodes, item]);
    addEdges([
      { id: `${id}-source-bottom`, source: id, target: studioNodeData.id },
    ]);
    setTimeout(() => selectNode(studioNodeData.id), 100);
  };

  return (
    <OuterContainer>
      <Container $selected={selected}>
        <div>Start</div>
        <Handle
          style={{ visibility: data.newEpisode ? 'hidden' : 'visible' }}
          nodeId={id}
          type="source"
          position={Position.Bottom}
          id="source-bottom"
        />
      </Container>
      {data.newEpisode && (
        <NewEpisodePopup>
          <PopupTitle>Welcome to Content Studio</PopupTitle>
          <PopupDescription>
            Begin your story by adding an enticing intro
          </PopupDescription>
          <Button variant="default" width="100%" onClick={onBeginClick}>
            Let's begin!
          </Button>
        </NewEpisodePopup>
      )}
    </OuterContainer>
  );
};

export const StartNode = memo(Node);

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div<{ $selected: boolean }>`
  ${textStyles.label.lb16sb};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${dimensions.size72};
  height: ${dimensions.size72};
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.background.accent};
  border-radius: ${dimensions.size999};
  border: ${dimensions.size1} solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.base.light[1000] : 'transparent'};
`;

const NewEpisodePopup = styled.div`
  position: absolute;
  bottom: -190px;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  align-items: center;
  justify-content: center;
  width: ${dimensions.size280};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};

  animation: zoomIn 0.5s forwards;

  &:before {
    position: absolute;
    top: -${dimensions.size16};
    left: 50%;
    width: ${dimensions.size0};
    height: ${dimensions.size0};
    margin-left: -${dimensions.size16};
    content: ' ';
    border-right: ${dimensions.size16} solid transparent;
    border-bottom: ${dimensions.size16} solid
      ${({ theme }) => theme.colors.background.default};
    border-left: ${dimensions.size16} solid transparent;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: translate(0, -50%) scale(0);
    }
    to {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }
`;

const PopupTitle = styled.div`
  ${textStyles.label.lb16sb}
  width: 100%;
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const PopupDescription = styled.div`
  ${textStyles.body.b14m}
  width: 100%;
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;
