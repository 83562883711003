import { DependencyList, useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any[]) => any>(
  callback: T,
  dependencies: DependencyList = [],
  timeout: number = 1000,
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  return useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        callback(...args);
        timeoutRef.current = undefined;
      }, timeout);
    },
    [callback, timeout, ...dependencies],
  );
};
