import { Select } from '@chakra-ui/react';
import type { CommentNodeData } from '@common/studio-types';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { StudioNodeFormComponent } from '../Node.types';

const maxCharacters = 1000;

export const CommentForm: StudioNodeFormComponent<CommentNodeData> = ({
  data,
  onChange,
}) => {
  return (
    <Container>
      <Hint>
        Put a comment on anywhere in the studio, it won't be visible to the
        user.
      </Hint>
      <Label>Comment</Label>
      <Textarea
        placeholder="Write a comment here"
        maxCharacters={maxCharacters}
        autoFocus={!data.comment}
        value={data.comment}
        onChange={(e) => onChange({ ...data, comment: e.target.value })}
      />

      <Field label="Arrow position">
        <Select
          value={data.arrowPosition ?? '0'}
          onChange={(e) =>
            onChange({
              ...data,
              arrowPosition:
                e.target.value === '0' ? undefined : (e.target.value as never),
            })
          }
        >
          <option value="0">(none)</option>
          <option value="left">Left</option>
          <option value="right">Right</option>
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
        </Select>
      </Field>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
