import { StatBlockNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { StatBlockForm } from './StatBlockForm';
import { StatBlockNode } from './StatBlockNode';

const name = 'Card';

export const statBlockNode: NodeConfig<
  StudioNodeType.StatBlock,
  StatBlockNodeData
> = {
  type: StudioNodeType.StatBlock,
  name,
  icon: 'sheet',
  group: NodeGroup.Advanced,
  node: StatBlockNode,
  form: StatBlockForm,
  getNodeData: (data) => ({ title: name, description: data.name }),
  cloneNodeData: ({
    name,
    subtitle,
    description,
    sections,
    image,
    layout,
  }) => ({
    data: {
      id: toNodeId(),
      name,
      subtitle,
      description,
      layout,
      image: image ? { ...image } : undefined,
      sections: sections.map(({ fields, layout, title }) => ({
        fields: fields.map(({ name, value }) => ({ name, value })),
        layout,
        title,
      })),
    },
  }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.StatBlock,
    name: '',
    subtitle: '',
    description: '',
    sections: [],
  }),
};
