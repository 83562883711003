import {
  DataType,
  PropertyScope,
  ValueType,
  type NumberPropertyConfig,
  type ChangeValueNodeData,
} from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { getValue } from './utils';

const STRING_MAX_LENGTH = 255;

export const changeValue = async (
  node: GameNode<ChangeValueNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  if (!config.rpgConfig || !node.value) {
    return { messages: [], state };
  }

  if (node.changedValue.dataType === DataType.Number) {
    const returnedValue = await getValue(node.value, state, config, playerData);
    const inputValue = returnedValue.value as number;
    const isSet = node.action === 'set';
    const value: number = node.action === 'reduce' ? -inputValue : inputValue;

    if (node.changedValue.type === ValueType.Item) {
      const ref = node.changedValue.ref;
      const { newValue, delta } = await playerData.setItem(
        ref,
        node.action === 'set' ? { value } : { delta: value },
      );

      if (node.isSilent || delta === 0) {
        return { messages: [], state, output: { default: newValue } };
      }

      return {
        messages: [
          {
            nodeId: node.id,
            type: 'inventory-item-change',
            itemId: ref,
            delta,
          },
        ],
        haltExecution: true,
        state,
        output: { default: newValue },
      };
    } else if (node.changedValue.type === ValueType.Property) {
      const propertyRef = node.changedValue.ref;
      const property = config.getProperty(propertyRef);

      if (!property) {
        return { messages: [], state };
      }

      const propConfig = property.config as NumberPropertyConfig;
      const forceInt = (value: string | number) => {
        return typeof value === 'number' ? value : parseInt(value, 10);
      };
      const propertyValue = await config
        .getPropertyValue(state, propertyRef)
        .then(forceInt);

      let newValue: number = isSet ? value : value + propertyValue;

      const minValue = propConfig.minValue
        ? await getValue<number>(propConfig.minValue, state, config, playerData)
        : undefined;
      const maxValue = propConfig.maxValue
        ? await getValue<number>(propConfig.maxValue, state, config, playerData)
        : undefined;

      if (minValue && newValue < minValue.value) {
        newValue = minValue.value;
      }

      if (maxValue && newValue > maxValue.value) {
        newValue = maxValue.value;
      }

      if (property.scope === PropertyScope.Series) {
        await playerData.setAttribute(property.id, newValue);

        if (node.isSilent) {
          return { messages: [], state, output: { default: newValue } };
        }

        return {
          messages: [
            {
              nodeId: node.id,
              type: 'player-attribute',
              attribute: { name: property.name, description: '' },
              delta: newValue - propertyValue,
            },
          ],
          state,
          haltExecution: true,
          output: { default: newValue },
        };
      } else if (property.scope === PropertyScope.Episode) {
        return {
          messages: [],
          state: {
            ...state,
            properties: {
              ...state.properties,
              [node.changedValue.ref]: newValue,
            },
          },
          output: { default: newValue },
        };
      }
    }
  } else if (node.changedValue.dataType === DataType.String) {
    if (node.changedValue.type === ValueType.Property) {
      const propertyRef = node.changedValue.ref;
      const property = config.getProperty(propertyRef);

      if (!property) {
        return { messages: [], state };
      }

      const forceString = (value: string | number): string => {
        return typeof value === 'number' ? String(value) : value;
      };
      const returnedValue = await getValue<string>(
        node.value,
        state,
        config,
        playerData,
      );
      let value = forceString(returnedValue.value);

      if (value.length > STRING_MAX_LENGTH) {
        value = value.slice(0, STRING_MAX_LENGTH);
      }

      if (property.scope === PropertyScope.Series) {
        await playerData.setAttribute(property.id, value);

        return { messages: [], state, output: { default: value } };
      } else if (property.scope === PropertyScope.Episode) {
        return {
          messages: [],
          state: {
            ...state,
            properties: {
              ...state.properties,
              [node.changedValue.ref]: value,
            },
          },
          output: { default: 0 }, // we don't support output with string
        };
      }
    }
  } else if (node.changedValue.dataType === DataType.Enum) {
    if (node.changedValue.type === ValueType.Property) {
      const propertyRef = node.changedValue.ref;
      const property = config.getProperty(propertyRef);

      if (!property) {
        return { messages: [], state };
      }

      const returnedValue = await getValue<string>(
        node.value,
        state,
        config,
        playerData,
      );

      if (returnedValue.dataType !== DataType.Enum) {
        return { messages: [], state };
      }

      const value = returnedValue.value;

      if (property.scope === PropertyScope.Series) {
        await playerData.setAttribute(property.id, value);

        return { messages: [], state, output: { default: value } };
      } else if (property.scope === PropertyScope.Episode) {
        return {
          messages: [],
          state: {
            ...state,
            properties: {
              ...state.properties,
              [node.changedValue.ref]: value,
            },
          },
          output: { default: 0 }, // we don't support output with string
        };
      }
    }
  }

  return { messages: [], state };
};
