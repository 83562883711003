import { Button, ButtonProps } from '@chakra-ui/react';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, {
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

type MenuDropdownButtonProps = ButtonProps & {
  isInvalid?: boolean;
};

export const MenuDropdownButton: React.FC<MenuDropdownButtonProps> = ({
  children,
  isInvalid,
  ...buttonProps
}) => {
  return (
    <MenuButton>
      <Button
        variant={isInvalid ? 'invalidInput' : 'input'}
        height={dimensions.size48}
        width={'100%'}
        justifyContent={'start'}
        rightIcon={<Icon name="chevron-down" size={16} />}
        margin={dimensions.size0}
        padding={`${dimensions.size16} ${dimensions.size12}`}
        display="flex"
        flexFlow={'row'}
        alignItems={'center'}
        gap={dimensions.size16}
        iconSpacing={dimensions.size0}
        {...(buttonProps ?? {})}
      >
        <InputButtonContainer>{children}</InputButtonContainer>
      </Button>
    </MenuButton>
  );
};

const InputButtonContainer = styled.div`
  width: calc(100% - ${dimensions.size28});
  text-align: left;
`;

export const MenuButton: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="menu-button">{children}</div>;
};

const getRelativePosition = (child: HTMLDivElement) => {
  let parent: HTMLElement | null = child.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);

    if (
      !parent.className.includes('menu-container') &&
      ['fixed', 'absolute', 'relative'].includes(style.position)
    ) {
      break;
    }

    parent = parent.parentElement;
  }

  const childRect = child.getBoundingClientRect();
  const parentRect = parent?.getBoundingClientRect();

  return {
    top: childRect.top - (parentRect?.top ?? 0),
    right: childRect.right - (parentRect?.left ?? 0),
    bottom: childRect.bottom - (parentRect?.top ?? 0),
    left: childRect.left - (parentRect?.left ?? 0),
    width: childRect.width,
    height: childRect.height,
    parentWidth: parentRect?.width ?? window.innerWidth,
    parentHeight: parentRect?.height ?? window.innerHeight,
  };
};

type MenuProps = PropsWithChildren<{
  className?: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}>;

export const Menu: React.FC<MenuProps> = (props) => {
  const { children, className, onOpen, onClose } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
      onClose?.();
    } else {
      setIsOpen(true);
      onOpen?.();
    }
  };

  const close = () => {
    setIsOpen(false);
    onClose?.();
  };

  useEffect(() => {
    props.isOpen ? setIsOpen(true) : setIsOpen(false);
  }, [props.isOpen]);

  useEffect(() => {
    const result = ref.current?.querySelector('.menu-button');

    const onClickListener = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      const menuListContainer: HTMLDivElement | null | undefined =
        ref.current?.querySelector('.menu-list-container');

      if (menuListContainer) {
        menuListContainer.style.display = 'flex';
        menuListContainer.style.width = result?.clientWidth + 'px';

        const { left, bottom, parentHeight, parentWidth } = getRelativePosition(
          ref.current!,
        );
        const { height, width } = menuListContainer.getBoundingClientRect();

        if (left + width > parentWidth) {
          menuListContainer.style.right = dimensions.size0;
          menuListContainer.style.left = 'auto';
          menuListContainer.style.top = 'auto';

          if (left - width < 0) {
            menuListContainer.style.right = `${left - width}px`;
          }
        }

        if (bottom + height > parentHeight) {
          menuListContainer.style.marginTop = `-${dimensions.size8}`;
          menuListContainer.style.top = `-${height}px`;
        } else {
          menuListContainer.style.marginTop = dimensions.size8;
        }
      }
    };

    result?.addEventListener('mouseup', onClickListener as never);

    return () =>
      result?.removeEventListener('mouseup', onClickListener as never);
  }, []);

  return (
    <>
      {isOpen && <Overlay onClick={close} />}
      <MenuContainer
        onClick={toggle}
        className={`${className} menu-container ${isOpen ? 'open' : ''}`}
        ref={ref}
      >
        {children}
      </MenuContainer>
    </>
  );
};

export const MenuList: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MenuListContainer className="menu-list-container">
      {children}
    </MenuListContainer>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  width: 100%;
  height: 100%;
  z-index: 19;
`;

const MenuListContainer = styled.div`
  ${textStyles.label.lb16sb}
  position: absolute;
  z-index: 20;
  display: none;
  flex-direction: column;
  min-width: ${dimensions.size320};
  max-height: ${dimensions.size320};
  padding: ${dimensions.size8} ${dimensions.size0};
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.text.header};
  visibility: hidden;
  background: #000;
  border-radius: ${dimensions.size8};
  opacity: 0;
  transition:
    opacity 0.2s,
    margin-top 0.2s,
    top 0.2s,
    right 0.2s,
    left 0.2s;
`;

const MenuContainer = styled.div`
  position: relative;

  &.open ${MenuListContainer} {
    visibility: visible;
    opacity: 1;
  }
`;

export const MenuGroup: React.FC<PropsWithChildren> = ({ children }) => {
  return <MenuGroupContainer>{children}</MenuGroupContainer>;
};

const MenuGroupContainer = styled.div`
  ${textStyles.body.b12sb}
  position: -webkit-sticky;
  position: sticky;
  top: -${dimensions.size8};
  padding: ${dimensions.size8} ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.body};
  background: black;
`;

type MenuItemProps = PropsWithChildren<{
  ariaLabel?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  disableHover?: boolean;
  active?: boolean;
}>;

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onClick,
  className,
  disableHover,
  ariaLabel,
  active,
}) => {
  return (
    <MenuItemContainer
      aria-label={ariaLabel}
      $disableHover={disableHover}
      $isActive={active}
      className={className}
      onClick={onClick}
    >
      {children}
    </MenuItemContainer>
  );
};

type IconMenuItemProps = {
  ariaLabel?: string;
  icon: string;
  name: string;
  onClick?: () => void;
  className?: string;
};

export const IconMenuItem: React.FC<IconMenuItemProps> = (props) => {
  return (
    <MenuItem
      onClick={props.onClick}
      className={props.className}
      ariaLabel={props.ariaLabel}
    >
      <Icon name={props.icon} size={rawDimensions.size16} />
      {props.name}
    </MenuItem>
  );
};

const MenuItemContainer = styled.div<{
  $disableHover?: boolean;
  $isActive?: boolean;
}>`
  display: flex;
  gap: ${dimensions.size12};
  align-items: center;
  padding: ${dimensions.size12} ${dimensions.size16};
  cursor: pointer;

  ${({ $isActive }) => $isActive && `background: #111;`}

  &:hover {
    ${({ $disableHover }) => !$disableHover && `background: #111;`}
  }
`;
