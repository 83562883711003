import { useGetUserId } from '@maestro/components';
import { toSupabaseClient } from '@maestro/supabase';
import { useCallback, useEffect, useState } from 'react';

export type Item = {
  id: string;
  name: string;
  category: string;
  imagePath: string;
  description: string;
  series: { id: string; title: string };
  fromAuthor: boolean;
};

export type ItemRow = {
  id: string;
  name: string;
  category: string;
  image_path?: string;
  description: string;
  series: { id: string; title: string };
  created_by_id: string;
};

type Args = {
  seriesId?: string;
  userCreatedOnly?: boolean;
  pauseFetch?: boolean;
};

export const useItems = (args: Args) => {
  const userId = useGetUserId();
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchItems = useCallback(async () => {
    if (!userId || !args.seriesId) return;

    try {
      let query = toSupabaseClient()
        .from('item')
        .select('*, series(id, title)')
        .eq('series_id', args.seriesId)
        .order('name', { ascending: true });

      if (args?.userCreatedOnly) {
        query = query.eq('created_by_id', userId);
      }

      const { data, error } = await query.returns<ItemRow[]>();

      if (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching items', JSON.stringify(error, null, 2));
      }

      setItems(
        (data ?? []).map((row) => ({
          id: row.id,
          name: row.name,
          category: row.category,
          description: row.description,
          imagePath: row.image_path ?? '',
          series: { id: row.series?.id ?? '', title: row.series?.title ?? '' },
          fromAuthor: row.created_by_id === userId,
        })),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching items', error);
    } finally {
      setIsLoading(false);
    }
  }, [args?.userCreatedOnly, args?.seriesId, userId]);

  useEffect(() => {
    if (!args?.pauseFetch) {
      fetchItems().catch(() => void 0);
    }
  }, [fetchItems, args?.pauseFetch]);

  return {
    items,
    isLoading,
    refetch: fetchItems,
  };
};
