import { Button } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfig,
  RpgConfigHudElement,
  RpgConfigProperty,
  ValueType,
  hudIcons,
} from '@common/studio-types';
import { migrateRpgConfig } from '@common/studio-types/migration';
import {
  RadioButtons,
  Section,
  SectionCard,
  SectionTitle,
  useMaestroToast,
} from '@maestro/components';
import { AddPropertyButton, PropertyForm, useItems } from '@maestro/studio';
import { dimensions, textStyles } from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import { useOne } from '@refinedev/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { HudElementInput } from './HudElementInput';

export const SeriesRpgConfiguration: React.FC = () => {
  const params = useParams();
  const { data: series } = useOne({
    resource: 'series',
    id: params.id,
    meta: {
      select: '*, genre(title)',
    },
  });

  const [currentTab, setCurrentTab] = useState('seriesProperties');
  const [properties, setProperties] = useState<RpgConfigProperty[]>(
    series?.data.rpg_config.properties ?? [],
  );
  const [hudElements, setHudElements] = useState<RpgConfigHudElement[]>(
    series?.data.rpg_config.hud ?? [],
  );
  const { items, isLoading, refetch } = useItems({
    seriesId: series?.data.id?.toString() ?? '',
  });

  const seriesRpgConfig = useMemo(
    () =>
      series?.data?.rpg_config
        ? migrateRpgConfig(series.data.rpg_config)
        : undefined,
    [series],
  );

  useEffect(() => {
    if (seriesRpgConfig) {
      setProperties(seriesRpgConfig.properties);
      setHudElements(seriesRpgConfig.hud);
    }
  }, [seriesRpgConfig]);

  const onCreateProperty = (property: RpgConfigProperty) => {
    setProperties((prev) => [...prev, property]);
  };

  const onPropertyChange = (data: RpgConfigProperty) => {
    setProperties((prev) => prev.map((p) => (p.id === data.id ? data : p)));
  };
  const onRemoveProperty = (data: RpgConfigProperty) => {
    setProperties((prev) => prev.filter((p) => p.id !== data.id));
  };

  const localRpgConfig: RpgConfig = useMemo(
    () => ({
      version: 2,
      customItems: [],
      hud: hudElements,
      properties,
    }),
    [hudElements, properties],
  );

  const onCreateHudElement = () => {
    setHudElements((prev) => [
      ...prev,
      {
        id: String(Date.now()),
        label: properties[0]?.name,
        value: {
          dataType: DataType.Number,
          type: ValueType.Property,
          ref: properties[0]?.name,
        },
        icon: hudIcons[0].ref,
      },
    ]);
  };

  const onHudElementChange = (newHudElement: RpgConfigHudElement) => {
    setHudElements((prev) =>
      prev.map((item) => (item.id === newHudElement.id ? newHudElement : item)),
    );
  };

  const onRemoveHudElement = (hudElement: RpgConfigHudElement) => {
    setHudElements((prev) => prev.filter((item) => item.id !== hudElement.id));
  };

  const toast = useMaestroToast();

  // TODO: we need form validation here
  const onSave = async () => {
    if (series && seriesRpgConfig) {
      const rpgConfig: RpgConfig = {
        version: 2,
        customItems: seriesRpgConfig?.customItems,
        hud: hudElements,
        properties: properties,
      };

      await toSupabaseClient()
        .from('series')
        .update({ rpg_config: rpgConfig })
        .eq('id', series.data.id);

      toast({
        status: 'success',
        title: 'Configuration saved',
      });
    }
  };

  const tabs = [
    { label: 'Series Properties', value: 'seriesProperties' },
    { label: 'Episode Properties', value: 'episodeProperties' },
    { label: 'HUD', value: 'hud' },
  ];

  return (
    <Section>
      <StyledSectionTitle>
        RPG Configuration
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </StyledSectionTitle>
      <SectionCard>
        <Container>
          <RadioButtons
            value={currentTab}
            onChange={setCurrentTab}
            options={tabs}
          />
          {currentTab === 'seriesProperties' && (
            <SeriesForm>
              <FormTitle>
                Series Properties
                <AddPropertyButton
                  scope={PropertyScope.Series}
                  onAddProperty={onCreateProperty}
                >
                  <Button variant="ghost" size="sm">
                    Add Property
                  </Button>
                </AddPropertyButton>
              </FormTitle>
              <Hint>
                Series Properties are stats that stay with a player across all
                episodes in a series. For example, character attributes like
                “Stamina” or “Strength” could be series level properties that
                you want to stay with a player across all episodes in a series.
              </Hint>
              <FormItems>
                {properties
                  .filter(({ scope }) => scope === PropertyScope.Series)
                  .map((attribute) => (
                    <PropertyForm
                      rpgConfig={localRpgConfig}
                      key={attribute.id}
                      data={attribute}
                      onChange={onPropertyChange}
                      onRemove={() => onRemoveProperty(attribute)}
                    />
                  ))}
              </FormItems>
            </SeriesForm>
          )}
          {currentTab === 'episodeProperties' && (
            <SeriesForm>
              <FormTitle>
                Episode Properties
                <AddPropertyButton
                  scope={PropertyScope.Episode}
                  onAddProperty={onCreateProperty}
                >
                  <Button variant="ghost" size="sm">
                    Add Property
                  </Button>
                </AddPropertyButton>
              </FormTitle>
              <Hint>
                Episode Properties are stats that reset for the player at every
                episode. When you want a property to reset to its default value
                each time a player finishes an episode, then use episode level
                properties.
              </Hint>
              <FormItems>
                {properties
                  .filter(({ scope }) => scope === PropertyScope.Episode)
                  .map((property) => (
                    <PropertyForm
                      rpgConfig={localRpgConfig}
                      key={property.id}
                      data={property}
                      onChange={onPropertyChange}
                      onRemove={() => onRemoveProperty(property)}
                    />
                  ))}
              </FormItems>
            </SeriesForm>
          )}
          {currentTab === 'hud' && (
            <SeriesForm>
              <FormTitle>
                HUD
                {hudElements.length < 10 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={onCreateHudElement}
                  >
                    Add HUD Element
                  </Button>
                )}
              </FormTitle>
              <Hint>
                These are stats (max 10) that will be visible to the player in
                an episode's player menu. It can be used to display attributes
                like 'health', or quantity of items like 'gold' that are
                relevant to the episode.
              </Hint>
              <FormItems>
                {hudElements.map((hudElement) => (
                  <HudElementInput
                    onItemCreated={refetch}
                    key={hudElement.id}
                    isLoading={isLoading}
                    items={items}
                    properties={properties}
                    hudElement={hudElement}
                    onChange={onHudElementChange}
                    onRemove={() => onRemoveHudElement(hudElement)}
                  />
                ))}
              </FormItems>
            </SeriesForm>
          )}
        </Container>
      </SectionCard>
    </Section>
  );
};

const StyledSectionTitle = styled(SectionTitle)`
  margin-top: ${dimensions.size0};
`;

const SeriesForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
`;

const FormItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const FormTitle = styled.div`
  ${textStyles.title.t20b}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.header};
`;

export const Hint = styled.div<{ $withoutMarginBottom?: boolean }>`
  ${textStyles.body.b14m}
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size8};
  margin-bottom: ${({ $withoutMarginBottom }) =>
    !$withoutMarginBottom ? dimensions.size16 : dimensions.size0};
  color: ${({ theme }) => theme.colors.text.body};
`;
