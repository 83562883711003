import { RpgConfig } from '@common/studio-types';
import { create } from 'zustand';

type DeleteNodeHandler = (id: string) => void;
type DeleteEdgeHandler = (id: string) => void;
type SelectNodeHandler = (id: string | undefined) => void;

export type SaveStatus =
  | 'saved'
  | 'saving'
  | 'offline'
  | 'readonly'
  | undefined;

type StudioFlowStore = {
  seriesTitle?: string;
  seriesId?: string;
  setSeriesId: (seriesId: string | undefined) => void;
  setSeriesTitle: (seriesTitle: string | undefined) => void;

  rpgConfig: RpgConfig;
  setRpgConfig: (rpgConfig: RpgConfig) => void;

  episodeRef?: string;
  episodeName?: string;
  episodeCoverUrl?: string;
  saveStatus?: SaveStatus;

  setEpisodeRef: (episodeRef: string) => void;
  setEpisodeName: (episodeName: string) => void;
  setEpisodeCoverUrl: (episodeCoverUrl: string) => void;
  setSaveStatus: (saveStatus: SaveStatus) => void;

  selectedNode?: string;
  deleteNodeHandler?: DeleteNodeHandler;
  deleteEdgeHandler?: DeleteEdgeHandler;
  selectNodeHandler?: SelectNodeHandler;
  selectNode: SelectNodeHandler;
  setSelectNodeHandler: (handler: SelectNodeHandler | undefined) => void;
  setDeleteNodeHandler: (handler: DeleteNodeHandler | undefined) => void;
  setDeleteEdgeHandler: (handler: DeleteEdgeHandler | undefined) => void;
  deleteNode: DeleteNodeHandler;
  deleteEdge: DeleteEdgeHandler;
};

export const useStudioFlowStore = create<StudioFlowStore>()((set, get) => ({
  seriesTitle: undefined,
  seriesId: undefined,
  setSeriesId: (seriesId) => set((state) => ({ ...state, seriesId })),
  setSeriesTitle: (seriesTitle) => set((state) => ({ ...state, seriesTitle })),

  rpgConfig: undefined as never,
  setRpgConfig: (rpgConfig) => set((state) => ({ ...state, rpgConfig })),

  episodeRef: undefined,
  episodeName: undefined,
  episodeCoverUrl: undefined,
  saveStatus: undefined,

  setEpisodeRef: (episodeRef) => set((state) => ({ ...state, episodeRef })),
  setEpisodeName: (episodeName) => set((state) => ({ ...state, episodeName })),
  setEpisodeCoverUrl: (episodeCoverUrl) =>
    set((state) => ({ ...state, episodeCoverUrl })),
  setSaveStatus: (saveStatus) => set((state) => ({ ...state, saveStatus })),

  selectedNode: undefined,
  deleteNodeHandler: undefined,
  deleteEdgeHandler: undefined,
  selectNodeHandler: (selectedNode) =>
    set((state) => ({ ...state, selectedNode })),
  setSelectNodeHandler: (selectNodeHandler) =>
    set((state) => ({ ...state, selectNodeHandler })),
  setDeleteEdgeHandler: (deleteEdgeHandler) =>
    set((state) => ({ ...state, deleteEdgeHandler })),
  deleteEdge: (id) => get().deleteEdgeHandler?.(id),
  selectNode: (id) => get().selectNodeHandler?.(id),
  deleteNode: (id) => get().deleteNodeHandler?.(id),
  setDeleteNodeHandler: (deleteNodeHandler) =>
    set((state) => ({ ...state, deleteNodeHandler })),
}));

export const useCanDelete = () => {
  const handler = useStudioFlowStore((state) => state.deleteEdgeHandler);

  return !!handler;
};

export const useCanEdit = () => {
  const handler = useStudioFlowStore((state) => state.selectNodeHandler);

  return !!handler;
};
