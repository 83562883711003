import { Accordion } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { Drawer } from '../Drawer';
import { SuggestionsSection } from './suggestion.types';
import { SuggestionSection } from './SuggestionSection';

type Props = {
  isOpen: boolean;
  onCancel: VoidFunction;
  suggestions: SuggestionsSection[];
  withExpandedSuggestions?: boolean;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Drawer isOpen={props.isOpen} onCancel={props.onCancel} title="Suggestions">
      {props.suggestions.length === 0 ? (
        <EmptyContainer>
          <EmptyContainerText>
            Wow! You did it. No more suggestions. Time to publish your story
          </EmptyContainerText>
        </EmptyContainer>
      ) : (
        <Accordion
          variant="suggestions"
          allowMultiple
          defaultIndex={
            props?.withExpandedSuggestions && props.suggestions.length > 0
              ? props.suggestions.map((_, i) => i)
              : undefined
          }
        >
          {props.suggestions.map((section) => (
            <SuggestionSection key={section.title} section={section} />
          ))}
        </Accordion>
      )}
    </Drawer>
  );
};

export const SuggestionsDrawer = memo(Component);

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const EmptyContainerText = styled.div`
  ${textStyles.body.b14m}
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;
