import { Cms } from '@maestro/cms';
import { BrowserRouter } from 'react-router-dom';

export const RouterProvider = () => {
  return (
    <BrowserRouter>
      <Cms />
    </BrowserRouter>
  );
};
