import type { StudioNodeData } from '@common/studio-types';
import React from 'react';
import { nodeConfigs } from '../nodes';
import { StudioNodeFormComponent } from '../nodes/Node.types';

type Props = {
  studioNodeData: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
};

export const NodeForm: React.FC<Props> = ({
  studioNodeData,
  onStudioNodeDataChange,
}) => {
  const FormComponent: StudioNodeFormComponent<StudioNodeData> | null =
    studioNodeData?.type ? nodeConfigs[studioNodeData.type]?.form : null;

  if (!FormComponent) {
    return null;
  }

  return (
    <FormComponent data={studioNodeData} onChange={onStudioNodeDataChange} />
  );
};
