import {
  type Dice,
  type DiceRollNodeData,
  DiceRollStrategy,
  DiceRollModifierAction,
} from '@common/studio-types';
import { max, min, random } from 'lodash';
import { getValue } from '../actions/utils';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type {
  DiceRollResult,
  DiceRollResultMessage,
} from '../game/messages.types';
import type { PlayerData } from '../game/player';

const diceSizes: Record<Dice, number> = {
  d4: 4,
  d6: 6,
  d8: 8,
  d10: 10,
  d12: 12,
  d20: 20,
  d100: 100,
};

export const getDiceSize = (dice: Dice): number => diceSizes[dice] ?? 1;

export const rollDices = (dices: Dice[]): DiceRollResult[] => {
  return dices.map((dice) => ({
    dice: dice,
    result: random(1, getDiceSize(dice), false),
  }));
};

export const getModifier = async (
  data: DiceRollNodeData,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<number> => {
  let acc = 0;

  for (const modifier of data.modifiers) {
    const { value } = await getValue<number>(
      modifier.value,
      state,
      config,
      playerData,
    );

    if (modifier.action === DiceRollModifierAction.Add) {
      acc += value;
    } else {
      acc -= value;
    }
  }

  return acc;
};

export const getResult = async (
  diceRollResult: DiceRollResult[],
  data: DiceRollNodeData,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<number> => {
  const modifier = await getModifier(data, state, config, playerData);

  if (data.strategy.type === DiceRollStrategy.Highest) {
    return modifier + max(diceRollResult.map((result) => result.result))!;
  } else if (data.strategy.type === DiceRollStrategy.Lowest) {
    return modifier + min(diceRollResult.map((result) => result.result))!;
  } else if (data.strategy.type === DiceRollStrategy.Sum) {
    return diceRollResult.reduce(
      (acc, result) => acc + result.result,
      modifier,
    );
  } else if (data.strategy.type === DiceRollStrategy.ClassDifficulty) {
    const sum = diceRollResult.reduce(
      (acc, result) => acc + result.result,
      modifier,
    );

    return sum >= data.strategy.classDifficulty ? 1 : 0;
  }

  return 0;
};

export const createDiceRollResultMessage = (
  node: DiceRollNodeData,
  diceRollResult: DiceRollResult[],
  modifier: number,
): DiceRollResultMessage => {
  return {
    nodeId: node.id,
    type: 'dice-roll-result',
    id: node.id,
    modifier,
    classDifficulty:
      node.strategy.type === DiceRollStrategy.ClassDifficulty
        ? node.strategy.classDifficulty
        : undefined,
    result: diceRollResult,
  };
};
