import { CloseButton } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ImageModal } from '../ImageModal';

type Props = {
  imageUrl: string;
  onClear: () => void;
  className?: string;
  aspectRatio?: {
    width: string;
    height: string;
  };
};

export const UploadedImage: React.FC<Props> = (props) => {
  const { imageUrl, onClear, className } = props;
  const theme = useTheme();

  return (
    <Container className={className}>
      <ImageContainer>
        <StyledCloseButton
          background={theme.colors.base.dark[200]}
          _hover={{ background: theme.colors.base.dark[400] }}
          borderRadius={9999}
          onClick={onClear}
        />
        <ImageModal
          aspectRatio={props.aspectRatio}
          src={imageUrl}
          alt="Uploaded image"
        />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${dimensions.size4};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.base.light['100']};
  border-radius: ${dimensions.size8};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${dimensions.size8};
  right: ${dimensions.size8};
`;
