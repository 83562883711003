import { dimensions, rawDimensions } from '@maestro/styles';
import styled from 'styled-components';

export const CompactIcon = () => (
  <Container>
    <TopContainer>
      <Image />
      <TitleContainer>
        <Title />
        <Subtitle />
      </TitleContainer>
    </TopContainer>
    <BottomContainer>
      <StatsContainer>
        <Stat />
        <Stat />
        <Stat />
      </StatsContainer>
      <Description />
    </BottomContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rawDimensions.size80 + rawDimensions.size12}px;
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  padding: ${dimensions.size4};
`;

const Image = styled.div`
  width: ${dimensions.size24};
  height: ${dimensions.size24};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Title = styled.div`
  width: ${dimensions.size24};
  height: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const Subtitle = styled.div`
  width: 100%;
  height: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  padding: ${dimensions.size4};
  border-top: 1px solid ${({ theme }) => theme.colors.background.shade};
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  justify-content: center;
  margin: ${dimensions.size4} ${dimensions.size0};
`;

const Stat = styled.div`
  width: ${dimensions.size16};
  height: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const Description = styled.div`
  width: 100%;
  height: ${dimensions.size12};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;
