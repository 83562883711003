import { keyframes } from 'styled-components';

export const messageAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const choicesAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  50% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const hudDeltaAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-15px);
  }
`;

export const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
    display: flex;
  }
  to {
    transform: translateX(0);
    display: flex;
  }
`;

export const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
    display: flex;
  }
  to {
    transform: translateX(100%);
    display: none;
  }
`;

export const slideInLeftToRightAnimation = keyframes`
  from {
    transform: translateX(-100%);
    display: flex;
  }
  to {
    transform: translateX(0);
    display: flex;
  }
`;

export const slideOutLeftToRightAnimation = keyframes`
  from {
    transform: translateX(0);
    display: flex;
  }
  to {
    transform: translateX(-100%);
    display: none;
  }
`;
