import {
  EndEpisodeAction,
  ObjectiveCompleteNodeData,
} from '@common/studio-types';
import type { GameState } from '../game';
import type { Message } from '../game/messages.types';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

/*
 * Some episodes may have Complete Episode nodes
 * So let's treat it as EndEpisode node
 */
export const completeObjective = (
  node: GameNode<ObjectiveCompleteNodeData>,
  state: GameState,
): ActionResult => {
  const messages: Message[] = [
    {
      nodeId: node.id,
      type: 'end-episode',
      message: node.objective,
      action: EndEpisodeAction.PlayNextEpisode,
    },
  ];

  return { messages, state };
};
