import { dimensions, textStyles } from '@maestro/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  chat: string;
};

type ChatElement = {
  role: string;
  content: string;
};

export const GeneratedContainer: React.FC<Props> = ({ chat }) => {
  const parsed = useMemo(() => {
    try {
      return JSON.parse(chat) as ChatElement[];
    } catch (e) {
      return [{ role: 'user', content: chat }];
    }
  }, [chat]);

  return (
    <Container>
      <ContainerTitle>OpenAI Instructions</ContainerTitle>
      {parsed.map((item, index) => (
        <ChatItem key={index} role={item.role}>
          <ChatItemRole>{item.role}</ChatItemRole>
          {item.content}
        </ChatItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size16};
  border-radius: ${dimensions.size8};
  background-color: ${({ theme }) => theme.colors.background.shade};
  max-height: 100%;
  overflow-y: auto;
`;

const ContainerTitle = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const ChatItem = styled.div<{ role: string }>`
  ${textStyles.body.b12m}
  font-family: monospace;
  padding: ${dimensions.size8} ${dimensions.size16};
  border-radius: ${dimensions.size8};
  margin-left: ${({ role }) =>
    role === 'assistant' ? dimensions.size36 : dimensions.size0};
  margin-right: ${({ role }) =>
    role === 'user' ? dimensions.size36 : dimensions.size0};
  background-color: ${({ theme }) => theme.colors.background.shade};
  word-wrap: break-word;
  white-space: pre-line;
`;

const ChatItemRole = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
  margin-bottom: ${dimensions.size4};
  text-transform: capitalize;
`;
