import {
  CloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  src: string;
  alt: string;
  aspectRatio?: {
    width: string;
    height: string;
  };
};

export const ImageModal: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay>
          <ModalContent
            background="0"
            border="0"
            boxShadow="0"
            onClick={onClose}
          >
            <Container>
              <ImageContainer>
                <StyledCloseButton onClick={onClose} />
                <Image
                  src={toOptimizedImageUri(props.src, {
                    dpr: window.devicePixelRatio,
                  })}
                  alt={props.alt}
                />
              </ImageContainer>
            </Container>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      {!props?.aspectRatio && (
        <Image
          src={toOptimizedImageUri(props.src, {
            dpr: window.devicePixelRatio,
          })}
          alt={props.alt}
          onClick={onOpen}
        />
      )}
      {!!props?.aspectRatio && (
        <FixedImage
          $imageUrl={
            toOptimizedImageUri(props.src, {
              dpr: window.devicePixelRatio,
            }) ?? ''
          }
          $size={props.aspectRatio}
          onClick={onOpen}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${dimensions.size16};
  right: ${dimensions.size16};
`;

const FixedImage = styled.div<{
  $imageUrl: string;
  $size: { width: string; height: string };
}>`
  width: ${({ $size }) => $size.width};
  height: ${({ $size }) => $size.height};
  background: url(${({ $imageUrl }) => $imageUrl}) no-repeat center center;
  background-size: cover;
  border-radius: ${dimensions.size8};
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  max-height: 85vh;
  border-radius: ${dimensions.size8};
`;
