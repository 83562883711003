import {
  ConditionOperator,
  type ConditionCheckNodeData,
} from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { getValue } from './utils';

export const conditionCheck = async (
  node: GameNode<ConditionCheckNodeData>,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ActionResult> => {
  const { operator } = node;

  const valueA = await getValue(node.valueA, state, config, playerData);
  const valueB = await getValue(node.valueB, state, config, playerData);

  const isTrue = () => {
    switch (operator) {
      case ConditionOperator.NotEqual:
        return valueA.value !== valueB.value;
      case ConditionOperator.Equal:
        return valueA.value === valueB.value;
      case ConditionOperator.GreaterThan:
        return valueA.value > valueB.value;
      case ConditionOperator.LessThan:
        return valueA.value < valueB.value;
      case ConditionOperator.GreaterThanOrEqual:
        return valueA.value >= valueB.value;
      case ConditionOperator.LessThanOrEqual:
        return valueA.value <= valueB.value;
      default:
        return false;
    }
  };

  return {
    messages: [],
    state,
    nextNodeId: isTrue() ? node.trueNodeId : node.falseNodeId,
  };
};
