import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D100: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  const mainColor = alterHslColor(`hsl(223, 70%, 32%)`, multipliers);

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M48 11L68.0037 16.8736L81.6564 32.6296L84.6234 53.2656L75.9627 72.2298L58.4241 83.5012H37.5759L20.0373 72.2298L11.3766 53.2656L14.3436 32.6296L27.9963 16.8736L48 11Z"
        fill={mainColor}
      />
    </svg>
  );
};
