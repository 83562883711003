import {
  Button,
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from '@chakra-ui/react';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

type Props = Omit<NumberInputProps, 'onChange'> & {
  placeholder?: string;
  onChange: (value: number) => void;
};

export const NumericInput: React.FC<Props> = (props) => {
  const { placeholder, onChange, min, ...numberInputProps } = props;
  const minResult = min ?? 0;
  const value = (props.value ?? 0) as number;
  const decrement = () => {
    if (value > minResult) onChange(value - 1);
  };
  const [internalValue, setInternalValue] = useState<number | string>(value);

  useEffect(() => setInternalValue(props.value as number), [props.value]);

  return (
    <NumberInput
      min={minResult}
      onChange={(stringValue, valueAsNumber) => {
        if (!Number.isNaN(valueAsNumber)) {
          onChange(valueAsNumber);
        } else {
          setInternalValue(stringValue);
        }
      }}
      {...numberInputProps}
      value={internalValue}
    >
      <Container>
        <NumberInputField
          placeholder={placeholder}
          paddingLeft={dimensions.size44}
          paddingRight={dimensions.size44}
          textAlign="center"
        />
        <Button
          variant="stepper"
          position="absolute"
          left={dimensions.size8}
          top={dimensions.size8}
          zIndex={12}
          onClick={decrement}
        >
          <Icon name="minus" size={rawDimensions.size20} />
        </Button>
        <Button
          variant="stepper"
          position="absolute"
          right={dimensions.size8}
          top={dimensions.size8}
          zIndex={12}
          onClick={() => onChange(value + 1)}
        >
          <Icon name="plus" size={rawDimensions.size20} />
        </Button>
      </Container>
    </NumberInput>
  );
};

const Container = styled.div`
  position: relative;
`;
