import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { env } from '@maestro/env';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { QRCodeSVG } from 'qrcode.react';
import { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { Icon } from '../Icon';
import { MenuItem } from '../menu/Menu';
import { ModalCloseButton } from '../ModalCloseButton';

type Props = {
  episodeId: string;
};

export const PlayOnAppMenuItem: FC<Props> = ({ episodeId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openPlayOnAppModal = () => onOpen();
  const { copyToClipboard } = useCopyToClipboard();

  const qrCodeLink = () => {
    if (env.VITE_SHARE_QR_CODE_LINK?.match(/http/)) {
      return `${env.VITE_SHARE_QR_CODE_LINK}?draft=${episodeId}`;
    }

    return `${env.VITE_SHARE_QR_CODE_LINK}/${episodeId}`;
  };

  const copyLink = () => {
    if (env.VITE_SHARE_DRAFT_APP_LINK?.match(/http/)) {
      return `${env.VITE_SHARE_DRAFT_APP_LINK}?draft=${episodeId}`;
    }

    return `${env.VITE_SHARE_DRAFT_APP_LINK}/${episodeId}`;
  };

  const onCopyLinkToClipboardClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const linkToCopy = copyLink();

    copyToClipboard(linkToCopy, {
      onSuccessMessage: 'Copied link to the clipboard',
      onSuccessDescription:
        'Anyone with this link will have access to this episode on Avenue',
    });
  };

  return (
    <>
      <MenuItem onClick={openPlayOnAppModal}>
        <Icon name="phone" size={rawDimensions.size20} />
        Preview on app
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" variant={'fromCta'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <span>Preview on app</span>
            <ModalCloseButton onClose={onClose} />
          </ModalHeader>
          <ModalBody>
            <Container>
              <QrCodeContainer>
                <QRCodeSVG value={qrCodeLink()} />
              </QrCodeContainer>
              <TextContainer>
                <Title>Scan to open in Avenue app</Title>
                <Instructions>
                  Use the QR code to see your draft on the Avenue app, or copy
                  the link to share your draft with a friend.
                </Instructions>
              </TextContainer>
              <Button
                variant={'primary'}
                leftIcon={<Icon name="copy" size={rawDimensions.size16} />}
                onClick={onCopyLinkToClipboardClick}
              >
                Copy link to episode
              </Button>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size24};
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  align-items: center;
`;

const Title = styled.h3`
  ${textStyles.title.t24b};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const Instructions = styled.p`
  ${textStyles.body.b16m};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;

const QrCodeContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: ${dimensions.size24};
  background-color: #000;
  border-radius: ${dimensions.size8};
`;
