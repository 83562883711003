import { CommentNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { CommentForm } from './CommentForm';
import { CommentNode } from './CommentNode';

const name = 'Comment';

export const commentNode: NodeConfig<StudioNodeType.Comment, CommentNodeData> =
  {
    type: StudioNodeType.Comment,
    name,
    icon: 'comment',
    node: CommentNode,
    form: CommentForm,
    sourceHandles: () => [],
    getNodeData: (data) => ({ title: name, description: data.comment }),
    cloneNodeData: ({ arrowPosition, comment }) => ({
      data: {
        id: toNodeId(),
        arrowPosition,
        comment,
      },
    }),
    createNodeData: () => ({
      id: toNodeId(),
      type: StudioNodeType.Comment,
      comment: '',
    }),
  };
