import { Loader } from '@maestro/components';
import { AblyProvider } from 'ably/react';
import React, { PropsWithChildren } from 'react';
import { useRealtimeClient } from './useRealtimeClient';

export const RealtimeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { realtimeClient } = useRealtimeClient();

  if (!realtimeClient) {
    return <Loader />;
  }

  return <AblyProvider client={realtimeClient}>{children}</AblyProvider>;
};
