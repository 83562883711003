import type { NarratorTextNodeData } from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import { Role } from '../game/messages.types';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const text = async (
  block: GameNode<NarratorTextNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  const message = await convertToText(block.text, state, config, playerData);

  return {
    messages: [
      {
        nodeId: block.id,
        type: 'text',
        message,
        name: config.narratorName,
        role: Role.Narrator,
      },
    ],
    haltExecution: true,
    state,
  };
};
