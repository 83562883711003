import {
  DataType,
  RpgConfig,
  StaticValue,
  Value,
  ValueType,
  getEnumOptions,
} from '@common/studio-types';

export const useStudioUtils = (rpgConfig: RpgConfig) => {
  const createStaticValue = (value: Value): StaticValue => {
    if (value.dataType === DataType.Number) {
      return {
        dataType: DataType.Number,
        type: ValueType.StaticValue,
        value: 0,
      };
    } else if (value.dataType === DataType.String) {
      return {
        dataType: DataType.String,
        type: ValueType.StaticValue,
        value: '',
      };
    } else if (value.dataType === DataType.Enum) {
      const options = getEnumOptions(value.enumRef, rpgConfig.properties);

      return {
        dataType: DataType.Enum,
        type: ValueType.StaticValue,
        ref: options[0]?.ref ?? '',
        enumRef: value.enumRef,
      };
    }

    return null as never;
  };

  return { createStaticValue };
};
