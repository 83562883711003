import {
  NumberPropertyConfig,
  RpgConfig,
  ValueType,
} from '@common/studio-types';
import { Field, NumericInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueInput } from '../value/ValueInput';

type Props = {
  propertyId: string;
  onChange: (data: NumberPropertyConfig) => void;
  data: NumberPropertyConfig;
  rpgConfig: RpgConfig;
};

export const NumberPropertyForm: React.FC<Props> = (props) => {
  const { propertyId, onChange, data, rpgConfig } = props;

  const rpgConfigWithoutThisProp = {
    ...rpgConfig,
    properties: rpgConfig.properties.filter((prop) => prop.id !== propertyId),
  };

  return (
    <Container>
      <StyledField label="Default Value">
        <NumericInput
          min={-1000}
          placeholder="Default"
          onChange={(defaultValue) => onChange({ ...data, defaultValue })}
          value={data.defaultValue}
        />
      </StyledField>

      <StyledField label="Minimum Value">
        <ValueInput
          disabledTypes={[ValueType.Input]}
          rpgConfig={rpgConfigWithoutThisProp}
          value={data.minValue}
          onChange={(minValue) => onChange({ ...data, minValue })}
        />
      </StyledField>

      <StyledField label="Maximum Value">
        <ValueInput
          disabledTypes={[ValueType.Input]}
          rpgConfig={rpgConfigWithoutThisProp}
          value={data.maxValue}
          onChange={(maxValue) => onChange({ ...data, maxValue })}
        />
      </StyledField>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
`;

const StyledField = styled(Field)`
  width: 100%;
  margin-bottom: ${dimensions.size0};
`;
