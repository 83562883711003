import { EpisodeSetupNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { EpisodeSetupForm } from './EpisodeSetupForm';
import { EpisodeSetupNode } from './EpisodeSetupNode';

const name = 'Episode setup';

export const episodeSetupNode: NodeConfig<
  StudioNodeType.EpisodeSetup,
  EpisodeSetupNodeData
> = {
  type: StudioNodeType.EpisodeSetup,
  name,
  icon: 'settings',
  group: NodeGroup.Advanced,
  node: EpisodeSetupNode,
  form: EpisodeSetupForm,
  cloneNodeData: () => ({
    data: { version: 1, id: toNodeId(), properties: [] },
  }),
  sourceHandles: () => [], // no source handle
  getNodeData: () => ({ title: name }),
  createNodeData: () => ({
    version: 1,
    id: toNodeId(),
    type: StudioNodeType.EpisodeSetup,
    properties: [],
  }),
  validateNodeData: () => {
    // TODO we need to work on validation
    return [];
  },
};
