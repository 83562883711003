import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { Menu, MenuDropdownButton, MenuItem, MenuList } from './menu/Menu';

type Props = {
  placeholder?: string;
  value: string | undefined;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  isInvalid?: boolean;
};

export const Select: React.FC<Props> = ({
  value,
  options,
  onChange,
  placeholder,
  isInvalid,
}) => {
  const valueLabel = () => {
    if (!value?.length) return undefined;

    return options.find((option) => value === option.value)?.label;
  };

  return (
    <Menu>
      <MenuDropdownButton isInvalid={isInvalid}>
        <StyledLabel $isPLaceholder={!valueLabel}>
          {valueLabel() ?? placeholder ?? 'Select an option'}
        </StyledLabel>
      </MenuDropdownButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => onChange(option.value === value ? '' : option.value)}
          >
            <ValueContainer>
              <Name>{option.label}</Name>
            </ValueContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const StyledLabel = styled.div<{ $isPLaceholder: boolean }>`
  ${textStyles.body.b14m}
  overflow: hidden;
  color: ${({ theme, $isPLaceholder }) =>
    $isPLaceholder ? theme.colors.text.placeholder : theme.colors.text.header};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;
