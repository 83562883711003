import { type EndEpisodeNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { Message } from '../game/messages.types';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const endEpisode = (
  node: GameNode<EndEpisodeNodeData>,
  state: GameState,
): ActionResult => {
  const messages: Message[] = [
    {
      nodeId: node.id,
      type: 'end-episode',
      message: node.message,
      action: node.action,
    },
  ];

  return { messages, state };
};
