import { CmsCard } from '@maestro/components';
import React from 'react';
import { Series } from '../types';

type Props = {
  series: Series;
};

export const SeriesCard: React.FC<Props> = ({ series }) => {
  return (
    <CmsCard
      image={series.coverUrl}
      aspectRatio={1.5}
      description={series.description}
      title={series.title}
    />
  );
};
