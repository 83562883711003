import type { SingleSelectNodeData } from '@common/studio-types';
import type { GameNodes } from '../createGame/getGameNodes';
import { toSingleSelectOption } from '../createGame/interactionUtils';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const singleSelectInteraction = async (
  node: GameNode<SingleSelectNodeData>,
  state: GameState,
  config: StatefulGameConfig,
  gameNodes: GameNodes,
  playerData: PlayerData,
): Promise<ActionResult> => {
  const nodeOptions = node.options.map((option) =>
    toSingleSelectOption(option, gameNodes, config),
  );
  const selectedOptions = state.selectedOptions?.[node.id] ?? [];
  const prompt = await convertToText(node.prompt, state, config, playerData);
  const options = node.hideSelectedOptions
    ? nodeOptions.filter((option) => !selectedOptions.includes(option.id))
    : nodeOptions;
  const hasSelectedAllOptions = options.length === 0;
  const nextNodeId = node.nodeAfterOtherOptionsId;

  if (hasSelectedAllOptions && node.nodeAfterOtherOptionsId) {
    return {
      messages: [],
      state,
      haltExecution: false,
      nextNodeId: nextNodeId,
    };
  }

  return {
    messages: [
      {
        nodeId: node.id,
        type: 'single-select-interaction',
        id: node.id,
        prompt,
        options,
      },
    ],
    state: { ...state, currentInteraction: node.id },
    haltExecution: true,
  };
};
