import React from 'react';
import { EpisodePublishButtonV2 } from './EpisodePublishButtonV2';

type Props = {
  episodeId: string;
  variant?: 'menu-item' | 'button';
};

export const EpisodePublishButton: React.FC<Props> = ({
  variant = 'menu-item',
  episodeId,
}) => {
  return <EpisodePublishButtonV2 episodeId={episodeId} variant={variant} />;
};
