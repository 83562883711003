import { Button, CloseButton } from '@chakra-ui/react';
import { EnumOption, EnumPropertyConfig } from '@common/studio-types';
import { TextInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onChange: (data: EnumPropertyConfig) => void;
  data: EnumPropertyConfig;
};

export const EnumPropertyForm: React.FC<Props> = (props) => {
  const { data, onChange } = props;

  const onAddNewValue = () => {
    onChange({
      ...data,
      options: [
        ...data.options,
        { ref: window.crypto.randomUUID(), label: '', description: '' },
      ],
    });
  };

  const onChangeOption = (ref: string, option: EnumOption) => {
    onChange({
      ...data,
      options: data.options.map((value) =>
        value.ref === ref ? option : value,
      ),
    });
  };

  return (
    <Container>
      {data.options.map((option) => (
        <RowContainer key={option.ref}>
          <TextInput
            placeholder="Name"
            onChange={(e) =>
              onChangeOption(option.ref, { ...option, label: e.target.value })
            }
            value={option.label}
          />

          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              onChange({
                ...data,
                options: data.options.filter(({ ref }) => ref !== option.ref),
              });
            }}
          />
        </RowContainer>
      ))}

      <Button
        size="sm"
        variant="default"
        onClick={onAddNewValue}
        isDisabled={data.options.length >= 10}
        marginTop={dimensions.size12}
      >
        Add option
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  align-items: center;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  width: 100%;
`;
