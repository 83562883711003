import { useAuthStore } from '@maestro/auth';
import { Identity } from '@maestro/cms/auth';
import { useGetIdentity } from '@refinedev/core';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

export const useFeatureFlagIdentifyUser = () => {
  const ldClient = useLDClient();
  const jwt = useAuthStore((state) => state.setJwt);
  const { data: identity } = useGetIdentity<Identity>();

  useEffect(() => {
    if (!jwt || !identity?.email || !ldClient) {
      return;
    }

    (async () => {
      try {
        await ldClient.identify({ key: identity.email });
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log('Failed to identify user with LaunchDarkly');
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })().catch(() => void 0);
  }, [jwt, identity?.email, ldClient]);
};
