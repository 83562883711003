import { env } from '@maestro/env';
import { rawDimensions } from '@maestro/styles';
import { FC, MouseEvent } from 'react';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { Icon } from '../Icon';
import { MenuItem } from '../menu/Menu';

type Props = {
  episodeId: string;
  label?: string;
};

export const CopyEpisodeShareLinkMenuItem: FC<Props> = ({
  episodeId,
  label = 'Copy link to episode',
}) => {
  const { copyToClipboard } = useCopyToClipboard();
  const copyLink = () => {
    if (env.VITE_SHARE_DRAFT_APP_LINK?.match(/http/)) {
      return `${env.VITE_SHARE_DRAFT_APP_LINK}?draft=${episodeId}`;
    }

    return `${env.VITE_SHARE_DRAFT_APP_LINK}/${episodeId}`;
  };

  const onCopyLinkToClipboardClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const linkToCopy = copyLink();

    copyToClipboard(linkToCopy, {
      onSuccessMessage: 'Copied link to the clipboard',
      onSuccessDescription:
        'Anyone with this link will have access to this episode on Avenue',
    });
  };

  return (
    <MenuItem onClick={onCopyLinkToClipboardClick}>
      <Icon name="share" size={rawDimensions.size20} />
      {label}
    </MenuItem>
  );
};
