import * as React from 'react';
import { D10 } from './svg/D10';
import { D100 } from './svg/D100';
import { D12 } from './svg/D12';
import { D20 } from './svg/D20';
import { D4 } from './svg/D4';
import { D6 } from './svg/D6';
import { D8 } from './svg/D8';

export type ColorMultiplier = {
  saturation: number;
  lightness: number;
};

export type DiceSvgProps = {
  multipliers: ColorMultiplier;
  size?: number;
};

type DiceSvgs = {
  d4: React.FC<DiceSvgProps>;
  d6: React.FC<DiceSvgProps>;
  d8: React.FC<DiceSvgProps>;
  d10: React.FC<DiceSvgProps>;
  d12: React.FC<DiceSvgProps>;
  d20: React.FC<DiceSvgProps>;
  d100: React.FC<DiceSvgProps>;
};

export type DiceType = keyof DiceSvgs;

const svgs: DiceSvgs = {
  d4: D4,
  d6: D6,
  d8: D8,
  d10: D10,
  d12: D12,
  d20: D20,
  d100: D100,
};

type Props = DiceSvgProps & {
  type: DiceType;
};

export const DiceSvg: React.FC<Props> = ({ type, ...props }) => {
  const Shape = svgs[type];

  return <Shape {...props} />;
};
