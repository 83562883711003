import { useAuthStore } from '@maestro/auth';
import { identify } from '@maestro/monitoring';
import { toSupabaseClient } from '@maestro/supabase';
import { AuthBindings } from '@refinedev/core';

type LoginParams = {
  providerName: 'google' | 'apple';
};

export type Identity = {
  internalId: string;
  isAdmin: boolean;
  username: string;
  email: string;
};

export const authProvider: AuthBindings = {
  login: async ({ providerName }: LoginParams) => {
    try {
      const { error } = await toSupabaseClient().auth.signInWithOAuth({
        provider: providerName,
        options: {
          redirectTo: `${window.location.origin}/sb`,
        },
      });

      if (!error) {
        return {
          success: true,
          redirectTo: window.location.origin,
        };
      }

      throw error;
    } catch (e: unknown) {
      return {
        success: false,
        e,
      };
    }
  },
  logout: async () => {
    const { error } = await toSupabaseClient().auth.signOut();
    useAuthStore.getState().setJwt();

    if (error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      redirectTo: window.location.origin,
    };
  },
  onError: async (error) => {
    // eslint-disable-next-line no-console
    console.error(error);

    return { error };
  },
  check: async () => {
    try {
      const { data } = await toSupabaseClient().auth.getSession();
      const { session } = data;

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: 'Check failed',
            name: 'Session not found',
          },
          logout: true,
          redirectTo: `${window.location.origin}/login`,
        };
      }
    } catch (error: unknown) {
      return {
        authenticated: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error as any) || {
          message: 'Check failed',
          name: 'Not authenticated',
        },
        logout: true,
        redirectTo: `${window.location.origin}/login`,
      };
    }

    return {
      authenticated: true,
    };
  },
  getIdentity: async () => {
    const { data } = await toSupabaseClient().auth.getUser();

    const { data: userData } = await toSupabaseClient()
      .from('user')
      .select('*')
      .eq('auth_id', data.user?.id)
      .single();

    if (data?.user) {
      identify(
        userData.id,
        data.user.email ? { email: data.user.email } : undefined,
      );

      return {
        ...data.user,
        name: data.user.email,
        isAdmin: userData.role === 'admin',
        username: userData.username,
        internalId: userData.id,
      };
    }

    return null;
  },
};
