import { useFeatureFlagIdentifyUser } from '@maestro/feature-flags/src/hooks/useFeatureFlagIdentifyUser';
import {
  AuthenticationProvider,
  DeviceType,
  useAuthenticateWithProviderMutation,
  useLogoutMutation,
} from '@maestro/graphql';
import { toSupabaseClient } from '@maestro/supabase';
import React, { PropsWithChildren, useEffect } from 'react';
import { useAuthStore } from '@maestro/auth';

export const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const setJwt = useAuthStore((state) => state.setJwt);
  const { mutateAsync: logout } = useLogoutMutation();
  const { mutateAsync: authenticateWithProvider } =
    useAuthenticateWithProviderMutation();

  useFeatureFlagIdentifyUser();

  useEffect(() => {
    const fetch = async () => {
      const { data } = await toSupabaseClient().auth.getSession();

      if (!!data.session) {
        try {
          const { authenticateWithProvider: authResponse } =
            await authenticateWithProvider({
              input: {
                provider: AuthenticationProvider.Supabase,
                token: data.session.access_token,
                providerMetadata: {
                  deviceType: DeviceType.Unknown,
                  nonce: data.session.refresh_token,
                },
              },
            });

          if ((authResponse.error?.length ?? 0) > 0) {
            throw new Error(`Internal server error ${authResponse.error}`);
          }

          setJwt(authResponse.jwt ?? undefined);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Unable to authenticate user with Enigma API', e);
          setJwt(undefined);
          await logout({});
          await toSupabaseClient()
            .auth.signOut()
            // eslint-disable-next-line no-console
            .catch((e) => console.error('Failed to log out', e));
        }
      }
    };

    // eslint-disable-next-line no-console
    fetch().catch((e) => console.error('Uncaught error in useSyncUser', e));
  }, []);

  return children;
};
