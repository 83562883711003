import { useAuthStore } from '@maestro/auth';
import { useQueryParam } from '@maestro/components';
import {
  AuthenticationProvider,
  DeviceType,
  useAuthenticateWithProviderMutation,
} from '@maestro/graphql';
import { toSupabaseClient } from '@maestro/supabase';
import { useNavigation } from '@refinedev/core';
import { useEffect } from 'react';
import { TableLoader } from '../../components/TableLoader';

export const LoginPass: React.FC = () => {
  const setJwt = useAuthStore((state) => state.setJwt);
  const { mutateAsync: authenticateWithProvider } =
    useAuthenticateWithProviderMutation();

  const navigate = useNavigation();

  const [email] = useQueryParam({ param: 'e' });
  const [password] = useQueryParam({ param: 'p' });

  useEffect(() => {
    if (email && password) {
      toSupabaseClient()
        .auth.signInWithPassword({
          email,
          password,
        })
        .then(async ({ data, error }) => {
          if (!error && data) {
            const { authenticateWithProvider: authResponse } =
              await authenticateWithProvider({
                input: {
                  provider: AuthenticationProvider.Supabase,
                  token: data.session.access_token,
                  providerMetadata: {
                    deviceType: DeviceType.Unknown,
                    nonce: data.session.refresh_token,
                  },
                },
              });

            if (authResponse.jwt) {
              setJwt(authResponse.jwt);
            }
          }
        })
        .then(() => navigate.push('/'))
        .catch(() => void 0);
    }
  }, [email, password]);

  return <TableLoader />;
};
