import { Icon } from '@maestro/components/Icon';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';
import styled from 'styled-components';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';

const getPath = (props: EdgeProps) => {
  const sourceSide =
    props.sourcePosition === 'left' || props.sourcePosition === 'right';

  return getSmoothStepPath({
    borderRadius: 8,
    offset: sourceSide ? 20 : 10,
    sourceX:
      props.sourcePosition === 'right'
        ? props.sourceX - 6
        : props.sourcePosition === 'left'
          ? props.sourceX + 6
          : props.sourceX,
    sourceY:
      props.sourcePosition === 'bottom' ? props.sourceY - 3 : props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX:
      props.targetPosition === 'left' ? props.targetX + 4 : props.targetX,
    targetY: props.targetPosition === 'top' ? props.targetY + 4 : props.targetY,
    targetPosition: props.targetPosition,
  });
};

export const BaseStudioEdge: React.FC<EdgeProps> = (props: EdgeProps) => {
  const { id, label, selected } = props;
  const { deleteEdge } = useStudioFlowStore();
  const color = selected ? '#7b80ff' : '#B3B3B3';
  const style = { strokeWidth: 2, stroke: color };
  const [edgePath, labelX, labelY] = getPath(props);

  const markerEnd = selected
    ? 'url(#arrowhead-selected)'
    : 'url(#arrowhead-default)';

  return (
    <>
      <BaseEdge path={edgePath} style={style} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <LabelRendererContainer $labelX={labelX} $labelY={labelY}>
          {label && label !== 'Output' && !selected && <Text>{label}</Text>}
          <Close $visible={selected} onClick={() => deleteEdge(id)}>
            <Icon name="trash" size={16} />
          </Close>
        </LabelRendererContainer>
      </EdgeLabelRenderer>
    </>
  );
};

const Close = styled.div<{ $visible?: boolean }>`
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  padding: ${dimensions.size6};
  color: ${({ theme }) => theme.colors.text.placeholder};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size999};
  transition: color 0.2s;

  &:hover {
    color: white;
  }
`;

const Text = styled.div`
  ${textStyles.body.b12m}
  padding: ${dimensions.size2} ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.body};
  background-color: #18181f;
  border-radius: ${dimensions.size4};
`;

const LabelRendererContainer = styled.div<{
  $labelX?: number;
  $labelY?: number;
}>`
  position: absolute;
  pointer-events: all;

  transform: ${({ $labelX, $labelY }) =>
    `translate(-50%, -50%) translate(${$labelX}px, ${$labelY}px)`};
`;
