import { Button } from '@chakra-ui/react';
import { Icon, Link } from '@maestro/components';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import styled from 'styled-components';
import { TableLoader } from '../../components/TableLoader';
import { useCreateSeries } from '../series/hooks/useCreateSeries';
import { useListWorlds } from './hooks/useListWorlds';
import { WorldCard } from './WorldCard';

const imgUrl =
  'https://liwwunutcahympidrjyx.supabase.co/storage/v1/object/public/images/cms/home-cover.png';

export const WorldList = () => {
  const { worlds, isLoading } = useListWorlds();
  const { createSeries } = useCreateSeries({ createFirstEpisode: true });

  const onCreateSeries = async () => {
    await createSeries();
  };

  return (
    <Container>
      <WorldCardCTA>
        <BackgroundContainer>
          <TitleContainer>
            <PageTitle>What will you create today?</PageTitle>
            <PageSubtitle>
              Create a series where your actions and choices script the
              narrative. For those bold enough to “be the story”
            </PageSubtitle>
            <PageCTA>
              I want to create a...
              <Button
                onClick={onCreateSeries}
                variant="primary"
                leftIcon={<Icon name="sheet" size={rawDimensions.size16} />}
              >
                New series
              </Button>
            </PageCTA>
          </TitleContainer>
        </BackgroundContainer>
      </WorldCardCTA>
      <DicoverWorldsTitle>Discover world bibles</DicoverWorldsTitle>
      {isLoading ? (
        <TableLoader />
      ) : (
        <WorldListContainer>
          {worlds.map((world) => (
            <Link to={`/world/${world.id}`} key={world.id}>
              <WorldCard world={world} />
            </Link>
          ))}
        </WorldListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: ${dimensions.size48};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
  }
`;

const WorldCardCTA = styled.div`
  position: relative;
  min-height: 300px;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    min-height: 100px;
  }
`;

const TitleContainer = styled.div`
  width: 50%;

  @media ${breakpoints.tablet} {
    width: 100%;
  }
`;

const BackgroundContainer = styled.div`
  height: 100%;
  padding: ${dimensions.size48};
  background-image: url(${imgUrl});
  background-repeat: no-repeat;
  background-position: right;
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
  }
`;

const PageTitle = styled.div`
  ${textStyles.title.t32b}
  margin: ${dimensions.size24} 0;
  color: ${({ theme }) => theme.colors.text.header};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const PageSubtitle = styled.div`
  ${textStyles.body.b16m}
  margin: ${dimensions.size24} 0;
  color: ${({ theme }) => theme.colors.text.body};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const PageCTA = styled.div`
  ${textStyles.body.b16m}
  display: flex;
  gap: ${dimensions.size16};
  align-items: center;
  margin: ${dimensions.size24} 0;
  font-style: italic;
  color: ${({ theme }) => theme.colors.text.header};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const DicoverWorldsTitle = styled.div`
  ${textStyles.title.t20b}
  margin: ${dimensions.size24} 0;
  color: ${({ theme }) => theme.colors.text.header};

  @media ${breakpoints.tablet} {
    margin: ${dimensions.size12} 0;
  }
`;

const WorldListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: ${dimensions.size24};

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    gap: ${dimensions.size12};
    align-items: start;
    justify-content: start;
    overflow-x: auto;
  }
`;
