import { useToast } from '@chakra-ui/react';
import {
  DesignSystem,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon';

type ToastProps = {
  title: string;
  status: 'success' | 'warning';
  description?: string;
  duration?: number;
  icon?: string;
};

export const useMaestroToast = () => {
  const toast = useToast();
  const theme = useTheme();

  return (args: ToastProps) => {
    const icon = args.status === 'success' ? 'checkmark-filled' : 'warning';

    toast({
      title: args.title,
      description: args.description,
      render: () => (
        <Container>
          <IconContainer theme={theme} $status={args.status}>
            <Icon name={args.icon ?? icon} size={rawDimensions.size20} />
          </IconContainer>
          <TextContainer>
            <TitleContainer>{args.title}</TitleContainer>
            <DescriptionContainer $color={theme.colors.text.body}>
              {args.description}
            </DescriptionContainer>
          </TextContainer>
        </Container>
      ),
      duration: args.duration ?? 3000,
      position: 'bottom',
    });
  };
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  align-self: start;
  justify-content: start;
  max-width: ${dimensions.size360};
  padding: ${dimensions.size12} ${dimensions.size16} ${dimensions.size12}
    ${dimensions.size12};
  color: #fff;
  background-color: #000;
  border-radius: ${dimensions.size8};
`;

const IconContainer = styled.div<{
  theme: DesignSystem;
  $status: ToastProps['status'];
}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  color: ${({ theme, $status }) =>
    $status === 'warning' ? theme.colors.warning : theme.colors.text.accent};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

const TitleContainer = styled.div`
  ${textStyles.label.lb14sb}
`;

const DescriptionContainer = styled.div<{ $color: string }>`
  ${textStyles.body.b12m}
  color: ${({ $color }) => $color};
`;
