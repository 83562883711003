import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { Popover as BasePopup } from '@mui/material';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

type PopoverContextController = {
  open: boolean;
  element: HTMLElement | null;
  setElement: (element: HTMLElement | null) => void;
};

const PopoverContext = createContext<PopoverContextController>({
  open: false,
  element: null,
  setElement: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const Popover: React.FC<PropsWithChildren> = ({ children }) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const context: PopoverContextController = {
    open: !!element,
    element,
    setElement,
  };

  return (
    <PopoverContext.Provider value={context}>
      {children}
    </PopoverContext.Provider>
  );
};

export const PopoverButton: React.FC<PropsWithChildren> = ({ children }) => {
  const { setElement, open } = useContext(PopoverContext);

  return (
    <div onClick={(e) => setElement(open ? null : e.currentTarget)}>
      {children}
    </div>
  );
};

type BodyProps = PropsWithChildren<{
  title?: string;
  width?: number;
}>;

export const PopoverBody: React.FC<BodyProps> = ({
  children,
  title,
  width,
}) => {
  const { open, element, setElement } = useContext(PopoverContext);

  return (
    <StyledBasePopup
      open={open}
      classes={{ paper: 'MuiPopover-paper' }}
      anchorEl={element}
      onClose={() => setElement(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <PopoverBodyContainer style={{ width: width ?? element?.clientWidth }}>
        <HeaderContainer>
          <TitleContainer>{title}</TitleContainer>
          <CloseButton onClick={() => setElement(null)}>
            <Icon name="close" size={rawDimensions.size16} />
          </CloseButton>
        </HeaderContainer>
        {children}
      </PopoverBodyContainer>
    </StyledBasePopup>
  );
};

const StyledBasePopup = styled(BasePopup)`
  background: none;

  .MuiPaper-root {
    background: none;
    box-shadow: none;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const PopoverBodyContainer = styled.div`
  ${textStyles.label.lb16sb}
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background: #111;
  border-radius: ${dimensions.size8};
  transition: all 0.2s;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${dimensions.size8};
`;

const TitleContainer = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;
