import { useMaestroToast } from '@maestro/components';
import { useCreateSeriesMutation } from '@maestro/graphql';
import { useNavigation } from '@refinedev/core';

type Options = {
  worldId?: string;
  createFirstEpisode?: boolean;
};

export const useCreateSeries = ({ worldId, createFirstEpisode }: Options) => {
  const { mutateAsync } = useCreateSeriesMutation();
  const navigation = useNavigation();
  const toast = useMaestroToast();
  const createSeries = async () => {
    try {
      const response = await mutateAsync({
        input: {
          worldId,
          createFirstEpisode,
        },
      });

      if (response.createSeries.firstEpisodeId) {
        navigation.push(`/studio/${response.createSeries.firstEpisodeId}`);
      } else if (response.createSeries.series) {
        navigation.push(`/series/${response.createSeries.series.id}`);
      } else {
        throw new Error('No navigation action to take');
      }
    } catch {
      toast({
        status: 'warning',
        title: 'Error creating a new series',
      });
    }
  };

  return { createSeries };
};
