import { Button } from '@chakra-ui/react';
import {
  Icon,
  Link,
  NoResult,
  NoResultContainer,
  ScreenContainer,
  Section,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { breakpoints, dimensions, rawDimensions } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import styled from 'styled-components';
import { TableLoader } from '../../components/TableLoader';
import { EpisodeCard } from '../episode/components/EpisodeCard';
import { useEpisodes } from '../episode/hooks/useEpisodes';
import { SeriesCard } from '../series/components/SeriesCard';
import { useCreateSeries } from '../series/hooks/useCreateSeries';
import { useListSeries } from '../series/hooks/useListSeries';
import { useListWorlds } from '../world/hooks/useListWorlds';
import { WorldCard } from '../world/WorldCard';

export const MyStudio = () => {
  const { series, isLoading } = useListSeries({ userCreatedOnly: true });
  const { createSeries } = useCreateSeries({ createFirstEpisode: true });
  const { episodes, isLoading: isLoadingEpisodes } = useEpisodes({
    draftsFirst: true,
  });
  const { worlds, isLoading: isLoadingWorld } = useListWorlds({
    userCreatedOnly: true,
  });
  const navigation = useNavigation();

  const onCreateSeries = async () => {
    await createSeries();
  };

  const createWorld = () => {
    navigation.push('/world/create');
  };

  if (isLoading || isLoadingWorld || isLoadingEpisodes) {
    return <TableLoader />;
  }

  return (
    <ScreenContainer>
      <SectionContainer $gap={dimensions.size48}>
        {episodes.length > 0 && (
          <Section>
            <SectionTitle>Recent episodes</SectionTitle>
            <SectionCard>
              <ScrollableRowContainer>
                {episodes.map((episode) => (
                  <Link to={`/studio/${episode.id}`} key={episode.id}>
                    <EpisodeCard episode={episode} />
                  </Link>
                ))}
              </ScrollableRowContainer>
            </SectionCard>
          </Section>
        )}
        <Section>
          <SectionTitle>
            My series
            {series.length > 0 && (
              <Button
                variant="primary"
                leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                onClick={onCreateSeries}
              >
                New series
              </Button>
            )}
          </SectionTitle>
          <SectionCard>
            {series.length === 0 ? (
              <NoResultContainer>
                <NoResult>
                  You have no interactive series in your studio. Choose from an
                  existing world bible or create a new one to get started.
                  <Button
                    variant="primary"
                    leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                    onClick={onCreateSeries}
                  >
                    New series
                  </Button>
                </NoResult>
              </NoResultContainer>
            ) : (
              <ScrollableRowContainer>
                {series.map((series) => (
                  <Link to={`/series/${series.id}`} key={series.id}>
                    <SeriesCard series={series} />
                  </Link>
                ))}
              </ScrollableRowContainer>
            )}
          </SectionCard>
        </Section>

        <Section>
          <SectionTitle>
            My worlds
            {worlds.length > 0 && (
              <Button
                variant="primary"
                leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                onClick={createWorld}
              >
                New world
              </Button>
            )}
          </SectionTitle>
          <SectionCard>
            {worlds.length === 0 ? (
              <NoResultContainer>
                <NoResult>
                  You have no world bibles in your studio. Use your imagination
                  to create an entirely new world with lore, characters, and
                  locations.
                  <Button
                    variant="primary"
                    leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                    onClick={createWorld}
                  >
                    New world
                  </Button>
                </NoResult>
              </NoResultContainer>
            ) : (
              <ScrollableRowContainer>
                {worlds.map((world) => (
                  <Link to={`/world/${world.id}`} key={world.id}>
                    <WorldCard world={world} />
                  </Link>
                ))}
              </ScrollableRowContainer>
            )}
          </SectionCard>
        </Section>
      </SectionContainer>
    </ScreenContainer>
  );
};

const ScrollableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size24};
  align-items: start;
  justify-content: start;
  overflow-x: auto;

  @media ${breakpoints.tablet} {
    gap: ${dimensions.size12};
  }
`;
