import { Button } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  Icon,
  ScreenContainer,
  SectionContainer,
} from '@maestro/components';
import { rawDimensions } from '@maestro/styles';
import { Reorder } from 'framer-motion';
import styled from 'styled-components';
import { EmptyState } from '../../components/EmptyState';
import { TableLoader } from '../../components/TableLoader';
import { DiscoverySectionRenderer } from './DiscoverySectionRenderer';
import { useCreateSection } from './hooks/useCreateSection';
import { useGetSections } from './hooks/useGetSections';
import { toItem } from './sections.type';

const scrollConfig: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest',
};

export const DiscoverySections = () => {
  const { createSection, isSaving } = useCreateSection();
  const { sections, series, updateSections, deleteSection, isLoading } =
    useGetSections();

  const onAddSection = async () => {
    const newSection = await createSection('New Section');

    if (newSection) {
      updateSections([...sections, toItem(newSection)]);

      setTimeout(() => {
        document
          .getElementById(`discovery-section-${newSection.id}`)
          ?.scrollIntoView(scrollConfig);
      }, 100);
    }
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>Discovery Sections</HeaderTitle>
          <Button
            variant="primary"
            isLoading={isSaving}
            onClick={onAddSection}
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
          >
            Add section
          </Button>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        {isLoading && sections.length === 0 ? (
          <TableLoader />
        ) : sections.length > 0 ? (
          <DiscoverySectionsContainer>
            <Reorder.Group
              axis="y"
              values={sections}
              onReorder={updateSections}
            >
              {sections.map((item) => (
                <DiscoverySectionRenderer
                  onRemove={() => deleteSection(item)}
                  allSeries={series}
                  key={item.id}
                  onSave={(updatedSection) =>
                    updateSections(
                      sections.map((section) =>
                        section.id === updatedSection.id
                          ? updatedSection
                          : section,
                      ),
                    )
                  }
                  section={item}
                />
              ))}
            </Reorder.Group>
          </DiscoverySectionsContainer>
        ) : (
          <EmptyState title="No sections yet found." />
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

const DiscoverySectionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ul,
  li {
    list-style: none;
  }
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  justify-content: space-between;
  width: 100%;
`;
