import { Badge } from '@chakra-ui/react';
import { Icon } from '@maestro/components';

type Props = {
  outputName: string;
};

export const OutputName: React.FC<Props> = ({ outputName }) => {
  return (
    <Badge variant="value">
      {outputName}
      &nbsp;
      <Icon name="arrow-right" size={10} />
    </Badge>
  );
};
