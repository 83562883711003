import { DataType, ValueType, type Value } from '@common/studio-types';
import type { StatefulGameConfig } from '../../createGame/statefulGame';
import type { GameState } from '../../game';
import type { PlayerData } from '../../game/player';
import type { ReturnedValue } from './types';

export const getNumberValue = async (
  value: Value,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ReturnedValue<number>> => {
  if (value.dataType === DataType.Number) {
    if (value.type === ValueType.Input) {
      return {
        dataType: DataType.Number,
        value: state.input[value.ref] ? +state.input[value.ref] : 0,
        visualValue: String(state.input[value.ref] ?? 0),
        label: value.ref,
      };
    } else if (value.type === ValueType.Property) {
      const property = config.getProperty(value.ref);
      const propertyValue = await config.getPropertyValue(state, value.ref);

      return {
        dataType: DataType.Number,
        value: Number(propertyValue),
        visualValue: String(propertyValue),
        label: property?.name ?? '',
      };
    } else if (value.type === ValueType.Item) {
      const item = await playerData.getItem(value.ref);

      return {
        dataType: DataType.Number,
        value: item?.quantity ?? 0,
        visualValue: String(item?.quantity ?? 0),
        label: item?.name ?? '',
      };
    } else if (value.type === ValueType.StaticValue) {
      return {
        dataType: DataType.Number,
        value: value.value ?? 0,
        visualValue: String(value.value ?? 0),
        label: String(value.value ?? 0),
      };
    }

    return {
      dataType: DataType.Number,
      value: 0,
      visualValue: '0',
      label: '0',
    };
  }

  throw new Error('Should never go here');
};
