/**
 * Attribution: This file was taken from React Flow Pro examples.
 * It wasn't created by Tally Labs although changes were made.
 *
 * @see https://pro.reactflow.dev/examples/react/undo-redo
 */
import { useCallback, useState } from 'react';
import { Edge, Node, useReactFlow } from 'reactflow';
import { useShortcut } from './useShortcut';
import { useSingleExecWithinSpan } from './useSingleExecWithinSpan';

type UseUndoRedoOptions = {
  maxHistorySize: number;
};

type UseUndoRedo = (options?: UseUndoRedoOptions) => {
  canUndo: boolean;
  canRedo: boolean;
  takeSnapshot: () => void;
  debouncedTakeSnapshot: () => void;
  undo: () => void;
  redo: () => void;
};

type HistoryItem = {
  nodes: Node[];
  edges: Edge[];
};

const defaultOptions: UseUndoRedoOptions = {
  maxHistorySize: 100,
};

// https://redux.js.org/usage/implementing-undo-history
export const useUndoRedo: UseUndoRedo = ({
  maxHistorySize = defaultOptions.maxHistorySize,
} = defaultOptions) => {
  // the past and future arrays store the states that we can jump to
  const [past, setPast] = useState<HistoryItem[]>([]);
  const [future, setFuture] = useState<HistoryItem[]>([]);

  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow();

  const takeSnapshot = useCallback(() => {
    // push the current graph to the past state
    setPast((past) => [
      ...past.slice(past.length - maxHistorySize + 1, past.length),
      { nodes: getNodes(), edges: getEdges() },
    ]);

    // whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
    setFuture([]);
  }, [getNodes, getEdges, maxHistorySize]);

  // Attribution: changes made by Tally Labs on the debouncedSnapshot
  // will call only once and discard subsequent calls within 1s span
  const debouncedTakeSnapshot = useSingleExecWithinSpan(takeSnapshot);

  const undo = useCallback(() => {
    // get the last state that we want to go back to
    const pastState = past[past.length - 1];

    if (pastState) {
      // first we remove the state from the history
      setPast((past) => past.slice(0, past.length - 1));
      // we store the current graph for the redo operation
      setFuture((future) => [
        ...future,
        { nodes: getNodes(), edges: getEdges() },
      ]);
      // now we can set the graph to the past state
      setNodes(pastState.nodes);
      setEdges(pastState.edges);
    }
  }, [setNodes, setEdges, getNodes, getEdges, past]);

  const redo = useCallback(() => {
    const futureState = future[future.length - 1];

    if (futureState) {
      setFuture((future) => future.slice(0, future.length - 1));
      setPast((past) => [...past, { nodes: getNodes(), edges: getEdges() }]);
      setNodes(futureState.nodes);
      setEdges(futureState.edges);
    }
  }, [setNodes, setEdges, getNodes, getEdges, future]);

  // Attribution: changes made by Tally Labs to have shortcuts at hook level
  useShortcut(['Meta+z', 'Control+z'], undo);
  useShortcut(['Meta+Shift+z', 'Control+Shift+z'], redo);

  return {
    takeSnapshot,
    debouncedTakeSnapshot,
    undo,
    redo,
    canUndo: past.length > 0,
    canRedo: future.length > 0,
  };
};

export default useUndoRedo;
