import { useState } from 'react';
import { WorldCharacter, WorldLocation } from '../types';

export const useGeneratedWorldItemsState = (withDefaults = true) => {
  const [characters, setCharacters] = useState<WorldCharacter[]>(
    withDefaults
      ? [{ name: '', traits: '', goals: '', relationships: '' }]
      : [],
  );
  const [locations, setLocations] = useState<WorldLocation[]>(
    withDefaults ? [{ name: '', description: '', npcs: '' }] : [],
  );

  return {
    locations,
    characters,
    setLocations,
    setCharacters,
  };
};
