import { Button, useDisclosure } from '@chakra-ui/react';
import { Icon, Link } from '@maestro/components';
import { StudioEpisode } from '@maestro/graphql';
import { useUserPreferencesStore } from '@maestro/studio/state/userPreferences';
import { rawDimensions } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import React, { useState } from 'react';
import { EpisodeModal } from './EpisodeModal';
import { NeverShowAgainCheckbox } from './NeverShowAgainCheckbox';

type Props = {
  episode: StudioEpisode;
  isDraft: boolean;
};

export const EpisodeBuilderButton: React.FC<Props> = ({ episode, isDraft }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigation = useNavigation();
  const [checked, setChecked] = useState(false);

  const handleDontShowThisAgainChange = () => {
    setChecked(!checked);
  };

  const { showPublishNewEpisodeVersionWarning, togglePreference } =
    useUserPreferencesStore(
      ({ showPublishNewEpisodeVersionWarning, togglePreference }) => ({
        showPublishNewEpisodeVersionWarning,
        togglePreference,
      }),
    );

  const goToBuilder = () => {
    navigation.push(`/studio/${episode.id}`);
    togglePreference('showPublishNewEpisodeVersionWarning', !checked);
  };

  const button = (
    <Button
      variant="default"
      leftIcon={<Icon name="node-editor" size={rawDimensions.size16} />}
    >
      Episode builder
    </Button>
  );

  if (isDraft || !showPublishNewEpisodeVersionWarning) {
    return <Link to={`/studio/${episode.id}`}>{button}</Link>;
  }

  return (
    <>
      <EpisodeModal
        isEdit
        episodeCoverUrl={episode?.media?.main}
        title="Edit episode"
        operation="Create new version of published episode"
        onClose={onClose}
        isOpen={isOpen}
        onConfirm={goToBuilder}
        description={
          <>
            You are editing the published episode{' '}
            <strong>{episode.title}</strong> which will create a{' '}
            <strong>new version</strong>. If you choose to publish this new
            version, it will overwrite the original published episode.
          </>
        }
        additionalContent={
          <NeverShowAgainCheckbox
            onChange={handleDontShowThisAgainChange}
            checked={checked}
          />
        }
      />

      <div onClick={onOpen}>{button}</div>
    </>
  );
};
