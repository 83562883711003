import { PrePublishEpisodeVersionValidation } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDrawerStore } from '../../../hooks/useDrawerStore';
import { SuggestionsSection } from '../../suggestions/suggestion.types';
import { VerificationStatusStep } from './VerificationStatusStep';

type Props = {
  seriesId?: string;
  validationResults: {
    episode: PrePublishEpisodeVersionValidation[];
    series: PrePublishEpisodeVersionValidation[];
  };
  suggestions: SuggestionsSection[];
};

export const PublishEpisodeVerificationSteps: FC<Props> = ({
  validationResults,
  seriesId,
  suggestions,
}) => {
  const { id } = useParams();
  const [highlightedStep, setHighlightedStep] = useState(0);
  const openDrawer = useDrawerStore((store) => store.openDrawer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (validationResults.series.length > 0) {
      setHighlightedStep(0);

      return;
    }

    if (validationResults.episode.length > 0) {
      setHighlightedStep(1);

      return;
    }

    if (suggestions.length > 0) {
      setHighlightedStep(2);

      return;
    }
  }, [suggestions, validationResults]);

  const massageFieldNames = (
    validations?: PrePublishEpisodeVersionValidation[],
  ) =>
    validations?.map((validation) => {
      if (validation.field === 'coverUrl') {
        return { ...validation, field: 'cover image' };
      }

      if (validation.field === 'genreId') {
        return { ...validation, field: 'genre' };
      }

      return validation;
    });

  const onSuggestionsViewClick = () => {
    navigate(location.pathname);
    openDrawer('suggestions');
  };

  return (
    <Container>
      <VerificationStatusStep
        label="Series details"
        isValid={!validationResults.series.length}
        withSeparator
        validations={massageFieldNames(validationResults.series)}
        isCollapsed={highlightedStep !== 0 || !validationResults.series.length}
        showNext={
          (validationResults.episode.length > 0 || suggestions.length > 0) &&
          validationResults.series.length > 0 &&
          highlightedStep === 0
        }
        onNextClick={() =>
          setHighlightedStep(
            validationResults.episode.length > 0
              ? 1
              : suggestions.length > 0
                ? 2
                : 0,
          )
        }
        onViewClick={() =>
          !!seriesId
            ? navigate(
                `${location.pathname}?edit=series&seriesId=${seriesId}&referer=publish`,
              )
            : void 0
        }
      />
      <VerificationStatusStep
        label="Episode details"
        isValid={!validationResults.episode.length}
        withSeparator
        validations={massageFieldNames(validationResults.episode)}
        isCollapsed={highlightedStep !== 1}
        showNext={suggestions.length > 0 && highlightedStep === 1}
        onNextClick={() => setHighlightedStep(2)}
        onViewClick={() =>
          navigate(
            `${location.pathname}?edit=episode&episodeId=${id}&referer=publish`,
          )
        }
      />
      <VerificationStatusStep
        label="Episode builder"
        isValid={!suggestions.length}
        isCollapsed={highlightedStep !== 2}
        onViewClick={onSuggestionsViewClick}
        withSeparator={Boolean(suggestions.length > 0) && highlightedStep === 2}
        onNextClick={() =>
          setHighlightedStep(
            validationResults.series.length > 0
              ? 0
              : validationResults.episode.length > 1
                ? 1
                : 2,
          )
        }
        showNext={
          (validationResults.series.length > 0 ||
            validationResults.episode.length > 0) &&
          suggestions.length > 0 &&
          highlightedStep === 2
        }
        validations={suggestions.map((suggestion) => {
          let field = String(suggestion.type);

          if (suggestion.type === 'story') {
            field = 'story items';
          } else if (suggestion.type === 'missingConnectors') {
            field = 'node connections';
          } else if (suggestion.type === 'validation') {
            field = 'node validations';
          }

          return {
            field,
            // NOTE: this doesn't matter, since we are not showing ech field message
            message: 'Needs attention',
          };
        })}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
`;
