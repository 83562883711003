import { Value } from './value';

export enum ConditionOperator {
  Equal = 'eq',
  NotEqual = 'neq',
  GreaterThanOrEqual = 'gte',
  GreaterThan = 'gt',
  LessThanOrEqual = 'lte',
  LessThan = 'lt',
}

export type ConditionNodeData = {
  operator: ConditionOperator;
  valueA: Value;
  valueB: Value;
  trueNodeId: string;
  falseNodeId: string;
};
