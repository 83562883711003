import { ColorMultiplier } from './DiceSvg';

const calculateNewColorValue = (value: number, multiplier: number) => {
  return Math.min(100, Math.max(0, value * multiplier));
};

export const alterHslColor = (color: string, multipliers: ColorMultiplier) => {
  const hsl = color.match(/hsl\((\d+), (\d+)%, (\d+)%\)/);

  if (!hsl) {
    return color;
  }

  const [, hue, s, l] = hsl.map(Number);

  const saturation = calculateNewColorValue(s, multipliers.saturation);
  const lightness = calculateNewColorValue(l, multipliers.lightness);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
