import {
  DataType,
  EnumOption,
  PropertyScope,
  RpgConfigProperty,
  getEnumOptions,
} from '@common/studio-types';
import { uniq } from 'lodash';
import type { GameData, GameState } from '../game';
import type { GameConfig } from '../game/gameConfig';
import { Role, type Message } from '../game/messages.types';
import type { PlayerData } from '../game/player';
import { executeNodes } from './executeNodes';
import type { GameNodes } from './getGameNodes';

export type StatefulGameConfig = GameConfig & {
  getProperties: () => RpgConfigProperty[];
  getPropertyByName: (ref: string) => RpgConfigProperty | undefined;
  getProperty: (ref: string) => RpgConfigProperty | undefined;
  getEnumOptions: (enumRef?: string) => EnumOption[];
  getPropertyValue: (state: GameState, ref: string) => Promise<number | string>;
};

export type StatefulGame = {
  gameNodes: GameNodes;
  gameData: GameData;
  config: StatefulGameConfig;
  addSelectedOption(nodeId: string, optionId: string): void;
  setGameState(partialState: Partial<GameState>): void;
  errorMessage(text: string): Message;
  execute(nodeId: string): Promise<Message[]>;
  state(): GameState;
  setOutput(output: Record<string, number>): void;
};

export const statefulGame = (
  gameNodes: GameNodes,
  gameData: GameData,
  rawConfig: GameConfig,
  playerData: PlayerData,
): StatefulGame => {
  const setGameState = (partialState: Partial<GameState>): void => {
    gameData.state = { ...gameData.state, ...partialState };
  };

  const state = () => gameData.state;
  const errorMessage = (text: string): Message => ({
    nodeId: undefined as never,
    type: 'text',
    message: text,
    name: rawConfig.narratorName,
    role: Role.Narrator,
  });

  const convertToMap = (
    properties?: RpgConfigProperty[],
  ): Record<string, RpgConfigProperty> => {
    return (
      properties?.reduce((agg, prop) => ({ ...agg, [prop.name]: prop }), {}) ??
      {}
    );
  };

  const properties = Object.values({
    ...convertToMap(gameNodes.setupNode()?.properties),
    ...convertToMap(rawConfig.rpgConfig?.properties),
  });

  const config: StatefulGameConfig = {
    ...rawConfig,
    getProperties: () => properties,
    getPropertyByName: (search) =>
      properties.find(
        ({ name }) => name?.toLowerCase() === search?.toLowerCase(),
      ),
    getEnumOptions: (enumRef) => getEnumOptions(enumRef, properties),
    getProperty: (ref) => properties.find(({ id }) => id === ref),
    getPropertyValue: async (state, ref) => {
      const property = properties.find(({ id }) => id === ref);

      if (!property) {
        return 0;
      }

      const { scope, config } = property;
      const defaultValue =
        config.dataType === DataType.String ||
        config.dataType === DataType.Number
          ? config.defaultValue
          : '';

      if (scope === PropertyScope.Series) {
        return (await playerData.getAttribute(ref)) ?? defaultValue;
      }

      return state.properties[ref] ?? defaultValue;
    },
  };

  const execute = async (nodeId: string): Promise<Message[]> => {
    const result = await executeNodes(gameNodes, nodeId, gameData, config);
    const { messages, state } = result;

    setGameState(state);

    return messages;
  };

  const setOutput = (output: Record<string, number>) => {
    setGameState({ input: { ...state().input, ...output } });
  };

  const addSelectedOption = (nodeId: string, optionId: string) => {
    const selectedOptions = state().selectedOptions ?? {};
    const nodeOptions = selectedOptions[nodeId] ?? [];

    setGameState({
      selectedOptions: {
        ...selectedOptions,
        [nodeId]: uniq([...nodeOptions, optionId]),
      },
    });
  };

  return {
    gameData,
    gameNodes,
    config,
    setGameState,
    state,
    errorMessage,
    execute,
    setOutput,
    addSelectedOption,
  };
};
