import {
  StatBlockLayout,
  type StatBlockData,
  type StatBlockNodeData,
} from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const statBlock = async (
  block: GameNode<StatBlockNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  const description = await convertToText(
    block.description ?? '',
    state,
    config,
    playerData,
  );
  const name = await convertToText(block.name ?? '', state, config, playerData);
  const subtitle = await convertToText(
    block.subtitle ?? '',
    state,
    config,
    playerData,
  );
  const layout = block.layout ?? StatBlockLayout.Compact;
  const sections = await Promise.all(
    block.sections.map(async (section) => {
      const title = section.title
        ? await convertToText(section.title, state, config, playerData)
        : section.title;

      return {
        ...section,
        title,
        fields: await Promise.all(
          section.fields.map(async (field) => ({
            ...field,
            name: await convertToText(field.name, state, config, playerData),
            value: await convertToText(field.value, state, config, playerData),
          })),
        ),
      };
    }),
  );

  const image = block.image;
  const data: StatBlockData = {
    name,
    subtitle,
    description,
    layout,
    sections,
    image,
  };

  return {
    messages: [{ nodeId: block.id, id: block.id, type: 'stat-block', data }],
    haltExecution: true,
    state,
  };
};
