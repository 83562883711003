import { Button, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  useUploadImage,
  UploadedImage as Image,
} from '../hooks/useUploadImage';
import { ImageSelector } from './ImageSelector';
import { UploadedImage } from './UploadedImage';

type Props = {
  uploadPath: string;
  value: string | undefined;
  isInvalid?: boolean;
  onChange: (image: Image | undefined) => void;
  insideFlexContainer?: boolean;
  containerAspectRatio?: {
    width: string;
    height: string;
  };
};

export const ImageInput: React.FC<Props> = (props) => {
  const { onChange, value, uploadPath } = props;
  const [uploadType, setUploadType] = useState<'computer' | 'url'>('computer');
  const [url, setUrl] = useState('');
  const [uploadError, setUploadError] = useState('');
  const { uploadImage, uploadFromUrl } = useUploadImage(uploadPath);
  const [isUploadingFromUrl, setIsUploadingFromUrl] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const isValidUrl = useMemo(() => {
    if (!url) return false;

    try {
      new URL(url);

      return true;
    } catch (err) {
      return false;
    }
  }, [url]);

  const onUpload = async () => {
    if (url) {
      setIsUploadingFromUrl(true);
      setUploadError('');

      try {
        const uploadedImage = await uploadFromUrl(url);

        if (uploadedImage) {
          onChange(uploadedImage);
          setUrl('');
        }
      } catch (err) {
        setUploadError(
          'Unable to download this image, try saving to your computer and uploading it or another URL.',
        );
      } finally {
        setIsUploadingFromUrl(false);
      }
    }
  };

  const onFileSelected = async (file: File) => {
    setIsUploading(true);
    const uploadedImage = await uploadImage(file).finally(() =>
      setIsUploading(false),
    );

    if (uploadedImage) {
      onChange(uploadedImage);
    }
  };

  return (
    <Container
      $isInvalid={props.isInvalid || !!uploadError}
      $insideFlexContainer={props?.insideFlexContainer}
    >
      {value ? (
        <Field label="Uploaded image">
          <AspectRatio $aspectRatio={props?.containerAspectRatio}>
            <UploadedImage
              imageUrl={value}
              onClear={() => onChange(undefined)}
              aspectRatio={props?.containerAspectRatio}
            />
          </AspectRatio>
        </Field>
      ) : (
        <>
          <Field label="Upload image from:">
            <ButtonsContainer>
              <Button
                size="md"
                variant="imageUpload"
                isDisabled={isUploading || isUploadingFromUrl}
                isActive={uploadType === 'computer'}
                onClick={() => setUploadType('computer')}
              >
                Computer
              </Button>
              <Button
                size="md"
                variant="imageUpload"
                isDisabled={isUploading || isUploadingFromUrl}
                isActive={uploadType === 'url'}
                onClick={() => setUploadType('url')}
              >
                URL
              </Button>
            </ButtonsContainer>
          </Field>

          {uploadType === 'computer' ? (
            <AspectRatio $aspectRatio={props?.containerAspectRatio}>
              <ImageSelector
                isLoading={isUploading}
                onFileSelected={onFileSelected}
              />
            </AspectRatio>
          ) : (
            <FormControl isInvalid={!!uploadError}>
              <UrlContainer>
                <Input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  isInvalid={props.isInvalid || !!uploadError}
                  isDisabled={isUploadingFromUrl}
                />
                <Button
                  fontSize={'14px'}
                  variant="primary"
                  size="lg"
                  onClick={onUpload}
                  isDisabled={!isValidUrl}
                  isLoading={isUploadingFromUrl}
                >
                  Upload
                </Button>
              </UrlContainer>
              <FormErrorMessage>{uploadError}</FormErrorMessage>
            </FormControl>
          )}
        </>
      )}
    </Container>
  );
};

const AspectRatio = styled.div<{
  $aspectRatio?: Props['containerAspectRatio'];
}>`
  width: ${({ $aspectRatio }) =>
    $aspectRatio?.width
      ? `calc(${$aspectRatio?.width} + ${dimensions.size10})`
      : '100%'};
  height: ${({ $aspectRatio }) =>
    $aspectRatio?.height
      ? `calc(${$aspectRatio?.height} + ${dimensions.size10})`
      : '100%'};
  margin: ${({ $aspectRatio }) => (!!$aspectRatio ? '0 auto' : 'unset')};
  ${({ $aspectRatio }) => !!$aspectRatio && 'overflow: hidden;'}
`;

const Container = styled.div<{
  $insideFlexContainer?: boolean;
  $isInvalid?: boolean;
}>`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size24};
  width: 100%;
  max-width: ${dimensions.size600};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border: ${dimensions.size1} solid
    ${({ theme, $isInvalid }) =>
      $isInvalid
        ? theme.colors.base.red['1000']
        : theme.colors.background.shade};

  border-radius: ${dimensions.size4};
  ${({ $insideFlexContainer }) => ($insideFlexContainer ? 'flex: 1;' : '')}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  width: 100%;
`;

const UrlContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

export const Field: React.FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children,
}) => {
  return (
    <FieldContainer>
      <Label>{label}</Label>
      {children}
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
`;

const Label = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;
