import { Button, useDisclosure } from '@chakra-ui/react';
import { DataType, RpgConfig, RpgConfigProperty } from '@common/studio-types';
import { Icon, TextInput } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { EnumPropertyForm } from './EnumPropertyForm';
import { NumberPropertyForm } from './NumberPropertyForm';
import { StringPropertyForm } from './StringPropertyForm';

type PropertyFormProps = {
  onChange: (data: RpgConfigProperty) => void;
  data: RpgConfigProperty;
  onRemove: () => void;
  rpgConfig: RpgConfig;
};

const icons: Record<DataType, string> = {
  [DataType.Number]: 'number-type',
  [DataType.String]: 'text-type',
  [DataType.Enum]: 'enum-type',
};

export const PropertyForm: React.FC<PropertyFormProps> = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { data, onChange, onRemove, rpgConfig } = props;
  const icon = icons[data.config.dataType];

  return (
    <Container>
      <RowContainer>
        <DataTypeContainer>
          <Icon name={icon} size={rawDimensions.size16} />
        </DataTypeContainer>

        <TextInput
          placeholder="Name"
          maxCharacters={20}
          isInvalid={!data.name}
          value={data.name}
          onChange={(e) => onChange({ ...data, name: e.target.value })}
        />

        <Button
          variant="default"
          height="48px"
          width="48px"
          fontSize={dimensions.size12}
          onClick={onToggle}
        >
          <Icon
            name={isOpen ? 'chevron-up' : 'chevron-down'}
            size={rawDimensions.size20}
          />
        </Button>

        <Button variant="default" onClick={onRemove} height="48px">
          <Icon name="trash" size={rawDimensions.size20} />
        </Button>
      </RowContainer>

      {isOpen && (
        <DetailsContainer>
          {data.config.dataType === DataType.Number && (
            <NumberPropertyForm
              propertyId={data.id}
              rpgConfig={rpgConfig}
              data={data.config}
              onChange={(config) => onChange({ ...data, config })}
            />
          )}
          {data.config.dataType === DataType.String && (
            <StringPropertyForm
              data={data.config}
              onChange={(config) => onChange({ ...data, config })}
            />
          )}
          {data.config.dataType === DataType.Enum && (
            <EnumPropertyForm
              data={data.config}
              onChange={(config) => onChange({ ...data, config })}
            />
          )}
        </DetailsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: ${dimensions.size16};
  background: #111;
  border-radius: ${dimensions.size8};
`;

const DataTypeContainer = styled.div`
  ${textStyles.body.b12sb}
  display:flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.text.header};

  border-radius: ${dimensions.size4};
`;
