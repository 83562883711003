import { DataType, type Value } from '@common/studio-types';
import type { StatefulGameConfig } from '../../createGame/statefulGame';
import type { GameState } from '../../game';
import type { PlayerData } from '../../game/player';
import { getEnumValue } from './getEnumValue';
import { getNumberValue } from './getNumberValue';
import { getStringValue } from './getStringValue';
import type { ReturnedValue } from './types';

export const getValue = async <T extends string | number = string | number>(
  value: Value,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ReturnedValue<T>> => {
  if (value.dataType === DataType.Number) {
    return getNumberValue(value, state, config, playerData) as Promise<
      ReturnedValue<T>
    >;
  } else if (value.dataType === DataType.String) {
    return getStringValue(value, state, config, playerData) as Promise<
      ReturnedValue<T>
    >;
  } else if (value.dataType === DataType.Enum) {
    return getEnumValue(value, state, config) as Promise<ReturnedValue<T>>;
  } else {
    return { value: '', visualValue: '', label: '' } as ReturnedValue<T>;
  }
};
