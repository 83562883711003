import { Button } from '@chakra-ui/react';
import { RpgConfigAiGeneration } from '@common/studio-types';
import { Icon, ListItem, ListItemColumn } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueBadge } from '../../../value/ValueBadgeV2';

type Props = {
  aiGeneration: RpgConfigAiGeneration;
  onRemove: () => void;
  onEdit: () => void;
};

export const AiGenerationRow: React.FC<Props> = (props) => {
  const { aiGeneration } = props;

  return (
    <>
      <ListItem onClick={props.onEdit} draggable record={aiGeneration}>
        <ListItemColumn width="30%">
          {aiGeneration.displayName || (
            <StyledValueBadge value={aiGeneration.value} hideIcon />
          )}
        </ListItemColumn>
        <ListItemColumn flex>
          <StyledValueBadge value={aiGeneration.value} />
        </ListItemColumn>
        <ListItemColumn reverse width={dimensions.size120}>
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
          >
            <Icon name="edit" size={rawDimensions.size20} />
          </Button>

          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove();
            }}
          >
            <Icon name="trash" size={rawDimensions.size20} />
          </Button>
        </ListItemColumn>
      </ListItem>
    </>
  );
};

const StyledValueBadge = styled(ValueBadge)`
  ${textStyles.body.b14m}
  background: transparent;
  gap: ${dimensions.size12};
  padding: ${dimensions.size0};
`;
