import { env } from '@maestro/env';
import { useParams } from 'react-router-dom';

export const useGenerateAiEpisode = () => {
  const { id } = useParams();

  return async (props: Record<string, string | number>): Promise<void> => {
    const body = JSON.stringify({ props });
    const response = await fetch(
      `${env.VITE_MAESTRO_API_URL}/studio/${id}/generate-episode`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body,
      },
    );

    if (!response.ok) {
      throw new Error('Failed to generate episode');
    }

    const { episodeId } = await response.json();

    if (episodeId) {
      window.location.href = `/studio/${episodeId}`;
    }
  };
};
