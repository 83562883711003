export const AppleIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9983 15.1717C11.2229 15.9233 10.3764 15.8047 9.56144 15.4486C8.69905 15.0847 7.90786 15.0689 6.99799 15.4486C5.85868 15.9392 5.25738 15.7968 4.57696 15.1717C0.715965 11.192 1.28562 5.13155 5.6688 4.91002C6.7369 4.9654 7.48062 5.4955 8.10565 5.54297C9.03926 5.35308 9.9333 4.80716 10.9302 4.87837C12.1249 4.97331 13.0268 5.44802 13.6202 6.30251C11.1517 7.78203 11.7372 11.0338 14 11.9437C13.549 13.1305 12.9635 14.3093 11.9904 15.1796L11.9983 15.1717ZM8.02654 4.86255C7.90786 3.0982 9.33991 1.64241 10.9856 1.5C11.215 3.54126 9.1342 5.06034 8.02654 4.86255Z"
        fill="white"
      />
    </svg>
  );
};
