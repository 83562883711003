import { CoinTossNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { validateText } from '../validationUtils';
import { CoinTossForm } from './CoinTossForm';
import { CoinTossNode } from './CoinTossNode';

const name = 'Coin toss';
const rules = { min: 1, max: 100 };

export const coinTossNode: NodeConfig<
  StudioNodeType.CoinToss,
  CoinTossNodeData
> = {
  type: StudioNodeType.CoinToss,
  name,
  group: NodeGroup.Advanced,
  icon: 'coin-flip',
  node: CoinTossNode,
  form: CoinTossForm,
  sourceHandles: (data) => [
    {
      handleId: `${data.id}-success`,
      name: 'success',
      description: data.prompt,
    },
    { handleId: `${data.id}-fail`, name: 'fail', description: data.prompt },
  ],
  getNodeData: (data) => ({ title: name, description: data.prompt }),
  cloneNodeData: ({ id, prompt, property }) => {
    const newId = toNodeId();

    return {
      data: {
        id: newId,
        prompt,
        property: property ? { ...property } : undefined,

        failNodeId: undefined as never,
        successNodeId: undefined as never,
      },
      connections: [
        { oldId: `${id}-success`, newId: `${newId}-success` },
        { oldId: `${id}-fail`, newId: `${newId}-fail` },
      ],
    };
  },
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.CoinToss,
    attributeRef: undefined,
    prompt: '',
    failNodeId: undefined as never,
    successNodeId: undefined as never,
  }),
  validateNodeData: (data) =>
    validateText(data.prompt, rules) ? [] : [{ text: data.prompt }],
};
