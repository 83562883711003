import { Checkbox } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  title: string;
  description: string;
};

export const CheckButton: React.FC<Props> = (props) => {
  return (
    <Container
      $selected={props.value}
      onClick={() => props.onChange?.(!props.value)}
    >
      <Checkbox variant="checkButton" isChecked={props.value} />
      <ColumnContainer>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </ColumnContainer>
    </Container>
  );
};

const Container = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  padding: ${dimensions.size8} ${dimensions.size16};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.base.dark['1000']};
  border: solid ${dimensions.size2}
    ${({ theme }) => theme.colors.base.dark['1000']};
  border-color: ${({ $selected, theme }) =>
    $selected
      ? theme.colors.base.accent['1000']
      : theme.colors.border.default['100']};
  border-radius: ${dimensions.size4};
  transition: border-color 0.2s;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;
