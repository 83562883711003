import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Edge, Node } from 'reactflow';
import {
  Suggestions,
  SuggestionsSection,
} from '../../components/suggestions/suggestion.types';
import { nodeConfigs } from '../../nodes';

const nodesWithoutTargetHandle = [
  StudioNodeType.Start,
  StudioNodeType.EpisodeSetup,
  StudioNodeType.Comment,
];

export const getMissingConnectorsSuggestion = (
  edges: Edge[],
  nodes: Node[],
  selectNode: (nodeId: string) => void,
): SuggestionsSection | undefined => {
  const missingConnectors: Suggestions[] = [];

  nodes.forEach((node: Node<StudioNodeData>) => {
    const nodeConfig = nodeConfigs[node.data.type];
    const noTargetHandle = nodesWithoutTargetHandle.includes(node.data.type);

    if (noTargetHandle || !nodeConfig) {
      return;
    }

    const selectThisNode = () => selectNode(node.id);
    const hasSource = edges.some((edge) => edge.target === node.id);
    const isConnected =
      nodeConfig.sourceHandles || edges.some((edge) => edge.source === node.id);
    const isMissingConnector = !hasSource || !isConnected;

    if (isMissingConnector) {
      const { title, description } = nodeConfig.getNodeData(node.data);

      missingConnectors.push({
        title,
        id: node.id,
        description,
        action: { onClick: selectThisNode },
      });
    }

    const sourceHandles = nodeConfig.sourceHandles?.(node.data) ?? [];

    if (sourceHandles.length) {
      sourceHandles.forEach((sourceHandle) => {
        if (
          !edges.some((edge) =>
            edge.sourceHandle?.includes(sourceHandle.handleId),
          )
        ) {
          missingConnectors.push({
            id: sourceHandle.handleId,
            title: nodeConfig.name,
            subtitle: sourceHandle.name,
            description: sourceHandle.description,
            action: { onClick: selectThisNode },
          });
        }
      });
    }
  });

  return missingConnectors.length
    ? {
        title: 'Missing connectors',
        suggestions: missingConnectors,
        type: 'missingConnectors',
      }
    : undefined;
};
