import {
  BackgroundColorDesignSystem,
  backgroundColorMap,
} from '../theme/backgroundColors';
import { BaseColorDesignSystem, baseColorMap } from '../theme/baseColors';
import { BorderColorDesignSystem, borderColorMap } from '../theme/borderColors';
import { FillColorDesignSystem, fillColorMap } from '../theme/fillColors';
import { ColorTheme, ThemeMode } from '../types/theme';
import { GameplayColorDesignSystem, gameplayColorMap } from './gameplayColors';
import { TextColorDesignSystem, textColorMap } from './textColors';

export type DesignSystem = {
  colors: {
    warning: string;
    base: BaseColorDesignSystem;
    background: BackgroundColorDesignSystem;
    border: BorderColorDesignSystem;
    fill: FillColorDesignSystem;
    text: TextColorDesignSystem;
    gameplay: GameplayColorDesignSystem;
  };
};

export const getTheme = (mode: ThemeMode): DesignSystem => ({
  ...generateTheme(mode),
});

const generateTheme = (themeMode: ThemeMode): DesignSystem => {
  const base = toThemeModeValues(baseColorMap, themeMode);
  const background = toThemeModeValues(backgroundColorMap, themeMode);
  const border = toThemeModeValues(borderColorMap, themeMode);
  const fill = toThemeModeValues(fillColorMap, themeMode);
  const text = toThemeModeValues(textColorMap, themeMode);
  const gameplay = toThemeModeValues(gameplayColorMap, themeMode);

  return {
    colors: {
      warning: '#C0A54C',
      base,
      background,
      border,
      fill,
      text,
      gameplay,
    },
  };
};

const toThemeModeValues = <T>(colorMap: ColorTheme<T>, themeMode: ThemeMode) =>
  themeMode === ThemeMode.Dark ? colorMap.dark : colorMap.light;
