import { Button, Tooltip } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Props = {
  label: string;
  episodeId: string;
};

export const EditEpisodeButton: FC<Props> = ({ label, episodeId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [displayToolTip, setDisplayTooltip] = useState(false);

  return (
    <Tooltip
      label="Edit episode details"
      autoFocus={false}
      isOpen={displayToolTip}
    >
      <Button
        variant={'studioTopBarBreadcrumbActive'}
        onClick={() =>
          navigate(
            `${!!location.pathname.match(/studio/) ? location.pathname : `/studio/${episodeId}`}?edit=episode&episodeId=${episodeId}`,
          )
        }
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
      >
        {label}
      </Button>
    </Tooltip>
  );
};
