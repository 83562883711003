import {
  DiceRollAction,
  DiceRollNodeData,
  DiceRollStrategy,
} from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import {
  createDiceRollResultMessage,
  getModifier,
  getResult,
  rollDices,
} from '../diceRoll/diceRoll.utils';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const diceRoll = async (
  node: GameNode<DiceRollNodeData>,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ActionResult> => {
  const outputName = node.outputName || 'default';
  const modifier = await getModifier(node, state, config, playerData);
  const prompt = await convertToText(node.prompt, state, config, playerData);

  if (node.rollAction === DiceRollAction.User) {
    return {
      messages: [
        {
          nodeId: node.id,
          type: 'dice-roll-interaction',
          id: node.id,
          prompt,
          dices: node.dices,
          modifier,
          classDifficulty:
            node.strategy.type === DiceRollStrategy.ClassDifficulty
              ? node.strategy.classDifficulty
              : undefined,
        },
      ],
      state: { ...state, currentInteraction: node.id },
      haltExecution: true,
    };
  }

  const diceRollResult = rollDices(node.dices);
  const output = await getResult(
    diceRollResult,
    node,
    state,
    config,
    playerData,
  );

  if (node.rollAction === DiceRollAction.AutoPlay) {
    return {
      messages: [createDiceRollResultMessage(node, diceRollResult, modifier)],
      state,
      output: { [outputName]: output },
      haltExecution: false,
    };
  }

  return {
    messages: [],
    state,
    output: { [outputName]: output },
    haltExecution: false,
  };
};
