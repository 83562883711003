import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D12: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  const mainColor = alterHslColor(`hsl(358, 69%, 55%)`, multipliers);

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M61.7807 66.6929H34.819L27.7722 77.4163L48.2999 83.8503L68.8275 77.4163L61.7807 66.6929Z"
        fill={mainColor}
      />
      <path
        d="M61.7807 66.6929H34.819L27.7722 77.4163L48.2999 83.8503L68.8275 77.4163L61.7807 66.6929Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M26.24 40.9566L14.291 37.5864L14.5974 59.646L27.7719 77.4162L34.8187 66.6928L26.24 40.9566Z"
        fill={mainColor}
      />
      <path
        d="M26.24 40.9566L14.291 37.5864L14.5974 59.646L27.7719 77.4162L34.8187 66.6928L26.24 40.9566Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M70.3592 40.9566L82.3081 37.5864L82.0017 59.646L68.8273 77.4162L61.7804 66.6928L70.3592 40.9566Z"
        fill={mainColor}
      />
      <path
        d="M70.3592 40.9566L82.3081 37.5864L82.0017 59.646L68.8273 77.4162L61.7804 66.6928L70.3592 40.9566Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M48.2998 11.8501V25.0246L26.2402 40.9565L14.2913 37.5863L27.4657 19.8161L48.2998 11.8501Z"
        fill={mainColor}
      />
      <path
        d="M48.2998 11.8501V25.0246L26.2402 40.9565L14.2913 37.5863L27.4657 19.8161L48.2998 11.8501Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M48.2996 11.8501V25.0246L70.3592 40.9565L82.3081 37.5863L69.1336 19.8161L48.2996 11.8501Z"
        fill={mainColor}
      />
      <path
        d="M48.2996 11.8501V25.0246L70.3592 40.9565L82.3081 37.5863L69.1336 19.8161L48.2996 11.8501Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M48.2998 25.0244L70.3594 40.9563L61.7807 66.6925L34.819 66.6925L26.2402 40.9563L48.2998 25.0244Z"
        fill={mainColor}
      />
    </svg>
  );
};
