import { CreateEpisodeButton } from '@maestro/studio/components/episode/CreateEpisodeButton';
import React from 'react';

type Props = {
  seriesId: string;
};

export const NewEpisodeButton: React.FC<Props> = ({ seriesId }) => {
  return <CreateEpisodeButton seriesId={seriesId} />;
};
