import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D10: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  const mainColor = alterHslColor(`hsl(325, 77%, 53%)`, multipliers);

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M25.5001 57.6L48.3001 12L12.0001 39.9L11.1001 57.3L25.5001 57.6Z"
        fill={mainColor}
      />
      <path
        d="M25.5001 57.6L48.3001 12L12.0001 39.9L11.1001 57.3L25.5001 57.6Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M71.1 57.6L48.3 12L84.6 39.9L85.5 57.3L71.1 57.6Z"
        fill={mainColor}
      />
      <path
        d="M71.1 57.6L48.3 12L84.6 39.9L85.5 57.3L71.1 57.6Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M25.5 57.6L48.3 12L71.1 57.6L48.3 69L25.5 57.6Z"
        fill={mainColor}
      />
      <path
        d="M48.3 83.9998V68.9998L25.5 57.5998L10.8 57.2998L48.3 83.9998Z"
        fill={mainColor}
      />
      <path
        d="M48.3 83.9998V68.9998L25.5 57.5998L10.8 57.2998L48.3 83.9998Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M48.3 83.9998V68.9998L71.1 57.5998L85.8 57.2998L48.3 83.9998Z"
        fill={mainColor}
      />
      <path
        d="M48.3 83.9998V68.9998L71.1 57.5998L85.8 57.2998L48.3 83.9998Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
};
