import { dimensions, textStyles } from '@maestro/styles';
import { ReactNode, FC } from 'react';
import styled from 'styled-components';
import { Link } from './Link';

type BreadcrumbItem = {
  label: React.ReactNode;
  to?: string;
};

type Props = {
  breadcrumb: BreadcrumbItem[];
  additionalItems?: ReactNode;
};

export const Breadcrumb: FC<Props> = ({ breadcrumb, additionalItems }) => {
  return (
    <BreadcrumbContainer>
      {breadcrumb.map((item, index) => {
        const isLast = index === breadcrumb.length - 1;

        return (
          <Wrapper key={index}>
            <ItemContainer>
              <BreadcrumbItem $isLast={isLast}>
                {item.to ? <Link to={item.to}>{item.label}</Link> : item.label}
              </BreadcrumbItem>
            </ItemContainer>
            {!isLast && <BreadcrumbItem>/</BreadcrumbItem>}
          </Wrapper>
        );
      })}
      {additionalItems}
    </BreadcrumbContainer>
  );
};

const BreadcrumbItem = styled.div<{ $isLast?: boolean }>`
  ${textStyles.label.lb14sb}
  color: ${({ theme, $isLast }) =>
    $isLast ? theme.colors.text.header : theme.colors.text.placeholder};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;
