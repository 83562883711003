import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { rawDimensions } from '@maestro/styles';
import { EmptyState } from '../../../../components/EmptyState';
import { WorldLocation } from '../../types';
import {
  Container,
  GeneratorFormContainer,
  Header,
  TextContainer,
} from './GeneratorComponents';
import { LocationForm } from './LocationForm';

type Props = {
  submitPressed?: boolean;
  locations: WorldLocation[];
  onNewLocation: () => void;
  onLocationChange: (location: WorldLocation, index: number) => void;
  onLocationDelete: (index: number) => void;
};

export const LocationGenerator: React.FC<Props> = ({
  locations,
  submitPressed,
  onNewLocation,
  onLocationChange,
  onLocationDelete,
}) => {
  return (
    <Container>
      <Header>
        <TextContainer></TextContainer>
        <Button
          onClick={onNewLocation}
          textTransform={'uppercase'}
          variant="outline"
          leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
        >
          Location
        </Button>
      </Header>
      {!locations ||
        (locations.length === 0 && (
          <GeneratorFormContainer>
            <EmptyState
              title="No locations, add your first location"
              button={{ label: 'Add Location', onClick: onNewLocation }}
            />
          </GeneratorFormContainer>
        ))}
      {locations?.map((location, index) => (
        <LocationForm
          submitPressed={submitPressed}
          key={`location-${index}`}
          location={location}
          locations={locations}
          onDelete={() => onLocationDelete(index)}
          onChange={(nextLocation) => onLocationChange(nextLocation, index)}
          index={index}
        />
      ))}
    </Container>
  );
};
