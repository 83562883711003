import React, { memo } from 'react';
import { SelectedInteractionOptionMessage } from '@enigma-engine/core';
import { MaestroSelectedInteraction } from './MaestroSelectedInteraction';

type Props = {
  message: SelectedInteractionOptionMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  const icon =
    message.selectedOption.addon?.type === 'coin-toss'
      ? 'coin-flip'
      : undefined;

  return (
    <MaestroSelectedInteraction
      text={message.selectedOption.text}
      icon={icon}
    />
  );
};

export const MaestroGameMessageSelectedPlayerChoice = memo(Component);
