import { DataType, ValueType, hudIcons } from '@common/studio-types';
import { getValue } from '../actions/utils';
import type { EpisodeData, EpisodeDataHudItem } from '../game/episodeData';
import type { PlayerData } from '../game/player';
import type { StatefulGame, StatefulGameConfig } from './statefulGame';

export const getEpisodeData = async (
  state: StatefulGame,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<EpisodeData> => {
  if (!config.rpgConfig) {
    return { hud: [], customItems: [] };
  }

  const hud: EpisodeDataHudItem[] = [];
  const gameState = state.state();

  for (const hudItem of config.rpgConfig.hud) {
    const value = await getValue<number>(
      hudItem.value,
      gameState,
      config,
      playerData,
    );
    let maxValue: number | undefined = undefined;

    if (hudItem.displayMaxValue && hudItem.value.type === ValueType.Property) {
      const property = config.getProperty(hudItem.value.ref);

      if (
        property?.config.dataType === DataType.Number &&
        property.config.maxValue
      ) {
        const result = await getValue<number>(
          property.config.maxValue,
          gameState,
          config,
          playerData,
        );
        maxValue = result.value;
      }
    }

    hud.push({
      id: hudItem.id,
      label: hudItem.displayName || value.label,
      value: value.value,
      maxValue,
      iconUrl: hudIcons.find((icon) => icon.ref === hudItem.icon)?.icon ?? '',
    });
  }

  const customItems = config.rpgConfig.customItems ?? [];

  return { hud, customItems };
};
