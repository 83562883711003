import {
  ConditionCheckNodeData,
  ConditionOperator,
  Value,
} from '@common/studio-types';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { ValueInput } from '../../components/value/ValueInput';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { useStudioUtils } from '../../hooks/useStudioUtils';
import { StudioNodeFormComponent } from '../Node.types';
import { Condition } from './Condition';
import { OperatorDropdown } from './OperatorDropdown';

export const ConditionCheckForm: StudioNodeFormComponent<
  ConditionCheckNodeData
> = ({ data, onChange }) => {
  const { rpgConfig } = useStudioRpgConfig();
  const { createStaticValue } = useStudioUtils(rpgConfig);

  const onValueAChange = (valueA: Value) => {
    onChange({
      ...data,
      valueA,
      valueB:
        valueA.dataType === data.valueB.dataType
          ? data.valueB
          : createStaticValue(valueA),
    });
  };

  return (
    <Container>
      <Hint>
        Condition is used to determine the flow of the game, you can use it to
        check player attribute, dice roll results, or any other condition.
      </Hint>

      <Field label="Condition">
        <Condition data={data} />
      </Field>

      <ValueInput
        rpgConfig={rpgConfig}
        value={data.valueA}
        onChange={onValueAChange}
      />

      <Field label="Operator">
        <OperatorDropdown
          dataType={data.valueA.dataType}
          value={data.operator}
          onChange={(operator: ConditionOperator) =>
            onChange({ ...data, operator })
          }
        />
      </Field>

      <ValueInput
        rpgConfig={rpgConfig}
        value={data.valueB}
        onChange={(valueB) => onChange({ ...data, valueB })}
        referenceValue={data.valueA}
      />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
