import {
  EndEpisodeAction,
  EndEpisodeNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { validateText } from '../validationUtils';
import { EndEpisodeForm } from './EndEpisodeForm';
import { EndEpisodeNode } from './EndEpisodeNode';

const name = 'End episode';
const rules = { min: 1, max: 200 };

export const endEpisodeNode: NodeConfig<
  StudioNodeType.EndEpisode,
  EndEpisodeNodeData
> = {
  type: StudioNodeType.EndEpisode,
  name,
  icon: 'objective',
  node: EndEpisodeNode,
  form: EndEpisodeForm,
  cloneNodeData: ({ message, action }) => ({
    data: { id: toNodeId(), message, action },
  }),
  sourceHandles: () => [], // no source handle
  getNodeData: (data) => ({ title: name, description: data.message }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.EndEpisode,
    message: '',
    action: EndEpisodeAction.PlayNextEpisode,
  }),
  validateNodeData: (data) =>
    validateText(data.message, rules) ? [] : [{ text: data.message }],
};
