import { dimensions } from '@maestro/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size32};
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  max-width: 70%;
`;

export const GeneratorFormHeader = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size16};
  align-items: center;
  justify-content: space-between;
`;

export const GeneratorFormContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

export const GeneratorItemBody = styled.div`
  gap: ${dimensions.size16};
`;

export const GeneratorItemOutputContainer = styled.div`
  flex: 1;
`;
