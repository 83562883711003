import {
  DataType,
  PropertyScope,
  RpgConfigProperty,
  ValueType,
} from '@common/studio-types';
import { Menu, MenuButton, MenuItem, MenuList } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{
  scope: PropertyScope;
  onAddProperty: (newProperty: RpgConfigProperty) => void;
  disabledTypes?: DataType[];
}>;

export const AddPropertyButton: React.FC<Props> = (props) => {
  const { onAddProperty, scope, children, disabledTypes } = props;

  const onAdd = (type: DataType) => {
    const id = window.crypto.randomUUID();
    let config: RpgConfigProperty['config'];

    if (type === DataType.String) {
      config = {
        dataType: DataType.String,
        defaultValue: '',
      };
    } else if (type === DataType.Number) {
      config = {
        dataType: DataType.Number,
        minValue: {
          dataType: DataType.Number,
          type: ValueType.StaticValue,
          value: 0,
        },
        defaultValue: 0,
        maxValue: {
          dataType: DataType.Number,
          type: ValueType.StaticValue,
          value: 100,
        },
      };
    } else if (type === DataType.Enum) {
      const enumId = window.crypto.randomUUID();

      config = { dataType: DataType.Enum, enumRef: enumId, options: [] };
    }

    onAddProperty({ id, name: '', scope, config: config! });
  };

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuList>
        {!disabledTypes?.includes(DataType.Number) && (
          <MenuItem onClick={() => onAdd(DataType.Number)}>
            <ValueContainer>
              <Name>Number</Name>
              <Description>
                Used to store numeric values, like strength, health, etc.
              </Description>
            </ValueContainer>
          </MenuItem>
        )}

        {!disabledTypes?.includes(DataType.String) && (
          <MenuItem onClick={() => onAdd(DataType.String)}>
            <ValueContainer>
              <Name>Text</Name>
              <Description>
                Used to store text values, like character name, etc.
              </Description>
            </ValueContainer>
          </MenuItem>
        )}
        {!disabledTypes?.includes(DataType.Enum) && (
          <MenuItem onClick={() => onAdd(DataType.Enum)}>
            <ValueContainer>
              <Name>Choice</Name>
              <Description>
                Typically used to store an option on a list, like race,
                character class, profession, etc.
              </Description>
            </ValueContainer>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;
