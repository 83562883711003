import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { DiceRollResultMessage } from '@enigma-engine/core';
import { Dice } from './diceRoll/Dice';
import { DiceRollDetails } from './diceRoll/DiceRollDetails';

type Props = {
  message: DiceRollResultMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  return (
    <DiceRollContainer>
      <DiceContainer>
        {message.result.map(({ dice, result }, index) => (
          <Dice key={index} id={dice} result={result} />
        ))}
      </DiceContainer>
      <DiceRollDetails message={message} />
    </DiceRollContainer>
  );
};

export const MaestroGameMessageDiceRollResult = memo(Component);

const DiceRollContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size12};
`;

const DiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${dimensions.size16};
`;
