import type { CommentNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps } from 'reactflow';
import styled from 'styled-components';

type Props = NodeProps<CommentNodeData>;

const Node: React.FC<Props> = ({ data, selected }) => {
  return (
    <Container
      title="Comment"
      $selected={selected}
      $arrowPosition={data.arrowPosition}
    >
      <Text>{data.comment}</Text>
    </Container>
  );
};

export const CommentNode = memo(Node);

const Container = styled.div<{
  $selected: boolean;
  $arrowPosition?: 'top' | 'right' | 'bottom' | 'left';
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${dimensions.size280};
  max-width: ${dimensions.size360};
  min-height: ${dimensions.size48};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.warning};
  border-radius: ${dimensions.size8};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.9)};

  &:after {
    position: absolute;
    ${({ $arrowPosition }) => {
      switch ($arrowPosition) {
        case 'top':
          return 'top: -7px; left: calc(50% - 8px);';
        case 'right':
          return 'right: -7px; top: calc(50% - 8px);';
        case 'bottom':
          return 'bottom: -7px; left: calc(50% - 8px);';
        case 'left':
          return 'left: -7px; top: calc(50% - 8px);';
        default:
          return 'left: 0';
      }
    }}
    width: 0;
    height: 0;
    content: '';
    border: ${dimensions.size8} solid transparent;

    ${({ $arrowPosition, theme }) => {
      switch ($arrowPosition) {
        case 'top':
          return `border-bottom: 8px solid ${theme.colors.warning}; border-top: 0;`;
        case 'right':
          return `border-left: 8px solid ${theme.colors.warning}; border-right: 0;`;
        case 'bottom':
          return `border-top: 8px solid ${theme.colors.warning}; border-bottom: 0;`;
        case 'left':
          return `border-right: 8px solid ${theme.colors.warning}; border-left: 0;`;
      }
    }}
  }
`;
const Text = styled.div`
  ${textStyles.body.b14m}
  color: black;
  white-space: pre-line;
`;
