import { Button } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { abbreviateNumber } from '@common/utils';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { Item } from '../../../hooks/useItems';

type Props = {
  item: Item;
  quantity: number;
  action?: { label: string; onUse: () => void };
};

export const InventoryItem: React.FC<Props> = ({ item, quantity, action }) => {
  return (
    <Container>
      <ImageWrapper>
        <Image
          src={toOptimizedImageUri(item.imagePath, {
            width: rawDimensions.size72,
            height: rawDimensions.size72,
            dpr: window.devicePixelRatio,
          })}
          alt={item.name}
        />
        {quantity > 1 && (
          <QuantityWrapper>
            <Quantity>{abbreviateNumber(quantity)}</Quantity>
          </QuantityWrapper>
        )}
      </ImageWrapper>
      <DescriptionWrapper>
        <ItemName>{item.name}</ItemName>
        <ItemCategory>{item.category}</ItemCategory>
      </DescriptionWrapper>
      {action && (
        <Button variant="default" size="sm" onClick={action.onUse}>
          {action.label}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size12};
  align-items: center;
  padding: ${dimensions.size8};
`;

const ItemName = styled.div`
  ${textStyles.label.lb16b};
  color: ${({ theme }) => theme.colors.text.header};
`;

const ItemCategory = styled.div`
  ${textStyles.body.b12m};
  color: ${({ theme }) => theme.colors.text.body};
`;

const ImageWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Image = styled.img`
  width: ${dimensions.size72};
  height: ${dimensions.size72};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const QuantityWrapper = styled.div`
  position: absolute;
  top: ${dimensions.size4};
  right: ${dimensions.size4};
  align-items: center;
  justify-content: center;
  min-width: ${dimensions.size20};
  max-width: ${dimensions.size64};
  height: ${dimensions.size20};
  padding: ${dimensions.size1} ${dimensions.size6};
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size4};
`;

const Quantity = styled.div`
  ${textStyles.label.lb12b};
  color: ${({ theme }) => theme.colors.text.header};
`;
