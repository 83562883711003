import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { RpgConfigCustomItem } from '@common/studio-types';
import { useMaestroToast, ModalCloseButton, Loader } from '@maestro/components';
import { toSupabaseClient } from '@maestro/supabase';
import React, { useEffect, useState } from 'react';
import { useGetSeriesRpgConfig } from '../../hooks/useGetSeriesRpgConfig';
import { ItemsForm } from './ItemsForm';
import { ItemFormValue } from './types';

type Props = {
  seriesId: string;
  itemId: string;
  onClose: () => void;
  onUpdated: () => void;
};

export const EditItemModal: React.FC<Props> = (props) => {
  const [item, setItem] = useState<ItemFormValue>({
    category: 'Miscellaneous',
    description: '',
    name: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useMaestroToast();
  const { rpgConfig, saveItemAction, deleteItemAction, fetch } =
    useGetSeriesRpgConfig(props.seriesId);

  useEffect(() => {
    if (!props.itemId) {
      setItem({ category: 'Miscellaneous', description: '', name: '' });
    } else {
      setIsLoading(true);
      fetch().catch(() => void 0);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      toSupabaseClient()
        .from('item')
        .select('*')
        .eq('id', props.itemId)
        .single()
        .then(({ data }) => {
          setItem({
            id: data.id,
            name: data.name,
            description: data.description,
            category: data.category,
            image: data.image_path,
          });
          setIsLoading(false);
        });
    }
  }, [props.itemId]);

  const submit = async (customItem?: RpgConfigCustomItem) => {
    setIsSaving(true);

    try {
      const { error } = await toSupabaseClient()
        .from('item')
        .update({
          name: item.name,
          description: item.description,
          image_path: item.image,
          category: item.category,
        })
        .eq('id', props.itemId);

      if (error) {
        toast({
          status: 'warning',
          title: 'Error while trying to save item',
          description: error.message,
        });
      } else {
        if (customItem) {
          customItem.itemRef = props.itemId;
          await saveItemAction(customItem);
        } else {
          await deleteItemAction(props.itemId);
        }

        props.onUpdated();
        props.onClose();
        toast({
          status: 'success',
          title: 'Item saved',
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen={!!props.itemId}
      onClose={props.onClose}
      size="4xl"
      closeOnEsc={isLoading}
      closeOnOverlayClick={isLoading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span>Edit item</span>
          <ModalCloseButton
            disabled={isLoading || isSaving}
            onClose={props.onClose}
          />
        </ModalHeader>
        {isLoading ? (
          <Loader />
        ) : (
          <ItemsForm
            itemId={props.itemId}
            rpgConfig={rpgConfig}
            seriesId={props.seriesId}
            onCancel={props.onClose}
            onSubmit={submit}
            formTitle="Create Item"
            item={item}
            onChange={setItem}
            isLoading={isSaving}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
