import { DataType, ValueType, type Value } from '@common/studio-types';
import type { StatefulGameConfig } from '../../createGame/statefulGame';
import type { GameState } from '../../game';
import type { PlayerData } from '../../game/player';
import type { ReturnedValue } from './types';

export const getStringValue = async (
  value: Value,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ReturnedValue<string>> => {
  if (value.dataType === DataType.String) {
    if (value.type === ValueType.Input) {
      return {
        dataType: DataType.String,
        value: String(state.input[value.ref]) ?? '',
        visualValue: String(state.input[value.ref] ?? ''),
        label: value.ref,
      };
    } else if (value.type === ValueType.Property) {
      const property = config.getProperty(value.ref);
      const propertyValue = await config.getPropertyValue(state, value.ref);

      return {
        dataType: DataType.String,
        value: String(propertyValue),
        visualValue: String(propertyValue),
        label: property?.name ?? '',
      };
    } else if (value.type === ValueType.Item) {
      const item = await playerData.getItem(value.ref);

      return {
        dataType: DataType.String,
        value: item?.name ?? '',
        visualValue: item?.name ?? '',
        label: item?.name ?? '',
      };
    } else if (value.type === ValueType.StaticValue) {
      return {
        dataType: DataType.String,
        value: String(value.value) ?? '',
        visualValue: String(value.value) ?? '',
        label: String(value.value) ?? '',
      };
    }

    return { dataType: DataType.String, value: '', visualValue: '', label: '' };
  }

  throw new Error('Should never go here');
};
