import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { RpgConfigCustomItem } from '@common/studio-types';
import { useGetUserId, useMaestroToast } from '@maestro/components';
import { ModalCloseButton } from '@maestro/components/ModalCloseButton';
import { toSupabaseClient } from '@maestro/supabase';
import React, { useEffect, useState } from 'react';
import { useGetSeriesRpgConfig } from '../../hooks/useGetSeriesRpgConfig';
import { Item } from '../../hooks/useItems';
import { ItemsForm } from './ItemsForm';
import { ItemFormValue } from './types';

type Props = {
  seriesId: string;
  isOpen: boolean;
  onClose: () => void;
  onCreated: (item: Item) => void;
};

export const CreateItemModal: React.FC<Props> = (props) => {
  const userId = useGetUserId();
  const [item, setItem] = useState<ItemFormValue>({
    category: 'Miscellaneous',
    description: '',
    name: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useMaestroToast();
  const { rpgConfig, saveItemAction, fetch } = useGetSeriesRpgConfig(
    props.seriesId,
  );

  useEffect(() => {
    if (!props.isOpen) {
      setItem({ category: 'Miscellaneous', description: '', name: '' });
    } else {
      fetch().catch(() => void 0);
    }
  }, [props.isOpen]);

  const submit = async (customItem?: RpgConfigCustomItem) => {
    setIsLoading(true);

    try {
      const { data, error } = await toSupabaseClient()
        .from('item')
        .insert({
          series_id: props.seriesId,
          name: item.name,
          description: item.description,
          category: item.category,
          image_path: item.image,
          created_by_id: userId ?? '',
        })
        .select('*, series(id, title)');

      if (error) {
        toast({
          status: 'warning',
          title: 'Error while trying to create item',
          description: error.message,
        });
      } else {
        if (customItem) {
          customItem.itemRef = data[0].id;
          await saveItemAction(customItem);
        }

        props.onCreated({
          id: data[0].id,
          name: data[0].name,
          category: data[0].category,
          series: { id: data[0].series.id, title: data[0].series.title },
          description: data[0].description,
          imagePath: data[0].image_path ?? '',
          fromAuthor: true,
        });
        props.onClose();
        toast({
          status: 'success',
          title: 'Item created',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="4xl"
      closeOnEsc={isLoading}
      closeOnOverlayClick={isLoading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span>Create item</span>
          <ModalCloseButton disabled={isLoading} onClose={props.onClose} />
        </ModalHeader>
        <ItemsForm
          rpgConfig={rpgConfig}
          seriesId={props.seriesId}
          onCancel={props.onClose}
          onSubmit={submit}
          formTitle="Create Item"
          item={item}
          onChange={setItem}
          isLoading={isLoading}
        />
      </ModalContent>
    </Modal>
  );
};
