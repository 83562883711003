import { env } from '@maestro/env';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, PropsWithChildren } from 'react';

export const ChildWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

const RootProvider = withLDProvider({
  clientSideID: env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  reactOptions: { useCamelCaseFlagKeys: false },
  options: {
    application: {
      id: 'maestro',
    },
    fetchGoals: false,
  },
})(ChildWrapper) as FC<PropsWithChildren>;

export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  return <RootProvider>{children}</RootProvider>;
};
