import {
  DiscoverySectionType,
  SeriesShortFragment,
  useDeleteDiscoverySectionSeriesMutation,
  useMaestroDiscoveryQuery,
  useUpdateDiscoverySectionSeriesMutation,
} from '@maestro/graphql';
import { useEffect, useState } from 'react';
import { DiscoverySectionItem, toItem } from '../sections.type';

type Result = {
  series: SeriesShortFragment[];
  sections: DiscoverySectionItem[];
  updateSections: (rows: DiscoverySectionItem[]) => void;
  deleteSection: (row: DiscoverySectionItem) => Promise<void>;
  isLoading: boolean;
};

export const useGetSections = (): Result => {
  const [sections, setSections] = useState<DiscoverySectionItem[]>([]);
  const { data, isLoading } = useMaestroDiscoveryQuery({
    input: { includeEmptySections: true },
  });
  const { mutateAsync: deleteSectionMutation } =
    useDeleteDiscoverySectionSeriesMutation();
  const { mutateAsync: updateSectionMutation } =
    useUpdateDiscoverySectionSeriesMutation();

  useEffect(() => {
    if (data?.discovery.sections) {
      setSections(
        data.discovery.sections
          .filter(({ type }) => type === DiscoverySectionType.Series)
          .map(toItem),
      );
    } else {
      setSections([]);
    }
  }, [data?.discovery.sections]);

  const updateSectionOrder = async (section: DiscoverySectionItem) => {
    await updateSectionMutation({
      input: { id: section.id, order: section.order },
    });
  };

  const updateSections = (rows: DiscoverySectionItem[]) => {
    const newOrderSections = rows.map((section, index) => ({
      ...section,
      order: index + 1,
    }));

    const updatedOrderSections = newOrderSections.filter((section) =>
      sections.find(
        ({ id, order }) => id === section.id && order !== section.order,
      ),
    );

    setSections(newOrderSections);

    Promise.all(
      updatedOrderSections.map((section) => updateSectionOrder(section)),
    ).catch(() => void 0);
  };

  const deleteSection = async (section: DiscoverySectionItem) => {
    setSections((prevSections) =>
      prevSections
        .filter(({ id }) => id !== section.id)
        .map((section, index) => ({ ...section, order: index + 1 })),
    );

    await deleteSectionMutation({ id: section.id });
  };

  return {
    sections,
    series: data?.series?.series ?? [],
    updateSections,
    deleteSection,
    isLoading,
  };
};
