import {
  DataType,
  GetValueNodeData,
  StudioNodeType,
  ValueType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { GetValueForm } from './GetValueForm';
import { GetValueNode } from './GetValueNode';

const name = 'Get Value';

export const getValueNode: NodeConfig<
  StudioNodeType.GetValue,
  GetValueNodeData
> = {
  type: StudioNodeType.GetValue,
  name,
  icon: 'get-value',
  group: NodeGroup.Advanced,
  node: GetValueNode,
  form: GetValueForm,
  cloneNodeData: ({ value, outputName }) => ({
    data: { id: toNodeId(), value: { ...value }, outputName },
  }),
  getNodeData: () => ({ title: name }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.GetValue,
    value: { type: ValueType.Item, dataType: DataType.Number, ref: '' },
    outputName: 'default',
  }),
};
