export type InventoryItem = {
  id: string;
  name: string;
  quantity: number;
};

export type UpdateValue = { delta: number } | { value: number };

export type UpdateResult = { newValue: number; delta: number };

export function isDeltaValue(value: UpdateValue): value is { delta: number } {
  return 'delta' in value;
}

export type PlayerData = {
  generateNarration: (text: string) => Promise<string>;
  getAttribute(attributeRef: string): Promise<number | string>;
  setAttribute(attributeName: string, value: string | number): Promise<void>;
  setItem(itemId: string, value: UpdateValue): Promise<UpdateResult>;
  getItem(itemId: string): Promise<InventoryItem>;
};
