import { ColorTheme, ColorWithOpacity } from '../types/theme';

export type BaseColorDesignSystem = {
  light: ColorWithOpacity;
  dark: ColorWithOpacity;
  accent: ColorWithOpacity;
  red: ColorWithOpacity;
  yellow: ColorWithOpacity;
};

export const baseColorMap: ColorTheme<BaseColorDesignSystem> = {
  dark: {
    light: {
      '1000': 'rgba(255, 255, 255, 1)',
      '950': 'rgba(255, 255, 255, .95)',
      '900': 'rgba(255, 255, 255, .90)',
      '800': 'rgba(255, 255, 255, .80)',
      '600': 'rgba(255, 255, 255, .60)',
      '400': 'rgba(255, 255, 255, .40)',
      '200': 'rgba(255, 255, 255, .20)',
      '100': 'rgba(255, 255, 255, .10)',
      '50': 'rgba(255, 255, 255, .05)',
      '30': 'rgba(255, 255, 255, .03)',
    },
    dark: {
      '1000': 'rgba(33, 33, 44, 1)',
      '950': 'rgba(33, 33, 44, .95)',
      '900': 'rgba(33, 33, 44, .90)',
      '800': 'rgba(33, 33, 44, .80)',
      '600': 'rgba(33, 33, 44, .60)',
      '400': 'rgba(33, 33, 44, .40)',
      '200': 'rgba(33, 33, 44, .20)',
      '100': 'rgba(33, 33, 44, .10)',
      '50': 'rgba(33, 33, 44, .05)',
      '30': 'rgba(33, 33, 44, .03)',
    },
    accent: {
      '1000': 'rgba(123, 128, 255, 1)',
      '950': 'rgba(123, 128, 255, .95)',
      '900': 'rgba(123, 128, 255, .90)',
      '800': 'rgba(123, 128, 255, .80)',
      '600': 'rgba(123, 128, 255, .60)',
      '400': 'rgba(123, 128, 255, .40)',
      '200': 'rgba(123, 128, 255, .20)',
      '100': 'rgba(123, 128, 255, .10)',
      '50': 'rgba(123, 128, 255, .05)',
      '30': 'rgba(123, 128, 255, .03)',
    },
    red: {
      '1000': 'rgba(236, 36, 72, 1)',
      '950': 'rgba(236, 36, 72, .95)',
      '900': 'rgba(236, 36, 72, .90)',
      '800': 'rgba(236, 36, 72, .80)',
      '600': 'rgba(236, 36, 72, .60)',
      '400': 'rgba(236, 36, 72, .40)',
      '200': 'rgba(236, 36, 72, .20)',
      '100': 'rgba(236, 36, 72, .10)',
      '50': 'rgba(236, 36, 72, .05)',
      '30': 'rgba(236, 36, 72, .03)',
    },
    yellow: {
      '1000': 'rgba(192, 165, 76, 1)',
      '950': 'rgba(192, 165, 76, .95)',
      '900': 'rgba(192, 165, 76, .90)',
      '800': 'rgba(192, 165, 76, .80)',
      '600': 'rgba(192, 165, 76, .60)',
      '400': 'rgba(192, 165, 76, .40)',
      '200': 'rgba(192, 165, 76, .20)',
      '100': 'rgba(192, 165, 76, .10)',
      '50': 'rgba(192, 165, 76, .05)',
      '30': 'rgba(192, 165, 76, .03)',
    },
  },

  light: {
    light: {
      '1000': 'rgba(255, 255, 255, 1)',
      '950': 'rgba(255, 255, 255, .95)',
      '900': 'rgba(255, 255, 255, .90)',
      '800': 'rgba(255, 255, 255, .80)',
      '600': 'rgba(255, 255, 255, .60)',
      '400': 'rgba(255, 255, 255, .40)',
      '200': 'rgba(255, 255, 255, .20)',
      '100': 'rgba(255, 255, 255, .10)',
      '50': 'rgba(255, 255, 255, .05)',
      '30': 'rgba(255, 255, 255, .03)',
    },
    dark: {
      '1000': 'rgba(33, 33, 44, 1)',
      '950': 'rgba(33, 33, 44, .95)',
      '900': 'rgba(33, 33, 44, .90)',
      '800': 'rgba(33, 33, 44, .80)',
      '600': 'rgba(33, 33, 44, .60)',
      '400': 'rgba(33, 33, 44, .40)',
      '200': 'rgba(33, 33, 44, .20)',
      '100': 'rgba(33, 33, 44, .10)',
      '50': 'rgba(33, 33, 44, .05)',
      '30': 'rgba(33, 33, 44, .03)',
    },
    accent: {
      '1000': 'rgba(51, 47, 247, 1)',
      '950': 'rgba(51, 47, 247, .95)',
      '900': 'rgba(51, 47, 247, .90)',
      '800': 'rgba(51, 47, 247, .80)',
      '600': 'rgba(51, 47, 247, .60)',
      '400': 'rgba(51, 47, 247, .40)',
      '200': 'rgba(51, 47, 247, .20)',
      '100': 'rgba(51, 47, 247, .10)',
      '50': 'rgba(51, 47, 247, .05)',
      '30': 'rgba(51, 47, 247, .03)',
    },
    red: {
      '1000': 'rgba(236, 36, 72, 1)',
      '950': 'rgba(236, 36, 72, .95)',
      '900': 'rgba(236, 36, 72, .90)',
      '800': 'rgba(236, 36, 72, .80)',
      '600': 'rgba(236, 36, 72, .60)',
      '400': 'rgba(236, 36, 72, .40)',
      '200': 'rgba(236, 36, 72, .20)',
      '100': 'rgba(236, 36, 72, .10)',
      '50': 'rgba(236, 36, 72, .05)',
      '30': 'rgba(236, 36, 72, .03)',
    },
    yellow: {
      '1000': 'rgba(192, 165, 76, 1)',
      '950': 'rgba(192, 165, 76, .95)',
      '900': 'rgba(192, 165, 76, .90)',
      '800': 'rgba(192, 165, 76, .80)',
      '600': 'rgba(192, 165, 76, .60)',
      '400': 'rgba(192, 165, 76, .40)',
      '200': 'rgba(192, 165, 76, .20)',
      '100': 'rgba(192, 165, 76, .10)',
      '50': 'rgba(192, 165, 76, .05)',
      '30': 'rgba(192, 165, 76, .03)',
    },
  },
};
