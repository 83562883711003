import { Badge } from '@chakra-ui/react';
import { DataType, RpgConfigProperty } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type EpisodeSetupPropertyProps = {
  data: RpgConfigProperty;
};

const dataType: Record<DataType, string> = {
  [DataType.Number]: 'Number',
  [DataType.String]: 'Text',
  [DataType.Enum]: 'Choice',
};

export const EpisodeSetupPropertyItem: React.FC<EpisodeSetupPropertyProps> = ({
  data,
}) => {
  return (
    <EpisodeSetupPropertyItemContainer>
      <Badge variant="circle" fontFamily="mono">
        {data.name}
      </Badge>
      <Badge variant="value">
        {dataType[data.config?.dataType] ?? 'Number'}
      </Badge>
    </EpisodeSetupPropertyItemContainer>
  );
};

const EpisodeSetupPropertyItemContainer = styled.div`
  ${textStyles.body.b12sb}
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;
