import { useNavigation } from '@refinedev/core';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  to: string;
  className?: string;
}>;

export const Link: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigation.push(props.to);
  };

  return (
    <a href={props.to} onClick={onClick}>
      {props.children}
    </a>
  );
};
