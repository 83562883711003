import { Button } from '@chakra-ui/react';
import { DataType, RpgConfig, RpgConfigProperty } from '@common/studio-types';
import { Icon, ListItem, ListItemColumn } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { useMemo } from 'react';
import styled from 'styled-components';
import { EnumValues } from './EnumValues';

type PropertyFormProps = {
  onChange: (data: RpgConfigProperty) => void;
  data: RpgConfigProperty;
  onRemove: () => void;
  onEdit: () => void;
  rpgConfig: RpgConfig;
};

const icons: Record<DataType, string> = {
  [DataType.Number]: 'number-type',
  [DataType.String]: 'text-type',
  [DataType.Enum]: 'enum-type',
};

export const PropertyRow: React.FC<PropertyFormProps> = (props) => {
  const icon = icons[props.data.config.dataType];

  const description = useMemo(() => {
    switch (props.data.config.dataType) {
      case DataType.Number:
        return props.data.config.defaultValue;
      case DataType.String:
        return props.data.config.defaultValue;
      case DataType.Enum:
        return props.data.config.options.length ? (
          <EnumValues options={props.data.config.options} />
        ) : undefined;
    }
  }, [props.data]);

  return (
    <ListItem onClick={props.onEdit}>
      <ListItemColumn width="30%">
        <Icon name={icon} size={rawDimensions.size16} />
        {props.data.name || <Empty>Empty</Empty>}
      </ListItemColumn>
      <ListItemColumn flex>
        {description || <Empty>Empty</Empty>}
      </ListItemColumn>
      <ListItemColumn reverse width={dimensions.size120}>
        <Button
          variant="inputButton"
          background="transparent"
          color="text.placeholder"
        >
          <Icon name="edit" size={rawDimensions.size20} />
        </Button>

        <Button
          variant="inputButton"
          background="transparent"
          color="text.placeholder"
          onClick={(e) => {
            e.stopPropagation();
            props.onRemove();
          }}
        >
          <Icon name="trash" size={rawDimensions.size20} />
        </Button>
      </ListItemColumn>
    </ListItem>
  );
};

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
