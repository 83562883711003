import { useEffect, useState } from 'react';

export const useDebounceValue = (
  inputValue: string,
  timeout: number = 1000,
) => {
  const [value, setValue] = useState<string>(inputValue);

  useEffect(() => {
    const timeoutRef = setTimeout(() => setValue(inputValue), timeout);

    return () => clearTimeout(timeoutRef);
  }, [inputValue]);

  return value;
};
