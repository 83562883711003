import type { PlayerInputNodeData } from '@common/studio-types';
import { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const playerInput = async (
  node: GameNode<PlayerInputNodeData>,
  state: GameState,
  config: StatefulGameConfig,
  playerData: PlayerData,
): Promise<ActionResult> => {
  const prompt = await convertToText(node.prompt, state, config, playerData);

  return {
    haltExecution: true,
    messages: [
      {
        nodeId: node.id,
        id: node.id,
        type: 'player-input',
        prompt,
        maxLength: node.maxLength,
      },
    ],
    state: { ...state, currentInteraction: node.id },
  };
};
