import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D4: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M48 11L90 80H6L48 11Z"
        fill={alterHslColor(`hsl(138, 75%, 36%)`, multipliers)}
      />
    </svg>
  );
};
