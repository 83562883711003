import { Button } from '@chakra-ui/react';
import type { EndEpisodeNodeData } from '@common/studio-types';
import { Menu, MenuButton, MenuItem, MenuList } from '@maestro/components';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions, textStyles } from '@maestro/styles';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { StudioNodeFormComponent } from '../Node.types';
import { endEpisodeActions } from './utils';

export const EndEpisodeForm: StudioNodeFormComponent<EndEpisodeNodeData> = ({
  data,
  onChange,
}) => {
  const selectedAction = useMemo(() => {
    return endEpisodeActions.find((action) => action.value === data.action);
  }, [data.action]);

  return (
    <>
      <Hint>
        When reaching this node, the episode will end with a message defined by
        you. You can select if the end is successful or not, when successful,
        the play next episode will show up (if applicable), otherwise it will
        show to restart the episode.
        <br />
        <br />
        If you would like the player to restart the entire series, you can use
        the "Restart Series" action.
      </Hint>
      <Field label="Message">
        <Textarea
          placeholder="Write a message to be displayed at the end of the episode"
          maxCharacters={200}
          value={data.message}
          autoFocus={!data.message}
          onChange={(e) => onChange({ ...data, message: e.target.value })}
        />
      </Field>
      <Field label="Player action">
        <Menu>
          <MenuButton>
            <Button
              variant="input"
              height={'48px'}
              width={'100%'}
              justifyContent={'start'}
            >
              {selectedAction?.label ?? 'Select an action'}
            </Button>
          </MenuButton>
          <MenuList>
            {endEpisodeActions.map((action) => (
              <MenuItem
                key={action.value}
                onClick={() => onChange({ ...data, action: action.value })}
              >
                <ValueContainer>
                  <Label>{action.label}</Label>
                </ValueContainer>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Field>
    </>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Label = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;
