import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * This is good to add callback as ref to reduce react rendering
 * @param callback the callback to add to useRef
 * @returns ref
 */
export const useCallbackRef = <T extends CallableFunction>(
  callback: T,
): MutableRefObject<T | undefined> => {
  const ref = useRef<T>();

  // need this to reduce unnecessary re-render
  useEffect(() => {
    ref.current = callback as T;
  }, [callback]);

  return ref;
};
