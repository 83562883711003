import { CmsCard } from '@maestro/components';
import React from 'react';
import { World } from './hooks/useListWorlds';

type Props = {
  world: World;
  className?: string;
};

export const WorldCard: React.FC<Props> = ({ world }) => {
  return (
    <CmsCard
      image={world.cover}
      aspectRatio={1}
      title={world.title}
      description={world.sourceStory}
    />
  );
};
