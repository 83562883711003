import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

export const Hint = styled.div`
  ${textStyles.body.b14m}
  margin-bottom: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.body};

  & > strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text.header};
  }
`;

export const SmallHint = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;
