import { toOptimizedImageUri } from '@common/image';
import {
  DataType,
  RpgConfigAiGeneration,
  RpgConfigProperty,
  hudIcons,
  valueUtils,
} from '@common/studio-types';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { random } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const uuid = () => window.crypto.randomUUID();

type Props = {
  properties: RpgConfigProperty[];
  onCreateElement: (element: RpgConfigAiGeneration) => void;
};

export const AiGenerationQuickAdd: React.FC<Props> = (props) => {
  const { onCreateElement, properties } = props;
  const getRandomIcon = () => hudIcons[random(0, hudIcons.length - 1)];
  const numericProperties = properties.filter(
    (property) => property.config.dataType === DataType.Number,
  );

  return (
    <QuickAdd>
      {numericProperties.length > 0 && <QuickAddTitle>Quick Add</QuickAddTitle>}
      <QuickAddButtons>
        {numericProperties.map((property) => {
          const randomIcon = getRandomIcon();
          const createElement = () => {
            onCreateElement({
              id: uuid(),
              displayName: property.name,
              value: valueUtils.numberProp(property.id),
            });
          };

          return (
            <QuickAddButton key={property.id} onClick={createElement}>
              <HudIcon
                src={toOptimizedImageUri(randomIcon.icon, {
                  width: rawDimensions.size16,
                  height: rawDimensions.size16,
                  dpr: window.devicePixelRatio,
                })}
              />
              {property.name}
            </QuickAddButton>
          );
        })}
      </QuickAddButtons>
    </QuickAdd>
  );
};

const QuickAdd = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  padding: ${dimensions.size0} ${dimensions.size16} ${dimensions.size16}
    ${dimensions.size16};
  text-align: center;
  max-width: ${dimensions.size600};
  margin: 0 auto;
`;

const QuickAddButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const QuickAddButton = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.header};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${dimensions.size6};
  border-radius: ${dimensions.size999};
  background: ${({ theme }) => theme.colors.background.shade};
  padding: ${dimensions.size6} ${dimensions.size12} ${dimensions.size6}
    ${dimensions.size12};
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.base.light[100]};
  }
`;

const QuickAddTitle = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.body};
`;

const HudIcon = styled.img`
  width: ${dimensions.size16};
  height: ${dimensions.size16};
`;
