import {
  DataType,
  PlayerInputNodeData,
  StudioNodeType,
  ValueType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { validateText } from '../validationUtils';
import { PlayerInputForm } from './PlayerInputForm';
import { PlayerInputNode } from './PlayerInputNode';

const name = 'Player input';
const rules = { min: 1, max: 100 };

export const playerInputNode: NodeConfig<
  StudioNodeType.PlayerInput,
  PlayerInputNodeData
> = {
  type: StudioNodeType.PlayerInput,
  name,
  icon: 'player-input',
  node: PlayerInputNode,
  form: PlayerInputForm,
  group: NodeGroup.Advanced,
  cloneNodeData: ({ prompt, property }) => ({
    data: { id: toNodeId(), prompt, property: { ...property } },
  }),
  getNodeData: (data) => ({ title: name, description: data.prompt }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.PlayerInput,
    prompt: '',
    property: {
      type: ValueType.Property,
      dataType: DataType.String,
      ref: '',
    },
  }),
  validateNodeData: (data) =>
    validateText(data.prompt, rules) ? [] : [{ text: data.prompt }],
};
