import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D8: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  const mainColor = alterHslColor(`hsl(273, 75%, 56%)`, multipliers);

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M78.5029 32.1356L48.2996 12L80.3335 67.5254L78.5029 32.1356Z"
        fill={mainColor}
      />
      <path
        d="M78.5029 32.1356L48.2996 12L80.3335 67.5254L78.5029 32.1356Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M18.0962 32.1356L48.2996 12L16.2657 67.5254L18.0962 32.1356Z"
        fill={mainColor}
      />
      <path
        d="M18.0962 32.1356L48.2996 12L16.2657 67.5254L18.0962 32.1356Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M48.2998 12L80.3337 67.5254H16.2659L48.2998 12Z"
        fill={mainColor}
      />
      <path
        d="M48.2995 84L80.3334 67.5254H16.2656L48.2995 84Z"
        fill={mainColor}
      />
      <path
        d="M48.2995 84L80.3334 67.5254H16.2656L48.2995 84Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
};
