import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { Icon, useMaestroToast } from '@maestro/components';
import { ImageFallback } from '@maestro/components/ImageFallback';
import { ModalCloseButton } from '@maestro/components/ModalCloseButton';
import {
  PrePublishEpisodeVersionValidationStatus,
  usePrePublishEpisodeVersionValidationQuery,
  usePublishEpisodeMutation,
  useStudioEpisodeQuery,
} from '@maestro/graphql';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSuggestionsStore } from '../../../hooks/suggestions/useSuggestionsStore';
import { PublishEpisodeVerificationSteps } from './PublishEpisodeVerificationSteps';

export const PublishEpisodeModal = () => {
  const [isPublishing, setIsPublishing] = useState(false);

  const navigate = useNavigate();
  const { id: episodeId } = useParams();
  const { search, ...location } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useMaestroToast();
  const suggestions = useSuggestionsStore((store) =>
    store.suggestions.filter((section) => !!section.required),
  );

  const {
    data: prePublishValidationResponse,
    isLoading,
    refetch: refetchValidations,
  } = usePrePublishEpisodeVersionValidationQuery(
    {
      input: {
        episodeId: episodeId!,
      },
    },
    {
      enabled: !!episodeId,
    },
  );

  const {
    data: studioEpisode,
    isLoading: isLoadingStudioEpisode,
    refetch: refetchEpisode,
  } = useStudioEpisodeQuery(
    {
      input: { episodeId: episodeId! },
    },
    {
      enabled: !!episodeId,
    },
  );

  const { mutateAsync } = usePublishEpisodeMutation();

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const showPublishEpisodeModal = params.get('publish');

      if (!!showPublishEpisodeModal) {
        onOpen();
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  }, [search]);

  useEffect(() => {
    const refresh = async () => {
      await refetchEpisode();
      await refetchValidations();
    };

    if (isOpen) {
      refresh().catch(() => void 0);
    }
  }, [isOpen]);

  const showLoaders = !!isLoading || !!isPublishing || !!isLoadingStudioEpisode;

  const episode = studioEpisode?.studioEpisode?.data;

  const needsReview =
    prePublishValidationResponse?.prePublishEpisodeVersionValidation?.status ===
    PrePublishEpisodeVersionValidationStatus.Invalid;

  const onPublishEpisodeConfirmed = async () => {
    try {
      if (!episodeId) return;

      setIsPublishing(true);
      const result = await mutateAsync({
        input: {
          episodeId,
        },
      });

      if (result?.publishEpisode?.error) {
        Sentry.captureMessage('Error publishing episode');
        toast({
          status: 'warning',
          title: 'Error publishing episode',
          description: 'There was an error while publishing the episode',
        });

        return;
      }

      toast({
        status: 'success',
        title: 'Episode published',
        icon: 'checkmark-filled',
      });

      // NOTE: by refetching here we make sure all other instances get the latest studio episode query data
      await refetchEpisode();

      navigate(location.pathname);
    } catch (error) {
      toast({
        status: 'warning',
        title: 'Episode failed to publish',
        description: 'Oops! Something went wrong while publishing the episode',
      });
    } finally {
      setIsPublishing(false);
    }
  };

  const onCloseClick = () => {
    navigate(location.pathname);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseClick}
      size="3xl"
      variant={'wizard'}
      closeOnEsc={!showLoaders}
      closeOnOverlayClick={!showLoaders}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span>Publish episode</span>
          <ModalCloseButton disabled={showLoaders} onClose={onCloseClick} />
        </ModalHeader>
        <ModalBody>
          <SplitView>
            <Column>
              <PrePublishStateText>
                {needsReview ? 'Needs review' : 'Publish episode'}
              </PrePublishStateText>
              <PrePublishInstructionsText>
                {needsReview
                  ? `You have some outstanding review items you need to address before publishing Episode${episode?.order ? ` ${episode?.order}` : ''}: ${episode?.title}`
                  : `You are publishing Episode${episode?.order ? ` ${episode?.order}` : ''}: ${episode?.title} to the app. Once it’s published, this episode will be available for the public to view in the Avenue app.`}
              </PrePublishInstructionsText>
              <PublishEpisodeVerificationSteps
                seriesId={episode?.series.id}
                validationResults={{
                  episode:
                    prePublishValidationResponse
                      ?.prePublishEpisodeVersionValidation
                      ?.episodeValidations ?? [],
                  series:
                    prePublishValidationResponse
                      ?.prePublishEpisodeVersionValidation?.seriesValidations ??
                    [],
                }}
                suggestions={suggestions}
              />
            </Column>
            <Column>
              <EpisodeDetailsCard>
                {!!episode?.media?.main ? (
                  <EpisodeImageContainer>
                    <EpisodeImage
                      src={toOptimizedImageUri(episode?.media?.main, {
                        dpr: window.devicePixelRatio,
                        resize: 'cover',
                      })}
                    />
                  </EpisodeImageContainer>
                ) : (
                  <ImageFallback
                    width={rawDimensions.size300}
                    height={rawDimensions.size200}
                    iconSize={rawDimensions.size32}
                  />
                )}
                <EpisodeSummary>
                  <EpisodeCount>
                    Episode{episode?.order ? ` ${episode?.order}` : ''}
                  </EpisodeCount>
                  <EpisodeTitle>{episode?.title}</EpisodeTitle>
                </EpisodeSummary>
              </EpisodeDetailsCard>
            </Column>
          </SplitView>
        </ModalBody>

        <ModalFooter>
          <StyledPublishButton
            variant="primary"
            onClick={onPublishEpisodeConfirmed}
            isLoading={showLoaders}
            isDisabled={
              showLoaders ||
              prePublishValidationResponse?.prePublishEpisodeVersionValidation
                ?.status === PrePublishEpisodeVersionValidationStatus.Invalid ||
              suggestions.length > 0
            }
          >
            <Icon name="publish" size={rawDimensions.size16} />
            Publish episode
          </StyledPublishButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const EpisodeCount = styled.label`
  ${textStyles.label.lb12sbcap};
  color: ${({ theme }) => theme.colors.base.light['900']};
`;

const EpisodeTitle = styled.h3`
  ${textStyles.label.lb16sb};
  color: ${({ theme }) => theme.colors.text.header};
`;

const EpisodeDetailsCard = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size24};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
    gap: ${dimensions.size12};
  }
`;

const EpisodeSummary = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size8};
`;

const StyledPublishButton = styled(Button)`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size40};
  align-items: stretch;
  width: 100%;

  @media ${breakpoints.tablet} {
    flex-direction: column;
    gap: ${dimensions.size24};
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size12};
  width: calc(100% - ${dimensions.size10});
`;

const EpisodeImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 66.67%; /* 3:2 aspect ratio (2 / 3 * 100) */
`;

const EpisodeImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
`;

const PrePublishStateText = styled.h2`
  ${textStyles.title.t24b};
  color: ${({ theme }) => theme.colors.text.header};
`;

const PrePublishInstructionsText = styled.p`
  ${textStyles.body.b16m};
  padding: 0;
  margin: 0;
  margin-bottom: ${dimensions.size24};
  color: ${({ theme }) => theme.colors.text.body};

  @media ${breakpoints.tablet} {
    margin-bottom: ${dimensions.size24};
  }
`;
