import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type DynamicPreferenceKeys =
  | 'showPublishNewEpisodeVersionWarning'
  | 'bypassMobileLanding';

type UserPreferencesStoreState = {
  isRpgModeEnabled: boolean;
  showPublishNewEpisodeVersionWarning: boolean;
  bypassMobileLanding: boolean;
  toggleRpgMode: (enabled: boolean) => void;
  togglePreference: (key: DynamicPreferenceKeys, enabled: boolean) => void;
};

const DEFAULT_STATE: Pick<
  UserPreferencesStoreState,
  | 'isRpgModeEnabled'
  | 'showPublishNewEpisodeVersionWarning'
  | 'bypassMobileLanding'
> = {
  isRpgModeEnabled: false,
  showPublishNewEpisodeVersionWarning: true,
  bypassMobileLanding: false,
};

export const useUserPreferencesStore = create<UserPreferencesStoreState>()(
  persist(
    (set) => ({
      ...DEFAULT_STATE,
      toggleRpgMode: (enabled: boolean) => {
        set((state) => ({ ...state, isRpgModeEnabled: enabled }));
      },
      togglePreference: (key: DynamicPreferenceKeys, enabled: boolean) => {
        set((state) => ({
          ...state,
          [key]: enabled,
        }));
      },
    }),
    {
      name: 'studio-user-preferences',
    },
  ),
);
