import { Button, ButtonGroup } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import { CoinTossSide } from '@enigma-engine/core';
import { ProbabilityCircle } from './coinToss/ProbabilityCircle';
import { MaestroSelectedInteraction } from './MaestroSelectedInteraction';

type Props = {
  successRate: number;
  successful: boolean;
  messageContainer: React.ReactNode;
  selectedOption?: CoinTossSide;
  onSelectOption?: (side: CoinTossSide) => void;
};

const Component: React.FC<Props> = (props) => {
  const {
    messageContainer,
    selectedOption,
    successRate,
    successful,
    onSelectOption,
  } = props;
  const theme = useTheme();

  return (
    <Container>
      <MaestroSelectedInteraction text="Flip a coin" icon="coin-flip" />

      <CoinTossContainer>
        <TopContainer>
          <ProbabilityContainer>
            <ProbabilityCircle
              size={200}
              percentage={selectedOption ? successRate : 0}
              clockwise={selectedOption === CoinTossSide.Tails}
              activeColor={
                successful
                  ? theme.colors.border.accent['1000']
                  : theme.colors.border.error['1000']
              }
              defaultColor={theme.colors.border.default['200']}
            />
            <Image
              $size={100}
              src={toOptimizedImageUri(
                'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/coin.png',
                {
                  width: 100,
                  height: 100,
                  dpr: window.devicePixelRatio,
                },
              )}
            />
          </ProbabilityContainer>
          {messageContainer}
        </TopContainer>
        <BottomContainer>
          {!selectedOption ? (
            <ButtonGroup width="100%">
              {[CoinTossSide.Heads, CoinTossSide.Tails].map((side) => (
                <Button
                  width="100%"
                  onClick={() => onSelectOption?.(side)}
                  variant="default"
                >
                  <ButtonText>{side}</ButtonText>
                </Button>
              ))}
            </ButtonGroup>
          ) : (
            <ChosenOption>
              <ButtonAlternateText>You chose&nbsp;</ButtonAlternateText>
              <ButtonText>{selectedOption}</ButtonText>
            </ChosenOption>
          )}
        </BottomContainer>
      </CoinTossContainer>
    </Container>
  );
};

export const MaestroCoinTossComponent = memo(Component);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
`;

const ProbabilityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${dimensions.size0} auto ${dimensions.size16} auto;
`;

const Image = styled.img<{ $size: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  ${({ $size }) => `
    margin-top: -${$size / 2}px;
    margin-left: -${$size / 2}px;
    width: ${$size}px;
    height: ${$size}px;
  `};
`;

const CoinTossContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const TopContainer = styled.div`
  padding: ${dimensions.size16};
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  padding: ${dimensions.size16};
  border: ${dimensions.size0} solid
    ${({ theme }) => theme.colors.background.shade};
  border-top-width: ${dimensions.size1};
`;

const ChosenOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: ${dimensions.size12};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const ButtonText = styled.div`
  ${textStyles.body.b16sb};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
  text-transform: capitalize;
`;

const ButtonAlternateText = styled.div`
  ${textStyles.body.b16m};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;
