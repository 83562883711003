import { Switch } from '@chakra-ui/react';
import {
  ChangeValueNodeData,
  DataType,
  PropertyScope,
  Value,
  ValueType,
} from '@common/studio-types';
import { RadioButtons } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { ValueInput } from '../../components/value/ValueInput';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { useStudioUtils } from '../../hooks/useStudioUtils';
import { StudioNodeFormComponent } from '../Node.types';

export const ChangeValueForm: StudioNodeFormComponent<ChangeValueNodeData> = ({
  data,
  onChange,
}) => {
  const { rpgConfig } = useStudioRpgConfig();
  const { createStaticValue } = useStudioUtils(rpgConfig);

  const isChangedValueSeriesProp = useMemo(() => {
    if (data.changedValue.type === ValueType.Property) {
      const ref = data.changedValue.ref;
      const property = rpgConfig.properties.find(({ id }) => id === ref);

      if (!property) {
        return false;
      }

      return (
        property.scope === PropertyScope.Series &&
        property.config.dataType === DataType.Number
      );
    }

    return false;
  }, [rpgConfig.properties, data.changedValue]);

  const shouldShowSilentMode =
    data.changedValue.type === ValueType.Item || isChangedValueSeriesProp;

  const actions = [
    { label: 'Set', value: 'set' },
    {
      label: 'Increase',
      value: 'increase',
      isDisabled: data.changedValue.dataType !== DataType.Number,
    },
    {
      label: 'Reduce',
      value: 'reduce',
      isDisabled: data.changedValue.dataType !== DataType.Number,
    },
  ];

  const onChangeValue = (changedValue: Value) => {
    // Changing between enums should always reset the form, as enums do not share values
    const changedToEnum = changedValue.dataType === DataType.Enum;
    const value =
      data.value?.dataType === changedValue.dataType && !changedToEnum
        ? data.value
        : createStaticValue(changedValue);

    if (changedValue.type === ValueType.Property) {
      onChange({ ...data, changedValue, value, isSilent: false });
    } else {
      onChange({ ...data, changedValue, value });
    }
  };

  return (
    <Container>
      <Hint>Use this node to change the value from state or character</Hint>
      <ValueInput
        rpgConfig={rpgConfig}
        updateOperation
        disabledTypes={[ValueType.Input, ValueType.StaticValue]}
        value={data.changedValue}
        onChange={onChangeValue}
      />
      <Field label="Action">
        <RadioButtons
          options={actions}
          value={data.action}
          onChange={(action) => onChange({ ...data, action })}
        />
      </Field>
      {data.value && (
        <ValueInput
          rpgConfig={rpgConfig}
          value={data.value}
          referenceValue={data.changedValue}
          onChange={(value) => onChange({ ...data, value })}
        />
      )}
      {shouldShowSilentMode && (
        <Card>
          <Field label="Silent mode">
            <Hint>
              If checked, the action will not be displayed in the game log.
            </Hint>
            <Switch
              size="lg"
              isChecked={data.isSilent}
              onChange={(e) =>
                onChange({ ...data, isSilent: e.target.checked })
              }
            />
          </Field>
        </Card>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;

const Card = styled.div`
  padding: ${dimensions.size16} ${dimensions.size16} ${dimensions.size0}
    ${dimensions.size16};
  margin-top: ${dimensions.size8};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;
