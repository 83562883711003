import { Value } from './value';

export enum DiceRollAction {
  // The user will be requested to roll the dice
  User = 'User',
  // User won't know a dice was rolled
  Silent = 'Silent',
  // The engine will roll the dice and show the result
  AutoPlay = 'AutoPlay',
}

export enum DiceRollStrategy {
  // Will return the highest rolled dice
  Highest = 'Highest',
  // Will return the lowest rolled dice
  Lowest = 'Lowest',
  // Will return the sum of all rolled dice
  Sum = 'Sum',
  // Will return 0 or 1 based on the input difficulty class number
  ClassDifficulty = 'ClassDifficulty',
}

/**
 * Modifier type can use character attribute,
 * property or inventory to make the dice roll
 * higher or lower
 */
export enum DiceRollModifierType {
  CharacterAttribute = 'CharacterAttribute',
  Property = 'Property',
  // Inventory = 'Inventory',
}

export enum DiceRollModifierAction {
  // Will add the modifier to the dice roll
  Add = 'add',
  // Will subtract the modifier to the dice roll
  Sub = 'sub',
}

export type DiceRollModifier = {
  id: string;
  action: DiceRollModifierAction;
  value: Value;
};

type BaseDiceRollStrategy<TType extends DiceRollStrategy, TData> = TData & {
  type: TType;
};

export type HighestDiceRollStrategy = BaseDiceRollStrategy<
  DiceRollStrategy.Highest,
  object
>;

export type LowestDiceRollStrategy = BaseDiceRollStrategy<
  DiceRollStrategy.Lowest,
  object
>;

export type SumDiceRollStrategy = BaseDiceRollStrategy<
  DiceRollStrategy.Sum,
  object
>;

export type ClassDifficultyDiceRollStrategy = BaseDiceRollStrategy<
  DiceRollStrategy.ClassDifficulty,
  {
    classDifficulty: number;
  }
>;

export type DiceRollStrategyData =
  | HighestDiceRollStrategy
  | LowestDiceRollStrategy
  | SumDiceRollStrategy
  | ClassDifficultyDiceRollStrategy;

export type Dice = 'd4' | 'd6' | 'd8' | 'd10' | 'd12' | 'd20' | 'd100';

export type DiceRollData = {
  prompt: string;
  dices: Dice[];
  rollAction: DiceRollAction;
  strategy: DiceRollStrategyData;
  modifiers: DiceRollModifier[];
  outputName: string;
};

export enum DiceRollImage {
  d4 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d4.png',
  d6 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d6.png',
  d8 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d8.png',
  d10 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d10.png',
  d12 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d12.png',
  d20 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d20.png',
  d100 = 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/dice/d100.png',
}
