import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Icon, TextInput, Textarea } from '@maestro/components';
import { FC } from 'react';
import { WorldCharacter, WorldLocation } from '../../types';
import {
  GeneratorFormContainer,
  GeneratorFormHeader,
  GeneratorItemBody,
} from './GeneratorComponents';

type Props = {
  submitPressed?: boolean;
  characters: WorldCharacter[];
  locations: WorldLocation[];
  onDelete: () => void;
  character: WorldCharacter;
  onChange: (character: WorldCharacter) => void;
  index: number;
};

export const CharacterForm: FC<Props> = ({
  character,
  onDelete,
  onChange,
  index,
  submitPressed,
}) => {
  return (
    <GeneratorFormContainer>
      <GeneratorFormHeader>
        <Text fontWeight={'semibold'} fontSize={18}>
          Character {index + 1}
        </Text>
        <ButtonGroup>
          <Button
            onClick={onDelete}
            variant="ghost"
            size="sm"
            leftIcon={<Icon name="trash" size={16} />}
          >
            Delete
          </Button>
        </ButtonGroup>
      </GeneratorFormHeader>
      <GeneratorItemBody>
        <FormControl mb="3" isInvalid={submitPressed && !character.name}>
          <FormLabel>Character name</FormLabel>
          <TextInput
            maxCharacters={100}
            value={character.name}
            onChange={(e) => onChange({ ...character, name: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.name && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !character.traits}>
          <FormLabel>What defining traits does this character have?</FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.traits}
            onChange={(e) => onChange({ ...character, traits: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.traits && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !character.goals}>
          <FormLabel>
            What are this character's biggest goals or aspirations?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.goals}
            onChange={(e) => onChange({ ...character, goals: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.goals && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          mb="3"
          isInvalid={submitPressed && !character.relationships}
        >
          <FormLabel>
            What important interpersonal relationships does this character have
            with other characters?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.relationships}
            onChange={(e) =>
              onChange({ ...character, relationships: e.target.value })
            }
          />
          <FormErrorMessage>
            {submitPressed &&
              !character.relationships &&
              'This field is required'}
          </FormErrorMessage>
        </FormControl>
      </GeneratorItemBody>
    </GeneratorFormContainer>
  );
};
