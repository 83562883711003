import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { CoinTossSide } from '@enigma-engine/core';

export const GettingReady = () => {
  return (
    <>
      <CoinTossTitle>Getting ready for the toss...</CoinTossTitle>
      <CoinTossSubtitle>Let's flip a coin and test your luck</CoinTossSubtitle>
    </>
  );
};

export const ChooseSide = () => {
  return (
    <>
      <CoinTossTitle>Choose a side</CoinTossTitle>
      <CoinTossSubtitle>Which side will the coin land on?</CoinTossSubtitle>
    </>
  );
};

export const YouChose: React.FC<{ side: CoinTossSide }> = ({ side }) => {
  const sideText = side === CoinTossSide.Heads ? 'Heads' : 'Tails';

  return (
    <>
      <CoinTossTitle>You chose {sideText}</CoinTossTitle>
      <CoinTossSubtitle>Let's hope that you're right</CoinTossSubtitle>
    </>
  );
};

export const AddingModifier: React.FC<{ attr: string }> = ({ attr }) => {
  return (
    <>
      <CoinTossTitle>Adding {attr} modifier...</CoinTossTitle>
      <CoinTossSubtitle>
        Changing probability based on your attribute
      </CoinTossSubtitle>
    </>
  );
};

export const FlippingCoin: React.FC = () => {
  return (
    <>
      <CoinTossTitle>Flipping coin...</CoinTossTitle>
      <CoinTossSubtitle>Waiting for the results</CoinTossSubtitle>
    </>
  );
};

export const ActionSuccessful = () => {
  return (
    <>
      <TitleContainer>
        <CoinTossTitle>Action is successful!</CoinTossTitle>
      </TitleContainer>
      <CoinTossSubtitle>You got lucky this time</CoinTossSubtitle>
    </>
  );
};

export const ActionFail = () => {
  return (
    <>
      <TitleContainer>
        <CoinTossTitle>Action is unsuccessful...</CoinTossTitle>
      </TitleContainer>
      <CoinTossSubtitle>It looks like you're out of luck</CoinTossSubtitle>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  margin: ${dimensions.size0} auto;
`;

const CoinTossTitle = styled.div`
  ${textStyles.body.b18sb};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
  width: 100%;
`;

const CoinTossSubtitle = styled.div`
  ${textStyles.body.b14m};
  margin: ${dimensions.size8} ${dimensions.size0} ${dimensions.size0};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;
