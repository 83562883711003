import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import {
  IconMenuItem,
  Menu,
  MenuButton,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions, rawDimensions } from '@maestro/styles';
import styled from 'styled-components';

type Props = { onRemove: () => void };

export const DeleteDropdown: React.FC<Props> = ({ onRemove }) => {
  return (
    <Menu>
      <MenuButton>
        <Button
          variant="default"
          color="red.900"
          size="sm"
          leftIcon={<Icon name="trash" size={rawDimensions.size16} />}
        >
          Delete
        </Button>
      </MenuButton>
      <MenuList>
        <PromptContainer>Are you sure you want to delete?</PromptContainer>
        <DangerIconMenuItem onClick={onRemove} icon="trash" name="Delete" />
        <IconMenuItem icon="arrow-hook-up-left" name="Cancel" />
      </MenuList>
    </Menu>
  );
};

const DangerIconMenuItem = styled(IconMenuItem)`
  color: ${({ theme }) => theme.colors.base.red[1000]};
`;

const PromptContainer = styled.div`
  padding: ${dimensions.size12} ${dimensions.size16};
`;
