import { Button, Tooltip } from '@chakra-ui/react';
import { breakpoints, dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
import { Logo } from './Logo';

type Props = PropsWithChildren<{
  isExpanded?: boolean;
  onExpandClick?: () => void;
  className?: string;
  logoConfig?: {
    onLogoClick: VoidFunction;
    clickLocation: string;
  };
  breadcrumb?: React.ReactNode;
}>;

export const TopBar: React.FC<Props> = (props) => {
  const container = (
    <Container onClick={props.logoConfig?.onLogoClick} id="avenue-logo">
      <Logo />
      <BetaTag>BETA</BetaTag>
    </Container>
  );

  return (
    <MainContainer className={props.className}>
      {props.onExpandClick && (
        <OpenMenuContainer>
          <Button
            variant="icon"
            color={props.isExpanded ? 'text.accent' : 'text.header'}
            onClick={props.onExpandClick}
          >
            <Icon name="menu" size={20} />
          </Button>
        </OpenMenuContainer>
      )}

      <LogoContainer>
        {!!props.logoConfig ? (
          <Tooltip
            label={`Go back to ${props.logoConfig.clickLocation}`}
            placement="bottom-start"
          >
            {container}
          </Tooltip>
        ) : (
          container
        )}
      </LogoContainer>

      {props.breadcrumb && (
        <BreadcrumbContainer>{props.breadcrumb}</BreadcrumbContainer>
      )}
      <SideContainer>{props.children}</SideContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${dimensions.size56};
  padding: ${dimensions.size10} ${dimensions.size12};
  background: ${({ theme }) => theme.colors.background.default};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};

  & > div {
    flex: 1;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${dimensions.size4};
  border-radius: ${dimensions.size4};

  &:hover {
    background: ${({ theme, onClick }) =>
      onClick ? theme.colors.background.shade : 'transparent'};
  }
`;

const BetaTag = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.body};
`;

const SideContainer = styled(Container)`
  justify-content: flex-end;
  width: fit-content;
`;

const OpenMenuContainer = styled.span`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
  }
`;
