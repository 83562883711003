import type { ImageNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const image = (
  node: GameNode<ImageNodeData>,
  state: GameState,
): ActionResult => {
  return {
    messages: [
      {
        nodeId: node.id,
        type: 'image',
        imageUrl: node.imageUrl,
        alt: node.alt,
        width: node.width,
        height: node.height,
      },
    ],
    haltExecution: true,
    state,
  };
};
