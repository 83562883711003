/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataType, Value, ValueType } from '..';
import { PropertyScope, RpgConfig, RpgConfigProperty } from '../episodeSetup';

export const migrateProperty = (
  property: any,
  scope: PropertyScope,
): RpgConfigProperty => {
  return {
    ...property, // keeping old data there
    id: property.name,
    name: property.name,
    scope,
    config: {
      dataType: DataType.Number,
      defaultValue: property.defaultValue,
      maxValue: {
        dataType: DataType.Number,
        value: property.maxValue,
        type: ValueType.StaticValue,
      },
      minValue: {
        dataType: DataType.Number,
        value: 0,
        type: ValueType.StaticValue,
      },
    },
  };
};

export function remapValue(value: Value, rpgConfig: RpgConfig): Value {
  value.dataType = value.dataType ?? DataType.Number;

  const oldValue = value as any;

  if (value.type === ValueType.StaticValue) {
    if (!oldValue.dataType) {
      value.dataType = DataType.Number;
    }
  } else if (oldValue.type === 'character-attribute') {
    // this will cause breaking changes, we can't avoid that
    value.type = ValueType.Property;

    if (value.type === ValueType.Property) {
      value.ref = oldValue.ref;

      const seriesProperty = rpgConfig.properties.find(
        (prop) => prop.id === value.ref && prop.scope === PropertyScope.Series,
      );

      if (seriesProperty) {
        value.dataType = seriesProperty.config.dataType;
      }
    }
  } else if (value.type === ValueType.Property) {
    value.ref = oldValue.ref;

    const episodeProperty = rpgConfig.properties.find(
      (prop) => prop.id === value.ref && prop.scope === PropertyScope.Episode,
    );

    if (episodeProperty) {
      value.dataType = episodeProperty.config.dataType;
    }
  }

  return value;
}
