import type { AiNarrationNodeData } from '@common/studio-types';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { Warning } from '../../components/Warning';
import { StudioNodeFormComponent } from '../Node.types';

const maxCharacters = 1000;

export const AiNarrationForm: StudioNodeFormComponent<AiNarrationNodeData> = ({
  data,
  onChange,
}) => {
  return (
    <Container>
      <Hint>
        This node generates a narration using AI based on what is described in
        the prompt. Properties can be used.
      </Hint>
      <Label>Prompt to AI</Label>
      <Textarea
        placeholder="Generate a narration of player choice: {prop.userChoice}. The player name is: {prop.playerName}."
        maxCharacters={maxCharacters}
        autoFocus={!data.promptToAi}
        value={data.promptToAi}
        onChange={(e) => onChange({ ...data, promptToAi: e.target.value })}
      />
      {data.promptToAi.length > maxCharacters && (
        <Warning>
          We recommend less than {maxCharacters} characters for the prompt to
          make the AI generation faster.
        </Warning>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
