import { EndEpisodeAction } from '@common/studio-types';
import type { Message } from '../game/messages.types';
import type { StatefulGame } from './statefulGame';

export const handleContinueEpisode = async (
  game: StatefulGame,
): Promise<Message[]> => {
  const state = game.state();
  const currentNodeId = state.currentNodeId;

  if (!currentNodeId || state.currentInteraction) {
    // there's no node to continue from
    return [];
  }

  const node = game.gameNodes.nodeById(currentNodeId);

  if (!node) {
    return [
      {
        type: 'end-episode',
        action: EndEpisodeAction.RestartEpisode,
        message:
          'It looks like the node got deleted, please restart the episode',
      },
    ];
  }

  if (!node.nextNodeId) {
    // dead-end node
    return [];
  }

  return game.execute(node.nextNodeId);
};
