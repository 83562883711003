import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../featureFlags';

/**
 * This is important to not cause re-rendering when another feature flag changes
 * @param id just the feature flag
 * @returns feature flag data
 */
export const useFeatureFlag = (flag: FeatureFlags) => {
  const flags = useFlags();

  return flags[flag];
};
