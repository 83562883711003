import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { TextInput, useQueryParam } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import { useGetIdentity, useNavigation } from '@refinedev/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { Identity } from '../../auth';
import { OnboardingScreen } from './OnboardingScreen';

class UsernameTakenError extends Error {}

const usernameValidator = z
  .string()
  .min(2, { message: 'Your username must be 2-32 characters' })
  .max(32, { message: 'Your username must be 2-32 characters' })
  .regex(
    /^[a-zA-Z0-9_. -]+$/,
    'This username can only contain letters, numbers, spaces, hyphen (-), dots (.), or underscores (_)',
  );

export const Username: React.FC = () => {
  const [username, setUsername] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: identity, refetch: fetchIdentity } = useGetIdentity<Identity>();
  const navigation = useNavigation();
  const [redirectTo] = useQueryParam({
    param: 'redirectTo',
    defaultValue: '/',
  });

  const onCreate = async () => {
    if (identity?.internalId) {
      await toSupabaseClient()
        .from('user')
        .update({ username })
        .eq('id', identity.internalId);

      // Refetch to update cache
      await fetchIdentity();
      navigation.replace(redirectTo ?? '/');
    }
  };

  return (
    <OnboardingScreen
      titleText="Create a username"
      subtitleText="Your username will be used as your author names on the stories you publish to the app."
    >
      <Container>
        <FormControl w={rawDimensions.size400} isInvalid={!!errorMessage}>
          <FormLabel>Username</FormLabel>
          <TextInput
            value={username}
            placeholder="awesomepossum"
            onChange={(e) => setUsername(e.target.value)}
            onFocusCapture={() => setErrorMessage(undefined)}
            onBlur={async () => {
              try {
                usernameValidator.parse(username);
                const isUsernameTaken = (
                  await toSupabaseClient().rpc('username_exists', {
                    uname: username,
                  })
                ).data;

                if (isUsernameTaken) {
                  throw new UsernameTakenError();
                }
              } catch (error: unknown) {
                if (error instanceof z.ZodError) {
                  setErrorMessage(error.issues[0].message);
                } else if (error instanceof UsernameTakenError) {
                  setErrorMessage('This username is taken');
                } else {
                  setErrorMessage('Unknown error');
                }
              }
            }}
          />
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
        <Button
          isDisabled={
            !usernameValidator.safeParse(username).success || !!errorMessage
          }
          w={rawDimensions.size400}
          marginTop={dimensions.size24}
          variant="primary"
          onClick={onCreate}
        >
          Create
        </Button>
      </Container>
    </OnboardingScreen>
  );
};

const Container = styled.div`
  width: ${dimensions.size400};
  margin: ${dimensions.size0} auto;
`;
