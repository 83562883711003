import type { AiNarrationNodeData } from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import { Role } from '../game/messages.types';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const aiNarration = async (
  block: GameNode<AiNarrationNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  const prompt = await convertToText(
    block.promptToAi,
    state,
    config,
    playerData,
  );

  const messages = await playerData.generateNarration(prompt);

  return {
    messages: messages.split('\n\n').map((message) => ({
      nodeId: block.id,
      type: 'text',
      message,
      name: config.narratorName,
      role: Role.Narrator,
    })),
    haltExecution: true,
    state,
  };
};
