import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D20: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  const mainColor = alterHslColor(`hsl(32, 100%, 48%)`, multipliers);

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M48.0003 84L28.4602 63.5977H67.5405L48.0003 84Z"
        fill={mainColor}
      />
      <path
        d="M48.0003 84L28.4602 63.5977H67.5405L48.0003 84Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M80.1838 63.3105L48 84.0002L67.5401 63.5979L80.1838 63.3105Z"
        fill={mainColor}
      />
      <path
        d="M80.1838 63.3105L48 84.0002L67.5401 63.5979L80.1838 63.3105Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M15.8162 63.3104V29.4023L28.4598 63.5977L15.8162 63.3104Z"
        fill={mainColor}
      />
      <path
        d="M15.8162 63.3104V29.4023L28.4598 63.5977L15.8162 63.3104Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M80.1838 63.3104V29.4023L67.5402 63.5977L80.1838 63.3104Z"
        fill={mainColor}
      />
      <path
        d="M80.1838 63.3104V29.4023L67.5402 63.5977L80.1838 63.3104Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M47.9999 28.5402L15.8162 29.4023L47.9999 9V28.5402Z"
        fill={mainColor}
      />
      <path
        d="M47.9999 28.5402L15.8162 29.4023L47.9999 9V28.5402Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M48.0001 28.5402L80.1838 29.4023L48.0001 9V28.5402Z"
        fill={mainColor}
      />
      <path
        d="M48.0001 28.5402L80.1838 29.4023L48.0001 9V28.5402Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M80.1838 29.4021L48 28.54L67.5401 63.5975L80.1838 29.4021Z"
        fill={mainColor}
      />
      <path
        d="M80.1838 29.4021L48 28.54L67.5401 63.5975L80.1838 29.4021Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M15.8162 29.4021L48 28.54L28.4599 63.5975L15.8162 29.4021Z"
        fill={mainColor}
      />
      <path
        d="M15.8162 29.4021L48 28.54L28.4599 63.5975L15.8162 29.4021Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M48.0003 28.54L67.5405 63.5975H28.4602L48.0003 28.54Z"
        fill={mainColor}
      />
      <path
        d="M28.4598 63.5979L15.8162 63.3105L47.9999 84.0002L28.4598 63.5979Z"
        fill={mainColor}
      />
      <path
        d="M28.4598 63.5979L15.8162 63.3105L47.9999 84.0002L28.4598 63.5979Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
};
