import { Button } from '@chakra-ui/react';
import {
  DiceRollModifier,
  DiceRollModifierAction,
  RpgConfig,
  ValueType,
} from '@common/studio-types';
import { Icon } from '@maestro/components';
import {
  IconMenuItem,
  Menu,
  MenuButton,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { ValueInput } from '../../components/value/ValueInput';

type Props = {
  rpgConfig: RpgConfig;
  value: DiceRollModifier;
  onChange: (data: DiceRollModifier) => void;
  onDelete: () => void;
};

const add = DiceRollModifierAction.Add;
const subtract = DiceRollModifierAction.Sub;

export const ModifierInput: React.FC<Props> = (props) => {
  return (
    <Container>
      <StyledField
        label="Modifier"
        rightButton={
          <Button
            size="sm"
            variant="ghost"
            onClick={props.onDelete}
            fontSize={12}
          >
            Delete
          </Button>
        }
      >
        <RowContainer>
          <Menu>
            <MenuButton>
              <Button variant="default" height="48px">
                <Icon
                  name={props.value.action === add ? 'plus' : 'minus'}
                  size={16}
                />
              </Button>
            </MenuButton>
            <MenuList>
              <IconMenuItem
                icon="plus"
                name="Add"
                onClick={() => props.onChange({ ...props.value, action: add })}
              />
              <IconMenuItem
                icon="minus"
                name="Subtract"
                onClick={() =>
                  props.onChange({ ...props.value, action: subtract })
                }
              />
            </MenuList>
          </Menu>
          <Flex>
            <ValueInput
              referenceValue={props.value.value}
              rpgConfig={props.rpgConfig}
              disabledTypes={[ValueType.Item]}
              value={props.value.value}
              onChange={(value) => props.onChange({ ...props.value, value })}
            />
          </Flex>
        </RowContainer>
      </StyledField>
    </Container>
  );
};

const Container = styled.div`
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const RowContainer = styled.div`
  display: flex;
  gap: ${dimensions.size4};
  align-items: center;
`;

const Flex = styled.div`
  flex: 1;
`;

const StyledField = styled(Field)`
  margin-bottom: ${dimensions.size0};
`;
