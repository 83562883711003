import { typography } from './typography';

const { InterRegular, InterMedium, InterSemiBold, InterBold } = typography;

const title = {
  t12b: `
    ${InterBold}
    font-size: 12px;
  `,
  t18b: `
    ${InterBold}
    font-size: 18px;
  `,
  t48b: `
    ${InterBold}
    font-size: 48px;
    line-height: 56px;
  `,
  t40b: `
    ${InterBold}
    font-size: 40px;
    line-height: 48px;
  `,
  t32b: `
    ${InterBold}
    font-size: 32px;
    line-height: 40px;
  `,
  t28b: `
    ${InterBold}
    font-size: 28px;
    line-height: 36px;
  `,
  t24b: `
    ${InterBold}
    font-size: 24px;
    line-height: 30px;
  `,
  t20b: `
    ${InterBold}
    font-size: 20px;
    line-height: 24px;
  `,
};

const body = {
  b0m: `
    ${InterRegular}
    font-size: 16px;
    line-height: 19px;
  `,
  b14m: `
    ${InterMedium}
    font-size: 14px;
    line-height: 20px;
  `,
  b14sb: `
    ${InterSemiBold}
    font-size: 14px;
    line-height: 20px;
  `,
  b10m: `
    ${InterMedium}
    font-size: 10px;
    line-height: 14px;
  `,
  b12m: `
    ${InterMedium}
    font-size: 12px;
    line-height: 18px;
  `,
  b12sb: `
    ${InterSemiBold}
    font-size: 12px;
    line-height: 18px;
  `,
  b18m: `
    ${InterMedium}
    font-size: 18px;
    line-height: 28px;
  `,
  b18sb: `
    ${InterSemiBold}
    font-size: 18px;
    line-height: 28px;
  `,
  b18sbi: `
    ${InterSemiBold}
    font-size: 18px;
    font-style: italic;
    line-height: 28px;
  `,
  b16m: `
    ${InterMedium}
    font-size: 16px;
    line-height: 24px;
  `,
  b16sb: `
    ${InterSemiBold}
    font-size: 16px;
    line-height: 24px;
  `,
  b16sbi: `
    ${InterSemiBold}
    font-size: 16px;
    font-style: italic;
    line-height: 24px;
  `,
};

const label = {
  lb12sb: `
    ${InterSemiBold}
    font-size: 12px;
    line-height: 18px;
  `,
  lb12b: `
    ${InterBold}
    font-size: 12px;
    line-height: 18px;
  `,
  lb10sb: `
    ${InterSemiBold}
    font-size: 10px;
    line-height: 16px;
  `,
  lb10b: `
    ${InterBold}
    font-size: 10px;
    line-height: 16px;
  `,
  lb14sb: `
    ${InterSemiBold}
    font-size: 14px;
    line-height: 20px;
  `,
  lb14b: `
    ${InterBold}
    font-size: 14px;
    line-height: 20px;
  `,
  lb16sb: `
    ${InterSemiBold}
    font-size: 16px;
    line-height: 20px;
  `,
  lb16b: `
    ${InterBold}
    font-size: 16px;
    line-height: 20px;
  `,
  lb10sbcap: `
    ${InterSemiBold}
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb10bcap: `
    ${InterBold}
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb12sbcap: `
    ${InterSemiBold}
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb12bcap: `
    ${InterBold}
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb14sbcap: `
    ${InterSemiBold}
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  `,
  lb14bcap: `
    ${InterBold}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb16sbcap: `
    ${InterSemiBold}
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  lb16bcap: `
    ${InterBold}
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  `,
  l12sb: `
    ${InterSemiBold}
    font-size: 12px;
    line-height: 20px;
  `,
};

export const textStyles = {
  title,
  body,
  label,
};
