import { toSupabaseClient } from '@maestro/supabase';

export const ALLOWED_FILE_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.webp',
  '.gif',
];

const toNodeId = () => `studio-${window.crypto.randomUUID()}`;

type ImageSize = {
  width: number;
  height: number;
};

export type UploadedImage = ImageSize & {
  path: string;
};

type FileUploader = {
  uploadImage: (image: File) => Promise<UploadedImage | undefined>;
  uploadFromUrl: (url: string) => Promise<UploadedImage | undefined>;
};

const convertToJpg = async (image: File | Blob): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Failed to convert image to JPEG'));

              return;
            }

            resolve(new File([blob], 'converted.jpg', { type: 'image/jpeg' }));
          },
          'image/jpeg',
          0.92, // JPEG quality (0-1)
        );
      };

      if (img && !!event.target) {
        img.src = event.target.result?.toString() ?? '';
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(image);
  });
};

export const useUploadImage = (path: string): FileUploader => {
  async function toDataURL(url: string): Promise<Blob> {
    const res = await fetch(url);

    return res.blob();
  }

  function getExtension(file: string): string {
    const ext = file.split('.');

    return ext[ext.length - 1];
  }

  async function upload(fileName: string, image: Blob | File) {
    const client = toSupabaseClient();
    const { data, error } = await client.storage
      .from('images')
      .upload(`${path}/${fileName}`, image);

    if (!data || error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading image', error);

      return undefined;
    }

    const {
      data: { publicUrl },
    } = client.storage.from('images').getPublicUrl(data.path);

    const { width, height } = await new Promise<ImageSize>((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.src = publicUrl;
    });

    return { path: publicUrl, width, height };
  }

  const uploadImage = async (image: File) => {
    let extension = getExtension(image.name);
    let nextImage = image;

    if (!ALLOWED_FILE_EXTENSIONS.includes(`.${extension}`)) {
      nextImage = await convertToJpg(nextImage);
      extension = 'jpeg';
    }

    const fileName = `image-${toNodeId()}.${extension}`;

    return upload(fileName, nextImage);
  };

  const uploadFromUrl = async (url: string) => {
    let extension = getExtension(url);
    const dataUrl = await toDataURL(url);

    let nextImage: Blob | File = dataUrl;

    if (!ALLOWED_FILE_EXTENSIONS.includes(`.${extension}`)) {
      nextImage = await convertToJpg(nextImage);
      extension = 'jpeg';
    }

    const fileName = `image-${toNodeId()}.${extension}`;

    return upload(fileName, nextImage);
  };

  return { uploadFromUrl, uploadImage };
};
