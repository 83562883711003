import { env } from '@maestro/env';
import { createClient } from '@refinedev/supabase';

let client: ReturnType<typeof createClient<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any

export const toSupabaseClient = () => {
  if (client) {
    return client;
  }

  client = createClient(env.VITE_SUPABASE_URL, env.VITE_SUPABASE_PUBLIC_KEY);

  return client;
};
