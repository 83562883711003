import { ColorTheme, ColorWithOpacity } from '../types/theme';
import { baseColorMap } from './baseColors';

export type BorderColorDesignSystem = {
  default: Pick<ColorWithOpacity, 400 | 200 | 100>;
  accent: Pick<ColorWithOpacity, 1000 | 800>;
  error: Pick<ColorWithOpacity, 1000>;
};

export const borderColorMap: ColorTheme<BorderColorDesignSystem> = {
  dark: {
    default: {
      400: baseColorMap.dark.light['400'],
      200: baseColorMap.dark.light['200'],
      100: baseColorMap.dark.light['100'],
    },
    accent: {
      1000: baseColorMap.dark.accent['1000'],
      800: baseColorMap.dark.accent['800'],
    },
    error: {
      1000: baseColorMap.dark.red['800'],
    },
  },
  light: {
    default: {
      400: baseColorMap.light.dark['400'],
      200: baseColorMap.light.dark['200'],
      100: baseColorMap.light.dark['100'],
    },
    accent: {
      1000: baseColorMap.light.accent['1000'],
      800: baseColorMap.light.accent['800'],
    },
    error: {
      1000: baseColorMap.light.red['800'],
    },
  },
};
