import { AiNarrationNodeData, StudioNodeType } from '@common/studio-types';
import { FeatureFlags } from '@maestro/feature-flags';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { validateText } from '../validationUtils';
import { AiNarrationForm } from './AiNarrationForm';
import { AiNarrationNode } from './AiNarrationNode';

const name = 'AI narration';
const rules = { min: 1, max: 1000 };

export const aiNarration: NodeConfig<
  StudioNodeType.AiNarration,
  AiNarrationNodeData
> = {
  type: StudioNodeType.AiNarration,
  name,
  icon: 'edit-with-ai',
  node: AiNarrationNode,
  form: AiNarrationForm,
  featureFlag: FeatureFlags.AskAI,
  cloneNodeData: ({ promptToAi }) => ({ data: { id: toNodeId(), promptToAi } }),
  getNodeData: (data) => ({ title: name, description: data.promptToAi }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.AiNarration,
    promptToAi: '',
  }),
  validateNodeData: (data) =>
    validateText(data.promptToAi, rules) ? [] : [{ text: data.promptToAi }],
};
