import { ConditionOperator } from '@common/studio-types';

export const operators: {
  value: ConditionOperator;
  label: string;
  description: string;
}[] = [
  {
    value: ConditionOperator.Equal,
    label: '=',
    description: 'Equal to',
  },
  {
    value: ConditionOperator.GreaterThanOrEqual,
    label: '>=',
    description: 'Greater than or equal to',
  },
  {
    value: ConditionOperator.GreaterThan,
    label: '>',
    description: 'Greater than',
  },
  {
    value: ConditionOperator.LessThanOrEqual,
    label: '<=',
    description: 'Less than or equal to',
  },
  {
    value: ConditionOperator.LessThan,
    label: '<',
    description: 'Less than',
  },
];
