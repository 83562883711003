import { useCallback, useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { SuggestionsSection } from '../components/suggestions/suggestion.types';
import { CreateStudioNode } from './maestro.types';
import { getMissingConnectorsSuggestion } from './suggestions/getMissingConnectorsSuggestion';
import { getStorySuggestion } from './suggestions/getStorySuggestion';
import { getValidationSuggestion } from './suggestions/getValidationSuggestion';
import { useSuggestionsStore } from './suggestions/useSuggestionsStore';
import { useCallbackRef } from './useCallbackRef';
import { useDebounce } from './useDebounce';
import { useStudioFlowStore } from './useStudioFlowStore';

type Props = {
  onCreateStudioNode: CreateStudioNode;
};

export const useSuggestions = (props: Props) => {
  const studioFlowStore = useStudioFlowStore();
  const [missingConnectors, setMissingConnectors] = useState<
    SuggestionsSection | undefined
  >();
  const [story, setStory] = useState<SuggestionsSection | undefined>();
  const [validations, setValidations] = useState<
    SuggestionsSection | undefined
  >();
  const flow = useReactFlow();
  const { getNodes, getEdges, getNode } = flow;

  const setCenter = useCallbackRef(flow.setCenter);
  const createStudioNode = useCallbackRef(props.onCreateStudioNode);
  const { setSuggestions } = useSuggestionsStore();

  const selectNode = useCallback((nodeId: string) => {
    const node = getNode(nodeId);

    if (node) {
      const x = node.width ? node.position.x + node.width / 2 : node.position.x;
      const y = node.height
        ? node.position.y + node.height / 2
        : node.position.y;

      setCenter.current?.(x, y, { duration: 300, zoom: 1.2 });
    }
  }, []);

  useEffect(
    () =>
      setSuggestions(
        [story, missingConnectors, validations].filter(
          Boolean,
        ) as SuggestionsSection[],
      ),
    [story, missingConnectors, validations],
  );

  const onNodeChange = useDebounce(() => {
    const nodes = getNodes();
    const edges = getEdges();

    setMissingConnectors(
      getMissingConnectorsSuggestion(edges, nodes, selectNode),
    );
    setStory(
      getStorySuggestion(edges, nodes, selectNode, createStudioNode.current!),
    );
    setValidations(
      getValidationSuggestion(nodes, (nodeId) => {
        selectNode(nodeId);
        studioFlowStore.selectNode(nodeId);
      }),
    );
  });

  const onEdgeChange = useDebounce(() => {
    const nodes = getNodes();
    const edges = getEdges();

    setMissingConnectors(
      getMissingConnectorsSuggestion(edges, nodes, selectNode),
    );
    setStory(
      getStorySuggestion(edges, nodes, selectNode, createStudioNode.current!),
    );
  });

  return {
    onNodeChange,
    onEdgeChange,
  };
};
