import { DataType, type CoinTossNodeData } from '@common/studio-types';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { PropertyInput } from '../../components/value/PropertyInput';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { StudioNodeFormComponent } from '../Node.types';

export const CoinTossForm: StudioNodeFormComponent<CoinTossNodeData> = ({
  data,
  onChange,
}) => {
  const { rpgConfig } = useStudioRpgConfig();

  return (
    <Container>
      <Hint>
        Coin toss is a risk based game with both a success and failure path. The
        series level property modifier changes the player's percentage of
        success.
      </Hint>
      <Field label="Narrator prompt">
        <Textarea
          placeholder="Write a narrator prompt here"
          maxCharacters={100}
          autoFocus={!data.prompt}
          value={data.prompt}
          onChange={(e) => onChange({ ...data, prompt: e.target.value })}
        />
      </Field>
      <Field label="Property modifier">
        <PropertyInput
          isNullable
          dataTypes={[DataType.Number]}
          rpgConfig={rpgConfig}
          value={data.property}
          onChange={(property) => onChange({ ...data, property })}
        />
      </Field>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
