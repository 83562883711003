/* cspell:words contexify */
import { MouseEvent, useCallback, useRef, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { Position, useReactFlow } from 'reactflow';
import { CONTEXT_MENU_ID } from '../components/ContextMenu';
import { StudioConnection } from './Node.types';

type Props = {
  id?: string;
  nodeId: string;
  type: string;
  position: Position;
  label?: string;
};

export const useOnAddNodeClick = (props: Props) => {
  const contextMenu = useContextMenu({ id: CONTEXT_MENU_ID });
  const { getNode, flowToScreenPosition } = useReactFlow();

  return (event: MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const node = getNode(props.nodeId);
    const position = flowToScreenPosition(node!.position);

    const x =
      props.position === Position.Left
        ? rect.x - 320
        : props.position === Position.Right
          ? rect.x + 50
          : position.x;
    const y = props.position === Position.Bottom ? rect.y + 50 : rect.y + 100;

    contextMenu.show({
      event,
      props: {
        x,
        y,
        nodeId: props.nodeId,
        handleId: props.id,
        label: props.label,
      },
    });
  };
};

export const useHandle = (props: Props) => {
  const hoverActive = useRef(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const { getEdges } = useReactFlow();

  const internalIsValidConnection = useCallback(() => {
    const parseEdgeId = (id: string) => id.replace('-left', '');
    const edges = getEdges();
    const parsedId = parseEdgeId(props.id!);
    const isEdgeConnected = edges.some(
      (edge) =>
        edge.source === props.nodeId &&
        edge.sourceHandle &&
        parseEdgeId(edge.sourceHandle) === parsedId,
    );

    return !isEdgeConnected;
  }, [props.id, props.nodeId, getEdges]);

  const isValidConnection = useCallback(
    (connection: StudioConnection) => {
      if (connection) {
        // manually adding label from handle
        connection.label = props.label;

        return internalIsValidConnection();
      }

      return false;
    },
    [internalIsValidConnection, props.label],
  );

  const onMouseLeave = useCallback(() => {
    hoverActive.current = false;
    setButtonVisible(false);
  }, []);

  const onMouseOver = useCallback(() => {
    if (!hoverActive.current && props.type === 'source') {
      setButtonVisible(internalIsValidConnection);
    }
    hoverActive.current = true;
  }, [props.type, internalIsValidConnection]);

  return { onMouseLeave, onMouseOver, buttonVisible, isValidConnection };
};
