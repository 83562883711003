import { breakpoints, dimensions } from '@maestro/styles';
import styled from 'styled-components';

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};

  @media ${breakpoints.tablet} {
    gap: ${dimensions.size12};
  }
`;
