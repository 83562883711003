import { Button, CloseButton } from '@chakra-ui/react';
import {
  RpgConfig,
  RpgConfigCustomItem,
  Value,
  ValueType,
} from '@common/studio-types';
import {
  Icon,
  IconMenuItem,
  Menu,
  MenuButton,
  MenuList,
} from '@maestro/components';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { ValueInput } from '../value/ValueInput';

type Props = {
  effect: RpgConfigCustomItem['effects'][number];
  rpgConfig: RpgConfig;
  onChange: (effect: RpgConfigCustomItem['effects'][number]) => void;
  onDelete: () => void;
};

export const ItemActionEffectForm: React.FC<Props> = ({
  effect,
  rpgConfig,
  onChange,
  onDelete,
}) => {
  const onChangeValue = (changedValue: Value) => {
    onChange({ ...effect, changedValue, value: { ...changedValue } });
  };

  return (
    <ColumnContainer>
      <RowContainer>
        <Container>
          <ValueInput
            updateOperation
            rpgConfig={rpgConfig}
            disabledTypes={[ValueType.Input, ValueType.StaticValue]}
            value={effect.changedValue}
            onChange={onChangeValue}
          />
        </Container>
        <Menu>
          <MenuButton>
            <Button variant="input" color="light.600" height="48px">
              <Icon
                name={
                  effect.action === 'increase'
                    ? 'plus'
                    : effect.action === 'reduce'
                      ? 'minus'
                      : 'arrow-left'
                }
                size={20}
              />
            </Button>
          </MenuButton>
          <MenuList>
            <IconMenuItem
              name="Set"
              icon="arrow-left"
              onClick={() => onChange({ ...effect, action: 'set' })}
            />

            <IconMenuItem
              name="Increase"
              icon="plus"
              onClick={() => onChange({ ...effect, action: 'increase' })}
            />
            <IconMenuItem
              name="Reduce"
              icon="minus"
              onClick={() => onChange({ ...effect, action: 'reduce' })}
            />
          </MenuList>
        </Menu>
        <Container>
          {effect.value && (
            <ValueInput
              rpgConfig={rpgConfig}
              value={effect.value}
              disabledTypes={[ValueType.Item, ValueType.Input]}
              onChange={(value) => onChange({ ...effect, value })}
            />
          )}
        </Container>
        <CloseButton onClick={onDelete} />
      </RowContainer>
    </ColumnContainer>
  );
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
`;

const ColumnContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;

const Container = styled.div`
  flex: 1;
`;
