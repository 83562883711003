import { DataType } from '@common/studio-types';
import { GameState } from '../game';
import { StatefulGame } from './statefulGame';

export const init = (game: StatefulGame, state?: GameState): void => {
  const properties = game.config.getProperties().reduce((agg, prop) => {
    const defaultValue =
      prop.config.dataType === DataType.Number ||
      prop.config.dataType === DataType.String
        ? prop.config.defaultValue
        : '';

    return { ...agg, [prop.id]: defaultValue };
  }, {});

  game.setGameState(
    state ?? {
      input: {},
      currentInteraction: undefined,
      properties,
      selectedOptions: {},
    },
  );
};
