import {
  OnboardingCompleteNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { OnboardingCompleteForm } from './OnboardingCompleteForm';
import { OnboardingCompleteNode } from './OnboardingCompleteNode';

const name = 'Onboarding Complete';

export const onboardingCompleteNode: NodeConfig<
  StudioNodeType.OnboardingComplete,
  OnboardingCompleteNodeData
> = {
  type: StudioNodeType.OnboardingComplete,
  name,
  icon: 'objective',
  episodeRef: 'onboarding',
  node: OnboardingCompleteNode,
  form: OnboardingCompleteForm,
  sourceHandles: () => [], // no source handle
  getNodeData: (data) => ({ title: name, description: data.text }),
  cloneNodeData: ({ text }) => ({ data: { id: toNodeId(), text } }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.OnboardingComplete,
    text: '',
  }),
};
