import { ColorTheme, RGBA } from '../types/theme';
import { fillColorMap } from './fillColors';

export type TextColorDesignSystem = {
  header: RGBA;
  body: RGBA;
  highlight: RGBA;
  placeholder: RGBA;
  accent: RGBA;
};

export const textColorMap: ColorTheme<TextColorDesignSystem> = {
  dark: {
    accent: fillColorMap.dark.accent['1000'],
    header: fillColorMap.dark.default['1000'],
    body: fillColorMap.dark.default['600'],
    highlight: fillColorMap.dark.accent['1000'],
    placeholder: fillColorMap.dark.default['400'],
  },
  light: {
    accent: fillColorMap.dark.accent['1000'],
    header: fillColorMap.light.default['1000'],
    body: fillColorMap.light.default['600'],
    highlight: fillColorMap.light.accent['1000'],
    placeholder: fillColorMap.light.default['400'],
  },
};
