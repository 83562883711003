import { textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Label: React.FC<PropsWithChildren> = ({ children }) => {
  return <LabelContainer>{children}</LabelContainer>;
};

const LabelContainer = styled.div`
  ${textStyles.label.lb14sb};
  color: ${({ theme }) => theme.colors.text.header};
`;
