import { CloseButton, useDisclosure } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { NoResultContainer } from '@maestro/components';
import {
  IconMenuItem,
  Menu,
  MenuDropdownButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from './menu/Menu';

type Group = { name?: string; items: string[] };

type Props<T> = {
  onChange: (value: T) => void;
  selectedItem?: T;
  groups: Group[];
  listItems: T[];
  onSearch: (search: string) => T[];
  renderListItem: (item: T) => React.ReactNode;
  renderSelectedItem: (item: T) => React.ReactNode;
  isNullable?: boolean;
  getId: (item: T) => string;
  onCreateItemClick?: () => void;
  createItemLabel?: string;
};

export const SelectInput: <T>(p: Props<T>) => React.ReactElement<Props<T>> = (
  props,
) => {
  const [search, setSearch] = useState('');
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setItems(props.onSearch(searchValue));
  };
  const [items, setItems] = useState(props.listItems);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onMenuOpen = () => {
    onOpen();
    setHoveredIndex(0);
    setSearch('');
    setItems(props.listItems);
  };

  const groups = useMemo(() => {
    const itemsMap = items.reduce(
      (acc, item) => ({ ...acc, [props.getId(item)]: item }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<string, any>,
    );

    return props.groups
      .map((group) => {
        return {
          name: group.name,
          items: group.items.map((id) => itemsMap[id]).filter(Boolean),
        };
      })
      .filter(({ items }) => items.length > 0);
  }, [items, props.groups]);
  const groupItems = useMemo(() => groups.flatMap((g) => g.items), [groups]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    const listSize = items.length;

    if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    } else if (e.key === 'ArrowDown') {
      setHoveredIndex((prev) => (prev === listSize - 1 ? 0 : prev + 1));
    } else if (e.key === 'ArrowUp') {
      setHoveredIndex((prev) => (prev > 0 ? prev - 1 : listSize - 1));
    } else if (e.key === 'Enter') {
      const item = groupItems[hoveredIndex];

      if (item) {
        props.onChange(item);
        onClose();
      }
    } else {
      setHoveredIndex(0);
    }
  };

  const onClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onChange(undefined!);
  };

  return (
    <StyledMenu isOpen={isOpen} onOpen={onMenuOpen} onClose={onClose}>
      <MenuDropdownButton isInvalid={!props.isNullable && !props.selectedItem}>
        {isOpen ? (
          <Searcher>
            <CustomInput
              type="text"
              autoFocus
              value={search}
              placeholder="Search"
              onKeyDown={onKeyDown}
              onClick={(e) => e.stopPropagation()}
              onChange={onChangeSearch}
            />
          </Searcher>
        ) : (
          <DropdownContainer>
            {props.selectedItem && props.renderSelectedItem(props.selectedItem)}
            {props.isNullable && props.selectedItem && (
              <CloseButton onClick={onClear} />
            )}
          </DropdownContainer>
        )}
      </MenuDropdownButton>
      <MenuList>
        {props.onCreateItemClick && (
          <IconMenuItem
            icon="plus"
            name={props.createItemLabel || 'Create item'}
            onClick={props.onCreateItemClick}
          />
        )}
        {items.length === 0 && (
          <NoResultContainer>No properties found</NoResultContainer>
        )}

        {groups.map((group) => (
          <React.Fragment key={group.name}>
            {group.name && <MenuGroup>{group.name}</MenuGroup>}
            {group.items.map((item) => (
              <MenuItem
                key={props.getId(item)}
                active={
                  hoveredIndex === groupItems.findIndex((i) => i === item)
                }
                onClick={() => props.onChange(item)}
              >
                {props.renderListItem(item)}
              </MenuItem>
            ))}
          </React.Fragment>
        ))}
      </MenuList>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  flex: 1;
`;

const CustomInput = styled.input`
  ${textStyles.body.b14m}
  background: transparent;
  width: 100%;
  padding: ${dimensions.size12};
  margin-top: ${dimensions.size8};
  margin-left: -${dimensions.size16};
  outline: ${dimensions.size0};
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size4};
`;

const Searcher = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  padding: ${dimensions.size0} ${dimensions.size8};
  margin-bottom: ${dimensions.size8};
`;
