import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from './router';

// Polyfills
import '../polyfills';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider />
  </React.StrictMode>,
);
