import {
  ConditionCheckNodeData,
  ConditionOperator,
  ValueType,
  StudioNodeType,
  DataType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { ConditionCheckForm } from './ConditionCheckForm';
import { ConditionCheckNode } from './ConditionCheckNode';

const name = 'Condition';

export const conditionCheckNode: NodeConfig<
  StudioNodeType.ConditionCheck,
  ConditionCheckNodeData
> = {
  type: StudioNodeType.ConditionCheck,
  name,
  icon: 'arrow-split',
  group: NodeGroup.Advanced,
  node: ConditionCheckNode,
  form: ConditionCheckForm,
  sourceHandles: (data) => [
    { handleId: `${data.id}-true`, name: 'true' },
    { handleId: `${data.id}-false`, name: 'false' },
  ],
  getNodeData: () => ({ title: name }),
  cloneNodeData: ({ id, valueA, valueB, operator }) => {
    const newId = toNodeId();

    return {
      data: {
        id: newId,
        valueA: { ...valueA },
        valueB: { ...valueB },
        operator,

        falseNodeId: null as never,
        trueNodeId: null as never,
      },
      connections: [
        { oldId: `${id}-true`, newId: `${newId}-true` },
        { oldId: `${id}-false`, newId: `${newId}-false` },
      ],
    };
  },
  createNodeData: () => ({
    id: toNodeId(),
    valueA: {
      type: ValueType.Input,
      ref: 'default',
      dataType: DataType.Number,
    },
    valueB: {
      type: ValueType.StaticValue,
      value: 1,
      dataType: DataType.Number,
    },
    type: StudioNodeType.ConditionCheck,
    operator: ConditionOperator.Equal,
    falseNodeId: null as never,
    trueNodeId: null as never,
  }),
};
