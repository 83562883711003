import { StudioNodeData } from '@common/studio-types';
import React from 'react';
import { Edge, Node, ReactFlowInstance, useReactFlow } from 'reactflow';
import { useAutoLayout } from './hooks/useAutoLayout';
import { useDebounce } from './hooks/useDebounce';
import { useRealtimeStudioFlow } from './realtime/useRealtimeStudioFlow';
import { StudioFlow } from './StudioFlow';

type Props = {
  episodeId: string;
  save: (instance: ReactFlowInstance) => Promise<void>;
  showControls?: boolean;
  nodes: Node<StudioNodeData>[];
  edges: Edge[];
};

export const EpisodeStudioFlow: React.FC<Props> = (props) => {
  const instance = useReactFlow();
  const autoLayout = useAutoLayout();
  const organizeNodes = useDebounce(
    () => {
      const { nodes, edges } = autoLayout(
        instance.getNodes(),
        instance.getEdges(),
      );
      instance.setNodes(nodes);
      instance.setEdges(edges);
    },
    [],
    2000,
  );

  useRealtimeStudioFlow({
    episodeId: props.episodeId,
    onNodeChange: (nodes) => {
      instance.setNodes(
        instance.getNodes().map((n) => {
          const matchedNode = nodes.find((node) => node.id === n.id);

          return matchedNode ? { ...n, data: matchedNode } : n;
        }),
      );
      organizeNodes();
    },
  });

  return (
    <StudioFlow
      showControls
      onChange={() => props.save(instance)}
      initialNodes={props.nodes}
      initialEdges={props.edges}
    />
  );
};
