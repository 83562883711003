import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const TabBar = styled.div`
  display: flex;
  gap: ${dimensions.size0};
`;

type Props = PropsWithChildren<{
  isActive?: boolean;
  onClick?: VoidFunction;
}>;

export const Tab: React.FC<Props> = ({ children, isActive, onClick }) => {
  return (
    <TabContainer onClick={onClick} $isActive={isActive}>
      {children}
    </TabContainer>
  );
};

const TabContainer = styled.div<{ $isActive?: boolean }>`
  ${textStyles.label.lb14sb};
  padding: ${dimensions.size12} ${dimensions.size24};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.text.header : theme.colors.text.placeholder};
  cursor: pointer;
  border-bottom: ${dimensions.size2} solid
    ${({ theme, $isActive }) =>
      $isActive ? theme.colors.base.accent[1000] : 'transparent'};
`;
