import { StringPropertyConfig } from '@common/studio-types';
import { Field, TextInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onChange: (data: StringPropertyConfig) => void;
  data: StringPropertyConfig;
};

export const StringPropertyForm: React.FC<Props> = ({ onChange, data }) => {
  return (
    <Container>
      <StyledField label="Default Value">
        <TextInput
          placeholder="Default"
          onChange={(e) => onChange({ ...data, defaultValue: e.target.value })}
          value={data.defaultValue}
        />
      </StyledField>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const StyledField = styled(Field)`
  margin-bottom: ${dimensions.size0};
`;
