import { Tooltip } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';

type Props = {
  refreshStatus: () => void;
};

export const StudioSaveStatus: React.FC<Props> = ({ refreshStatus }) => {
  const [isOfflineOpen, setIsOfflineOpen] = useState<boolean | undefined>();
  const [isReadonlyOpen, setIsReadonlyOpen] = useState<boolean | undefined>();
  const { saveStatus } = useStudioFlowStore();

  useEffect(() => {
    if (saveStatus === 'offline') {
      setIsOfflineOpen(true);
      setTimeout(() => setIsOfflineOpen(undefined), 3000);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (saveStatus === 'readonly') {
      setIsReadonlyOpen(true);
      setTimeout(() => setIsReadonlyOpen(undefined), 3000);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (saveStatus === 'saved') {
      refreshStatus();
    }
  }, [saveStatus, refreshStatus]);

  return (
    <Container aria-label="Save Status">
      {saveStatus === 'readonly' ? (
        <Tooltip
          width="200px"
          label="This version is read-only, nothing you change will be saved"
          placement={'bottom-end'}
          isOpen={isReadonlyOpen}
        >
          <StatusContainer className="save-status" aria-label="Read only">
            <StyledIcon name={'offline'} size={rawDimensions.size20} />
            Read-only
          </StatusContainer>
        </Tooltip>
      ) : saveStatus === 'offline' ? (
        <Tooltip
          width="200px"
          label="Offline changes will be saved when you go online"
          placement={'bottom'}
          isOpen={isOfflineOpen}
        >
          <StatusContainer className="save-status" aria-label="Offline">
            <StyledIcon name={'offline'} size={rawDimensions.size20} />
          </StatusContainer>
        </Tooltip>
      ) : saveStatus === 'saving' ? (
        <StatusContainer className="save-status" aria-label="Saving">
          <LoadingIcon name={'loading'} size={rawDimensions.size20} />
        </StatusContainer>
      ) : (
        saveStatus === 'saved' && (
          <Tooltip label="Changes saved" placement={'bottom'}>
            <StatusContainer className="save-status" aria-label="Changes saved">
              <StyledIcon
                name={'cloud-checkmark-filled'}
                size={rawDimensions.size20}
              />
            </StatusContainer>
          </Tooltip>
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-right: ${dimensions.size8};
`;

const StatusContainer = styled.div`
  ${textStyles.label.lb14sb}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-content: center;
  align-items: center;
  width: auto;
  color: ${({ theme }) => theme.colors.text.placeholder};
  cursor: default;
`;

const StyledIcon = styled(Icon)`
  &:before {
    color: ${({ theme }) => theme.colors.fill.default[400]};
  }
`;

const LoadingIcon = styled(StyledIcon)`
  animation: spin 2s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
