import { Button, ButtonProps } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { rawDimensions } from '@maestro/styles';
import { FC } from 'react';
import { useCreateEmptyEpisode } from '../../hooks/useCreateEmptyEpisode';

type Props = ButtonProps & {
  seriesId?: string;
};

export const CreateEpisodeButton: FC<Props> = ({
  seriesId,
  ...buttonProps
}: Props) => {
  const { createEmptyEpisode, isLoading } = useCreateEmptyEpisode(seriesId);

  const onCreateEpisodeClick = async () => {
    if (isLoading) return;
    await createEmptyEpisode({ redirect: true });
  };

  return (
    <Button
      onClick={onCreateEpisodeClick}
      variant="primary"
      leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
      isLoading={isLoading}
      {...buttonProps}
    >
      New episode
    </Button>
  );
};
