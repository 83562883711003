import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ModalCloseButton } from '@maestro/components';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditSeriesForm } from './EditSeriesForm';

export const EditSeriesModal = () => {
  const navigate = useNavigate();
  const [seriesId, setSeriesId] = useState<string | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search, pathname } = useLocation();
  const modalFooterRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const seriesId = params.get('seriesId');
      const isModalOpen = params.get('edit') === 'series' && !!seriesId;

      if (!!seriesId) {
        setSeriesId(seriesId);
      }

      if (!!isModalOpen) {
        onOpen();
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  }, [search]);

  const handleRedirects = (isSaved = false) => {
    const params = new URLSearchParams(search);
    const referer = params.get('referer');

    if (referer === 'publish') {
      navigate(`${pathname}?publish=1`);
    } else if (referer === 'series-details' && isSaved) {
      navigate(`${pathname}?refresh=series`);
    } else {
      navigate(pathname);
    }
  };

  const onSave = () => {
    handleRedirects(true);

    onClose();
  };

  const onCloseClick = () => {
    handleRedirects();
  };

  if (!seriesId) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseClick} size="3xl" variant="wizard">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Edit series details
          <ModalCloseButton onClose={onCloseClick} />
        </ModalHeader>
        <ModalBody>
          <EditSeriesForm
            seriesId={seriesId}
            onComplete={() => onSave()}
            buttonsParentRef={modalFooterRef}
          />
        </ModalBody>
        <ModalFooter ref={modalFooterRef}></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
