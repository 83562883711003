import { Badge, Button } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { Suggestions } from './suggestion.types';

type Props = {
  suggestion: Suggestions;
};

export const SuggestionItem: React.FC<Props> = ({ suggestion }) => {
  return (
    <Container
      onClick={suggestion.action.title ? undefined : suggestion.action.onClick}
    >
      <LeftContainer>
        <TitleContainer>
          <Badge variant="dot" marginRight={dimensions.size8} />
          {suggestion.title}
          {suggestion.subtitle && (
            <Subtitle>&nbsp;/ {suggestion.subtitle}</Subtitle>
          )}
        </TitleContainer>
        <DescriptionContainer>{suggestion.description}</DescriptionContainer>
      </LeftContainer>
      {suggestion.action.title && (
        <Button
          fontSize="14px"
          fontWeight="600"
          padding={`${dimensions.size8} ${dimensions.size12}`}
          onClick={suggestion.action.onClick}
          variant="default"
          maxWidth="52px"
          minWidth="52px"
        >
          {suggestion.action.title}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${dimensions.size16};

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.background.shade};

  &:hover {
    background: ${({ theme }) => theme.colors.base.accent[100]};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const TitleContainer = styled.div`
  ${textStyles.label.lb12sb};
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.header};
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const DescriptionContainer = styled.div`
  ${textStyles.body.b12m};
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
