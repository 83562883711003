import React, { memo } from 'react';
import { SingleSelectInteractionMessage } from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import { MaestroPlayerChoiceInteraction } from './MaestroPlayerChoiceInteraction';

type Props = {
  message: SingleSelectInteractionMessage;
  game: GameSimulator;
  isLastMessage: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { message, game, isLastMessage } = props;

  const options = message.options.map((option) => ({
    id: option.id,
    text: option.text,
    icon: option.addon?.type === 'coin-toss' ? 'coin-flip' : undefined,
  }));

  return (
    <MaestroPlayerChoiceInteraction
      onSelect={(optionId) => game.singleSelect(message.id, optionId)}
      options={options}
      prompt={message.prompt}
      showChoices={isLastMessage}
    />
  );
};

export const MaestroGameMessagePlayerChoice = memo(Component);
