export const randomUUID = () => {
  if (typeof window.crypto === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).crypto = {};
  }

  if (typeof window.crypto.randomUUID === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).crypto.randomUUID = function randomUUID() {
      // https://stackoverflow.com/a/2117523/2800218
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16),
      );
    };
  }
};
