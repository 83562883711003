import { useAuthStore } from '@maestro/auth';
import { env } from '@maestro/env';
import { FetchError } from './types';

/**
 * @description Fetcher function for GraphQL hooks generated by codegen
 */

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
) {
  return async (): Promise<TData> => {
    const jwt = useAuthStore.getState().jwt;
    const res = await fetch(env.VITE_BASE_GQL_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(!!jwt ? { Authorization: `Bearer ${jwt}` } : {}),
      },
      body: JSON.stringify({ query, variables }),
    }).catch(() => {
      // if fetch throws an error, this means it was a network issue
      throw new FetchError('Network error', 'network');
    });

    const isAuthenticationError = res.status === 401;
    const json = await res.json();

    if (isAuthenticationError) {
      throw new FetchError('Authentication error', 'auth');
    } else if (json.errors) {
      throw new FetchError(json.errors[0].message, 'server');
    }

    return json.data;
  };
}
