import type { CoinTossNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { ValueBadge } from '../../components/value/ValueBadge';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<CoinTossNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      nodeId={id}
      title="Coin toss"
      subtitle={data.prompt}
      selected={selected}
      node={data}
    >
      {data.property && (
        <AttributeContainer>
          <span>Property modifier:</span>
          <ValueBadge value={data.property} />
        </AttributeContainer>
      )}

      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <CoinTossNodeContainer>
        <CoinTossResultContainer $isSuccess>
          Success
          <Handle
            nodeId={data.id}
            label="Success"
            type="source"
            position={Position.Bottom}
            id={`${data.id}-success`}
          />
        </CoinTossResultContainer>
        <CoinTossResultContainer $isSuccess={false}>
          Fail
          <Handle
            nodeId={data.id}
            label="Fail"
            type="source"
            position={Position.Bottom}
            id={`${data.id}-fail`}
          />
        </CoinTossResultContainer>
      </CoinTossNodeContainer>
    </BaseStudioNode>
  );
};

export const CoinTossNode = memo(Node);

const CoinTossNodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  justify-content: space-evenly;
  margin-bottom: -${dimensions.size16};
`;

const AttributeContainer = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  gap: ${dimensions.size6};
  align-items: center;
  margin-bottom: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.body};
  border-radius: ${dimensions.size4};
`;

const CoinTossResultContainer = styled.div<{ $isSuccess: boolean }>`
  ${textStyles.body.b12sb}
  position: relative;
  width: ${dimensions.size72};
  padding: ${dimensions.size4} ${dimensions.size4} ${dimensions.size6}
    ${dimensions.size4};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4} ${dimensions.size4} ${dimensions.size0}
    ${dimensions.size0};
`;
