import { dimensions, textStyles } from '@maestro/styles';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type ListHeaderProps = PropsWithChildren<{
  draggable?: boolean;
}>;

export const ListHeader: React.FC<ListHeaderProps> = ({
  children,
  draggable,
}) => {
  return (
    <ListHeaderContainer $paddingLeft={draggable ? 44 : 0}>
      {children}
    </ListHeaderContainer>
  );
};

const ListHeaderContainer = styled.div<{ $paddingLeft: number }>`
  display: flex;
  align-items: center;
  padding-left: ${({ $paddingLeft }) => $paddingLeft}px;
  width: 100%;
  height: ${dimensions.size48};
`;

type ListHeaderColumnProps = PropsWithChildren<{
  width?: string;
  flex?: boolean;
}>;

export const ListHeaderColumn: React.FC<ListHeaderColumnProps> = ({
  children,
  width,
  flex,
}) => {
  return (
    <ListHeaderColumnContainer $width={width} $flex={flex}>
      {children}
    </ListHeaderColumnContainer>
  );
};

const ListHeaderColumnContainer = styled.div<{
  $width?: string;
  $flex?: boolean;
}>`
  ${textStyles.label.lb12sb}
  width: ${({ $width }) => $width};
  flex: ${({ $flex }) => ($flex ? 1 : 'unset')};
  color: ${({ theme }) => theme.colors.text.body};
  vertical-align: middle;
  padding: ${dimensions.size0} ${dimensions.size16};
`;
