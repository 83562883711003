import {
  DiceRollAction,
  DiceRollNodeData,
  DiceRollStrategy,
  StudioNodeType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { DiceRollForm } from './DiceRollForm';
import { DiceRollNode } from './DiceRollNode';

const name = 'Dice roll';

export const diceRollNode: NodeConfig<
  StudioNodeType.DiceRoll,
  DiceRollNodeData
> = {
  type: StudioNodeType.DiceRoll,
  name,
  icon: 'dice',
  group: NodeGroup.Advanced,
  node: DiceRollNode,
  form: DiceRollForm,
  getNodeData: () => ({ title: name }),
  cloneNodeData: ({
    prompt,
    modifiers,
    dices,
    rollAction,
    strategy,
    outputName,
  }) => ({
    data: {
      id: toNodeId(),
      dices,
      prompt,
      modifiers: modifiers.map(({ id, action, value }) => ({
        id,
        action,
        value: { ...value },
      })),
      rollAction,
      strategy: { ...strategy },
      outputName,
    },
  }),
  createNodeData: () => ({
    id: toNodeId(),
    prompt: '',
    type: StudioNodeType.DiceRoll,
    dices: ['d6'],
    modifiers: [],
    rollAction: DiceRollAction.User,
    strategy: { type: DiceRollStrategy.Sum },
    outputName: 'default',
  }),
  validateNodeData: () => {
    // TODO we need to work on validation
    return [];
  },
};
