import { RpgConfigProperty, StudioNodeType } from '@common/studio-types';
import { useMemo } from 'react';
import { useReactFlow } from 'reactflow';

export const useGetEpisodeProperties = (): RpgConfigProperty[] => {
  const { getNodes } = useReactFlow();

  return useMemo(() => {
    return getNodes()
      .map((node) => {
        return node.data.type === StudioNodeType.EpisodeSetup
          ? node.data.properties
          : [];
      })
      .flat();
  }, [getNodes]);
};
