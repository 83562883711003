import {
  ChangeValueNodeData,
  DataType,
  StudioNodeType,
  ValueType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import { NodeGroup, type NodeConfig } from '../Node.types';
import { ChangeValueForm } from './ChangeValueForm';
import { ChangeValueNode } from './ChangeValueNode';

const name = 'Set value';

export const changeValueNode: NodeConfig<
  StudioNodeType.ChangeValue,
  ChangeValueNodeData
> = {
  type: StudioNodeType.ChangeValue,
  name,
  icon: 'edit',
  group: NodeGroup.Advanced,
  node: ChangeValueNode,
  form: ChangeValueForm,
  getNodeData: () => ({ title: name }),
  cloneNodeData: ({ changedValue, value, action, isSilent }) => ({
    data: {
      id: toNodeId(),
      changedValue,
      action,
      isSilent,
      value: value ? { ...value } : undefined,
    },
  }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.ChangeValue,
    changedValue: { type: ValueType.Item, ref: '', dataType: DataType.Number },
    value: undefined,
    isSilent: false,
    action: 'increase',
  }),
};
