import { Badge, Button, Tooltip } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';
import { SuggestionsDrawer } from '../components/suggestions/SuggestionsDrawer';
import { useSuggestionsStore } from '../hooks/suggestions/useSuggestionsStore';
import { useDrawerStore } from '../hooks/useDrawerStore';

export const SuggestionsButton: React.FC = () => {
  const { suggestions } = useSuggestionsStore();
  const { closeDrawer, openDrawer, drawerOpen } = useDrawerStore();
  const isSuggestionsOpen = drawerOpen === 'suggestions';

  const onOpenSuggestions = () => {
    if (isSuggestionsOpen) {
      closeDrawer();

      return;
    }
    openDrawer('suggestions');
  };

  const suggestionsCount = suggestions.reduce(
    (acc, section) => acc + section.suggestions.length,
    0,
  );

  if (suggestionsCount <= 0) {
    return null;
  }

  return (
    <>
      <Tooltip label="Review" placement="bottom">
        <Button
          variant="studioTopBar"
          isActive={isSuggestionsOpen}
          onClick={onOpenSuggestions}
        >
          <Badge
            variant="primary"
            marginRight={dimensions.size8}
            fontSize={dimensions.size14}
            minW={dimensions.size20}
            minH={dimensions.size20}
            padding={`${dimensions.size0} ${dimensions.size6}`}
          >
            {suggestionsCount}
          </Badge>
          <Icon name="edit-bar-filled" size={rawDimensions.size16} />
        </Button>
      </Tooltip>
      <SuggestionsDrawer
        isOpen={isSuggestionsOpen}
        onCancel={closeDrawer}
        suggestions={suggestions}
        withExpandedSuggestions
      />
    </>
  );
};
