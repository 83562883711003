import { ValueType, type GetValueNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { OutputName } from '../../components/OutputName';
import { ItemContainer } from '../../components/value/ItemContainer';
import { ValueBadge } from '../../components/value/ValueBadge';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<GetValueNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      nodeId={id}
      title="Get Value"
      selected={selected}
      node={data}
    >
      <Container>
        <RowContainer>
          <ValueBadge value={data.value} />
          <OutputName outputName={data.outputName ?? 'default'} />
        </RowContainer>
        {data.value.type === ValueType.Item && (
          <ItemContainer itemId={data.value.ref} />
        )}
      </Container>

      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <Handle
        nodeId={data.id}
        label="Output"
        type="source"
        position={Position.Bottom}
        id={data.id}
      />
    </BaseStudioNode>
  );
};

export const GetValueNode = memo(Node);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const RowContainer = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.header};
`;
