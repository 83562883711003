import type { EnumOption, EnumPropertyConfig, RpgConfigProperty } from '.';

export enum ValueType {
  Property = 'property',
  StaticValue = 'static-value',
  Input = 'input',
  Item = 'item',
}

export enum DataType {
  Number = 'number',
  String = 'string',
  Enum = 'enum',
}

type BaseValue<TType extends ValueType, TData> = TData & {
  type: TType;
  dataType: DataType;
  enumRef?: string;
};

type StaticValueString = BaseValue<
  ValueType.StaticValue,
  { dataType: DataType.String; value: string }
>;

export type StaticValueNumber = BaseValue<
  ValueType.StaticValue,
  { dataType: DataType.Number; value: number }
>;

type StaticValueEnum = BaseValue<
  ValueType.StaticValue,
  { dataType: DataType.Enum; ref: string }
>;

export type StaticValue =
  | StaticValueString
  | StaticValueNumber
  | StaticValueEnum;

export type PropertyValue = BaseValue<ValueType.Property, { ref: string }>;
export type InputValue = BaseValue<ValueType.Input, { ref: string }>;
export type ItemValue = BaseValue<ValueType.Item, { ref: string }>;

export type Value = StaticValue | PropertyValue | InputValue | ItemValue;
type SplitVariableValue = Value & { isStartOfSentence: boolean };

export const splitVariables = (
  text: string,
  getPropertyByName: (ref: string) => RpgConfigProperty | undefined,
): Array<string | SplitVariableValue> => {
  const getProperty = (
    part: string,
    name: string,
    isStartOfSentence: boolean,
  ): string | SplitVariableValue => {
    const property = getPropertyByName(name);

    if (!property) return part;

    return {
      dataType: property.config.dataType,
      type: ValueType.Property,
      ref: property.id,
      isStartOfSentence,
    };
  };

  const split = text.split(/(\{[^\}]+\})/g);

  return split.map((part, index) => {
    if (part.startsWith('{') && part.endsWith('}')) {
      const value = part.slice(1, -1);
      const previous = index > 0 ? split[index - 1].trim() : '';
      const isStartOfSentence = previous[previous.length - 1] === '.';

      if (value.startsWith('prop.series.')) {
        return getProperty(value, value.slice(12), isStartOfSentence);
      } else if (value.startsWith('prop.episode.')) {
        return getProperty(value, value.slice(13), isStartOfSentence);
      } else if (value.startsWith('prop.')) {
        return getProperty(value, value.slice(5), isStartOfSentence);
      } else if (value.startsWith('input.')) {
        return {
          dataType: DataType.Number,
          type: ValueType.Input,
          ref: value.slice(6),
          isStartOfSentence,
        };
      }
    }

    return part;
  });
};

const stringProp = (ref: string): PropertyValue => ({
  type: ValueType.Property,
  dataType: DataType.String,
  ref,
});

const numberProp = (ref: string): PropertyValue => ({
  type: ValueType.Property,
  dataType: DataType.Number,
  ref,
});

const staticString = (value: string): StaticValueString => ({
  type: ValueType.StaticValue,
  dataType: DataType.String,
  value,
});

const staticNumber = (value: number): StaticValueNumber => ({
  type: ValueType.StaticValue,
  dataType: DataType.Number,
  value,
});

export const getEnumOptions = (
  enumRef: string | undefined,
  properties: RpgConfigProperty[],
): EnumOption[] => {
  const enumConfig = properties.find(({ config }) => {
    return config.dataType === DataType.Enum && config.enumRef === enumRef;
  })?.config as EnumPropertyConfig | undefined;

  return enumConfig?.options ?? [];
};

export const valueUtils = {
  stringProp,
  numberProp,
  staticString,
  staticNumber,
};
