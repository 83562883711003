import { ChangeValueNodeData, StudioNodeType } from '@common/studio-types';
import { changeValue } from '../actions';
import { EpisodeData } from '../game/episodeData';
import type { Message } from '../game/messages.types';
import type { StatefulGame } from './statefulGame';

type Args = {
  itemId: string;
  episodeData: EpisodeData;
};

export const handleTriggerItemActionInteraction = async (
  game: StatefulGame,
  interactionArgs: Args,
): Promise<Message[]> => {
  const { itemId, episodeData } = interactionArgs;
  const { playerData } = game.gameData;
  const { quantity } = await game.gameData.playerData.getItem(itemId);

  const customItem = episodeData.customItems.find(
    (item) => item.itemRef === itemId,
  );

  if (!customItem) {
    return [game.errorMessage('Invalid item action')];
  } else if (!quantity || quantity <= 0) {
    return [game.errorMessage('You do not have this item')];
  }

  for (const effect of customItem.effects) {
    const effectNodeData = {
      type: StudioNodeType.ChangeValue,
      ...effect,
    } as ChangeValueNodeData;

    const result = await changeValue(
      effectNodeData,
      game.state(),
      playerData,
      game.config,
    );

    game.setGameState(result.state);
  }

  if (customItem.consumable) {
    await playerData.setItem(itemId, { delta: -1 });
  }

  return [];
};
