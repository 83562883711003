import React, { memo } from 'react';
import { PlayerInputResultMessage } from '@enigma-engine/core';
import { MaestroSelectedInteraction } from './MaestroSelectedInteraction';

type Props = {
  message: PlayerInputResultMessage;
};

const Component: React.FC<Props> = (props) => {
  const { message } = props;

  return <MaestroSelectedInteraction text={message.input} />;
};

export const MaestroGameMessagePlayerInputResult = memo(Component);
