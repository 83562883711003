import { Button, ButtonGroup, ModalBody, ModalFooter } from '@chakra-ui/react';
import { RpgConfig, RpgConfigCustomItem } from '@common/studio-types';
import {
  FormControl,
  ImageInput,
  Loader,
  Tab,
  TabBar,
  TextInput,
  Textarea,
} from '@maestro/components';
import { breakpoints, dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CategoryDropdown } from './CategoryDropdown';
import { ItemActionFlow } from './ItemActionForm';
import { ItemFormValue } from './types';

type Props = {
  seriesId: string;
  formTitle: string;
  item: ItemFormValue;
  rpgConfig?: RpgConfig;
  onChange: (item: ItemFormValue) => void;
  onSubmit: (customItem?: RpgConfigCustomItem) => void;
  onCancel: () => void;
  isLoading?: boolean;
  itemId?: string;
};

export const ItemsForm: React.FC<Props> = (props) => {
  const { item, onChange, onCancel, rpgConfig } = props;
  const [submitPressed, setSubmitPressed] = useState(false);
  const [tab, setTab] = useState<string>('details');

  const [customItem, setCustomItem] = useState<
    RpgConfigCustomItem | undefined
  >();

  const onSubmit = async () => {
    if (!item.description || !item.name || !item.image || !item.category) {
      setSubmitPressed(true);

      return;
    }

    props.onSubmit(customItem);
  };

  useEffect(() => {
    if (rpgConfig) {
      if (props.itemId) {
        setCustomItem(
          rpgConfig.customItems?.find(
            (customItem) => customItem.itemRef === props.itemId,
          ),
        );
      } else {
        setCustomItem(undefined);
      }
    }
  }, [rpgConfig, props.itemId]);

  return (
    <>
      <TabBar>
        <Tab onClick={() => setTab('details')} isActive={tab === 'details'}>
          Details
        </Tab>
        <Tab isActive={tab === 'actions'} onClick={() => setTab('actions')}>
          Item Actions
        </Tab>
      </TabBar>

      <ModalBody padding={dimensions.size16}>
        {tab === 'actions' && (
          <>
            {!!rpgConfig ? (
              <ItemActionFlow
                seriesId={props.seriesId}
                rpgConfig={rpgConfig}
                customItem={customItem}
                onChange={setCustomItem}
              />
            ) : (
              <Loader />
            )}
          </>
        )}
        {tab === 'details' && (
          <RowContainer>
            <Container>
              <FormControl
                label="Name"
                isEnabled={submitPressed}
                object={item}
                field="name"
              >
                <TextInput
                  placeholder="Battle Axe"
                  maxCharacters={25}
                  enforceMaxCharacters
                  value={item.name}
                  onChange={(e) => onChange({ ...item, name: e.target.value })}
                />
              </FormControl>
              <FormControl
                label="Description"
                isEnabled={submitPressed}
                object={item}
                field="description"
              >
                <Textarea
                  placeholder="A hefty weapon with a sharp, wide blade, ideal for powerful strikes."
                  maxCharacters={500}
                  value={item.description}
                  onChange={(e) =>
                    onChange({ ...item, description: e.target.value })
                  }
                />
              </FormControl>
              <FormControl
                label="Category"
                isEnabled={submitPressed}
                object={item}
                field="category"
              >
                <CategoryDropdown
                  value={item.category}
                  onChange={(category) => onChange({ ...item, category })}
                />
              </FormControl>
            </Container>

            <Container>
              <FormControl
                label="Image"
                isEnabled={submitPressed}
                object={item}
                field="image"
              >
                <ImageContainer>
                  <ImageInput
                    value={item.image}
                    isInvalid={submitPressed && !item.image}
                    uploadPath="item"
                    onChange={(image) =>
                      onChange({ ...item, image: image?.path })
                    }
                  />
                </ImageContainer>
              </FormControl>
            </Container>
          </RowContainer>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={onCancel} variant="ghost" size="md">
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="primary" size="md">
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};

  @media ${breakpoints.tablet} {
    flex-direction: column;
  }
`;

const Container = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;
