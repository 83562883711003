import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

const itemCategories = [
  {
    name: 'Consumable',
    description:
      'Items that can be used once and then are expended or disappear, such as potions and food.',
  },
  {
    name: 'Equipment',
    description:
      'Items that can be worn or wielded by characters, typically providing bonuses or special abilities, including weapons, armor, and clothing.',
  },
  {
    name: 'Artifact',
    description:
      "Powerful and unique items that often carry significant historical, magical, or otherworldly importance, usually with strong effects and central to a campaign's story.",
  },
  {
    name: 'Currency',
    description:
      'Various forms of money used to buy and sell items and services within the game, including gold, silver, and copper coins.',
  },
  {
    name: 'Tool',
    description:
      "Items used to perform specific tasks or professions, such as thieves' tools, smithing tools, or musical instruments.",
  },
  {
    name: 'Magical Item',
    description:
      'A broad category that includes any item imbued with magical properties, ranging from simple magical clothing to complex enchanted weapons.',
  },
  {
    name: 'Miscellaneous',
    description:
      "Includes everything else that doesn't neatly fit into the other categories, such as trinkets, trophies, or decorative items.",
  },
];

type Props = {
  value: string | undefined;
  onChange: (value: string) => void;
};

export const CategoryDropdown: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Menu>
      <MenuDropdownButton>{value}</MenuDropdownButton>
      <MenuList>
        {itemCategories.map((category) => (
          <MenuItem key={category.name} onClick={() => onChange(category.name)}>
            <ValueContainer>
              <Name>{category.name}</Name>
              <Description>{category.description}</Description>
            </ValueContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;
