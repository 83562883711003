import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { dimensions } from '../dimensions';
import { baseColorMap } from './baseColors';
import { baseFont } from './baseFont';
import { borderColorMap } from './borderColors';
import { fillColorMap } from './fillColors';

const fieldStyle = {
  borderRadius: dimensions.size4,

  _placeholder: {
    color: 'text.placeholder',
  },

  _dark: {
    background: 'background.shade',
    fontSize: dimensions.size14,
    lineHeight: dimensions.size20,
    fontWeight: 500,
    borderColor: 'transparent',
    borderWidth: dimensions.size1,
    boxShadow: 'none',

    _invalid: {
      borderColor: 'border.error.1000',
    },

    _focus: {
      borderColor: 'accent.1000',
    },
  },
};

const inputStyle = {
  defaultProps: {
    size: 'lg',
    fontSize: dimensions.size14,
    fontWeight: 500,
    lineHeight: dimensions.size20,
    focusBorderColor: 'transparent',
  },
  baseStyle: {
    field: fieldStyle,
  },
  variants: {
    warning: {
      field: {
        _dark: {
          borderColor: 'warning',

          _invalid: { borderColor: 'warning' },
          _focus: { borderColor: 'warning' },
        },
      },
    },
  },
};

export const chakraTheme = extendTheme(
  {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
    zIndices: {
      modal: 10,
    },
    fonts: {
      heading: baseFont.family,
      body: baseFont.family,
    },
    colors: {
      warning: '#C0A54C',
      body: {
        bg: baseColorMap.dark.accent['1000'],
      },
      light: {
        30: baseColorMap.dark.light['30'],
        50: baseColorMap.dark.light['50'],
        100: baseColorMap.dark.light['100'],
        200: baseColorMap.dark.light['200'],
        400: baseColorMap.dark.light['400'],
        600: baseColorMap.dark.light['600'],
        800: baseColorMap.dark.light['800'],
        900: baseColorMap.dark.light['900'],
        1000: baseColorMap.dark.light['1000'],
      },
      accent: {
        100: baseColorMap.dark.accent['100'],
        200: baseColorMap.dark.accent['200'],
        400: baseColorMap.dark.accent['400'],
        600: baseColorMap.dark.accent['600'],
        800: baseColorMap.dark.accent['800'],
        900: baseColorMap.dark.accent['900'],
        1000: baseColorMap.dark.accent['1000'],
      },
      red: {
        100: baseColorMap.dark.red['100'],
        200: baseColorMap.dark.red['200'],
        400: baseColorMap.dark.red['400'],
        600: baseColorMap.dark.red['600'],
        800: baseColorMap.dark.red['800'],
        900: baseColorMap.dark.red['900'],
        1000: baseColorMap.dark.red['1000'],
      },
      background: {
        default: baseColorMap.dark.dark['1000'],
        hover: baseColorMap.dark.dark['800'],
        shade: baseColorMap.dark.light['50'],
        accent: baseColorMap.dark.accent['1000'],
        danger: baseColorMap.dark.red['200'],
        success: baseColorMap.dark.accent['200'],
      },
      border: {
        default: {
          400: baseColorMap.dark.light['400'],
          200: baseColorMap.dark.light['200'],
          100: baseColorMap.dark.light['100'],
        },
        accent: {
          1000: baseColorMap.dark.accent['1000'],
          800: baseColorMap.dark.accent['800'],
        },
        error: {
          1000: baseColorMap.dark.red['800'],
        },
      },
      text: {
        accent: fillColorMap.dark.accent['1000'],
        header: fillColorMap.dark.default['1000'],
        body: fillColorMap.dark.default['600'],
        highlight: fillColorMap.dark.accent['1000'],
        placeholder: fillColorMap.dark.default['400'],
      },
    },
    styles: {
      global: () => ({
        body: { bg: 'background.default' },
      }),
    },
    components: {
      Badge: {
        baseStyle: {
          borderRadius: dimensions.size4,
          padding: `${dimensions.size4} ${dimensions.size8}`,
          color: 'accent.1000',
          background: 'accent.100',
          fontWeight: '600',
        },
        variants: {
          primary: {
            background: 'background.accent',
            color: 'text.header',
            fontWeight: '800',
            borderRadius: dimensions.size999,
          },
          small: {
            fontSize: dimensions.size10,
            fontWeight: '700',
            padding: `${dimensions.size0} ${dimensions.size8}`,
            borderRadius: dimensions.size999,
          },
          dot: {
            background: 'background.accent',
            padding: dimensions.size4,
            borderRadius: dimensions.size999,
          },
          circle: {
            fontWeight: '800',
            padding: `${dimensions.size2} ${dimensions.size8}`,
            borderRadius: dimensions.size999,
          },
          statusActive: {
            fontSize: dimensions.size12,
            lineHeight: '18px',
            background: 'accent.100',
            textTransform: 'none',
            fontWeight: 600,
            padding: `${dimensions.size4} ${dimensions.size8}`,
            borderRadius: dimensions.size4,
          },
          statusInactive: {
            fontSize: dimensions.size12,
            lineHeight: '18px',
            background: 'light.100',
            textTransform: 'none',
            color: 'text.header',
            gap: dimensions.size4,
            fontWeight: 600,
            padding: `${dimensions.size4} ${dimensions.size8}`,
            borderRadius: dimensions.size4,
          },
          myStudioEpisodeCardActive: {
            fontSize: dimensions.size12,
            lineHeight: '18px',
            background: baseColorMap.dark.dark[600],
            textTransform: 'none',
            fontWeight: 600,
            padding: `${dimensions.size4} ${dimensions.size8}`,
            borderRadius: dimensions.size4,
          },
          myStudioEpisodeCardInactive: {
            fontSize: dimensions.size12,
            lineHeight: '18px',
            background: baseColorMap.dark.dark[600],
            textTransform: 'none',
            color: 'text.header',
            gap: dimensions.size4,
            fontWeight: 600,
            padding: `${dimensions.size4} ${dimensions.size8}`,
            borderRadius: dimensions.size4,
          },
          value: {
            fontWeight: '800',
            fontFamily: 'mono',
            padding: `${dimensions.size2} ${dimensions.size8}`,
            background: 'background.shade',
            color: 'text.placeholder',
            borderRadius: dimensions.size999,
          },
          capitalized: {
            textTransform: 'capitalize',
          },
        },
      },
      Tooltip: {
        baseStyle: {
          backgroundColor: 'black',
          color: 'text.header',
          fontSize: '12px',
          fontWeight: '600',
          textAlign: 'center',
          borderRadius: dimensions.size4,
        },
      },
      Table: {
        defaultProps: {
          size: 'md',
        },
        baseStyle: {
          tr: {
            fontSize: '14px',
            fontWeight: 500,

            _dark: {
              color: 'background.shade',
              textColor: 'text.header',

              _hover: {
                bg: 'accent.100',
                color: 'accent.500',
              },

              _active: {
                bg: 'accent.200',
                color: 'text.header',
              },
            },
          },
          td: {
            _dark: {
              borderColor: 'background.shade',
            },
          },
          th: {
            _dark: {
              borderColor: 'text.placeholder',
            },
          },
        },
      },
      Button: {
        baseStyle: {
          fontSize: '14px',
        },
        variants: {
          ghost: {
            bg: 'background.default',
            color: 'text.header',

            _dark: {
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },

          default: {
            bg: 'light.100',
            color: 'text.header',
            fontWeight: 600,
            border: '1px solid',
            borderColor: 'transparent',
            borderRadius: dimensions.size8,

            _dark: {
              _hover: { bg: 'light.200' },
              _active: {
                bg: 'accent.100',
                color: 'accent.1000',
                borderColor: 'accent.1000',
                border: '1px solid',
              },
              _disabled: {
                bg: 'light.100',
                color: 'text.placeholder',
              },
            },
          },

          input: fieldStyle,
          invalidInput: {
            ...fieldStyle,
            ...fieldStyle._dark._invalid,
            _dark: {
              ...fieldStyle._dark,
              ...fieldStyle._dark._invalid,
            },
          },

          danger: {
            bg: 'red.900',
            color: 'text.header',

            _dark: {
              _hover: { bg: 'red.800' },
              _active: { bg: 'red.1000' },
            },
          },
          icon: {
            bg: 'background.default',
            color: 'text.header',
            minWidth: dimensions.size36,
            width: dimensions.size36,
            height: dimensions.size36,

            _dark: {
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },
          drawerButton: {
            bg: 'background.default',
            color: 'text.header',
            minWidth: dimensions.size36,
            width: dimensions.size36,
            height: dimensions.size36,
            borderRadius: dimensions.size999,

            _dark: {
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },
          inputButton: {
            marginTop: -2,
            marginBottom: -2,
            bg: 'transparent',
            color: 'text.body',
            minWidth: dimensions.size36,
            width: dimensions.size36,
            height: dimensions.size36,
            borderRadius: dimensions.size999,

            _dark: {
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },
          stepper: {
            bg: 'transparent',
            color: 'text.header',
            borderRadius: dimensions.size999,

            minWidth: dimensions.size32,
            maxWidth: dimensions.size32,
            width: dimensions.size32,
            minHeight: dimensions.size32,
            maxHeight: dimensions.size32,
            height: dimensions.size32,

            _dark: {
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },
          sidebar: {
            bg: 'background.default',
            color: 'text.header',
            minWidth: dimensions.size28,
            width: dimensions.size28,
            height: dimensions.size28,
            padding: dimensions.size0,

            defaultProps: { color: 'text.header' },

            _hover: {
              bg: 'background.shade',
              color: 'text.header',
            },
          },
          studioNode: {
            bg: 'background.shade',
            color: 'text.placeholder',
            minWidth: dimensions.size28,
            width: dimensions.size28,
            height: dimensions.size28,
            padding: dimensions.size0,
            borderRadius: dimensions.size999,

            defaultProps: { color: 'text.header' },
            _hover: { color: 'text.header' },
            _active: { color: 'text.header' },
          },
          outline: {
            bg: 'background.default',
            borderColor: 'background.shade',
            color: 'text.header',
            _dark: {
              _hover: {
                bg: 'accent.100',
                borderColor: 'accent.1000',
                color: 'accent.1000',
              },
              _active: {
                bg: 'accent.1000',
                borderColor: 'accent.600',
                color: 'accent.600',
                textColor: 'text.header',
              },
              bg: 'background.default',
              color: 'background.shade',
              textColor: 'text.header',
              borderColor: 'text.placeholder',
            },
          },
          imageUpload: {
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'light.200',
            color: 'text.header',
            _dark: {
              _hover: {
                bg: 'accent.100',
                borderColor: 'accent.1000',
                color: 'accent.1000',
              },
              _active: {
                bg: 'accent.100',
                borderColor: 'accent.1000',
                border: '1px solid',
                color: 'accent.1000',
                textColor: 'accent.1000',
              },
              bg: 'transparent',
              color: 'background.shade',
              textColor: 'text.header',
              border: '1px solid',
              borderColor: 'light.200',
            },
          },

          primary: {
            _dark: {
              bg: 'accent.1000',
              color: 'text.header',

              _active: {
                bg: 'accent.300',
                color: 'accent.300',
              },

              _hover: {
                bg: 'accent.800',
                color: 'accent.500',
              },

              _disabled: {
                opacity: 0.4,
              },
            },
          },

          transparent: {
            bg: 'transparent',
          },

          studioTopBar: {
            bg: 'light.100',
            color: 'text.header',
            fontSize: dimensions.size14,
            lineHeight: dimensions.size20,
            borderRadius: dimensions.size8,
            border: '1px solid',
            borderColor: 'transparent',
            fontWeight: 600,
            _active: {
              bg: 'accent.100',
              color: 'accent.1000',
              borderColor: 'accent.1000',
              border: '1px solid',
            },
            _dark: {
              fontSize: dimensions.size14,
              lineHeight: dimensions.size20,
              fontWeight: 600,
              _hover: { bg: 'light.200' },
              _active: {
                bg: 'accent.100',
                color: 'accent.1000',
                borderColor: 'accent.1000',
                border: '1px solid',
              },
              _disabled: {
                bg: 'light.100',
                color: 'text.placeholder',
              },
            },
          },
          studioTopBarBreadcrumb: {
            bg: 'background.default',
            color: 'text.placeholder',
            fontSize: dimensions.size14,
            lineHeight: dimensions.size20,
            fontWeight: 600,
            paddingX: dimensions.size2,

            _dark: {
              fontSize: dimensions.size14,
              lineHeight: dimensions.size20,
              paddingX: dimensions.size2,
              fontWeight: 600,
              _hover: { bg: 'background.shade', color: 'text.header' },
              _active: { bg: 'background.shade' },
            },
          },
          studioTopBarBreadcrumbActive: {
            bg: 'background.default',
            color: 'text.header',
            fontSize: dimensions.size14,
            lineHeight: dimensions.size20,
            fontWeight: 600,
            paddingX: dimensions.size2,

            _dark: {
              fontSize: dimensions.size14,
              lineHeight: dimensions.size20,
              paddingX: dimensions.size2,
              fontWeight: 600,
              _hover: { bg: 'background.shade' },
              _active: { bg: 'background.shade' },
            },
          },
        },
      },
      Input: inputStyle,
      Select: inputStyle,
      NumberInput: inputStyle,
      Modal: {
        baseStyle: {
          dialog: {
            marginX: dimensions.size24,
            bg: 'background.default',
            color: 'text.header',
            fontSize: 14,
            borderRadius: dimensions.size8,
            border: '1px solid',
            borderColor: 'light.100',
          },
          header: {
            bg: 'background.default',
            padding: '16px 0px',
            color: 'text.header',
            fontWeight: 700,
            border: 0,
            fontSize: 16,
            textAlign: 'center',
            borderTopLeftRadius: dimensions.size8,
            borderTopRightRadius: dimensions.size8,
            borderBottom: `${dimensions.size1} solid ${borderColorMap.dark.default[100]}`,
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: dimensions.size8,
            position: 'relative',
          },
          body: {
            padding: dimensions.size24,
            margin: dimensions.size0,
          },
          footer: {
            border: 0,
            color: 'text.header',
            padding: `${dimensions.size16} ${dimensions.size24}`,
            borderTop: `${dimensions.size1} solid ${borderColorMap.dark.default[100]}`,
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'stretch',
            gap: dimensions.size12,
          },
        },
        variants: {
          fromCta: {
            dialog: {
              maxW: '640px',
            },
          },
          wizard: {
            dialog: {
              maxW: '900px',
            },
          },
        },
      },
      Popover: {
        baseStyle: {
          content: {
            bg: 'black',
            border: 'none',
            color: 'text.header',
            fontSize: 14,
          },
          body: {
            padding: 0,
            borderRadius: dimensions.size12,
          },
        },
      },
      Tabs: {
        baseStyle: {
          borderRadius: dimensions.size12,
          tab: {
            padding: `${dimensions.size12} ${dimensions.size16}`,
            color: 'light.600',
            unstyled: {
              padding: `${dimensions.size12} ${dimensions.size16}`,
            },
            _selected: {
              color: 'light.1000',
              borderBottomColor: 'accent.1000 !important',
              padding: `${dimensions.size12} ${dimensions.size16}`,
            },
          },
          tabpanel: {
            padding: dimensions.size16,
          },
        },
      },
      Textarea: {
        defaultProps: inputStyle.defaultProps,
        baseStyle: {
          minHeight: '200px',
          resize: 'vertical',
          _dark: fieldStyle,
        },
        variants: {
          none: {
            field: {
              borderColor: 'white',
              background: 'white',
              borderWidth: dimensions.size0,
              borderRadius: dimensions.size0,
            },
          },
        },
      },
      Switch: {
        baseStyle: {
          track: {
            bg: 'background.shade',
            _checked: { bg: 'background.accent' },
          },
          thumb: { bg: 'text.header' },
        },
      },
      Accordion: {
        variants: {
          suggestions: {
            button: {
              padding: `${dimensions.size12} ${dimensions.size16}`,
              background: 'background.shade',
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 600,
          color: 'text.header',
        },
      },
      FormError: {
        baseStyle: {
          text: {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 500,
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            borderRadius: dimensions.size4,
            borderColor: borderColorMap.dark.default['200'],

            _checked: {
              bg: baseColorMap.dark.accent['1000'],
              borderColor: baseColorMap.dark.accent['1000'],
            },
          },
        },
        variants: {
          checkButton: {
            label: {
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 500,
            },
            control: {
              width: dimensions.size24,
              height: dimensions.size24,

              _checked: {
                bg: 'accent.1000',
                borderColor: 'accent.1000',
              },
            },
            icon: {
              fontSize: dimensions.size12,
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'accent' }),
);
