import { dimensions } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const ListBody: React.FC<PropsWithChildren> = ({ children }) => {
  return <BodyContainer>{children}</BodyContainer>;
};

const BodyContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  overflow: hidden;
`;
