import { create } from 'zustand';

type SeriesStore = {
  worldId?: string;
  setWorldId: (worldId: string | undefined) => void;
};

export const useSeriesStore = create<SeriesStore>()((set) => ({
  worldId: undefined,
  setWorldId: (worldId) => set((state) => ({ ...state, worldId })),
}));
