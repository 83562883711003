import { create } from 'zustand';

type DrawerType = 'suggestions' | 'play-episode';

type PlayEpisodeData = {
  type: 'play-episode';
  nodeId: string;
};

export const isPlayEpisodeData = (
  data: DrawerData | undefined,
): data is PlayEpisodeData => {
  return data?.type === 'play-episode';
};

type DrawerData = PlayEpisodeData;

type DrawerStore = {
  drawerOpen?: DrawerType;
  closeDrawer: () => void;
  openDrawer: (drawer: DrawerType, data?: DrawerData) => void;
  data: DrawerData | undefined;
};

export const useDrawerStore = create<DrawerStore>()((set) => ({
  drawerOpen: undefined,
  data: undefined,
  closeDrawer: () => set((state) => ({ ...state, drawerOpen: undefined })),
  openDrawer: (drawer, data) =>
    set((state) => ({ ...state, drawerOpen: drawer, data })),
}));
