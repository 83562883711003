import { GenerateImageProvider } from '@common/studio-types/generateImage';
import { Field, RadioButtons } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { Hint } from '../Hint';
import { imageProviderOptions } from './shared';

type Props = {
  value?: GenerateImageProvider;
  onChange: (value: GenerateImageProvider) => void;
  label: string;
  hint?: string;
};

export const ImageProviderSelectionField: React.FC<Props> = (props) => {
  const isImageProviderSelectionEnabled = useFeatureFlag(
    FeatureFlags.ImageModelProviderSelection,
  );

  if (!isImageProviderSelectionEnabled) {
    return null;
  }

  return (
    <Field label={props.label}>
      {props.hint && <Hint>{props.hint}</Hint>}
      <RadioButtons
        options={imageProviderOptions}
        value={props.value}
        onChange={(selection) => props.onChange(selection)}
      />
    </Field>
  );
};
