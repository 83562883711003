import { Button, ButtonGroup } from '@chakra-ui/react';
import {
  Breadcrumb,
  HeaderContainer,
  HeaderImage,
  HeaderRowContainer,
  HeaderTitle,
  HeaderTitleContainer,
  Icon,
  IconMenuItem,
  ImageWrapper,
  Link,
  Menu,
  MenuButton,
  MenuList,
  NoResult,
  NoResultContainer,
  ScreenContainer,
  Section,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useNavigation, useOne } from '@refinedev/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TableLoader } from '../../components/TableLoader';
import { useIsAdminUser } from '../../useIsAdminUser';
import { SeriesCard } from '../series/components/SeriesCard';
import { useCreateSeries } from '../series/hooks/useCreateSeries';
import { useListSeries } from '../series/hooks/useListSeries';
import { ExpandableText } from './ExpandableText';
import { WorldCharacter, WorldLocation } from './types';
import { WorldElement } from './WorldElement';

export const WorldDetails: React.FC = () => {
  const params = useParams();
  const isAdminUser = useIsAdminUser();

  const { series, isLoading: isLoadingSeries } = useListSeries({
    worldId: params.id,
    userCreatedOnly: !isAdminUser,
    listAll: isAdminUser,
    pauseFetch: isAdminUser === undefined,
  });
  const navigation = useNavigation();

  const { data: world, isLoading } = useOne({
    resource: 'world',
    id: params.id,
    meta: { select: '*' },
  });

  const { createSeries } = useCreateSeries({
    createFirstEpisode: true,
    worldId: world?.data?.id as string,
  });
  const onCreateSeries = async () => {
    await createSeries();
  };

  const onEdit = () => {
    navigation.push(`/world/${params.id}/edit`);
  };

  if (isLoading || isLoadingSeries) {
    return <TableLoader />;
  }

  const breadcrumb = [
    { label: 'Home', to: '/' },
    { label: world?.data?.title },
  ];

  return (
    <ScreenContainer>
      <HeaderContainer>
        <Breadcrumb breadcrumb={breadcrumb} />
        <HeaderRowContainer>
          <ImageWrapper borderRadius={rawDimensions.size8}>
            <HeaderImage
              $width={160}
              $height={160}
              src={world?.data?.media.main}
            />
          </ImageWrapper>
          <HeaderTitleContainer>
            <HeaderTitle>{world?.data?.title}</HeaderTitle>
            <SeriesCounter>{series.length} Series</SeriesCounter>
            <ButtonGroup>
              <Button
                variant="primary"
                leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                onClick={onCreateSeries}
              >
                New series
              </Button>
              <Menu>
                <MenuButton>
                  <Button
                    variant="default"
                    width={dimensions.size40}
                    height={dimensions.size40}
                  >
                    <Icon name="ellipsis" size={rawDimensions.size16} />
                  </Button>
                </MenuButton>
                <MenuList>
                  <IconMenuItem
                    icon="edit"
                    name="Edit details"
                    onClick={onEdit}
                  />
                </MenuList>
              </Menu>
            </ButtonGroup>
          </HeaderTitleContainer>
        </HeaderRowContainer>
      </HeaderContainer>
      <SectionContainer $gap={dimensions.size48}>
        <Section>
          <SectionTitle>Overview</SectionTitle>
          <SectionCard>
            <Story>
              <ExpandableText
                maxCharacters={500}
                text={world?.data?.source_story}
              />
            </Story>
          </SectionCard>
        </Section>
        <Section>
          <SectionTitle>Series in this world</SectionTitle>
          <SectionCard>
            {!series.length ? (
              <NoResultContainer>
                <NoResult>
                  You have no interactive series in this world. Use this world
                  bible to create an interactive series to bring your story to
                  life.
                  <Button
                    variant="primary"
                    leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                    onClick={onCreateSeries}
                  >
                    New series
                  </Button>
                </NoResult>
              </NoResultContainer>
            ) : (
              <ScrollableRowContainer>
                {series.map((series) => (
                  <Link to={`/series/${series.id}`}>
                    <SeriesCard series={series} key={series.id} />
                  </Link>
                ))}
              </ScrollableRowContainer>
            )}
          </SectionCard>
          {series.length > 0 && (
            <NewSeriesButtonContainer>
              <Button
                variant="default"
                leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
                onClick={onCreateSeries}
              >
                New series
              </Button>
            </NewSeriesButtonContainer>
          )}
        </Section>
        <Section>
          <SectionTitle>Characters</SectionTitle>
          <SectionCard>
            <ElementContainer>
              {!world?.data?.characters?.length && (
                <NoResultContainer>
                  <NoResult>
                    There's no character currently created in this world.
                  </NoResult>
                </NoResultContainer>
              )}

              {world?.data?.characters
                ?.filter?.(Boolean)
                ?.map((character: WorldCharacter, index: number) => (
                  <WorldElement
                    icon="user"
                    key={index}
                    sections={[
                      {
                        name: 'Defining characteristics',
                        content: character.traits,
                      },
                      {
                        name: 'Relationships',
                        content: character.relationships,
                      },
                      {
                        name: 'Aspirations',
                        content: character.goals,
                      },
                    ]}
                    name={character.name}
                  />
                ))}
            </ElementContainer>
          </SectionCard>
        </Section>
        <Section>
          <SectionTitle>Locations</SectionTitle>
          <SectionCard>
            <ElementContainer>
              {!world?.data?.locations?.length && (
                <NoResultContainer>
                  <NoResult>
                    There's no location currently created in this world.
                  </NoResult>
                </NoResultContainer>
              )}

              {world?.data?.locations
                ?.filter?.(Boolean)
                ?.map?.((location: WorldLocation, index: number) => (
                  <WorldElement
                    icon="location"
                    key={index}
                    sections={[
                      {
                        name: 'Description',
                        content: location.description,
                      },
                      {
                        name: 'NPCs',
                        content: location.npcs,
                      },
                    ]}
                    name={location.name}
                  />
                ))}
            </ElementContainer>
          </SectionCard>
        </Section>
      </SectionContainer>
    </ScreenContainer>
  );
};

const SeriesCounter = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.body};
`;

const Story = styled.div`
  white-space: pre-line;
`;

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
`;

const ScrollableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: start;
  overflow-x: auto;
`;

const NewSeriesButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${dimensions.size16};
`;
