import { toOptimizedImageUri } from '@common/image';
import { hudIcons } from '@common/studio-types';
import { Menu, MenuDropdownButton, MenuList } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  onChange: (icon: string) => void;
  icon?: string;
};

export const IconInput: React.FC<Props> = ({ onChange, icon }) => {
  const selected = hudIcons.find(({ ref }) => ref === icon);

  return (
    <Menu>
      <MenuDropdownButton>
        {selected && (
          <IconContainer>
            <img
              src={toOptimizedImageUri(selected.icon, {
                width: rawDimensions.size16,
                height: rawDimensions.size16,
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
            />
          </IconContainer>
        )}
      </MenuDropdownButton>
      <MenuList>
        <IconsContainer>
          {hudIcons.map((icon) => (
            <SelectableIconContainer
              key={icon.ref}
              onClick={() => onChange(icon.ref)}
            >
              <img
                src={toOptimizedImageUri(icon.icon, {
                  width: rawDimensions.size16,
                  height: rawDimensions.size16,
                  dpr: window.devicePixelRatio,
                  resize: 'cover',
                })}
              />
            </SelectableIconContainer>
          ))}
        </IconsContainer>
      </MenuList>
    </Menu>
  );
};

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: ${dimensions.size8};
  padding: ${dimensions.size0} ${dimensions.size8};
  align-items: center;
`;

const IconContainer = styled.div`
  ${textStyles.body.b12m}

  img {
    width: ${dimensions.size16};
    height: ${dimensions.size16};
    object-fit: cover;
  }
`;

const SelectableIconContainer = styled(IconContainer)`
  padding: ${dimensions.size14};
  border-radius: ${dimensions.size8};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.background.shade};
  }
`;
