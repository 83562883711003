import {
  CharacterCreatorCompleteNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { CharacterCreatorCompleteForm } from './CharacterCreatorCompleteForm';
import { CharacterCreatorCompleteNode } from './CharacterCreatorCompleteNode';

const name = 'Character Creator Complete';

export const characterCreatorCompleteNode: NodeConfig<
  StudioNodeType.CharacterCreatorComplete,
  CharacterCreatorCompleteNodeData
> = {
  type: StudioNodeType.CharacterCreatorComplete,
  name,
  icon: 'objective',
  episodeRef: 'character-creator',
  node: CharacterCreatorCompleteNode,
  form: CharacterCreatorCompleteForm,
  sourceHandles: () => [], // no source handle
  getNodeData: (data) => ({ title: name, description: data.text }),
  cloneNodeData: ({ text }) => ({ data: { id: toNodeId(), text } }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.CharacterCreatorComplete,
    text: '',
  }),
};
