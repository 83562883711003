import { SeriesShortFragment } from '@maestro/graphql';
import { useEffect, useState } from 'react';
import { SectionSeries } from '../sections.type';

type Result = {
  series: SeriesShortFragment[];
  updateSeries: (rows: SeriesShortFragment[]) => void;
};

export const useGetSectionSeries = (section: SectionSeries): Result => {
  const [series, setSeries] = useState<SeriesShortFragment[]>([]);

  useEffect(() => setSeries(section.payload.series), [section.payload.series]);

  const updateSeries = (rows: SeriesShortFragment[]) => setSeries(rows);

  return { series, updateSeries };
};
