import { Badge } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { DiceRollImage, type DiceRollNodeData } from '@common/studio-types';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { OutputName } from '../../components/OutputName';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { actions, strategies } from './diceRoll';

type Props = NodeProps<DiceRollNodeData>;

const Node: React.FC<Props> = ({ data, selected }) => {
  const action = actions.find(({ value }) => value === data.rollAction);
  const strategy = strategies.find(({ value }) => value === data.strategy.type);

  return (
    <BaseStudioNode
      nodeId={data.id}
      title={'Dice roll'}
      node={data}
      selected={selected}
      subtitle={data.prompt}
    >
      <DiceRollNodeContainer>
        <DiceRollDiceContainer>
          {data.dices.map((dice, index) => (
            <img
              key={index}
              src={toOptimizedImageUri(DiceRollImage[dice], {
                width: rawDimensions.size48,
                height: rawDimensions.size48,
                dpr: window.devicePixelRatio,
              })}
            />
          ))}
        </DiceRollDiceContainer>
        <DetailsContainer>
          {action && <Badge variant="capitalized">{action.label}</Badge>}
          {strategy && <Badge variant="capitalized">{strategy.label}</Badge>}
          <OutputName outputName={data.outputName} />
        </DetailsContainer>
      </DiceRollNodeContainer>

      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <Handle
        nodeId={data.id}
        label="Output"
        type="source"
        position={Position.Bottom}
        id={data.id}
      />
    </BaseStudioNode>
  );
};

export const DiceRollNode = memo(Node);

const DiceRollNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const DiceRollDiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;

  img {
    width: ${dimensions.size48};
    height: ${dimensions.size48};
  }
`;

const DetailsContainer = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  padding-top: ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.header};
`;
