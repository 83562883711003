import { createGlobalStyle } from 'styled-components';
import { breakpoints } from '../breakpoints';
import { dimensions } from '../dimensions';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #18181f;
  }
  .chakra-toast__inner {
    min-width: 0 !important;
  }
  .chakra-modal__content {
    @media ${breakpoints.tablet} {
      margin: 0 !important;
      border-radius: 0 !important;

      .chakra-modal__header {
        position: sticky;
        top: 0;
        z-index: 12;
        border-radius: 0;
        background: ${({ theme }) => theme.colors.background.default};
      }
      
      .chakra-modal__footer {
        position: sticky;
        bottom: 0;
        z-index: 12;
        border-radius: 0;
        background: ${({ theme }) => theme.colors.background.default};
      }

      .chakra-modal__body {
        padding: ${dimensions.size16};
      }
    }
  }
`;
