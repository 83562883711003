import { useMaestroToast } from '@maestro/components';
import { useCreateEpisodeMutation } from '@maestro/graphql';
import { useNavigate } from 'react-router-dom';

export const useCreateEmptyEpisode = (seriesId?: string) => {
  const { mutateAsync, isLoading } = useCreateEpisodeMutation();
  const navigate = useNavigate();
  const toast = useMaestroToast();

  const createEmptyEpisode = async (options?: { redirect?: boolean }) => {
    if (!seriesId) {
      return;
    }

    const result = await mutateAsync({
      input: {
        seriesId,
      },
    });

    if (!result.createEpisode?.episode?.id) {
      toast({
        title: 'Failed to create episode',
        description:
          'It was not possible to create a new episode, please try again or contact support',
        status: 'warning',
      });

      return;
    }

    if (!!options?.redirect) {
      navigate(`/studio/${result.createEpisode.episode?.id}`);
    }

    return result.createEpisode.episode;
  };

  return {
    createEmptyEpisode,
    isLoading,
  };
};
