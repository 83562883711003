import { textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { TextMessage } from '@enigma-engine/core';
import { messageAnimation } from './keyframes';

type Props = {
  message: TextMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  return <Container>{message.message}</Container>;
};

export const MaestroGameMessageNarratorText = memo(Component);

const Container = styled.div`
  ${textStyles.body.b16m};
  color: ${({ theme }) => theme.colors.base.light[800]};
  white-space: pre-line;
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;
