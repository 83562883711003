// TODO
const InterRegular = `
  font-weight: 400;
`;

const InterMedium = `
  font-weight: 500;
`;

const InterSemiBold = `
  font-weight: 600;
`;

const InterBold = `
  font-weight: 700;
`;

export const typography = {
  InterRegular,
  InterMedium,
  InterSemiBold,
  InterBold,
};
