import { dimensions, textStyles } from '@maestro/styles';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { slideInAnimation, slideOutAnimation } from '../gameMessages/keyframes';
import { GameSimulator } from '../hooks/useGameSimulator';
import { InventoryItem } from './InventoryItem';
import { PlayerMenuStats } from './PlayerMenuStats';

type Props = {
  game: GameSimulator;
  isOpen: boolean;
  onClose: () => void;
};

export const PlayerMenu: FC<Props> = (props) => {
  const { game, isOpen, onClose } = props;
  const stats = game.hud;
  const shouldRenderStats = stats.length > 0;
  const itemsQuantity = game.inventoryItems.reduce(
    (acc, item) => acc + item.quantity,
    0,
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // necessary to not show the closing animation when mounted
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  return (
    <Container className={isOpen ? 'open' : 'closed'} $visible={isVisible}>
      {shouldRenderStats && (
        <PlayerMenuContainer>
          <PlayerMenuStats stats={stats} />
        </PlayerMenuContainer>
      )}
      <PlayerInventoryContainer>
        <InventoryTitle>
          <div>Inventory</div>
          {itemsQuantity > 0 && (
            <ItemQuantity>
              ({itemsQuantity.toLocaleString('en-US')})
            </ItemQuantity>
          )}
        </InventoryTitle>
        {game.inventoryItems.length === 0 && (
          <Empty>No items in inventory</Empty>
        )}
        {game.inventoryItems.map((item) => {
          return (
            <InventoryItem
              key={item.id}
              item={item}
              quantity={item.quantity}
              action={item.action}
            />
          );
        })}
      </PlayerInventoryContainer>
      <CloseButton onClick={onClose}>Back to episode</CloseButton>
    </Container>
  );
};

const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: ${({ theme }) => theme.colors.base.dark['1000']};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  &.open {
    animation: ${slideInAnimation} 0.2s ease forwards;
  }

  &.closed {
    animation: ${slideOutAnimation} 0.2s ease forwards;
  }
`;

const PlayerMenuContainer = styled.div`
  padding: ${dimensions.size16};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default['100']};
`;

const PlayerInventoryContainer = styled.div`
  flex: 1;
  padding: ${dimensions.size16};
  overflow: auto;
`;

const InventoryTitle = styled.div`
  ${textStyles.body.b14sb};
  color: ${({ theme }) => theme.colors.text.header};
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const ItemQuantity = styled.div`
  ${textStyles.label.lb14sb};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const Empty = styled.div`
  ${textStyles.label.lb14sb};
  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size16};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

const CloseButton = styled.div`
  ${textStyles.label.lb16sb};
  width: 100%;

  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size28};
  cursor: pointer;
  text-align: center;

  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default['100']};

  &:hover {
    color: ${({ theme }) => theme.colors.text.header};
  }
`;
