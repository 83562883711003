import { TopBar } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';
import { AvenueLogo } from './AvenueLogo';

type Props = PropsWithChildren<{
  titleText: string;
  subtitleText: string;
  className?: string;
}>;

export const OnboardingScreen: React.FC<Props> = (props) => {
  const { className, children, titleText, subtitleText } = props;

  return (
    <Container className={className}>
      <GradientContainer>
        <StyledTopBar />
        <BodyContainer>
          <AvenueLogo />
          <HeaderContainer>
            <Title>{titleText}</Title>
            <Body>{subtitleText}</Body>
          </HeaderContainer>
        </BodyContainer>
        <Content>{children}</Content>
      </GradientContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      0deg,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 0%,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 100%
    ),
    #21212c;
`;

const GradientContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    13deg,
    rgba(15, 33, 36, 1) 19%,
    rgba(65, 73, 224, 0.5) 66%,
    rgba(48, 34, 38, 1) 94%
  );
  background-image: url('https://liwwunutcahympidrjyx.supabase.co/storage/v1/object/public/images/cms/blurred-bg-1x.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const StyledTopBar = styled(TopBar)`
  position: fixed;
  top: 0;
  background: transparent;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${dimensions.size16};
`;

const animation = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const animation2 = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${dimensions.size64};
  animation: ${animation} ease 0.5s;
  max-width: ${dimensions.size560};

  /* TODO: Update these when breakpoints are added in */
  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-width: ${dimensions.size720}) {
    gap: ${dimensions.size40};
  }
`;

const Title = styled.div`
  ${textStyles.title.t32b};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
  animation: ${animation2} ease 1s;

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-width: ${dimensions.size720}) {
    ${textStyles.title.t24b};
    padding: ${dimensions.size0} ${dimensions.size32};
  }
`;

const Body = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size0} ${dimensions.size32};
  text-align: center;
  animation: ${animation2} ease 1s;
`;

const Content = styled.div`
  animation: ${animation2} ease 1s;
  margin-top: ${dimensions.size64};
  padding: ${dimensions.size0} ${dimensions.size24};
  width: 100%;

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-width: ${dimensions.size720}) {
    margin-top: ${dimensions.size40};
  }
`;
