import { dimensions } from '@maestro/styles';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{
  borderRadius?:
    | number
    | {
        top?: number;
        bottom?: number;
        topLeft?: number;
        topRight?: number;
        bottomLeft?: number;
        bottomRight?: number;
      };
}>;

export const ImageWrapper: React.FC<Props> = ({ borderRadius, children }) => {
  const object =
    typeof borderRadius === 'number'
      ? { top: borderRadius, bottom: borderRadius }
      : borderRadius;

  return <Container $borderRadius={object}>{children}</Container>;
};

const Container = styled.div<{
  $borderRadius?: {
    top?: number;
    bottom?: number;
    topLeft?: number;
    topRight?: number;
    bottomLeft?: number;
    bottomRight?: number;
  };
}>`
  border-bottom-left-radius: ${({ $borderRadius }) =>
    $borderRadius?.bottomLeft ?? $borderRadius?.bottom ?? 0}px;
  border-bottom-right-radius: ${({ $borderRadius }) =>
    $borderRadius?.bottomRight ?? $borderRadius?.bottom ?? 0}px;
  border-top-left-radius: ${({ $borderRadius }) =>
    $borderRadius?.topRight ?? $borderRadius?.top ?? 0}px;
  border-top-right-radius: ${({ $borderRadius }) =>
    $borderRadius?.topRight ?? $borderRadius?.top ?? 0}px;
  position: relative;
  overflow: hidden;

  &:after {
    top: ${dimensions.size0};
    left: ${dimensions.size0};

    border-bottom-left-radius: ${({ $borderRadius }) =>
      $borderRadius?.bottomLeft ?? $borderRadius?.bottom ?? 0}px;
    border-bottom-right-radius: ${({ $borderRadius }) =>
      $borderRadius?.bottomRight ?? $borderRadius?.bottom ?? 0}px;
    border-top-left-radius: ${({ $borderRadius }) =>
      $borderRadius?.topRight ?? $borderRadius?.top ?? 0}px;
    border-top-right-radius: ${({ $borderRadius }) =>
      $borderRadius?.topRight ?? $borderRadius?.top ?? 0}px;
    position: absolute;
    content: ' ';
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: transparent;
    border: ${dimensions.size1} solid
      ${({ theme }) => theme.colors.border.default[100]};
  }
`;
