type Props = {
  index: number;
  path: string;
  percentage: number;
  activeColor: string;
  defaultColor: string;
  tickCount: number;
  tickThickness: number;
};

export const TickMark: React.FC<Props> = ({
  index,
  path,
  percentage,
  activeColor,
  defaultColor,
  tickCount,
  tickThickness,
}) => {
  const currentPercentage = ((index + 1) * 100) / tickCount;

  return (
    <path
      key={index}
      d={path}
      fill={'none'}
      strokeWidth={tickThickness}
      stroke={currentPercentage > percentage ? defaultColor : activeColor}
    />
  );
};
