import { RpgConfigHudElement, RpgConfigProperty } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { HudElementQuickAdd } from './HudElementQuickAdd';

type Props = {
  onCreateElement: (element: RpgConfigHudElement) => void;
  properties: RpgConfigProperty[];
};

export const HudElementsEmptyState: React.FC<Props> = (props) => {
  const { onCreateElement, properties } = props;

  return (
    <EmptyState>
      <EmptyStateText>
        <EmptyStateTitle>
          There are no properties in your player menu
        </EmptyStateTitle>
        <EmptyStateDescription>
          The player menu shows key game information like health, score and
          ammo, right on your players screen. It allows the player to check the
          status of their properties without interrupting the gameplay.
        </EmptyStateDescription>
      </EmptyStateText>
      <HudElementQuickAdd
        properties={properties}
        onCreateElement={onCreateElement}
      />
    </EmptyState>
  );
};

const EmptyState = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  gap: ${dimensions.size24};
  padding: ${dimensions.size16};
  text-align: center;
  max-width: ${dimensions.size600};
  margin: 0 auto;
`;

const EmptyStateText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  text-align: center;
`;

const EmptyStateTitle = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const EmptyStateDescription = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;
