import { EnumOption } from '@common/studio-types';
import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  value: string;
  onChange?: (value: string) => void;
  options: EnumOption[];
};

export const EnumDropdown: React.FC<Props> = ({ value, onChange, options }) => {
  const selectedItem = options.find((option) => option.ref === value);

  return (
    <Menu>
      <MenuDropdownButton>
        {selectedItem?.label ?? <Placeholder>Select value</Placeholder>}
      </MenuDropdownButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem key={option.label} onClick={() => onChange?.(option.ref)}>
            <ValueContainer>
              <Name>{option.label}</Name>
              <Description>{option.description}</Description>
            </ValueContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
