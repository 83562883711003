import { ConditionOperator, DataType } from '@common/studio-types';
import {
  IconMenuItem,
  Menu,
  MenuDropdownButton,
  MenuList,
} from '@maestro/components/menu/Menu';
import React from 'react';

const operators = [
  {
    icon: 'condition-gt',
    name: 'Greater than',
    value: ConditionOperator.GreaterThan,
    dataTypes: [DataType.Number],
  },
  {
    icon: 'condition-gte',
    name: 'Greater than or equal to',
    value: ConditionOperator.GreaterThanOrEqual,
    dataTypes: [DataType.Number],
  },
  {
    icon: 'condition-lt',
    name: 'Less than',
    value: ConditionOperator.LessThan,
    dataTypes: [DataType.Number],
  },
  {
    icon: 'condition-lte',
    name: 'Less than or equal to',
    value: ConditionOperator.LessThanOrEqual,
    dataTypes: [DataType.Number],
  },
  {
    icon: 'condition-eq',
    name: 'Equal to',
    value: ConditionOperator.Equal,
  },
  {
    icon: 'condition-ne',
    name: 'Not equal to',
    value: ConditionOperator.NotEqual,
  },
];

type Props = {
  dataType: DataType;
  value: ConditionOperator;
  onChange: (value: ConditionOperator) => void;
};

export const OperatorDropdown: React.FC<Props> = (props) => {
  const { value, onChange, dataType } = props;

  return (
    <Menu>
      <MenuDropdownButton>
        {operators.find((operator) => operator.value === value)?.name}
      </MenuDropdownButton>
      <MenuList>
        {operators
          .filter((operator) => {
            return operator.dataTypes
              ? operator.dataTypes.includes(dataType)
              : true;
          })
          .map((operator) => (
            <IconMenuItem
              icon={operator.icon}
              name={operator.name}
              key={operator.value}
              onClick={() => onChange(operator.value)}
            />
          ))}
      </MenuList>
    </Menu>
  );
};
