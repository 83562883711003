import { useGetUserId } from '@maestro/components';
import { toSupabaseClient } from '@maestro/supabase';
import { useCallback, useEffect, useState } from 'react';

export type World = {
  id: string;
  title: string;
  cover: string;
  sourceStory: string;
};

export type WorldRow = {
  id: string;
  title: string;
  media: { main: string; thumbnail: string };
  source_story: string;
};

type Args = {
  userCreatedOnly?: boolean;
};

export const useListWorlds = (args?: Args) => {
  const userId = useGetUserId();
  const [worlds, setWorlds] = useState<World[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchWorld = useCallback(async () => {
    if (!userId) return;

    try {
      let query = toSupabaseClient().from('world').select('*');

      if (args?.userCreatedOnly) {
        query = query.eq('created_by_id', userId);
      }

      const { data, error } = await query.returns<WorldRow[]>();

      if (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching world', JSON.stringify(error, null, 2));
      }

      setWorlds(
        (data ?? []).map((row) => ({
          id: row.id,
          title: row.title,
          cover: row.media.main,
          sourceStory: row.source_story,
        })),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching world', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchWorld().catch(() => void 0);
  }, [fetchWorld]);

  return {
    worlds,
    isLoading,
  };
};
