import { RpgConfigProperty, StudioNodeType } from '@common/studio-types';
import { useCallback, useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { episodeSetupNode } from '../nodes/episodeSetup';

type Args = {
  onStudioFlowChange: () => void;
};

type Result = [RpgConfigProperty[], (props: RpgConfigProperty[]) => void];

export const useEpisodeProperties = (args: Args): Result => {
  const { onStudioFlowChange } = args;
  const [episodeProperties, setEpisodeProperties] = useState<
    RpgConfigProperty[]
  >([]);
  const [nodeId, setNodeId] = useState('');
  const { getNodes, setNodes } = useReactFlow();

  useEffect(() => {
    const allNodes = getNodes();
    const episodeSetupNodes = allNodes.filter(
      (node) => node.data.type === StudioNodeType.EpisodeSetup,
    );

    // we should merge nodes
    if (episodeSetupNodes.length >= 1) {
      const allProperties = episodeSetupNodes.reduce(
        (acc, node) => acc.concat(node.data.properties),
        [],
      );
      const [firstNode, ...rest] = episodeSetupNodes;
      const nodesToRemove = rest.map((node) => node.id);

      setNodes(
        allNodes
          .map((node) => {
            return node.id === firstNode.id
              ? { ...node, data: { ...node.data, properties: allProperties } }
              : node;
          })
          .filter((node) => !nodesToRemove.includes(node.id)),
      );

      setNodeId(firstNode.id);
      setEpisodeProperties(allProperties);
    } else {
      const data = episodeSetupNode.createNodeData();
      setNodes(
        allNodes.concat({
          id: data.id,
          position: { x: 0, y: 0 },
          type: StudioNodeType.EpisodeSetup,
          data,
        }),
      );

      setNodeId(data.id);
      setEpisodeProperties([]);
    }
  }, []);

  const onEpisodePropertiesChange = useCallback(
    (properties: RpgConfigProperty[]) => {
      setEpisodeProperties(properties);
      setNodes(
        getNodes().map((node) => {
          return node.id === nodeId
            ? { ...node, data: { ...node.data, properties } }
            : node;
        }),
      );

      // we need to first update the nodes and then trigger the flow change
      setTimeout(onStudioFlowChange, 1);
    },
    [nodeId, onStudioFlowChange],
  );

  return [episodeProperties, onEpisodePropertiesChange];
};
