export const getCoinTossSuccessRate = async (
  propertyValue?: number,
): Promise<number> => {
  const defaultSuccessRate = 50;

  if (propertyValue) {
    const rateIncrease = propertyValue * 0.5; // 0 to 50

    return (defaultSuccessRate + rateIncrease) / 100;
  }

  return defaultSuccessRate / 100;
};
