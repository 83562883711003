import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useNavigation } from '@refinedev/core';
import React, { useRef } from 'react';
import { useSeriesStore } from '../series/hooks/useSeriesStore';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  worldId?: string;
  worldTitle?: string;
};

export const WorldCreatedPrompt: React.FC<Props> = ({
  isOpen,
  onClose,
  worldId,
  worldTitle,
}) => {
  const cancelRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation();
  const { setWorldId } = useSeriesStore();

  const createSeries = () => {
    setWorldId(worldId);
    onClose();
    navigation.push('/series/create');
  };

  const viewWorld = () => {
    onClose();
    navigation.push(`/world/${worldId}`);
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={viewWorld}
      size="lg"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>World created</AlertDialogHeader>
          <AlertDialogBody>
            You created world {worldTitle}. You can now create a series in that
            world!
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={viewWorld} variant="default">
              View World
            </Button>
            <Button onClick={createSeries} ml={3} variant="primary">
              Create Series
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
