import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { DiceRollResultMessage } from '@enigma-engine/core';

type Props = {
  message: DiceRollResultMessage;
};

export const DiceRollDetails: React.FC<Props> = ({ message }) => {
  if (!message.modifier && !message.classDifficulty) {
    // no need to show descriptions container
    return null;
  }

  const result = message.result.reduce((acc, i) => acc + i.result, 0);

  return (
    <>
      <Divider />
      <BottomContainer>
        <Detail>
          <Label>Result</Label>
          <Value>{result}</Value>
        </Detail>
        {message.modifier > 0 && (
          <>
            <Detail>
              <Label>Modifier</Label>
              <Value>
                {message.modifier > 0 ? '+ ' : '- '}
                {Math.abs(message.modifier)}
              </Value>
            </Detail>
            <Detail>
              <Label>Result + Modifier</Label>
              <Value>{result + message.modifier}</Value>
            </Detail>
          </>
        )}

        {message.classDifficulty && (
          <Detail>
            <Label>Difficulty Class</Label>
            <Value>{message.classDifficulty}</Value>
          </Detail>
        )}
      </BottomContainer>
    </>
  );
};

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  padding: ${dimensions.size12} ${dimensions.size16} ${dimensions.size16};
`;

const Divider = styled.div`
  width: 100%;
  height: ${dimensions.size1};
  background: ${({ theme }) => theme.colors.border.default[100]};
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.div`
  ${textStyles.label.lb12sb};
  gap: ${dimensions.size4};
  color: ${({ theme }) => theme.colors.text.body};
`;

const Value = styled.div`
  ${textStyles.label.lb12sb};
  color: ${({ theme }) => theme.colors.text.header};
`;
