import {
  DataType,
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
  Value,
  ValueType,
} from '@common/studio-types';
import { SelectInput } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueBadge } from './ValueBadgeV2';

type Props = {
  rpgConfig: RpgConfig;
  onChange: (value: Value) => void;
  value?: Value;
  referenceValue?: Value;
  dataTypes?: DataType[];
  isNullable?: boolean;
};

function dataType(dataType: DataType): string {
  if (dataType === DataType.String) {
    return 'text';
  } else if (dataType === DataType.Number) {
    return 'number';
  } else if (dataType === DataType.Enum) {
    return 'choice';
  }

  return '';
}

export const PropertyInput: React.FC<Props> = (props) => {
  const { rpgConfig, dataTypes, referenceValue } = props;
  const filteredProperties = rpgConfig.properties.filter(
    (property) =>
      (!dataTypes || dataTypes.includes(property.config.dataType)) &&
      (!referenceValue || property.config.dataType === referenceValue.dataType),
  );
  const filteredSeriesProperties = filteredProperties
    .filter((property) => property.scope === PropertyScope.Series)
    .map(({ id }) => id);

  const filteredEpisodeProperties = filteredProperties
    .filter((property) => property.scope === PropertyScope.Episode)
    .map(({ id }) => id);

  const onSelectProperty = (property: RpgConfigProperty) => {
    props.onChange({
      type: ValueType.Property,
      ref: property.id,
      dataType: property.config.dataType,
      enumRef:
        property.config.dataType === DataType.Enum
          ? property.config.enumRef
          : undefined,
    });
  };

  const selectedProperty =
    props.value?.type === ValueType.Property && props.value.ref;

  return (
    <Container>
      <SelectInput
        isNullable={props.isNullable}
        getId={(property) => property.id}
        groups={[
          { name: 'Series properties', items: filteredSeriesProperties },
          { name: 'Episode properties', items: filteredEpisodeProperties },
        ]}
        listItems={filteredProperties}
        onChange={onSelectProperty}
        onSearch={(search) =>
          filteredProperties.filter((property) =>
            property.name.toLowerCase().includes(search.toLowerCase()),
          )
        }
        renderListItem={(property) => (
          <PropertyItemContainer>
            <PropertyName>{property.name}</PropertyName>
            <PropertyType>{dataType(property.config.dataType)}</PropertyType>
          </PropertyItemContainer>
        )}
        renderSelectedItem={() =>
          props.value?.type === ValueType.Property && props.value.ref ? (
            <ValueBadge value={props.value} />
          ) : null
        }
        selectedItem={selectedProperty as never}
      />
      {!selectedProperty && !props.isNullable && (
        <ErrorMessage>You must select a property</ErrorMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  flex: 1;
`;

const ErrorMessage = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.base.red[800]};
`;

const PropertyItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  justify-content: space-between;
  width: 100%;
`;

const PropertyName = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const PropertyType = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.body};
`;
