import { dimensions, rawDimensions } from '@maestro/styles';
import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

type Props = {
  onClose: () => void;
  disabled?: boolean;
};

export const ModalCloseButton: FC<Props> = ({ onClose, disabled }) => {
  return (
    <StyledContainer onClick={disabled ? void 0 : onClose} $disabled={disabled}>
      <Icon name="close" size={rawDimensions.size24} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $disabled?: boolean }>`
  position: absolute;
  top: ${dimensions.size16};
  right: ${dimensions.size16};
  bottom: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;
