import { Button } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Field, NumericInput, Textarea } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions } from '@maestro/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGenerateNodeWithAi } from '../hooks/useGenerateNodeWithAi';
import { useGetAiContext } from '../hooks/useGetAiContext';
import { GenerateImageNodeWithAi } from './ai/GenerateImageNodeWithAi';
import { Hint } from './Hint';

type Props = {
  studioNodeData: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  onFinishGeneration: () => void;
  generateContentType: 'text' | 'image';
};

export const GenerateNodeWithAi: React.FC<Props> = (props) => {
  const { studioNodeData, onStudioNodeDataChange, onFinishGeneration } = props;
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [contextCount, setContextCount] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const getAiContext = useGetAiContext();
  const { generate } = useGenerateNodeWithAi();
  const isGenerateImagesWithAiEnabled = useFeatureFlag(
    FeatureFlags.GenerateImagesWithAi,
  );

  const isImageNode =
    studioNodeData.type === StudioNodeType.Image &&
    isGenerateImagesWithAiEnabled;

  const onGenerate = async () => {
    setIsGenerating(true);

    const previousNodesContext =
      contextCount > 0
        ? getAiContext({
            nodeId: studioNodeData.id,
            traverseNodesCount: contextCount,
          })
        : [];

    const result = await generate(studioNodeData, prompt, previousNodesContext)
      .catch(() => void 0)
      .finally(() => setIsGenerating(false));

    if (isImageNode) {
      onStudioNodeDataChange({
        ...studioNodeData,
        imageUrl: (result as { imageUrl?: string })?.imageUrl ?? '',
      });
    }

    if (result) {
      onStudioNodeDataChange({ ...studioNodeData, ...result });
    }

    onFinishGeneration();
  };

  const close = () => {
    if (!isGenerating) {
      onFinishGeneration();
    }
  };

  if (isImageNode || props.generateContentType === 'image') {
    return (
      <GenerateImageNodeWithAi
        studioNodeData={studioNodeData}
        onStudioNodeDataChange={onStudioNodeDataChange}
        onFinishGeneration={onFinishGeneration}
      />
    );
  }

  return (
    <Container>
      <FormContainer>
        <Hint>
          {`Input outline of the story into the prompt box and tweak your input if
          necessary to get better results. Detailed prompts may result in longer
          waiting times.`}
        </Hint>
        <Field label="Prompt">
          <Textarea
            placeholder={
              'Write a really cool prompt to fill your node with AI-generated content.'
            }
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
          />
        </Field>
        {isImageNode && (
          <Field label="Negative Prompt">
            <Textarea
              placeholder={
                'Indicate what the AI should avoid when generating the image.'
              }
              value={negativePrompt}
              onChange={(event) => setNegativePrompt(event.target.value)}
            />
          </Field>
        )}

        {!isImageNode && (
          <Field label="Add previous nodes as context">
            <Hint>
              Add previous nodes as context make the AI generated content more
              accurate. Only <strong>Narrator Text</strong> and{' '}
              <strong>Player choice</strong> nodes are considered.
            </Hint>
            <NumericInput
              min={0}
              max={10}
              value={contextCount}
              onChange={setContextCount}
            />
          </Field>
        )}
      </FormContainer>

      <FormFooter>
        <Button
          variant="default"
          flex={1}
          onClick={close}
          isDisabled={isGenerating}
        >
          Cancel
        </Button>
        <Button
          flex={1}
          variant="primary"
          isLoading={isGenerating}
          onClick={onGenerate}
          isDisabled={!prompt}
        >
          Generate
        </Button>
      </FormFooter>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const FormContainer = styled.div`
  flex: 1;
  padding: ${dimensions.size16};
`;

const FormFooter = styled.div`
  display: flex;
  gap: ${dimensions.size16};
  justify-content: stretch;
  width: 100%;
  padding: ${dimensions.size16};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default[100]};
`;
