import type { CoinTossNodeData } from '@common/studio-types';
import { getCoinTossSuccessRate } from '../coinToss/getCoinTossSuccessRate';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import type { ReturnedValue } from './getValue/types';
import { getValue } from './utils';

export const coinToss = async (
  node: GameNode<CoinTossNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  let successRate = 0.5;
  let value: ReturnedValue<number> | undefined;

  if (node.property) {
    value = await getValue<number>(node.property, state, config, playerData);
    successRate = await getCoinTossSuccessRate(value.value);
  }

  return {
    messages: [
      {
        nodeId: node.id,
        type: 'coin-toss-interaction',
        id: node.id,
        attribute: value && { description: '', name: value.label },
        successRate: Math.ceil(successRate * 100),
        prompt: node.prompt,
      },
    ],
    state: { ...state, currentInteraction: node.id },
    haltExecution: true,
  };
};
