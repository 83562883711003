/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataType,
  EnumOption,
  RpgConfig,
  RpgConfigCustomItem,
  RpgConfigHudElement,
  RpgConfigProperty,
} from '../..';
import { MigrateRpgConfig } from '../migration.types';

type EnumPropertyConfigV1 = {
  dataType: DataType.Enum;
  enumRef: string;
  defaultOptionRef?: string;
};

type EnumDefinition = {
  id: string;
  name: string;
  dataType: DataType.Enum;
  options: EnumOption[];
};

type RpgConfigV1 = {
  version: 1;
  hud: RpgConfigHudElement[];
  properties: RpgConfigProperty[];
  customItems: RpgConfigCustomItem[];
  customTypes: EnumDefinition[];
};

export const v1tov2: MigrateRpgConfig = (config: RpgConfig): RpgConfig => {
  if (config.version >= 2) {
    // no need to migrate the config
    return config;
  }

  const oldRpgConfig = config as unknown as RpgConfigV1;

  const properties = config.properties.map(
    (property: RpgConfigProperty): RpgConfigProperty => {
      if (property.config.dataType === DataType.Enum) {
        const oldConfig = property.config as unknown as EnumPropertyConfigV1;
        const options =
          oldRpgConfig.customTypes.find(
            (enumDef) => enumDef.id === oldConfig.enumRef,
          )?.options ?? [];

        return {
          ...property,
          config: {
            dataType: DataType.Enum,
            enumRef: oldConfig.enumRef,
            options,
          },
        };
      } else if (property.config.dataType === DataType.String) {
        return {
          ...property,
          config: {
            dataType: DataType.String,
            defaultValue: property.config.defaultValue,
            // removes max length
          },
        };
      }

      return property;
    },
  );

  return {
    version: 2,
    hud: config.hud,
    customItems: config.customItems,
    properties: properties,
    // removes customTypes
  };
};
