import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components/Icon';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{
  ariaLabel?: string;
  alwaysVisible?: boolean;
  title: string;
  isOpen: boolean;
  onCancel: VoidFunction;
  headerContent?: React.ReactNode;
}>;

export const Drawer: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (props.alwaysVisible) {
      setIsOpen(props.isOpen);
      setIsVisible(true);
    } else if (props.isOpen) {
      setIsVisible(true);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setTimeout(() => setIsVisible(false), 0);
    }
  }, [props.alwaysVisible, props.isOpen]);

  return (
    <Container
      aria-label={props.ariaLabel}
      $isOpen={isOpen}
      $isVisible={isVisible}
      $alwaysVisible={!!props.alwaysVisible}
    >
      {props.isOpen && props.children && (
        <>
          <DrawerHeader>
            <DrawerHeaderButton>
              <Button variant="drawerButton" onClick={props.onCancel}>
                <Icon name="chevron-right" size={rawDimensions.size24} />
              </Button>
            </DrawerHeaderButton>
            <DrawerTitle aria-label="Title">{props.title}</DrawerTitle>
            {props.headerContent && (
              <DrawerHeaderContent>{props.headerContent}</DrawerHeaderContent>
            )}
          </DrawerHeader>
          <DrawerBody aria-label="Body">{props.children}</DrawerBody>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{
  $isOpen: boolean;
  $isVisible: boolean;
  $alwaysVisible: boolean;
}>`
  position: fixed;
  top: ${dimensions.size56};
  right: ${dimensions.size0};
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  height: calc(100% - 86px);

  margin: ${dimensions.size16};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  background: ${({ theme }) => theme.colors.background.default};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
  box-shadow: ${dimensions.size0} ${dimensions.size0} ${dimensions.size12}
    ${({ theme }) => theme.colors.base.dark[200]};
  transition: all ease 0.3s;
  transform: ${({ $alwaysVisible }) =>
    $alwaysVisible ? 'translateX(100%)' : 'translateX(110%)'};
  ${({ $isOpen }) => $isOpen && 'transform: translateX(0%);'}

  @media ${breakpoints.mobile} {
    width: 100vw;
    margin: 0;
    height: 100dvh;
    top: 0;
    border-radius: 0;
    border: 0;
  }
`;

const DrawerBody = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
`;

const DrawerHeader = styled.div`
  ${textStyles.label.lb14sb}
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;

const DrawerHeaderButton = styled.div`
  position: absolute;
  left: ${dimensions.size16};
`;

const DrawerTitle = styled.div`
  flex: 1;
  text-align: center;
`;

const DrawerHeaderContent = styled.div`
  display: flex;
  position: absolute;
  right: ${dimensions.size16};
`;
