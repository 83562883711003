import { Button } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React, { useMemo } from 'react';
import { Message } from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';

type Props = {
  game: GameSimulator;
};

const continueButtonMessages: Message['type'][] = [
  'image',
  'text',
  'stat-block',
  'inventory-item-change',
  'player-attribute',
];

export const MaestroContinueButton: React.FC<Props> = ({ game }) => {
  const shouldShowContinueButton = useMemo(() => {
    const lastMessage = game.messages[game.messages.length - 1];

    return continueButtonMessages.includes(lastMessage?.type);
  }, [game.messages]);

  return (
    <Button
      flex={1}
      variant="primary"
      onClick={game.continueEpisode}
      height={dimensions.size44}
      isDisabled={!shouldShowContinueButton}
    >
      Continue
    </Button>
  );
};
