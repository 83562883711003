import type { StudioFlowState } from '..';
import type { RpgConfig } from '../episodeSetup';
import type { MigrateRpgConfig, MigrateStudioFlow } from './migration.types';
import { v0tov1 as rpgConfigV0toV1 } from './rpgConfig/v0tov1';
import { v1tov2 as rpgConfigV1toV2 } from './rpgConfig/v1tov2';
import { v0tov2 as studioFlowV0toV2 } from './studioFlow/v0tov2';

const rpgConfigMigrations: MigrateRpgConfig[] = [
  // make sure to put in the correct order
  rpgConfigV0toV1,
  rpgConfigV1toV2,
];
const studioFlowMigrations: MigrateStudioFlow[] = [
  // make sure to put in the correct order
  studioFlowV0toV2,
];

export const migrateRpgConfig = (config: RpgConfig): RpgConfig => {
  return rpgConfigMigrations.reduce((acc, migration) => migration(acc), config);
};

export const migrateStudioFlowState = (
  state: StudioFlowState,
  config: RpgConfig,
): void => {
  studioFlowMigrations.forEach((migration) => migration(state, config));
};
