import { Button } from '@chakra-ui/react';
import { Icon, MenuItem } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  episodeId: string;
  variant?: 'menu-item' | 'button';
};

export const EpisodePublishButtonV2: React.FC<Props> = ({
  variant = 'menu-item',
  episodeId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onOpen = () => {
    if (location.pathname.match(/studio/)) {
      navigate(`${location.pathname}?publish=1`);

      return;
    }

    navigate(`/studio/${episodeId}?publish=1`);
  };

  return variant === 'menu-item' ? (
    <StyledMenuItem disableHover>
      <Button
        width="100%"
        variant="primary"
        onClick={onOpen}
        leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
      >
        Publish
      </Button>
    </StyledMenuItem>
  ) : (
    <Button
      width="100%"
      variant="primary"
      onClick={onOpen}
      leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
    >
      Publish
    </Button>
  );
};

const StyledMenuItem = styled(MenuItem)`
  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;
