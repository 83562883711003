import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { DiceRollInteractionMessage } from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import { MaestroPlayerChoiceInteraction } from './MaestroPlayerChoiceInteraction';
import { MaestroSelectedInteraction } from './MaestroSelectedInteraction';

type Props = {
  message: DiceRollInteractionMessage;
  game: GameSimulator;
  isLastMessage: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { message, isLastMessage, game } = props;
  const diceQuantity = message.dices.length;
  const option = {
    id: message.id,
    text: `Roll the ${diceQuantity > 1 ? 'dice' : 'die'}`,
    icon: 'dice',
  };

  if (!isLastMessage) {
    return (
      <SelectedContainer>
        <MaestroPlayerChoiceInteraction
          options={[]}
          prompt={message.prompt}
          showChoices={false}
        />
        <MaestroSelectedInteraction text={message.prompt} icon="dice" />
      </SelectedContainer>
    );
  }

  return (
    <MaestroPlayerChoiceInteraction
      onSelect={() => game.rollDice(message.id)}
      options={[option]}
      prompt={message.prompt}
      showChoices={isLastMessage}
    />
  );
};

export const MaestroGameMessageDiceRollInteraction = memo(Component);

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
`;
