import { dimensions } from '@maestro/styles';
import styled from 'styled-components';

export const Logo = () => {
  return (
    <LogoContainer>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3243 2.22207C19.3243 1.80954 19.1605 1.41391 18.8689 1.12221C18.5773 0.830512 18.1819 0.666626 17.7695 0.666626H2.22155C0.892195 0.666626 0.537178 0.96215 0.823517 1.71266L2.01815 4.13917C2.61416 5.27854 3.6753 5.27465 4.5421 5.13986L10.7859 4.36208C11.8069 4.2325 11.8069 4.2325 11.7447 4.75097C11.6955 5.1761 11.6955 5.1761 9.68718 5.82555L2.14253 8.34275C1.33144 8.6124 0.666748 9.53399 0.666748 10.3895V17.7778C0.666748 18.1903 0.830579 18.586 1.12216 18.8777C1.41374 19.1694 1.80919 19.3333 2.22155 19.3333H11.939C14.2712 19.3333 14.2712 19.3333 14.5822 18.7345C14.9152 18.1006 15.3751 17.0779 15.6913 16.3675C16.0074 15.6573 16.8302 15.7933 16.8833 16.4C17.4755 19.1143 17.4755 19.1143 17.8473 19.2516C18.1297 19.3553 19.3334 19.4591 19.3334 18.8316L19.3243 2.22207ZM12.4029 12.2664L11.1072 13.5626C10.002 14.663 10.0448 14.663 8.45238 14.663H6.8976C5.34283 14.663 5.34283 14.1446 5.34283 13.4965C5.34283 12.3299 5.34283 12.3299 6.73303 11.6338L10.9492 9.52621C12.3524 8.8301 12.3524 8.8301 12.9224 8.8301C13.4925 8.8301 13.5055 8.8301 13.5055 9.99669C13.503 11.127 13.5029 11.127 12.4029 12.2664Z"
          fill="white"
        />
      </svg>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  padding: ${dimensions.size4};
`;
