import { Badge, BadgeProps } from '@chakra-ui/react';
import {
  DataType,
  Value,
  ValueType,
  getEnumOptions,
} from '@common/studio-types';
import React from 'react';
import { useItem } from '../../hooks/useItem';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';

type Props = { value: Value; badgeProps?: BadgeProps };

const UNKNOWN = 'unknown';

export const ValueBadge: React.FC<Props> = ({ value, badgeProps }) => {
  const { rpgConfig, propertyName } = useStudioRpgConfig();
  const { item } = useItem(
    value.type === ValueType.Item ? value.ref : undefined,
  );

  const renderValue = (value: Value) => {
    if (!value) {
      return null;
    } else if (value.type === ValueType.Property) {
      return `prop.${propertyName(value.ref)}`;
    } else if (value.type === ValueType.StaticValue) {
      if (
        value.dataType === DataType.String ||
        value.dataType === DataType.Number
      ) {
        return value.value;
      }

      if (value.dataType === DataType.Enum) {
        const options = getEnumOptions(value.enumRef, rpgConfig.properties);
        const enumOption = options.find(({ ref }) => value.ref === ref);

        if (enumOption) {
          return enumOption.label;
        }
      }

      return '(invalid)';
    } else if (value.type === ValueType.Input) {
      return `input.${value.ref}`;
    } else if (value.type === ValueType.Item) {
      return `item.${item?.name ?? UNKNOWN}`;
    }
  };

  return (
    <Badge fontFamily="mono" variant="small" {...badgeProps}>
      {renderValue(value)}
    </Badge>
  );
};
