import { VersionSkewBoundary } from '@maestro/config';
import { FeatureFlagsProvider } from '@maestro/feature-flags/src/FeatureFlagsProvider';
import { StudioGraphqlClientProvider } from '@maestro/graphql-client/StudioGraphqlClientProvider';
import { PropsWithChildren, Children } from 'react';

/**
 * Simple wrapper component to make the diff for top-level providers easier to view
 */
export const AppProviders: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <FeatureFlagsProvider>
      <StudioGraphqlClientProvider>
        <VersionSkewBoundary>
          {Children.map(children, (child) => child)}
        </VersionSkewBoundary>
      </StudioGraphqlClientProvider>
    </FeatureFlagsProvider>
  );
};
