import {
  DataType,
  Value,
  ValueType,
  getEnumOptions,
} from '@common/studio-types';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { useItem } from '../../hooks/useItem';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';

type Props = {
  value: Value;
  className?: string;
  hideIcon?: boolean;
};

const UNKNOWN = 'unknown';

const getIconFromDataType = (dataType: DataType) => {
  if (dataType === DataType.Number) {
    return 'number-type';
  } else if (dataType === DataType.String) {
    return 'text-type';
  } else if (dataType === DataType.Enum) {
    return 'enum-type';
  }

  return undefined;
};

export const ValueBadge: React.FC<Props> = ({ hideIcon, value, className }) => {
  const { rpgConfig, propertyName } = useStudioRpgConfig();
  const { item } = useItem(
    value.type === ValueType.Item ? value.ref : undefined,
  );
  const getValueData = (
    value: Value,
  ): { icon?: string; label: string } | undefined => {
    if (!value) {
      return undefined;
    } else if (value.type === ValueType.Property) {
      return {
        icon: getIconFromDataType(value.dataType),
        label: propertyName(value.ref),
      };
    } else if (value.type === ValueType.StaticValue) {
      if (
        value.dataType === DataType.Number ||
        value.dataType === DataType.String
      ) {
        return {
          icon: 'static-value',
          label: value.value.toString(),
        };
      }

      if (value.dataType === DataType.Enum) {
        const options = getEnumOptions(value.enumRef, rpgConfig.properties);
        const enumOption = options.find(({ ref }) => value.ref === ref);

        return {
          icon: 'static-value',
          label: enumOption?.label ?? UNKNOWN,
        };
      }
    } else if (value.type === ValueType.Input) {
      return {
        icon: 'input-value',
        label: value.ref,
      };
    } else if (value.type === ValueType.Item) {
      return {
        icon: 'inventory',
        label: item?.name ?? UNKNOWN,
      };
    }
  };

  const result = getValueData(value);

  if (!result) {
    return null;
  }

  return (
    <Badge className={className}>
      {!hideIcon && result.icon && (
        <Icon name={result.icon} size={rawDimensions.size16} />
      )}
      <Label>{result.label}</Label>
    </Badge>
  );
};

const Badge = styled.div`
  ${textStyles.body.b14sb}
  background: ${({ theme }) => theme.colors.background.shade};
  padding: ${dimensions.size4} ${dimensions.size12};
  border-radius: ${dimensions.size12};
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  flex-shrink: 1;
`;

const Label = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
