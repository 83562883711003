import type { CharacterCreatorCompleteNodeData } from '@common/studio-types';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<CharacterCreatorCompleteNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      title="Character creator complete"
      selected={selected}
      nodeId={id}
      tag="End"
      node={data}
    >
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Text>{data.text}</Text>
    </BaseStudioNode>
  );
};

export const CharacterCreatorCompleteNode = memo(Node);

const Text = styled.div`
  white-space: pre-line;
`;
