import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { FeatureFlags } from '@maestro/feature-flags';
import React from 'react';
import { Connection, NodeProps } from 'reactflow';

export enum NodeGroup {
  Advanced = 'Advanced nodes',
}

export type StudioNodeComponent<TData> = React.FC<NodeProps<TData>>;

export type StudioNodeFormComponent<TData extends StudioNodeData> = React.FC<{
  data: TData;
  onChange: (data: TData) => void;
}>;

export type ValidationError = { field?: string; text: string };

type HandleData = {
  handleId: string;
  name: string;
  description?: string;
};

type ClonedData<TNodeData extends StudioNodeData> = {
  data: Omit<TNodeData, 'type'>;
  connections?: { oldId: string; newId: string }[];
};

export type NodeConfig<
  TType extends StudioNodeType,
  TNodeData extends StudioNodeData,
> = {
  name: string;
  icon: string;
  type: TType;
  group?: NodeGroup;
  featureFlag?: FeatureFlags;
  sourceHandles?: (data: TNodeData) => HandleData[];
  node: StudioNodeComponent<TNodeData>;
  form: StudioNodeFormComponent<TNodeData>;
  /**
   * This means the node will show only for specific
   * episode refs. If not provided, it will show for all.
   */
  episodeRef?: string;
  cloneNodeData: (data: TNodeData) => ClonedData<TNodeData>;
  createNodeData: () => TNodeData;
  getNodeData: (data: TNodeData) => { title: string; description?: string };
  validateNodeData?: (data: TNodeData) => ValidationError[];
};

export type StudioConnection = Connection & { label?: string };
