import { Button, ButtonGroup, Wrap } from '@chakra-ui/react';
import { DataType, RpgConfig, Value, ValueType } from '@common/studio-types';
import { TextInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Field } from '../Field';
import { PropertyInput } from './PropertyInput';
import { StaticValueInput } from './StaticValueInput';
import { ValueItemInput } from './ValueItemInput';

type Props = PropsWithChildren<{
  value: Value | undefined;
  rpgConfig: RpgConfig;
  onChange(value: Value): void;
  disabledTypes?: ValueType[];
  updateOperation?: boolean;
  referenceValue?: Value;
}>;

export const ValueInput: React.FC<Props> = ({
  value,
  rpgConfig,
  referenceValue,
  onChange,
  disabledTypes,
  children,
}) => {
  const properties = rpgConfig.properties.filter(
    (prop) =>
      !referenceValue || prop.config.dataType === referenceValue.dataType,
  );

  const onValueTypeChange = (type: ValueType) => {
    if (!value) {
      return;
    }

    if (type === ValueType.Property) {
      onChange({ ...value, type, ref: '' });
    } else if (type === ValueType.StaticValue) {
      if (value.dataType === DataType.Number) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          dataType: DataType.Number,
          value: 0,
        });
      } else if (value.dataType === DataType.String) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          value: '',
          dataType: DataType.String,
        });
      } else if (value.dataType === DataType.Enum) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          ref: '',
          dataType: DataType.Enum,
        });
      } else {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          value: 0,
          dataType: DataType.Number,
        });
      }
    } else if (type === ValueType.Item) {
      onChange({
        ...value,
        type: ValueType.Item,
        ref: '',
        dataType: DataType.Number,
      });
    } else if (type === ValueType.Input) {
      onChange({ ...value, type, ref: 'default', dataType: DataType.Number });
    }
  };

  return (
    <Container>
      <Field label="Value Type">
        <ButtonGroup>
          <Wrap>
            {!disabledTypes?.includes(ValueType.Property) && (
              <Button
                size="sm"
                variant="default"
                fontSize={dimensions.size12}
                isDisabled={!properties.length}
                isActive={value?.type === ValueType.Property}
                _active={{ bg: 'background.accent' }}
                onClick={() => onValueTypeChange(ValueType.Property)}
              >
                Property
              </Button>
            )}

            {!disabledTypes?.includes(ValueType.StaticValue) && (
              <Button
                size="sm"
                variant="default"
                fontSize={dimensions.size12}
                isActive={value?.type === ValueType.StaticValue}
                _active={{ bg: 'background.accent' }}
                onClick={() => onValueTypeChange(ValueType.StaticValue)}
              >
                Static Value
              </Button>
            )}

            {!disabledTypes?.includes(ValueType.Input) && (
              <Button
                size="sm"
                variant="default"
                fontSize={dimensions.size12}
                isActive={value?.type === ValueType.Input}
                _active={{ bg: 'background.accent' }}
                onClick={() => onValueTypeChange(ValueType.Input)}
              >
                Input
              </Button>
            )}

            {!disabledTypes?.includes(ValueType.Item) &&
              (!referenceValue ||
                referenceValue.dataType === DataType.Number ||
                referenceValue.dataType === DataType.String) && (
                <Button
                  size="sm"
                  variant="default"
                  fontSize={dimensions.size12}
                  isActive={value?.type === ValueType.Item}
                  _active={{ bg: 'background.accent' }}
                  onClick={() => onValueTypeChange(ValueType.Item)}
                >
                  Item
                </Button>
              )}
          </Wrap>
        </ButtonGroup>
      </Field>

      {value && (
        <>
          {value.type === ValueType.Property ? (
            <StyledField label="Property">
              <PropertyInput
                rpgConfig={rpgConfig}
                onChange={onChange}
                value={value}
                referenceValue={referenceValue}
              />
            </StyledField>
          ) : value.type === ValueType.Input ? (
            <StyledField label="Input name">
              <TextInput
                value={value.ref}
                onChange={(e) => onChange({ ...value, ref: e.target.value })}
              />
            </StyledField>
          ) : value.type === ValueType.StaticValue ? (
            <StyledField label="Static Value">
              <StaticValueInput
                rpgConfig={rpgConfig}
                onChange={onChange}
                value={value}
              />
            </StyledField>
          ) : value.type === ValueType.Item ? (
            <ValueItemInput
              value={value.ref}
              onChange={(item) => onChange({ ...value, ref: item })}
            >
              {children}
            </ValueItemInput>
          ) : null}
          {value.type !== ValueType.Item && children}
        </>
      )}
    </Container>
  );
};

const StyledField = styled(Field)`
  margin-bottom: ${dimensions.size0};
`;

const Container = styled.div`
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;
