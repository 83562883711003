import { Button } from '@chakra-ui/react';
import { EndEpisodeAction } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { EndEpisodeMessage } from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import { messageAnimation } from './keyframes';

type Props = {
  message: EndEpisodeMessage;
  game: GameSimulator;
};

const actions: Record<EndEpisodeAction, string> = {
  [EndEpisodeAction.PlayNextEpisode]: 'Play next episode',
  [EndEpisodeAction.RestartEpisode]: 'Restart episode',
  [EndEpisodeAction.RestartSeries]: 'Restart series',
};

const Component: React.FC<Props> = ({ message }) => {
  return (
    <>
      <Container>{message.message}</Container>
      <Button variant="primary" width="100%" isDisabled>
        {actions[message.action]}
      </Button>
    </>
  );
};

export const MaestroGameMessageEndEpisode = memo(Component);

const Container = styled.div`
  ${textStyles.body.b16sbi};
  margin: ${dimensions.size32} ${dimensions.size0} ${dimensions.size64}
    ${dimensions.size0};
  color: ${({ theme }) => theme.colors.text.body};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;
