import type { OnboardingCompleteNodeData } from '@common/studio-types';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { Warning } from '../../components/Warning';
import { StudioNodeFormComponent } from '../Node.types';

const maxCharacters = 1000;

export const OnboardingCompleteForm: StudioNodeFormComponent<
  OnboardingCompleteNodeData
> = ({ data, onChange }) => {
  return (
    <Container>
      <Hint>
        The onboarding complete node is the final node in the onboarding flow.
      </Hint>
      <Label>Onboarding complete</Label>
      <Textarea
        placeholder="Write a message to be shown at the end of onboarding, here"
        maxCharacters={maxCharacters}
        autoFocus={!data.text}
        value={data.text}
        onChange={(e) => onChange({ ...data, text: e.target.value })}
      />
      {data.text.length > maxCharacters && (
        <Warning>We recommend less than {maxCharacters} characters.</Warning>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
