import {
  DataType,
  type RpgConfig,
  type StaticValue,
  Value,
  ValueType,
  getEnumOptions,
} from '@common/studio-types';
import { NumericInput, TextInput } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { EnumDropdown } from './EnumDropdown';

type Props = {
  value: StaticValue;
  onChange: (value: StaticValue) => void;
  rpgConfig: RpgConfig;
  maxValue?: Value;
  minValue?: Value;
};

export const StaticValueInput: React.FC<Props> = (props) => {
  const { value, onChange, rpgConfig } = props;

  const minValue =
    props.minValue?.type === ValueType.StaticValue &&
    props.minValue.dataType === DataType.Number
      ? props.minValue.value
      : undefined;
  const maxValue =
    props.maxValue?.type === ValueType.StaticValue &&
    props.maxValue.dataType === DataType.Number
      ? props.maxValue.value
      : undefined;

  if (value.dataType === DataType.Number) {
    const isLessThanMin = minValue !== undefined && value.value < minValue;
    const isGreaterThanMax = maxValue !== undefined && value.value > maxValue;

    const input = (
      <NumericInput
        flex={1}
        placeholder="Value"
        value={value.value}
        min={-1000}
        isInvalid={isLessThanMin || isGreaterThanMax}
        onChange={(valueAsNumber) =>
          onChange({ ...value, value: valueAsNumber })
        }
      />
    );

    if (isLessThanMin || isGreaterThanMax) {
      const message = isLessThanMin
        ? `This value must be greater than ${minValue}`
        : `This value must be less than ${maxValue}`;

      return (
        <Container>
          {input}
          <Hint>{message}</Hint>
        </Container>
      );
    }

    return input;
  }

  if (value.dataType === DataType.String) {
    return (
      <TextInput
        placeholder="Type value"
        value={value.value}
        onChange={(e) => onChange({ ...value, value: e.target.value })}
      />
    );
  }

  if (value.dataType === DataType.Enum) {
    const options = getEnumOptions(value.enumRef, rpgConfig.properties);

    return (
      <EnumDropdown
        value={value.ref}
        options={options}
        onChange={(ref) => onChange({ ...value, ref })}
      />
    );
  }
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Hint = styled.div<{ $isFocused?: boolean }>`
  ${textStyles.body.b12m}
  color: ${({ theme, $isFocused }) =>
    $isFocused ? theme.colors.text.accent : theme.colors.base.red[900]};
`;
