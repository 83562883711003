import {
  ImageNodeData,
  StatBlockNodeData,
  StudioFlowState,
  StudioNodeType,
} from '@common/studio-types';
import type { GameNode } from './studioGameCreator.types';

const isImageNode = (
  nodeData: GameNode,
): nodeData is GameNode<ImageNodeData> => {
  return nodeData.type === StudioNodeType.Image;
};

const isStatBlockNode = (
  nodeData: GameNode,
): nodeData is GameNode<StatBlockNodeData> => {
  return nodeData.type === StudioNodeType.StatBlock;
};

export const toImageUrlListFromStateFlow = (
  stateFlow: StudioFlowState,
  commonImages: string[],
): string[] => {
  if (!stateFlow) return [];

  const stateFlowImages = stateFlow.nodes
    .map((node) => {
      const gameNode: GameNode = {
        ...node.data,
      };

      if (isImageNode(gameNode) && gameNode.imageUrl) {
        return gameNode.imageUrl;
      } else if (isStatBlockNode(gameNode) && gameNode.image?.url) {
        return gameNode.image.url;
      }

      return undefined;
    })
    .filter((imageUrl): imageUrl is string => !!imageUrl);

  return [...commonImages, ...stateFlowImages];
};
