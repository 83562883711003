import {
  type ChangeValueNodeData,
  DataType,
  type PlayerInputNodeData,
  StudioNodeType,
  valueUtils,
} from '@common/studio-types';
import { changeValue } from '../actions';
import type { Message } from '../game/messages.types';
import type { GameNode } from '../studioGameCreator.types';
import type { StatefulGame } from './statefulGame';

type Args = {
  interactionId: string;
  playerInput: string;
};

export const handlePlayerInput = async (
  game: StatefulGame,
  interactionArgs: Args,
): Promise<Message[]> => {
  const { interactionId, playerInput } = interactionArgs;
  const interaction =
    game.gameNodes.nodeById<PlayerInputNodeData>(interactionId);

  if (
    !interaction ||
    game.state().currentInteraction !== interactionId ||
    interaction?.type !== StudioNodeType.PlayerInput
  ) {
    return [game.errorMessage('Invalid interaction')];
  }

  /* This needs to run BEFORE the game execution, otherwise
   * it'll override the interaction that will be set during execution
   */
  game.setGameState({ currentInteraction: undefined });

  if (!interaction.nextNodeId) {
    return [];
  }

  // interaction.property
  const property = game.config.getProperty(interaction.property.ref);

  if (!property || property.config.dataType !== DataType.String) {
    // we don't allow users to use non-string properties
    // which means we're good here, this shouldn't happen
    return [game.errorMessage('Invalid property')];
  }

  const changeValueNode: GameNode<ChangeValueNodeData> = {
    action: 'set',
    id: interaction.id,
    type: StudioNodeType.ChangeValue,
    changedValue: valueUtils.stringProp(property.id),
    value: valueUtils.staticString(playerInput.trim()),
  };

  const { messages, state } = await changeValue(
    changeValueNode,
    game.state(),
    game.gameData.playerData,
    game.config,
  );

  game.setGameState(state);

  const moreMessages = await game.execute(interaction.nextNodeId);

  if (moreMessages.length === 0) {
    // setting back to the current interaction
    game.setGameState({ currentInteraction: interaction.id });

    return [];
  }

  return [
    {
      nodeId: interaction.id,
      type: 'player-input-result',
      id: interaction.id,
      input: playerInput,
    },
    ...messages,
    ...moreMessages,
  ];
};
