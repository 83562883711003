import { useEpisodeStatusQuery } from '@maestro/graphql';
import { useCallback, useMemo } from 'react';

export const useEpisodeStatus = (episodeId: string) => {
  const { data, refetch } = useEpisodeStatusQuery(
    { input: { episodeId } },
    { cacheTime: 0, staleTime: 0, retry: false },
  );

  const episodeStatus = useMemo(() => {
    const episode = data?.studioEpisode?.data;

    return {
      hasNewVersion: Boolean(
        !!episode?.draftVersion.updatedAt &&
          !!episode?.publishedVersion?.publishedAt,
      ),
      isDraft: !episode?.publishedVersion,
      isPublished: !!episode?.publishedVersion?.publishedAt,
      publishedAt: episode?.publishedVersion?.publishedAt ?? undefined,
      episodeOrder: episode?.order,
    };
  }, [data]);

  const refresh = useCallback(() => {
    refetch().catch(() => void 0);
  }, [refetch]);

  return { ...episodeStatus, refresh };
};
