import {
  Button,
  Tooltip,
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
} from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Field, Icon, Textarea } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shouldShowGenerateWithAi } from '../hooks/useGenerateNodeWithAi';
import { nodeConfigs } from '../nodes';
import { GenerateImageWithAiFields } from './ai/GenerateImageWithAiFields';
import { Drawer } from './Drawer';
import { GeneratedContainer } from './GeneratedContainer';
import { GenerateNodeWithAi } from './GenerateNodeWithAi';
import { NodeForm } from './NodeForm';

type Props = {
  isOpen: boolean;
  studioNodeData?: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  onCancel: VoidFunction;
};

export const StudioDrawer: React.FC<Props> = (props) => {
  const [isAiOpen, setIsAiOpen] = useState(false);
  const [aiContentGenerateType, setAiContentGenerateType] = useState<
    'text' | 'image'
  >('text');
  const title = props.studioNodeData
    ? nodeConfigs[props.studioNodeData.type]?.name
    : '';

  const isAiTemplateEpisodesEnabled = useFeatureFlag(
    FeatureFlags.AiTemplateEpisodes,
  );
  const isGenerateImagesWithAiEnabled = useFeatureFlag(
    FeatureFlags.GenerateImagesWithAi,
  );
  const toggleAi = (content?: 'text' | 'image') => {
    setAiContentGenerateType(content ?? 'text');
    setIsAiOpen(!!content ? true : !isAiOpen);
  };

  const isStatBlockNode =
    StudioNodeType.StatBlock === props.studioNodeData?.type;
  const isImageNode = StudioNodeType.Image === props.studioNodeData?.type;

  const showAiButton =
    props.studioNodeData &&
    shouldShowGenerateWithAi(props.studioNodeData?.type) &&
    (isImageNode ? isGenerateImagesWithAiEnabled : true);

  const showAiContentSelectionButton = !!isStatBlockNode;

  useEffect(() => setIsAiOpen(false), [props.studioNodeData]);

  return (
    <Drawer
      ariaLabel="Node Settings"
      isOpen={props.isOpen && !!title}
      onCancel={props.onCancel}
      title={title}
      headerContent={
        <>
          {showAiButton && (
            <Tooltip
              label="Generate with AI"
              aria-label="Generate with AI"
              placement="bottom-end"
            >
              <>
                {!showAiContentSelectionButton && (
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => toggleAi()}
                    isActive={isAiOpen}
                  >
                    <Icon name="edit-with-ai" size={16} />
                  </Button>
                )}
                {showAiContentSelectionButton && (
                  <Menu>
                    <MenuButton>
                      <Icon name="edit-with-ai" size={16} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => toggleAi('text')}>
                        Create Stat Block Content
                      </MenuItem>
                      <MenuItem onClick={() => toggleAi('image')}>
                        Create Stat Block Image
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </>
            </Tooltip>
          )}
        </>
      }
    >
      {props.studioNodeData && (
        <Container>
          {isAiOpen && (
            <GenerateNodeWithAi
              studioNodeData={props.studioNodeData}
              onStudioNodeDataChange={props.onStudioNodeDataChange}
              onFinishGeneration={() => setIsAiOpen(false)}
              generateContentType={aiContentGenerateType}
            />
          )}
          <NodeForm
            studioNodeData={props.studioNodeData}
            onStudioNodeDataChange={props.onStudioNodeDataChange}
          />
          {isAiTemplateEpisodesEnabled && (
            <AiContainer>
              {!isImageNode && (
                <Field label="Instructions to AI">
                  <Textarea
                    placeholder="Tell AI how to generate this node"
                    value={props.studioNodeData.aiInstructions ?? ''}
                    onChange={(e) =>
                      props.onStudioNodeDataChange({
                        ...props.studioNodeData!,
                        aiInstructions: e.target.value,
                      })
                    }
                  />
                </Field>
              )}
              {isGenerateImagesWithAiEnabled &&
                (isStatBlockNode || isImageNode) && (
                  <GenerateImageWithAiFields
                    onStudioNodeDataChange={props.onStudioNodeDataChange}
                    studioNodeData={props.studioNodeData}
                  />
                )}
            </AiContainer>
          )}
          {isAiTemplateEpisodesEnabled &&
            !!props.studioNodeData.generatedAiInstructions && (
              <GeneratedContainer
                chat={props.studioNodeData.generatedAiInstructions}
              />
            )}
        </Container>
      )}
    </Drawer>
  );
};

const Container = styled.div`
  padding: ${dimensions.size16};
`;

const AiContainer = styled.div`
  padding: ${dimensions.size16} ${dimensions.size0};
`;
