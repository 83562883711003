import { StudioNodeData } from '@common/studio-types';
import { Edge, Node } from 'reactflow';

type CopiedPayload<T> = { date: string; payload: T };

const EXPIRATION_IN_MS = 3_600_000; // lasts one hour

function hasExpired(date: string): boolean {
  return new Date().getTime() - new Date(date).getTime() > EXPIRATION_IN_MS;
}

export function saveNodes(payload: Node<StudioNodeData>[]) {
  const copiedPayload: CopiedPayload<Node<StudioNodeData>[]> = {
    date: new Date().toISOString(),
    payload,
  };

  sessionStorage.setItem('copiedNodes', JSON.stringify(copiedPayload));
}

export function saveEdges(payload: Edge[]) {
  const copiedPayload: CopiedPayload<Edge[]> = {
    date: new Date().toISOString(),
    payload,
  };

  sessionStorage.setItem('copiedEdges', JSON.stringify(copiedPayload));
}

export function getSavedNodes(): Node<StudioNodeData>[] {
  const copiedNodes = sessionStorage.getItem('copiedNodes');

  if (!copiedNodes) {
    return [];
  }

  const copiedPayload: CopiedPayload<Node<StudioNodeData>[]> =
    JSON.parse(copiedNodes);

  if (hasExpired(copiedPayload.date)) {
    return [];
  }

  return copiedPayload.payload;
}

export function getSavedEdges(): Edge[] {
  const copiedEdges = sessionStorage.getItem('copiedEdges');

  if (!copiedEdges) {
    return [];
  }

  const copiedPayload: CopiedPayload<Edge[]> = JSON.parse(copiedEdges);

  if (hasExpired(copiedPayload.date)) {
    return [];
  }

  return copiedPayload.payload;
}
