import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Hint } from './Hint';
import { Label } from './Label';

type Props = PropsWithChildren<{
  label: string;
  rightButton?: React.ReactNode;
  className?: string;
}>;

export const Field: React.FC<Props> = ({
  label,
  children,
  rightButton,
  className,
}) => {
  return (
    <FieldContainer className={className}>
      <LabelContainer>
        <Label>{label}</Label>
        {rightButton}
      </LabelContainer>
      {children}
    </FieldContainer>
  );
};

export const FieldContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  margin-bottom: ${dimensions.size24};

  & ${Hint} {
    ${textStyles.body.b12m}
    margin-bottom: ${dimensions.size8};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
  min-height: 18px;
`;
