import { RpgConfig, RpgConfigProperty } from '@common/studio-types';
import { useCallback, useMemo } from 'react';
import { useGetEpisodeProperties } from './useGetEpisodeProperties';
import { useStudioFlowStore } from './useStudioFlowStore';

type Result = {
  rpgConfig: RpgConfig;
  propertyName: (ref: string) => string;
  findProperty: (ref: string) => RpgConfigProperty | undefined;
  findPropertyByName: (name: string) => RpgConfigProperty | undefined;
};

export const useStudioRpgConfig = (): Result => {
  const localProperties = useGetEpisodeProperties();
  const globalConfig = useStudioFlowStore((state) => state.rpgConfig);

  const rpgConfig = useMemo(() => {
    return {
      ...globalConfig,
      properties: [...globalConfig.properties, ...localProperties],
    };
  }, [globalConfig, localProperties]);

  const findProperty = useCallback(
    (ref: string) => {
      return rpgConfig.properties.find(({ id }) => id === ref);
    },
    [rpgConfig.properties],
  );

  const findPropertyByName = useCallback(
    (search: string) => {
      return rpgConfig.properties.find(
        ({ name }) => name?.toLowerCase() === search?.toLowerCase(),
      );
    },
    [rpgConfig.properties],
  );

  const propertyName = useCallback(
    (ref: string) => findProperty(ref)?.name ?? '(unknown)',
    [findProperty],
  );

  return { rpgConfig, findProperty, propertyName, findPropertyByName };
};
