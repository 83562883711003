import { rawDimensions } from '@maestro/styles';
import React from 'react';
import { DiceSvgProps } from '../DiceSvg';
import { alterHslColor } from '../utils';

export const D6: React.FC<DiceSvgProps> = ({
  multipliers,
  size = rawDimensions.size96,
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none">
      <path
        d="M17 17H79V79H17V17Z"
        fill={alterHslColor(`hsl(186, 74%, 47%)`, multipliers)}
      />
    </svg>
  );
};
