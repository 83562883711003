export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;

export type ColorWithOpacity = {
  1000: RGBA;
  950: RGBA;
  900: RGBA;
  800: RGBA;
  600: RGBA;
  400: RGBA;
  200: RGBA;
  100: RGBA;
  50: RGBA;
  30: RGBA;
};

export type ColorTheme<T> = {
  dark: T;
  light: T;
};

export enum ThemeMode {
  Dark = 'dark',
  Light = 'light',
}
