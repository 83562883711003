import { Button } from '@chakra-ui/react';
import { Icon, TextInput } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { PlayerInputMessage } from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import { choicesAnimation, messageAnimation } from './keyframes';
import { MaestroSelectedInteraction } from './MaestroSelectedInteraction';

type Props = {
  message: PlayerInputMessage;
  game: GameSimulator;
  isLastMessage: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { message, game, isLastMessage } = props;
  const [value, setValue] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit = () => {
    setIsSubmitted(true);
    game.playerInput(message.id, value.trim());
  };

  return (
    <>
      <Prompt>{message.prompt}</Prompt>

      {isLastMessage && isSubmitted && (
        <SelectedContainer>
          <MaestroSelectedInteraction text={value.trim()} icon="keyboard" />
        </SelectedContainer>
      )}

      {isLastMessage && !isSubmitted && (
        <InputContainer>
          <TextInput
            onKeyUp={(evt) => {
              evt.key === 'Enter' && !!value.trim() && onSubmit();
            }}
            placeholder="Type your response"
            data-1p-ignore
            autoFocus
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            marginRight={dimensions.size36}
          />
          <Button
            position="absolute"
            right={dimensions.size12}
            top={dimensions.size12}
            padding={dimensions.size0}
            minWidth={dimensions.size28}
            width={dimensions.size28}
            height={dimensions.size28}
            borderRadius={dimensions.size999}
            variant="default"
            isDisabled={!value.trim()}
            onClick={onSubmit}
          >
            <Icon name="send" size={rawDimensions.size20} />
          </Button>
        </InputContainer>
      )}
    </>
  );
};

export const MaestroGameMessagePlayerInput = memo(Component);

const Prompt = styled.div`
  ${textStyles.body.b16sb};
  color: ${({ theme }) => theme.colors.text.header};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  margin-top: ${dimensions.size24};
  animation: ${choicesAnimation} 1s ease-in-out forwards;
  position: relative;
`;

const SelectedContainer = styled.div`
  margin-top: ${dimensions.size16};
  animation: ${messageAnimation} 0.2s ease-in-out forwards;
`;
