import { dimensions } from '@maestro/styles';
import styled from 'styled-components';

export const GhostNode = styled.div`
  width: ${dimensions.size280};
  height: ${dimensions.size160};
  background: ${({ theme }) => theme.colors.background.shade};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
`;
