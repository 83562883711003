import { PropsWithChildren } from 'react';
import { useCheckForVersionSkew } from './hooks/useCheckForVersionSkew';

export const VersionSkewBoundary: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useCheckForVersionSkew();

  return children;
};
