import { NarratorTextNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { validateText } from '../validationUtils';
import { NarratorTextForm } from './NarratorTextForm';
import { NarratorTextNode } from './NarratorTextNode';

const name = 'Narrator response';
const rules = { min: 1, max: 1000 };

export const narratorTextNode: NodeConfig<
  StudioNodeType.NarratorText,
  NarratorTextNodeData
> = {
  type: StudioNodeType.NarratorText,
  name,
  icon: 'narrator-text',
  node: NarratorTextNode,
  form: NarratorTextForm,
  cloneNodeData: ({ text }) => ({ data: { id: toNodeId(), text } }),
  getNodeData: (data) => ({ title: name, description: data.text }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.NarratorText,
    text: '',
  }),
  validateNodeData: (data) =>
    validateText(data.text, rules) ? [] : [{ text: data.text }],
};
