import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from './Icon';

type Props = {
  label?: string;
};

export const Loader: React.FC<Props> = ({ label }) => (
  <Container>
    <LoadingIcon name="loading" size={rawDimensions.size24} />
    {label && <Label>{label}</Label>}
  </Container>
);

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(Icon)`
  animation: ${spin} 1.5s linear infinite;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size112};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const Label = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
