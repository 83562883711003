import { DataType, RpgConfig, Value, ValueType } from '@common/studio-types';
import {
  Icon,
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
  TextInput,
} from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { PropertyInput } from './PropertyInputV2';
import { StaticValueInput } from './StaticValueInput';
import { ValueItemInput } from './ValueItemInputV2';

type Props = PropsWithChildren<{
  value: Value | undefined;
  rpgConfig: RpgConfig;
  onChange(value: Value): void;
  disabledTypes?: ValueType[];
  updateOperation?: boolean;
  referenceValue?: Value;
  minValue?: Value;
  maxValue?: Value;
}>;

const values: {
  valueType: ValueType;
  label: string;
  icon: string;
  description: string;
}[] = [
  {
    valueType: ValueType.Property,
    label: 'Property',
    icon: 'property-value',
    description: 'Reference existing property in the editor.',
  },
  {
    valueType: ValueType.StaticValue,
    label: 'Static Value',
    icon: 'static-value',
    description: 'Enter a fixed string or numerical value.',
  },
  {
    valueType: ValueType.Input,
    label: 'Input',
    icon: 'input-value',
    description: 'Get value from another node in the flow.',
  },
  {
    valueType: ValueType.Item,
    label: 'Item quantity',
    icon: 'inventory',
    description: 'Reference item quantity from player inventory.',
  },
];

const valuesMap = values.reduce(
  (acc, value) => ({ [value.valueType]: value, ...acc }),
  {} as Record<ValueType, { label: string; icon: string }>,
);

export const ValueInput: React.FC<Props> = (props) => {
  const { value, rpgConfig, referenceValue } = props;
  const { onChange, disabledTypes, children } = props;
  const { maxValue, minValue } = props;

  const onValueTypeChange = (type: ValueType) => {
    if (!value) {
      return;
    }

    if (type === ValueType.Property) {
      onChange({ ...value, type, ref: '' });
    } else if (type === ValueType.StaticValue) {
      if (value.dataType === DataType.Number) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          dataType: DataType.Number,
          value: 0,
        });
      } else if (value.dataType === DataType.String) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          value: '',
          dataType: DataType.String,
        });
      } else if (value.dataType === DataType.Enum) {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          ref: '',
          dataType: DataType.Enum,
        });
      } else {
        onChange({
          ...value,
          type: ValueType.StaticValue,
          value: 0,
          dataType: DataType.Number,
        });
      }
    } else if (type === ValueType.Item) {
      onChange({
        ...value,
        type: ValueType.Item,
        ref: '',
        dataType: DataType.Number,
      });
    } else if (type === ValueType.Input) {
      onChange({ ...value, type, ref: 'default', dataType: DataType.Number });
    }
  };

  const filteredValues = values.filter(({ valueType }) => {
    if (disabledTypes?.includes(valueType)) {
      return false;
    } else if (referenceValue) {
      if (referenceValue.dataType === DataType.Number) return true;

      return (
        valueType === ValueType.StaticValue || valueType === ValueType.Property
      );
    }

    return true;
  });
  const valueConfig = value?.type ? valuesMap[value.type] : undefined;

  return (
    <WrapperContainer>
      <Container>
        <Menu>
          <MenuDropdownButton style={{ width: 180 }}>
            {valueConfig && (
              <DropdownIcon>
                <StyledIcon
                  name={valueConfig.icon}
                  size={rawDimensions.size20}
                />
                {valueConfig.label}
              </DropdownIcon>
            )}
          </MenuDropdownButton>
          <MenuList>
            {filteredValues.map(({ valueType, label, icon, description }) => (
              <MenuItem
                key={valueType}
                onClick={() => onValueTypeChange(valueType)}
              >
                <MenuItemContainer>
                  <Icon name={icon} size={rawDimensions.size20} />
                  <TextContainer>
                    {label}
                    <Description>{description}</Description>
                  </TextContainer>
                </MenuItemContainer>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        {value?.type === ValueType.Property ? (
          <PropertyInput
            rpgConfig={rpgConfig}
            onChange={onChange}
            value={value}
            referenceValue={referenceValue}
          />
        ) : value?.type === ValueType.Input ? (
          <TextInput
            props={{ flex: 1 }}
            value={value.ref}
            onChange={(e) => onChange({ ...value, ref: e.target.value })}
          />
        ) : value?.type === ValueType.StaticValue ? (
          <StaticValueInput
            rpgConfig={rpgConfig}
            onChange={onChange}
            value={value}
            maxValue={maxValue}
            minValue={minValue}
          />
        ) : value?.type === ValueType.Item ? (
          <ValueItemInput
            value={value.ref}
            onChange={(item) => onChange({ ...value, ref: item })}
          />
        ) : null}
      </Container>
      {children}
    </WrapperContainer>
  );
};

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  gap: ${dimensions.size8};
  flex-wrap: wrap;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size12};
  align-items: start;
`;

const TextContainer = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.header};
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;

const DropdownIcon = styled.div`
  ${textStyles.body.b14m}
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: ${dimensions.size8};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
