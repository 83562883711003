import { CloseButton } from '@chakra-ui/react';
import {
  DataType,
  RpgConfig,
  RpgConfigHudElement,
  RpgConfigProperty,
  Value,
  ValueType,
  hudIcons,
} from '@common/studio-types';
import { Field } from '@maestro/components';
import { Item } from '@maestro/studio';
import { ValueInput } from '@maestro/studio/components/value/ValueInput';
import { dimensions } from '@maestro/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IconInput } from './IconInput';

type Props = {
  isLoading: boolean;
  items: Item[];
  onItemCreated: (item: Item) => void;
  properties: RpgConfigProperty[];
  hudElement: RpgConfigHudElement;
  onChange: (hudElement: RpgConfigHudElement) => void;
  onRemove: () => void;
};

// We only support support HUD items with numeric values
const onlyNumericValue: Value = {
  dataType: DataType.Number,
  type: ValueType.StaticValue,
  value: 0,
};

export const HudElementInput: React.FC<Props> = (props) => {
  const { properties, hudElement, onChange } = props;
  const hudIcon = hudIcons.find((icon) => icon.ref === hudElement.icon);

  const onValueChange = (value: Value) => {
    onChange({ ...hudElement, value });
  };

  const rpgConfig: RpgConfig = useMemo(
    () => ({
      version: 2,
      properties,
      customItems: [], // we don't need them in value input
      hud: [], // we don't need them in value input
    }),
    [properties],
  );

  return (
    <Container>
      <RowContainer>
        <Field label="Value">
          <ValueInput
            referenceValue={onlyNumericValue}
            updateOperation
            rpgConfig={rpgConfig}
            disabledTypes={[ValueType.Input, ValueType.StaticValue]}
            value={hudElement.value}
            onChange={onValueChange}
          />
        </Field>
        <Field label="Icon">
          <IconInput
            icon={hudIcon?.ref}
            onChange={(icon) => onChange({ ...hudElement, icon })}
          />
        </Field>
        <StyledField label="">
          <CloseButton onClick={props.onRemove} />
        </StyledField>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: stretch;
  width: 100%;
  margin-bottom: -${dimensions.size24};
`;

const StyledField = styled(Field)`
  flex: 0;
`;
