import { abbreviateNumber } from '@common/utils';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled, { useTheme } from 'styled-components';

type Props = {
  iconName: string;
  count: number;
  label?: string;
};

export const Stat: React.FC<Props> = ({ iconName, count, label }) => {
  const theme = useTheme();

  return (
    <Container>
      <Icon
        name={iconName}
        size={rawDimensions.size20}
        color={theme.colors.fill.default['600']}
      />
      <Count>
        {abbreviateNumber(count)}
        {label ? ` ${label}` : ''}
      </Count>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${dimensions.size4};
`;

const Count = styled.div`
  ${textStyles.body.b14sb}
  display: flex;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.body};
`;
