import { Badge, Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import {
  Breadcrumb,
  DeleteModal,
  HeaderContainer,
  HeaderImage,
  HeaderMetadata,
  HeaderRowContainer,
  HeaderTitle,
  HeaderTitleContainer,
  HeaderVerticalWrapper,
  Icon,
  IconMenuItem,
  ImageWrapper,
  Menu,
  MenuButton,
  MenuList,
  ScreenContainer,
  Section,
  SectionCard,
  SectionContainer,
  Tab,
  TabBar,
  useMaestroToast,
  useQueryParam,
} from '@maestro/components';
import { ImageFallback } from '@maestro/components/ImageFallback';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { EditEpisodeModal } from '@maestro/studio/components/episode/EditEpisode/EditEpisodeModal';
import { EditSeriesModal } from '@maestro/studio/components/series/EditSeries/EditSeriesModal';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import { useNavigation } from '@refinedev/core';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyState } from '../../components/EmptyState';
import { Stat } from '../../components/Stat';
import { TableLoader } from '../../components/TableLoader';
import { EpisodeRow } from '../episode/components/EpisodeRow';
import { NewEpisodeButton } from '../episode/components/NewEpisodeButton';
import { useEpisodes } from '../episode/hooks/useEpisodes';
import { ItemsList } from '../items/ItemsList';
import { useGetSeries } from './hooks/useGetSeries';
import { SeriesRpgConfiguration } from './rpg/SeriesRpgConfiguration';

export const SeriesDetailsPage = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams();
  const seriesId = params.id!;
  const [tab, setTab] = useQueryParam({
    param: 'tab',
    defaultValue: 'episode',
  });
  const toast = useMaestroToast();
  const { series, isLoading: isSeriesLoading } = useGetSeries(seriesId);
  const {
    episodes,
    isLoading: isLoadingEpisodes,
    refetch: refetchEpisodes,
    fetchMore,
    hasMore,
  } = useEpisodes({ seriesId });
  const navigation = useNavigation();
  const isPropertiesMenuEnabled = useFeatureFlag(FeatureFlags.PropertiesMenu);

  /**
   * NOTE: This is a workaround to force a refetch when one of the edit modals is closed
   * we need to replace the use of the fetcher logic with a graphql query client in order
   * to perform optimistic cache updates.
   *
   * https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-react-query#fetcher
   */
  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const refresh = params.get('refresh');

      if (refresh === 'episodes') {
        refetchEpisodes();
      }

      if (refresh) {
        navigate(pathname);
      }
    }
  }, [search]);

  const editSeries = () => {
    navigate(
      `${pathname}?edit=series&seriesId=${seriesId}&referer=series-details`,
    );
  };

  const onDelete = async () => {
    // TODO [supabase-to-graphql]
    await toSupabaseClient().from('series').delete().eq('id', seriesId);

    toast({
      status: 'success',
      title: 'Series deleted',
    });

    navigation.replace('/my-studio');
  };

  const deleteModal = useDisclosure();

  if (isSeriesLoading) return <TableLoader />;

  if (!series) return null;

  const breadcrumb = [
    { label: 'My Studio', to: '/my-studio' },
    series.world?.title && series.world?.id
      ? { label: series.world.title, to: `/world/${series.world.id}` }
      : undefined,
    { label: series.title },
  ].filter(Boolean);

  return (
    <>
      <ScreenContainer>
        <StyledHeaderContainer>
          <ImageHeaderContainer>
            <BreadcrumbContainer>
              <Breadcrumb breadcrumb={breadcrumb as never} />
            </BreadcrumbContainer>

            {series.media?.main ? (
              <MobileSeriesHeaderImage src={series.media?.main} />
            ) : (
              <ImagePlaceholder />
            )}
            <StyledHeaderRowContainer>
              <SeriesHeaderImage>
                <ImageWrapper borderRadius={rawDimensions.size8}>
                  {series.media?.main ? (
                    <HeaderImage
                      $width={160}
                      $height={240}
                      src={toOptimizedImageUri(series.media?.main, {
                        width: rawDimensions.size160,
                        height: rawDimensions.size240,
                        dpr: window.devicePixelRatio,
                        resize: 'cover',
                      })}
                    />
                  ) : (
                    <ImageFallback
                      width={160}
                      height={240}
                      iconSize={rawDimensions.size32}
                    />
                  )}
                </ImageWrapper>
              </SeriesHeaderImage>
              <HeaderTitleContainer>
                <HeaderVerticalWrapper>
                  {!!series?.genre?.title && (
                    <Badge variant="statusActive">{series.genre.title}</Badge>
                  )}
                  <HeaderTitle aria-label="Series title">
                    {series.title}
                  </HeaderTitle>

                  <HeaderMetadata>
                    {series.authorName && (
                      <Author>
                        <AuthorAvatar />
                        <div>
                          By <AuthorName>{series.authorName}</AuthorName>
                        </div>
                      </Author>
                    )}

                    <StatSeparator />
                    <Stat iconName="play" count={series.playsCount} />
                    <StatSeparator />
                    <Stat iconName="heart" count={series.likesCount} />
                  </HeaderMetadata>
                </HeaderVerticalWrapper>
                <ButtonGroup>
                  <NewEpisodeButton seriesId={seriesId} />

                  <DeleteModal
                    isOpen={deleteModal.isOpen}
                    onClose={deleteModal.onClose}
                    variant="danger"
                    onDelete={onDelete}
                    promptTitle="Delete series"
                    promptMessage={`This will delete ${series.title} from your studio. All episodes in this series will be deleted and you won't be able to recover your work.`}
                  />

                  <Menu>
                    <MenuButton>
                      <Button
                        variant="default"
                        aria-label="More series actions"
                        width={dimensions.size40}
                        height={dimensions.size40}
                      >
                        <Icon name="ellipsis" size={rawDimensions.size16} />
                      </Button>
                    </MenuButton>
                    <MenuList>
                      <IconMenuItem
                        icon="edit"
                        name="Edit details"
                        onClick={editSeries}
                      />

                      <IconMenuItem
                        ariaLabel="Delete series"
                        icon="trash"
                        name="Delete"
                        onClick={deleteModal.onOpen}
                      />
                    </MenuList>
                  </Menu>
                </ButtonGroup>
              </HeaderTitleContainer>
            </StyledHeaderRowContainer>
          </ImageHeaderContainer>
          <StyledTabBar>
            <Tab
              onClick={() => setTab('episode')}
              isActive={!tab || tab === 'episode'}
            >
              Episodes
            </Tab>
            {!isPropertiesMenuEnabled && (
              <Tab
                isActive={tab === 'rpg-config'}
                onClick={() => setTab('rpg-config')}
              >
                RPG Configuration
              </Tab>
            )}

            <Tab isActive={tab === 'items'} onClick={() => setTab('items')}>
              Items
            </Tab>
          </StyledTabBar>
        </StyledHeaderContainer>

        <SectionContainer>
          {tab === 'episode' && (
            <Section>
              <TabSectionTitle>Episodes in this series</TabSectionTitle>
              <SectionCard>
                {isLoadingEpisodes && episodes.length === 0 ? (
                  <TableLoader />
                ) : episodes.length > 0 ? (
                  <EpisodeListContainer>
                    {episodes.map((episode, index) => (
                      <div key={episode.id}>
                        <EpisodeRow
                          key={episode.id}
                          refetch={refetchEpisodes}
                          episode={episode}
                          seriesId={seriesId}
                          order={index + 1}
                          referer="series-details"
                        />
                        {index < episodes.length - 1 && (
                          <Separator key={`episode-row-separator_${index}`} />
                        )}
                      </div>
                    ))}
                  </EpisodeListContainer>
                ) : (
                  <EmptyState
                    title="Create interactive series with episodes; you can choose from existing world bibles or create your own"
                    buttonComponent={<NewEpisodeButton seriesId={seriesId} />}
                  />
                )}
              </SectionCard>
              {hasMore && (
                <LoadMoreButtonContainer>
                  <Button
                    variant="default"
                    onClick={fetchMore}
                    isLoading={isLoadingEpisodes}
                  >
                    Load more
                  </Button>
                </LoadMoreButtonContainer>
              )}
              {!isLoadingEpisodes && episodes.length > 0 ? (
                <EpisodeListFooter>
                  <NewEpisodeButton seriesId={seriesId} />
                </EpisodeListFooter>
              ) : null}
            </Section>
          )}

          {!isPropertiesMenuEnabled && tab === 'rpg-config' && (
            <SeriesRpgConfiguration />
          )}
          {tab === 'items' && <ItemsList seriesId={seriesId} />}
        </SectionContainer>
      </ScreenContainer>
      <EditEpisodeModal />
      <EditSeriesModal />
    </>
  );
};

const StyledHeaderContainer = styled(HeaderContainer)`
  padding-bottom: ${dimensions.size0};

  @media ${breakpoints.mobile} {
    padding: ${dimensions.size0};
  }
`;

const ImageHeaderContainer = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
`;

const BreadcrumbContainer = styled.div`
  @media ${breakpoints.mobile} {
    position: absolute;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(33, 33, 44, 0) 0%,
      rgba(33, 33, 44, 0.8) 50%,
      rgba(33, 33, 44, 0.8) 100%
    );
    top: 0;
    padding: ${dimensions.size16} ${dimensions.size16} ${dimensions.size32};
  }
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  @media ${breakpoints.mobile} {
    position: absolute;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(33, 33, 44, 0.8) 0%,
      rgba(33, 33, 44, 0.8) 50%,
      rgba(33, 33, 44, 0.1) 100%
    );
    bottom: 0;
    padding: ${dimensions.size32} ${dimensions.size16} ${dimensions.size16};
  }
`;

const ImagePlaceholder = styled.div`
  display: none;
  width: 100%;
  height: 300px;
  background: ${({ theme }) => theme.colors.background.default};

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const MobileSeriesHeaderImage = styled.img`
  display: none;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 5 / 6;

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const SeriesHeaderImage = styled.div`
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const Author = styled.div`
  ${textStyles.body.b16sb}
  color: ${({ theme }) => theme.colors.text.body};
  display: flex;
  align-items: center;
  gap: ${dimensions.size8};
`;

const AuthorAvatar = styled.div`
  width: ${dimensions.size32};
  height: ${dimensions.size32};
  border-radius: ${dimensions.size999};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;

const AuthorName = styled.strong`
  ${textStyles.body.b16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const StyledTabBar = styled(TabBar)`
  margin-top: ${dimensions.size24};

  @media ${breakpoints.tablet} {
    margin-top: ${dimensions.size8};
  }
`;

const EpisodeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.default};
`;

const LoadMoreButtonContainer = styled.div`
  display: flex;
  gap: ${dimensions.size4};
  align-items: center;
  justify-content: center;
`;

const TabSectionTitle = styled.h3`
  ${textStyles.title.t24b}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Separator = styled.div`
  height: ${dimensions.size1};
  margin: ${dimensions.size24} ${dimensions.size0};
  background: ${({ theme }) => theme.colors.border.default[100]};
`;

const EpisodeListFooter = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StatSeparator = styled.div`
  &:before {
    content: '•';
  }
`;
