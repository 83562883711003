import { toOptimizedImageUri } from '@common/image';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { hudDeltaAnimation } from '../gameMessages/keyframes';
import { GameSimulator } from '../hooks/useGameSimulator';

type Props = {
  stats: GameSimulator['hud'];
};

export const PlayerMenuStats: FC<Props> = ({ stats }) => {
  const [previousStats, setPreviousStats] = useState(stats);
  const [deltas, setDeltas] = useState(stats.map((stat) => ({ [stat.id]: 0 })));

  useEffect(() => {
    if (previousStats.length > 0) {
      calculateAndAnimateDeltas();
    }
    setPreviousStats(stats);
  }, [stats]);

  const calculateAndAnimateDeltas = () => {
    const newDeltas = stats.map((stat) => {
      const previousStat = previousStats.find(
        (prevStat) => prevStat.id === stat.id,
      );
      const delta = stat.value - (previousStat?.value ?? 0);

      return { [stat.id]: delta };
    });
    setDeltas(newDeltas);

    setTimeout(() => {
      setDeltas(stats.map((stat) => ({ [stat.id]: 0 })));
    }, 2000);
  };

  if (stats.length === 0) {
    return null;
  }

  return (
    <Stats>
      {stats.map((stat) => {
        const delta = deltas.find(
          (delta) => delta.hasOwnProperty(stat.id) && delta[stat.id] !== 0,
        );
        const isPositiveDelta = !!delta && delta[stat.id] >= 0;

        return (
          <Stat key={stat.id}>
            <InfoWrapper>
              <Icon
                src={toOptimizedImageUri(stat.iconUrl, {
                  width: rawDimensions.size16,
                  height: rawDimensions.size16,
                  dpr: window.devicePixelRatio,
                })}
              />
              <Label>{stat.label}</Label>
            </InfoWrapper>
            <ValuesWrapper>
              <ValueWrapper>
                <Value>{stat.value.toLocaleString('en-US')}</Value>
                {!!delta && (
                  <Delta $isPositive={isPositiveDelta}>
                    {`${isPositiveDelta ? '+' : ''}${delta[stat.id]}`}
                  </Delta>
                )}
              </ValueWrapper>
              {stat.maxValue && (
                <MaxValue>/{stat.maxValue.toLocaleString('en-US')}</MaxValue>
              )}
            </ValuesWrapper>
          </Stat>
        );
      })}
    </Stats>
  );
};

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${dimensions.size6};
  justify-content: space-between;
  width: 100%;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size2} ${dimensions.size0};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  gap: ${dimensions.size8};
  align-items: center;
`;

const Icon = styled.img`
  width: ${dimensions.size16};
  height: ${dimensions.size16};
`;

const Label = styled.div`
  ${textStyles.body.b12sb};
  display: -webkit-box;
  flex-shrink: 1;
  width: ${dimensions.size72};
  overflow: hidden;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.header};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media ${breakpoints.tablet} {
    width: ${dimensions.size64};
  }
`;

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${dimensions.size64};
  min-width: ${dimensions.size64};
  max-width: ${dimensions.size64};
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Value = styled.div`
  ${textStyles.body.b12sb};
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.header};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Delta = styled.div<{ $isPositive: boolean }>`
  ${textStyles.body.b12sb};
  position: absolute;
  color: ${({ theme, $isPositive }) =>
    $isPositive ? 'rgb(23,172,118)' : theme.colors.fill.error['1000']};
  animation: ${hudDeltaAnimation} 0.5s ease-in-out forwards;
`;

const MaxValue = styled.div`
  ${textStyles.body.b12sb};
  flex-shrink: 1;
  color: ${({ theme }) => theme.colors.text.body};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
