import { SingleSelectNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig, ValidationError } from '../Node.types';
import { validateText } from '../validationUtils';
import { SingleSelectForm } from './SingleSelectForm';
import { SingleSelectNode } from './SingleSelectNode';

const name = 'Player choice';
const promptRules = { min: 1, max: 100 };
const optionRules = { min: 1, max: 50 };

export const singleSelectNode: NodeConfig<
  StudioNodeType.SingleSelect,
  SingleSelectNodeData
> = {
  type: StudioNodeType.SingleSelect,
  name,
  icon: 'player-choice',
  node: SingleSelectNode,
  form: SingleSelectForm,
  sourceHandles: (data) =>
    data.options.map((option) => ({
      handleId: option.id,
      name: 'option',
      description: option.text,
    })),
  getNodeData: (data) => ({ title: name, description: data.prompt }),
  cloneNodeData: ({ prompt, options }) => {
    const newOptions = options.map(({ text, nextNodeId }) => ({
      id: toNodeId(),
      text,
      nextNodeId,
    }));

    return {
      data: { id: toNodeId(), prompt, options: newOptions },
      connections: options
        .map(({ id }, index) => [
          { oldId: id, newId: newOptions[index].id },
          { oldId: `${id}-left`, newId: `${newOptions[index].id}-left` },
        ])
        .flat(),
    };
  },
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.SingleSelect,
    prompt: '',
    options: [
      {
        id: toNodeId(),
        nextNodeId: null as never,
        text: '',
      },
    ],
  }),
  validateNodeData: (data) => {
    const errors: ValidationError[] = [];

    if (!validateText(data.prompt, promptRules)) {
      errors.push({ text: data.prompt });
    }

    data.options.forEach((option) => {
      if (!validateText(option.text, optionRules)) {
        errors.push({ field: 'option', text: option.text });
      }
    });

    return errors;
  },
};
