import { DataType, ValueType, type Value } from '@common/studio-types';
import type { StatefulGameConfig } from '../../createGame/statefulGame';
import type { GameState } from '../../game';
import type { ReturnedValue } from './types';

export const getEnumValue = async (
  value: Value,
  state: GameState,
  config: StatefulGameConfig,
): Promise<ReturnedValue<string>> => {
  if (value.dataType === DataType.Enum) {
    if (value.type === ValueType.Property) {
      const property = config.getProperty(value.ref);

      if (!property || property.config.dataType !== DataType.Enum) {
        return {
          dataType: DataType.Enum,
          value: '',
          visualValue: '',
          label: '',
        };
      }

      const propertyValue = await config.getPropertyValue(state, value.ref);
      const enumItem = property.config?.options.find(
        ({ ref }) => ref === propertyValue,
      );

      return {
        dataType: DataType.Enum,
        value: enumItem?.ref ?? '',
        visualValue: enumItem?.label ?? '',
        label: property?.name ?? '',
      };
    } else if (value.type === ValueType.StaticValue) {
      const options = config.getEnumOptions(value.enumRef!);
      const enumItem = options.find(({ ref }) => ref === value.ref);

      return {
        dataType: DataType.Enum,
        value: enumItem?.ref ?? '',
        visualValue: enumItem?.label ?? '',
        label: enumItem?.label ?? '',
      };
    }

    return { dataType: DataType.Enum, value: '', visualValue: '', label: '' };
  }

  throw new Error('Should never go here');
};
