import {
  DataType,
  PropertyScope,
  RpgConfigProperty,
  StudioFlowState,
} from '@common/studio-types';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CoinTossChoice,
  EpisodeDataHudItem,
  GameState,
  Message,
  TextMessage,
  enigmaEngine,
  isCoinTossResultMessage,
  isDiceRollResultMessage,
} from '@enigma-engine/core';
import { isDeltaValue } from '@enigma-engine/core/studioFlow/game/player';
import {
  isPlayEpisodeData,
  useDrawerStore,
} from '../../../hooks/useDrawerStore';
import { useGenerateNarrationWithAi } from '../../../hooks/useGenerateNarrationWithAi';
import { Item } from '../../../hooks/useItems';
import { useStudioFlowStore } from '../../../hooks/useStudioFlowStore';

type InventoryItem = Item & {
  quantity: number;
  action?: { label: string; onUse: () => void };
};

export type GameSimulator = {
  messages: Message[];
  inventoryItems: InventoryItem[];
  playerAttrRef: MutableRefObject<Record<string, string | number>>;
  properties(): { series: RpgConfigProperty[]; episode: RpgConfigProperty[] };
  getState: () => GameState;
  setState: (
    playerAttr: Record<string, string | number>,
    state: GameState,
  ) => void;
  restart: () => void;
  hud: EpisodeDataHudItem[];
  continueEpisode: () => void;
  singleSelect: (id: string, optionId: string) => void;
  rollDice: (id: string) => void;
  playerInput: (id: string, input: string) => void;
  coinToss: (id: string, choice: CoinTossChoice) => void;
};

type Args = {
  allItems: Item[];
  flowState: StudioFlowState;
  onNodeSelect: MutableRefObject<((nodeId: string) => void) | undefined>;
};

export const useGameSimulator = (args: Args): GameSimulator => {
  const { flowState, onNodeSelect } = args;
  const { rpgConfig } = useStudioFlowStore();
  const drawerData = useDrawerStore((state) => state.data);
  const playerAttr = useRef<Record<string, string | number>>({});
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const items = useRef<Record<string, number>>({});
  const [messages, setMessages] = useState<Message[]>([]);
  const [hud, setHud] = useState<EpisodeDataHudItem[]>([]);
  const hasStarted = useRef<boolean>(false);
  const allItems = useRef<Item[]>([]);
  const [seed, setSeed] = useState(0);

  useEffect(() => {
    // we can't assign a new object, we need to keep ref
    allItems.current.splice(0, allItems.current.length);
    allItems.current.push(...args.allItems);
  }, [args.allItems]);

  const handleError = (error: Error) => {
    // eslint-disable-next-line no-console
    console.log('error', { playerAttr: playerAttr.current }, error);
  };

  const onAddMessages = useCallback(
    async (messages: Message[]) => {
      const filteredMessages = messages.filter(
        ({ type }) => type !== 'episode-progress',
      );

      for (const message of filteredMessages) {
        const nodeId = (message as TextMessage).nodeId;

        if (nodeId) onNodeSelect.current?.(nodeId);

        setMessages((msg) => [...msg, message]);
        setHud(await game.episodeData().then(({ hud }) => hud));

        if (
          isDiceRollResultMessage(message) ||
          isCoinTossResultMessage(message)
        ) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    },
    [seed],
  );

  const generateNarration = useGenerateNarrationWithAi();

  const game = useMemo(() => {
    setMessages([]);
    setInventoryItems([]);
    setHud([]);

    hasStarted.current = false;

    Object.keys(items.current).forEach((key: string) => {
      delete items.current[key];
    });

    rpgConfig.properties.forEach((attribute) => {
      const defaultValue =
        attribute.config.dataType === DataType.Number ||
        attribute.config.dataType === DataType.String
          ? attribute.config.defaultValue
          : '';

      playerAttr.current[attribute.id] = defaultValue;
    });

    const game = enigmaEngine.createGame({
      config: { rpgConfig, narratorName: '' },
      playerData: {
        generateNarration,
        getAttribute: async (ref) => playerAttr.current[ref],
        setAttribute: async (ref, value) => {
          playerAttr.current[ref] = value;
        },
        getItem: async (itemId) => {
          const item = allItems.current.find(({ id }) => id === itemId);

          return {
            id: itemId,
            name: item?.name ?? '(Unknown)',
            quantity: items.current[itemId] ?? 0,
          };
        },
        setItem: async (itemId, value) => {
          let delta: number;

          if (isDeltaValue(value)) {
            const newValue = items.current[itemId] ?? 0;
            items.current[itemId] = Math.max(0, newValue + value.delta);
            delta = value.delta;
          } else {
            items.current[itemId] = value.value;
            delta = value.value;
          }

          setInventoryItems(
            Object.keys(items.current)
              .filter((itemId) => items.current[itemId] > 0)
              .map((itemId): InventoryItem => {
                const item = allItems.current.find(({ id }) => id === itemId);
                const itemAction = rpgConfig.customItems.find(
                  ({ itemRef }) => itemRef === itemId,
                );

                const useItem = async () => {
                  await game.triggerItemAction(itemId);
                  const { hud } = await game.episodeData();
                  setHud(hud);
                };

                if (item) {
                  const label = itemAction?.label ?? '';
                  const action = itemAction
                    ? { label, onUse: () => useItem().catch(handleError) }
                    : undefined;

                  return {
                    ...item,
                    quantity: items.current[itemId],
                    action,
                  };
                }

                // will be filtered, TS is not smart enough to understand .filter(Boolean)
                return null as never;
              })
              .filter(Boolean),
          );

          return { newValue: items.current[itemId], delta };
        },
      },
      studioFlowState: flowState,
    });

    game.init();

    return game;
  }, [rpgConfig, flowState, seed]);

  useEffect(() => {
    if (hasStarted.current) return;

    hasStarted.current = true;

    const nodeId = isPlayEpisodeData(drawerData)
      ? drawerData.nodeId
      : undefined;

    game.startGame(nodeId).then(onAddMessages).catch(handleError);
  }, [game, drawerData]);

  const continueEpisode = useCallback(() => {
    game.continueEpisode().then(onAddMessages).catch(handleError);
  }, [game]);

  const singleSelect = useCallback(
    (id: string, optionId: string) => {
      game.singleSelect(id, optionId).then(onAddMessages).catch(handleError);
    },
    [game],
  );

  const playerInput = useCallback(
    (id: string, input: string) => {
      game.playerInput(id, input).then(onAddMessages).catch(handleError);
    },
    [game],
  );

  const rollDice = useCallback(
    (id: string) => {
      game.rollDices(id).then(onAddMessages).catch(handleError);
    },
    [game],
  );

  const coinToss = useCallback(
    (id: string, choice: CoinTossChoice) => {
      game
        .coinToss(id, choice)
        .then((messages: Message[]) => {
          const [message] = messages;
          const isFailedCoinToss =
            message &&
            isCoinTossResultMessage(message) &&
            !message.successful &&
            !message.confirmed;

          if (isFailedCoinToss) {
            return coinToss(message.id, CoinTossChoice.ConfirmFail);
          }

          return onAddMessages(messages);
        })
        .catch(handleError);
    },
    [game],
  );

  const setState = useCallback(
    (attr: Record<string, string | number>, state: GameState) => {
      Object.keys(playerAttr.current).forEach((key) => {
        if (attr[key] !== undefined) {
          playerAttr.current[key] = attr[key];
        } else {
          delete playerAttr.current[key];
        }
      });
      game.setState(state);
      game
        .episodeData()
        .then(({ hud }) => setHud(hud))
        .catch(handleError);
    },
    [game],
  );

  const properties = useCallback(() => {
    const seriesProps = rpgConfig.properties.filter(
      (prop) => prop.scope === PropertyScope.Series,
    );
    const episodeProps = rpgConfig.properties.filter(
      (prop) => prop.scope === PropertyScope.Episode,
    );

    return { series: seriesProps, episode: episodeProps };
  }, [rpgConfig]);

  const restart = useCallback(() => setSeed((seed) => seed + 1), []);

  return {
    messages,
    hud,
    playerAttrRef: playerAttr,
    properties,
    getState: game.getState,
    setState,
    restart,
    inventoryItems,
    continueEpisode,
    singleSelect,
    rollDice,
    playerInput,
    coinToss,
  };
};
