import { Button, Switch } from '@chakra-ui/react';
import {
  DataType,
  RpgConfig,
  RpgConfigCustomItem,
  ValueType,
} from '@common/studio-types';
import { FormControl, TextInput } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { toNodeId } from '../../toId';
import { Hint } from '../Hint';
import { ItemActionEffectForm } from './ItemActionEffectForm';

type Props = {
  seriesId: string;
  rpgConfig: RpgConfig;
  customItem?: RpgConfigCustomItem;
  onChange: (value?: RpgConfigCustomItem) => void;
};

export const ItemActionFlow: React.FC<Props> = (props) => {
  const onAddEffect = () => {
    props.onChange({
      ...props.customItem!,
      effects: [
        ...props.customItem!.effects,
        {
          action: 'set',
          changedValue: {
            type: ValueType.StaticValue,
            value: 0,
            dataType: DataType.Number,
          },
          value: undefined,
        },
      ],
    });
  };

  const onDeleteEffect = (index: number) => {
    props.onChange({
      ...props.customItem!,
      effects: props.customItem!.effects.filter((_, i) => i !== index),
    });
  };

  return (
    <>
      <Hint>
        When enabled, the player will be able to use the item from their
        inventory at any time in the episode.
      </Hint>

      <EnableContainer>
        <FormControl label="Enable item action">
          <Switch
            size="lg"
            isChecked={!!props.customItem}
            onChange={(e) => {
              const newCustomItem = {
                id: toNodeId(),
                label: 'Use',
                consumable: true,
                effects: [],
                itemRef: '',
              };
              props.onChange(e.target.checked ? newCustomItem : undefined);
            }}
          />
        </FormControl>
      </EnableContainer>

      {!!props.customItem && (
        <>
          <FormContainer>
            <EffectContainer>
              <FormControl label="Button label">
                <Hint>What will be displayed in the button.</Hint>
                <TextInput
                  value={props.customItem.label}
                  onChange={(e) => {
                    props.onChange({
                      ...props.customItem!,
                      label: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </EffectContainer>
            <EffectContainer>
              <FormControl label="Is Consumable">
                <Hint>Whether the item will be deleted after use or not.</Hint>
                <Switch
                  size="lg"
                  isChecked={props.customItem.consumable}
                  onChange={(e) => {
                    props.onChange({
                      ...props.customItem!,
                      consumable: e.target.checked,
                    });
                  }}
                />
              </FormControl>
            </EffectContainer>
          </FormContainer>

          <HeaderContainer>
            <div>
              Effects
              <Hint>
                After the player uses the item, it will apply the effects in
                order.
              </Hint>
            </div>
            <Button variant="outline" size="sm" onClick={onAddEffect}>
              Add effect
            </Button>
          </HeaderContainer>

          <EffectsContainer>
            {props.customItem.effects.length === 0 && (
              <NoResultContainer>
                <NoResult>This item has no effects yet</NoResult>
              </NoResultContainer>
            )}

            {props.customItem.effects.map((effect, index) => (
              <EffectContainer key={index}>
                <ItemActionEffectForm
                  effect={effect}
                  onDelete={() => onDeleteEffect(index)}
                  rpgConfig={props.rpgConfig}
                  onChange={(value) => {
                    props.onChange({
                      ...props.customItem!,
                      effects: props.customItem!.effects.map((v, i) =>
                        i === index ? value : v,
                      ),
                    });
                  }}
                />
              </EffectContainer>
            ))}
          </EffectsContainer>
        </>
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  ${textStyles.label.lb16b}
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${dimensions.size32};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
`;

const EnableContainer = styled.div`
  margin-bottom: ${dimensions.size16};
`;

const EffectContainer = styled.div`
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const EffectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
`;

export const NoResultContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${dimensions.size40} 0;
  text-align: center;
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

export const NoResult = styled.div`
  ${textStyles.body.b14m}
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  align-items: center;
  max-width: ${dimensions.size600};
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;
