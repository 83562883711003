import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FC, PropsWithChildren, useMemo } from 'react';

export const StudioGraphqlClientProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const queryClient = useMemo(() => {
    const queryCache = new QueryCache();
    const mutationCache = new MutationCache();

    return new QueryClient({ queryCache, mutationCache });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
