import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
  icon?: string;
};

export const MaestroSelectedInteraction: React.FC<Props> = ({ text, icon }) => (
  <SelectedContainer>
    <YouTag>You</YouTag>
    <Border>
      <Container>
        <div>{text}</div>
        {icon && <StyledIcon name={icon} size={rawDimensions.size20} />}
      </Container>
    </Border>
  </SelectedContainer>
);

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const YouTag = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.accent};
`;

const Border = styled.div`
  padding: ${dimensions.size1};

  background: linear-gradient(
    160deg,
    rgb(214, 86, 255),
    rgb(124, 156, 226),
    rgb(30, 202, 226),
    rgb(80, 162, 242),
    rgb(123, 128, 255)
  );

  border-radius: ${dimensions.size8};
  box-shadow: 0px 0px 12px rgba(224, 153, 255, 0.35);
`;

const Container = styled.div`
  ${textStyles.body.b14sb}
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background: rgba(0, 0, 0, 0.7);
  border-radius: ${dimensions.size6};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.body};
`;
