import { EnumOption } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  options: EnumOption[];
};

export const EnumValues: React.FC<Props> = ({ options }) => {
  const firstThreeOptions = options.slice(0, 3);
  const restOptions = options.length - 3;

  return (
    <Container>
      {firstThreeOptions.map((option) => (
        <EnumOptionContainer key={option.ref}>
          {option.label}
        </EnumOptionContainer>
      ))}
      {restOptions > 0 && <Other>{restOptions} more</Other>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${dimensions.size4};
`;

const EnumOptionContainer = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.base.light[100]};
  padding: ${dimensions.size4} ${dimensions.size8};
  border-radius: ${dimensions.size4};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Other = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
  margin-left: ${dimensions.size4};
`;
