import { RpgConfig, RpgConfigCustomItem } from '@common/studio-types';
import { migrateRpgConfig } from '@common/studio-types/migration';
import { toSupabaseClient } from '@maestro/supabase';
import { useCallback, useState } from 'react';

export const useGetSeriesRpgConfig = (seriesId: string) => {
  const [rpgConfig, setRpgConfig] = useState<RpgConfig | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await toSupabaseClient()
        .from('series')
        .select('rpg_config')
        .eq('id', seriesId)
        .single();

      if (data?.rpg_config) {
        setRpgConfig(migrateRpgConfig(data.rpg_config as RpgConfig));
      }
    } finally {
      setIsLoading(false);
    }
  }, [seriesId]);

  const saveItemAction = async (customItem: RpgConfigCustomItem) => {
    const { data } = await toSupabaseClient()
      .from('series')
      .select('rpg_config')
      .eq('id', seriesId)
      .single();

    if (data) {
      const rpgConfig = data.rpg_config as RpgConfig;
      const hasItemInConfig = rpgConfig.customItems?.some(
        (item) => item.itemRef === customItem.itemRef,
      );

      if (hasItemInConfig) {
        const customItems = rpgConfig.customItems.map((item) =>
          item.itemRef === customItem.itemRef ? customItem : item,
        );

        await toSupabaseClient()
          .from('series')
          .update({ rpg_config: { ...data.rpg_config, customItems } })
          .eq('id', seriesId);
      } else {
        await toSupabaseClient()
          .from('series')
          .update({
            rpg_config: {
              ...data.rpg_config,
              customItems: [...(rpgConfig.customItems ?? []), customItem],
            },
          })
          .eq('id', seriesId);
      }
    }
  };

  const deleteItemAction = async (itemId: string) => {
    const { data } = await toSupabaseClient()
      .from('series')
      .select('rpg_config')
      .eq('id', seriesId)
      .single();

    if (data) {
      const rpgConfig = data.rpg_config as RpgConfig;
      const hasItemInConfig = rpgConfig.customItems?.some(
        (item) => item.itemRef === itemId,
      );

      if (hasItemInConfig) {
        const customItems = rpgConfig.customItems.filter(
          (item) => item.itemRef !== itemId,
        );

        await toSupabaseClient()
          .from('series')
          .update({ rpg_config: { ...data.rpg_config, customItems } })
          .eq('id', seriesId);
      }
    }
  };

  return { rpgConfig, isLoading, saveItemAction, deleteItemAction, fetch };
};
