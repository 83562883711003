import { dimensions, rawDimensions } from '@maestro/styles';
import { Reorder, useDragControls } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

type Props = PropsWithChildren<{
  draggable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record?: any;
  onClick?: () => void;
}>;

export const ListItem: React.FC<Props> = (props) => {
  const { children, draggable, record, onClick } = props;
  const controls = useDragControls();

  if (draggable && record) {
    return (
      <Reorder.Item value={record} dragListener={false} dragControls={controls}>
        <HoverableListItemContainer>
          <DragHandle
            className="reorder-handle"
            onPointerDown={(e) => controls.start(e)}
          >
            <Icon name="menu" size={rawDimensions.size20} />
          </DragHandle>
          <ListItemContainer onClick={onClick}>{children}</ListItemContainer>
        </HoverableListItemContainer>
      </Reorder.Item>
    );
  }

  return (
    <HoverableListItemContainer onClick={onClick}>
      {children}
    </HoverableListItemContainer>
  );
};

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${dimensions.size48};
  cursor: pointer;
  transition: background 0.2s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HoverableListItemContainer = styled(ListItemContainer)`
  &:hover {
    background: ${({ theme }) => theme.colors.background.shade};
  }
`;

const DragHandle = styled.div`
  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size0} ${dimensions.size12};
  cursor: grab;
`;
