export type WorldLocation = {
  name: string;
  description: string;
  npcs: string;
  generatedOutput?: Record<string, unknown>;
};

export type WorldCharacter = {
  name: string;
  traits: string;
  goals: string;
  relationships: string;
  generatedOutput?: Record<string, unknown>;
};

export type CreateWorldFields = {
  title?: string;
  story?: string;
  coverImage?: string;
};

export enum CREATE_WORLD_STEPS {
  BASE_FIELDS = 'base_fields',
  NPCS = 'npcs',
  LOCATIONS = 'locations',
}

export type GenerateNPCResponse = {
  characters: {
    name: string;
    physicalDescription: string;
    fears: string;
    personality: string;
    goals: string;
    motivations: string;
    relationships: string;
  }[];
};

export type GeneratedLocationResponse = {
  locations: {
    name: string;
    description: string;
    npcs: string;
    lighting: string;
    colors: string;
    sights: string;
    sounds: string;
    smells: string;
    tastes: string;
    weather: string;
    appearance: string;
    layout: string;
    densityOfPeople: string;
  }[];
};
