import { Badge } from '@chakra-ui/react';
import { EndEpisodeNodeData } from '@common/studio-types';
import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { endEpisodeActions } from './utils';

type Props = NodeProps<EndEpisodeNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      title="End episode"
      selected={selected}
      nodeId={id}
      node={data}
      tag="End"
    >
      <Container>
        <Badge variant="small">
          {endEpisodeActions.find(({ value }) => data.action === value)?.label}
        </Badge>
        {data.message}
      </Container>
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
    </BaseStudioNode>
  );
};

export const EndEpisodeNode = memo(Node);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: start;
`;
