/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PropertyScope,
  RpgConfig,
  RpgConfigCustomItem,
  RpgConfigProperty,
} from '../..';
import { MigrateRpgConfig } from '../migration.types';
import { migrateProperty, remapValue } from '../v0tov2.utils';

export const v0tov1: MigrateRpgConfig = (config: RpgConfig): RpgConfig => {
  if (config.version >= 1) {
    // no need to migrate the config
    return config;
  }

  const oldConfig = config as any;

  const seriesProperties =
    oldConfig.characterAttributes?.map((property: any): RpgConfigProperty => {
      return migrateProperty(property, PropertyScope.Series);
    }) ?? [];

  const episodeProperties = oldConfig.properties.map(
    (property: any): RpgConfigProperty => {
      return migrateProperty(property, PropertyScope.Episode);
    },
  );

  const earlyRpgConfig: any = {
    version: 2,
    hud: [],
    customItems: [],
    customTypes: oldConfig.customTypes ?? [],
    properties: [...seriesProperties, ...episodeProperties],
  };

  const customItems =
    config.customItems?.map((item: RpgConfigCustomItem) => {
      item.effects.forEach((effect) => {
        effect.changedValue = remapValue(effect.changedValue, earlyRpgConfig);
        effect.value = effect.value
          ? remapValue(effect.value, earlyRpgConfig)
          : undefined;
      });

      return item;
    }) ?? [];

  const hud = config.hud?.map((hudItem) => ({
    ...hudItem,
    value: remapValue(hudItem.value, earlyRpgConfig),
  }));

  return {
    ...oldConfig, // keeping old data there
    version: 1,
    hud: hud ?? [],
    customItems: customItems,
    customTypes: oldConfig.customTypes ?? [],
    properties: [...seriesProperties, ...episodeProperties],
  };
};
