import { Badge } from '@chakra-ui/react';
import { ConditionCheckNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueBadge } from '../../components/value/ValueBadge';
import { operators } from './conditionCheck';

type Props = {
  className?: string;
  data: ConditionCheckNodeData;
};

export const Condition: React.FC<Props> = ({ data, className }) => {
  return (
    <Container className={className}>
      <ValueBadge
        value={data.valueA}
        badgeProps={{
          variant: 'circle',
          borderRadius: `${dimensions.size12} 0 0 ${dimensions.size12}`,
        }}
      />
      <Badge variant="value" borderRadius={dimensions.size0} fontFamily="mono">
        {operators.find(({ value }) => value === data.operator)?.label}
      </Badge>
      {data.valueB && (
        <ValueBadge
          value={data.valueB}
          badgeProps={{
            variant: 'value',
            borderRadius: `0 ${dimensions.size12} ${dimensions.size12} 0`,
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.header};
`;
