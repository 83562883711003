export type FetchErrorType = 'network' | 'auth' | 'server';

export class FetchError extends Error {
  errorType: FetchErrorType;

  constructor(message: string, errorType: FetchErrorType) {
    super(message);
    this.errorType = errorType;
  }
}
