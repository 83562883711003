import { useRef, useCallback } from 'react';

/**
 * This hook will receive a callback and only call it once in the span
 * of 1 second. Any subsequent calls within this span will be ignored.
 *
 * @param callback to be executed once
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useSingleExecWithinSpan = <T extends Function>(callback: T): T => {
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any) => {
      if (!debounceTimeout.current) {
        callback(...args);
      } else {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        debounceTimeout.current = undefined;
      }, 1000);
    },
    [callback],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
};
