import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';
import { GameSimulator } from '../hooks/useGameSimulator';

type Props = {
  game: GameSimulator;
};

export const MaestroReplayButton: React.FC<Props> = ({ game }) => {
  return (
    <Button
      onClick={game.restart}
      height={dimensions.size44}
      leftIcon={
        <Icon name="arrow-counterclockwise" size={rawDimensions.size20} />
      }
      variant="default"
    >
      Replay
    </Button>
  );
};
