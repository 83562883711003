import { EndEpisodeAction } from '@common/studio-types';

export const endEpisodeActions = [
  {
    label: 'Play Next Episode',
    value: EndEpisodeAction.PlayNextEpisode,
  },
  {
    label: 'Restart Episode',
    value: EndEpisodeAction.RestartEpisode,
  },
  {
    label: 'Restart Series',
    value: EndEpisodeAction.RestartSeries,
  },
];
