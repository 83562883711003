import { Button } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  button?: {
    label: string;
    onClick: () => void;
  };
  buttonComponent?: React.ReactNode;
};

export const EmptyState: React.FC<Props> = (props) => (
  <Container>
    <div>{props.title}</div>
    {props.buttonComponent}
    {props.button && (
      <Button variant="default" size="sm" onClick={props.button.onClick}>
        {props.button.label}
      </Button>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  align-items: center;
  justify-content: center;
  margin: ${dimensions.size0} auto;
  padding: ${dimensions.size32};
  max-width: ${dimensions.size560};
  text-align: center;
`;
