import { Button } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfigProperty,
  StaticValue,
  ValueType,
} from '@common/studio-types';
import { Field } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { GameState } from '@enigma-engine/core';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { Hint } from '../Hint';
import { StaticValueInput } from '../value/StaticValueInput';
import { slideInAnimation, slideOutAnimation } from './gameMessages/keyframes';
import { GameSimulator } from './hooks/useGameSimulator';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  game: GameSimulator;
};

export const SimulatorPropertiesForm: React.FC<Props> = (props) => {
  const { rpgConfig } = useStudioFlowStore();
  const [playerAttr, setPlayerAttr] = useState<Record<string, string | number>>(
    {},
  );
  const [state, setState] = useState<GameState>(props.game.getState());
  const properties = useMemo(() => props.game.properties(), [props.game]);
  const [isVisible, setIsVisible] = useState(false);

  const onApply = () => {
    props.game.setState(playerAttr, state);
    props.onClose();
  };

  useEffect(() => {
    // necessary to not show the closing animation when mounted
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  useEffect(() => {
    setPlayerAttr(props.game.playerAttrRef.current);
    setState(props.game.getState());
  }, [props.isOpen]);

  const setStaticValue = (
    staticValue: StaticValue,
    attribute: RpgConfigProperty,
  ) => {
    const value =
      staticValue.dataType === DataType.Enum
        ? staticValue.ref
        : staticValue.value;

    if (attribute.scope === PropertyScope.Series) {
      setPlayerAttr({ ...playerAttr, [attribute.id]: value });
    } else if (attribute.scope === PropertyScope.Episode) {
      setState({
        ...state,
        properties: { ...state.properties, [attribute.id]: value },
      });
    }
  };

  const getValue = (attribute: RpgConfigProperty): StaticValue => {
    const value =
      attribute.scope === PropertyScope.Series
        ? playerAttr[attribute.id]
        : state.properties[attribute.id];

    if (attribute.config.dataType === DataType.Enum) {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.Enum,
        ref: (value as string) ?? '',
        enumRef: attribute.config.enumRef,
      };
    } else if (attribute.config.dataType === DataType.String) {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.String,
        value: (value as string) ?? '',
      };
    } else {
      return {
        type: ValueType.StaticValue,
        dataType: DataType.Number,
        value: (value as number) ?? 0,
      };
    }
  };

  return (
    <Container
      className={props.isOpen ? 'open' : 'closed'}
      $visible={isVisible}
    >
      <FormContainer>
        <StyledHint>
          Check and change property values at any time during the episode
          gameplay.
        </StyledHint>
        {properties.series.map((attribute) => (
          <StyledField label={attribute.name} key={attribute.id}>
            <StaticValueInput
              onChange={(value) => setStaticValue(value, attribute)}
              value={getValue(attribute)}
              rpgConfig={rpgConfig}
            />
          </StyledField>
        ))}
        {properties.episode.map((attribute) => (
          <StyledField label={attribute.name} key={attribute.id}>
            <StaticValueInput
              onChange={(value) => setStaticValue(value, attribute)}
              value={getValue(attribute)}
              rpgConfig={rpgConfig}
            />
          </StyledField>
        ))}
      </FormContainer>

      <ButtonGroup>
        <Button variant="primary" width="100%" onClick={onApply}>
          Apply
        </Button>
        <CloseButton onClick={props.onClose}>Back to episode</CloseButton>
      </ButtonGroup>
    </Container>
  );
};

const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  z-index: 11;

  background: ${({ theme }) => theme.colors.base.dark['1000']};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  &.open {
    animation: ${slideInAnimation} 0.2s ease forwards;
  }

  &.closed {
    animation: ${slideOutAnimation} 0.2s ease forwards;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${dimensions.size16};
  gap: ${dimensions.size8};
  width: 100%;
  overflow: auto;
`;

const StyledField = styled(Field)`
  margin-bottom: ${dimensions.size0};
  flex: 0;
`;

const StyledHint = styled(Hint)`
  margin-bottom: ${dimensions.size0};
`;

const ButtonGroup = styled.div`
  ${textStyles.label.lb16sb};
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size16};
  cursor: pointer;
  text-align: center;

  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default['100']};
`;

const CloseButton = styled.div`
  ${textStyles.label.lb16sb};
  width: 100%;

  color: ${({ theme }) => theme.colors.text.body};
  padding: ${dimensions.size12};
  cursor: pointer;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.text.header};
  }
`;
