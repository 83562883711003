import {
  DataType,
  PropertyScope,
  RpgConfigProperty,
} from '@common/studio-types';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

const uuid = () => window.crypto.randomUUID();

type Props = {
  onCreateProperty: (prop: RpgConfigProperty) => void;
};

type PropertyConfig = {
  icon: string;
  label: string;
  onCreate: () => RpgConfigProperty;
};

const properties: PropertyConfig[] = [
  {
    icon: 'number-type',
    label: 'health',
    onCreate: () => {
      return {
        id: uuid(),
        name: 'health',
        scope: PropertyScope.Series,
        config: { dataType: DataType.Number, defaultValue: 100 },
      };
    },
  },
  {
    icon: 'number-type',
    label: 'strength',
    onCreate: () => {
      return {
        id: uuid(),
        name: 'strength',
        scope: PropertyScope.Series,
        config: { dataType: DataType.Number, defaultValue: 10 },
      };
    },
  },
  {
    icon: 'text-type',
    label: 'character.name',
    onCreate: () => {
      return {
        id: uuid(),
        name: 'character.name',
        scope: PropertyScope.Series,
        config: { dataType: DataType.String, defaultValue: '' },
      };
    },
  },
  {
    icon: 'text-type',
    label: 'location.name',
    onCreate: () => {
      return {
        id: uuid(),
        name: 'location.name',
        scope: PropertyScope.Series,
        config: { dataType: DataType.String, defaultValue: '' },
      };
    },
  },
  {
    icon: 'enum-type',
    label: 'character.class',
    onCreate: () => {
      const ref = uuid();

      return {
        id: ref,
        name: 'character.class',
        scope: PropertyScope.Series,
        config: {
          dataType: DataType.Enum,
          enumRef: ref,
          options: [
            { ref: uuid(), label: 'Warrior' },
            { ref: uuid(), label: 'Mage' },
            { ref: uuid(), label: 'Rogue' },
            { ref: uuid(), label: 'Cleric' },
          ],
        },
      };
    },
  },
  {
    icon: 'enum-type',
    label: 'character.race',
    onCreate: () => {
      const ref = uuid();

      return {
        id: ref,
        name: 'character.race',
        scope: PropertyScope.Series,
        config: {
          dataType: DataType.Enum,
          enumRef: ref,
          options: [
            { ref: uuid(), label: 'Human' },
            { ref: uuid(), label: 'Dwarf' },
            { ref: uuid(), label: 'Elf' },
            { ref: uuid(), label: 'Orc' },
          ],
        },
      };
    },
  },
];

export const PropertiesFormQuickAdd: React.FC<Props> = (props) => {
  const { onCreateProperty } = props;

  return (
    <QuickAdd>
      <QuickAddTitle>Quick Add</QuickAddTitle>
      <QuickAddButtons>
        {properties.map((property) => (
          <QuickAddButton
            key={property.label}
            onClick={() => onCreateProperty(property.onCreate())}
          >
            <Icon name={property.icon} size={rawDimensions.size20} />
            {property.label}
          </QuickAddButton>
        ))}
      </QuickAddButtons>
    </QuickAdd>
  );
};

const QuickAdd = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  padding: ${dimensions.size0} ${dimensions.size16} ${dimensions.size16}
    ${dimensions.size16};
  text-align: center;
  max-width: ${dimensions.size600};
  margin: 0 auto;
`;

const QuickAddButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const QuickAddButton = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.header};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${dimensions.size4};
  border-radius: ${dimensions.size999};
  background: ${({ theme }) => theme.colors.background.shade};
  padding: ${dimensions.size6} ${dimensions.size12} ${dimensions.size6}
    ${dimensions.size12};
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.base.light[100]};
  }
`;

const QuickAddTitle = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.body};
`;
