import {
  DiscoverySectionFragment,
  DiscoverySectionSeriesFragment,
  DiscoverySectionType,
} from '@maestro/graphql';

export type DiscoverySectionItem<TData = unknown> = {
  id: string;
  name: string;
  order: number;
  type: DiscoverySectionType;
  payload: TData;
};

export type SectionSeries =
  DiscoverySectionItem<DiscoverySectionSeriesFragment>;

export const toItem = (
  message: DiscoverySectionFragment,
): DiscoverySectionItem<unknown> => {
  return message as unknown as DiscoverySectionItem<unknown>;
};

export const isSeriesSection = (
  message: DiscoverySectionItem<unknown>,
): message is SectionSeries => {
  return message.type === DiscoverySectionType.Series;
};
