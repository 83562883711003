import { Button, Tooltip } from '@chakra-ui/react';
import {
  Icon,
  IconMenuItem,
  Menu,
  MenuButton,
  MenuList,
} from '@maestro/components';
import { env } from '@maestro/env';
import { rawDimensions } from '@maestro/styles';
import { useParams } from 'react-router-dom';
import { useDrawerStore } from '../hooks/useDrawerStore';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';

const baseUrl = `https://appetize.io/embed/${env.VITE_APPETIZE_EMULATOR_PUBLIC_KEY}`;

export const PlayEpisodeButton = () => {
  const { id: episodeId } = useParams();
  const { seriesId } = useStudioFlowStore();
  const { openDrawer } = useDrawerStore();

  const playOnEmulator = () => {
    const queryParams = {
      device: 'iphone15pro',
      osVersion: '17.2',
      scale: 'auto',
      screenOnly: 'true',
      autoplay: 'true',
      params: JSON.stringify({ episodeId, seriesId }),
    };
    window.open(
      `${baseUrl}?${new URLSearchParams(queryParams).toString()}`,
      '_blank',
      `width=320,height=694,left=${window.screenLeft + 20},top=${window.screenTop + 20}`,
    );
  };
  const playOnBrowser = () => openDrawer('play-episode');

  return (
    <Menu>
      <MenuButton>
        <Tooltip label="Play episode" placement="bottom-end">
          <Button
            variant="primary"
            borderRadius={rawDimensions.size8}
            padding={rawDimensions.size0}
          >
            <Icon name="play" size={rawDimensions.size16} />
          </Button>
        </Tooltip>
      </MenuButton>
      <MenuList>
        <IconMenuItem
          icon="desktop-phone-filled"
          name="Play in browser"
          onClick={playOnBrowser}
        />
        <IconMenuItem
          icon="phone-filled"
          name="Play in iPhone emulator"
          onClick={playOnEmulator}
        />
      </MenuList>
    </Menu>
  );
};
