import React, { forwardRef } from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  size: number;
  className?: string;
  color?: string;
};

export const Icon: React.FC<Props> = forwardRef(
  (props, ref?: React.ForwardedRef<never>) => {
    return (
      <StyledIcon
        ref={ref}
        className={`${props.className ?? ''} icon-${props.name}`}
        size={props.size}
        $color={props.color}
      />
    );
  },
);

const StyledIcon = styled.span<{ size: number; $color?: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => size}px;
  color: ${({ $color }) => $color ?? 'inherit'};
`;
