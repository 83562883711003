import { Button, Spinner } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type ScreenProps = PropsWithChildren<{
  title?: string;
  buttons?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  backPath?: string | VoidFunction;
}>;

export const Screen: React.FC<ScreenProps> = (props) => {
  const navigation = useNavigation();
  const { className, title, children, buttons, isLoading } = props;

  return (
    <Container className={className}>
      {isLoading && (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
      {title || buttons ? (
        <Header>
          <Title>
            {props.backPath && (
              <Button
                onClick={() =>
                  typeof props.backPath === 'string'
                    ? navigation.replace(props.backPath)
                    : props.backPath!()
                }
                variant="icon"
                marginRight={dimensions.size8}
              >
                <Icon name="arrow-left" size={rawDimensions.size20} />
              </Button>
            )}
            {title}
          </Title>
          {buttons}
        </Header>
      ) : null}
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: ${dimensions.size8};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${dimensions.size16};
`;

const Title = styled.div`
  ${textStyles.title.t24b}
  color: ${({ theme }) => theme.colors.text.header};
`;
