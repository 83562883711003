/**
 * Abbreviates a number to a more readable format using suffixes for thousands (K),
 * millions (M), billions (B), and trillions (T).
 *
 * @param {number} number - The number to abbreviate.
 * @param {number} [digits=1] - The number of decimal places to include in the abbreviated number.
 * @returns {string} - The abbreviated number as a string with an appropriate suffix.
 *
 * @example
 * abbreviateNumber(1234); // "1.2K"
 * abbreviateNumber(1234567, 2); // "1.23M"
 * abbreviateNumber(9876543210); // "9.9B"
 * abbreviateNumber(100); // "100"
 */
export const abbreviateNumber = (
  number: number,
  digits: number = 1,
): string => {
  if (number < 1000) return number.toLocaleString();
  const valueSymbolLookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const i = Math.floor(Math.log(number) / Math.log(1000));
  const index = Math.min(i, valueSymbolLookup.length - 1); // Ensure index is within bounds

  return (
    (number / valueSymbolLookup[index].value)
      .toFixed(digits)
      .replace(rx, '$1') + valueSymbolLookup[index].symbol
  );
};
