import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { DiceSvg, DiceType } from './DiceSvg';

type Props = {
  id: string;
  result?: number;
};

export const Dice: React.FC<Props> = ({ id, result }) => {
  return (
    <Container>
      <DiceWrapper>
        <DiceSvg
          size={64}
          type={id as DiceType}
          multipliers={{ saturation: 1, lightness: 1 }}
        />
        <ResultWrapper>{result && <Result>{result}</Result>}</ResultWrapper>
      </DiceWrapper>
      <Label>{id.toUpperCase()}</Label>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ResultWrapper = styled.div`
  position: absolute;
  top: ${dimensions.size0};
  right: ${dimensions.size0};
  bottom: ${dimensions.size0};
  left: ${dimensions.size0};
  height: ${dimensions.size20};
  margin-top: 22px;
`;

const Result = styled.div`
  ${textStyles.label.lb16sb};
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const Label = styled.div`
  ${textStyles.body.b12sb};
  color: ${({ theme }) => theme.colors.fill.default[900]};
  text-align: center;
`;
