import { Spinner } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

export const TableLoader: React.FC = () => (
  <Container>
    <Spinner />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${dimensions.size112};
`;
