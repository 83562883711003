import { useNavigation } from '@refinedev/core';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type Args = {
  param: string;
  defaultValue?: string;
};

export const useQueryParam = ({
  param,
  defaultValue,
}: Args): [string | undefined, (value?: string, replace?: boolean) => void] => {
  const { pathname, search } = useLocation();
  const navigation = useNavigation();

  const value = useMemo(() => {
    const parsed = new URLSearchParams(search);

    return parsed.get(param);
  }, [search]);

  const changeParam = (value?: string, replace?: boolean) => {
    const parsed = new URLSearchParams(search);

    if (value === undefined) {
      parsed.delete(param);
    } else {
      parsed.set(param, value);
    }

    const newValue = pathname + '?' + parsed.toString();

    if (replace) {
      navigation.replace(newValue);
    } else {
      navigation.push(newValue);
    }
  };

  return [value ?? defaultValue, changeParam];
};
