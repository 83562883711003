import { dimensions } from '@maestro/styles';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ModalHeader } from './ModalHeader';

type Props = PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
}>;

export const StackDialog: React.FC<Props> = (props) => {
  const { isOpen, children, onClose, title } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // necessary to not show the closing animation when mounted
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  return (
    <Container className={isOpen ? 'open' : 'closed'} $visible={isVisible}>
      <ModalHeader title={title} showChevronButton onClose={onClose} />
      {isOpen && children}
    </Container>
  );
};

const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
    display: flex;
  }
  to {
    transform: translateX(0);
    display: flex;
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
    display: flex;
  }
  to {
    transform: translateX(100%);
    display: none;
  }
`;

const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 13;
  border-radius: ${dimensions.size8};
  background: ${({ theme }) => theme.colors.base.dark['1000']};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  overflow: hidden;

  &.open {
    animation: ${slideInAnimation} 0.2s ease forwards;
  }

  &.closed {
    animation: ${slideOutAnimation} 0.2s ease forwards;
  }
`;
