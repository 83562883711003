import { env } from '@maestro/env';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

type Config = {
  version: number;
  forceUpdate: boolean;
};

const fetchAndHandleNewVersion = async (
  onNewVersion: (forceUpdate: boolean) => void,
) => {
  try {
    const { data: config } = await axios.get<Config>(`/config.json`);

    if (config.version !== env.VITE_MAESTRO_VERSION) {
      // eslint-disable-next-line no-console
      console.log(
        `[Version Skew]: New version (${config.version}) available, force update: ${config.forceUpdate}`,
      );
      onNewVersion(config.forceUpdate);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('[Version Skew]: Failed to check config for updated version');
  }
};

/**
 *
 * This component detects version skew in the Maestro app by looking at the
 * environments latest `config.json` file, which will hold the latest published app version.
 *
 * This component will refetch the latest version at the following intervals:
 * 1. Every 5 minutes
 * 2. On every route change
 * 3. On every tab visibility re-gain (after a user leaves and comes back to the tab)
 *
 * By default, if the versions are different, the app will refresh on the _next_ user
 * navigation action.
 *
 * If the new config has a `forceUpdate` value of `true` at the time it is fetched,
 * the app will immediately refresh.
 *
 */
export const useCheckForVersionSkew = () => {
  const location = useLocation();
  const intervalRef = useRef<NodeJS.Timeout>();
  // NOTE: the reload value is irrelevant to rendering; ignore it for that case
  const reloadOnPathChangeRef = useRef(false);

  const onNewVersion = (forceUpdate: boolean) => {
    if (forceUpdate) {
      window.location.reload();
    } else {
      reloadOnPathChangeRef.current = true;
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      fetchAndHandleNewVersion(onNewVersion).catch(() => void 0);
    }
  };

  // Check for new version on window visibility change and every 5 minutes
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`[Version Skew]: Version is ${env.VITE_MAESTRO_VERSION}`);

    window.addEventListener('visibilitychange', handleVisibilityChange, false);
    intervalRef.current = setInterval(
      () => {
        fetchAndHandleNewVersion(onNewVersion).catch(() => void 0);
      },
      1000 * 60 * 5,
    );

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    fetchAndHandleNewVersion(onNewVersion).catch(() => void 0);

    if (reloadOnPathChangeRef.current) {
      window.location.reload();
    }
  }, [location.pathname]);
};
