import {
  CharacterCreationNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { CharacterCreationNode } from './CharacterCreationNode';

const name = 'Character creation';

export const characterCreationNode: NodeConfig<
  StudioNodeType.CharacterCreation,
  CharacterCreationNodeData
> = {
  type: StudioNodeType.CharacterCreation,
  icon: 'character-creation',
  name,
  node: CharacterCreationNode,
  episodeRef: 'onboarding',
  form: null as never,
  getNodeData: () => ({ title: name }),
  cloneNodeData: () => ({ data: { id: toNodeId() } }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.CharacterCreation,
  }),
};
