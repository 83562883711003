import { toOptimizedImageUri } from '@common/image';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ImageFallback } from './ImageFallback';

type Props = {
  aspectRatio: number;
  image?: string | undefined | null;
  title: string;
  description: React.ReactNode;
};

export const CmsCard: React.FC<Props> = (props) => {
  const { aspectRatio, image, title, description } = props;
  const width = 200;
  const height = width * aspectRatio;

  return (
    <CardContainer>
      {image ? (
        <ImageWrapper>
          <Image
            $width={width}
            $height={height}
            src={toOptimizedImageUri(image, {
              width,
              height,
              dpr: window.devicePixelRatio,
              resize: 'cover',
            })}
          />
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          <StyledImageFallback
            width={width}
            height={height}
            iconSize={rawDimensions.size32}
          />
        </ImageWrapper>
      )}
      <DetailsContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DetailsContainer>
    </CardContainer>
  );
};

const Image = styled.img<{ $width: number; $height: number }>`
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  object-fit: cover;

  border-radius: ${dimensions.size8} ${dimensions.size8} ${dimensions.size0}
    ${dimensions.size0};
  transition: transform 0.2s;
`;

const StyledImageFallback = styled(ImageFallback)`
  transition: transform 0.2s;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${dimensions.size200};
  min-width: ${dimensions.size200};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  position: relative;
  overflow: hidden;

  &:before {
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: ${dimensions.size8};
    position: absolute;
    content: ' ';
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: transparent;
    border: ${dimensions.size1} solid
      ${({ theme }) => theme.colors.border.default[100]};
  }

  &:hover ${Image} {
    transform: scale(1.1);
  }

  &:hover ${StyledImageFallback} {
    transform: scale(1.1);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${dimensions.size8} ${dimensions.size8} ${dimensions.size0}
    ${dimensions.size0};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  padding: ${dimensions.size12};
`;

const Title = styled.div`
  ${textStyles.label.lb14sb}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.header};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  ${textStyles.label.lb12sb}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
