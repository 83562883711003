import {
  Badge,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { getFormattedDate } from '../../../utils/getFormattedDate';

type Props = {
  hasNewVersion: boolean;
  isDraft: boolean;
  isPublished: boolean;
  publishedAt?: string;
  displayPublished?: boolean;
  isStudioEpisodeCard?: boolean;
};

export const EpisodeStatus: React.FC<Props> = ({
  hasNewVersion,
  isDraft,
  isPublished,
  publishedAt,
  displayPublished = true,
  isStudioEpisodeCard,
}) => {
  const toBadgeVariant = (active: boolean) =>
    active
      ? isStudioEpisodeCard
        ? 'myStudioEpisodeCardActive'
        : 'statusActive'
      : isStudioEpisodeCard
        ? 'myStudioEpisodeCardInactive'
        : 'statusInactive';

  const publishedBadge = publishedAt ? (
    <Popover matchWidth trigger="hover">
      <PopoverTrigger>
        <Badge variant={toBadgeVariant(true)}>Published</Badge>
      </PopoverTrigger>
      {!isStudioEpisodeCard && (
        <StyledPopoverContent>
          <PopoverBody>
            <PublishedAtLabel>
              {!!publishedAt
                ? `Last published ${getFormattedDate(publishedAt, 'MM/dd/yy')}
                `
                : 'no data'}
            </PublishedAtLabel>
          </PopoverBody>
        </StyledPopoverContent>
      )}
    </Popover>
  ) : (
    <Badge variant={toBadgeVariant(true)}>Published</Badge>
  );

  if (isPublished && !hasNewVersion) {
    return displayPublished ? publishedBadge : null;
  }

  if (isPublished && hasNewVersion) {
    return (
      <BadgeList>
        {displayPublished && publishedBadge}
        <Badge variant={toBadgeVariant(false)}>
          <BadgeRow>
            <Icon name="arrow-split-right" size={rawDimensions.size16} />
            New version
          </BadgeRow>
        </Badge>
      </BadgeList>
    );
  }

  if (isDraft) {
    return (
      <Badge variant={toBadgeVariant(false)}>
        <BadgeRow>
          <Icon name="edit-drafts" size={rawDimensions.size16} />
          Draft
        </BadgeRow>
      </Badge>
    );
  }

  return null;
};

const BadgeList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${dimensions.size8};
  align-items: center;
`;

const BadgeRow = styled.div`
  display: flex;
  gap: ${dimensions.size4};
  align-items: center;
`;

const PublishedAtLabel = styled.div`
  ${textStyles.label.lb12b}
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const StyledPopoverContent = styled(PopoverContent)`
  max-width: ${dimensions.siz180};
  padding: ${dimensions.size4} ${dimensions.size8};
`;
