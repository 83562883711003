import { Loader } from '@maestro/components';
import styled from 'styled-components';
import { useItems } from '../../hooks/useItems';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { PlayEpisodeSimulator } from './PlayEpisodeSimulator';

type Props = {
  isPropertiesOpen: boolean;
  onPropertiesClose: () => void;
};

export const PlayEpisodeItemsSimulator: React.FC<Props> = (props) => {
  const { seriesId } = useStudioFlowStore();
  const { items: allItems, isLoading } = useItems({ seriesId });

  if (isLoading) {
    return (
      <CenterLoader>
        <Loader label="Loading..." />
      </CenterLoader>
    );
  }

  return <PlayEpisodeSimulator {...props} allItems={allItems} />;
};

const CenterLoader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
