import type { Message } from '../game/messages.types';
import { StatefulGame } from './statefulGame';

export const startGame = async (
  game: StatefulGame,
  startNodeId?: string,
): Promise<Message[]> => {
  if (startNodeId && game.gameNodes.nodeById(startNodeId)) {
    return game.execute(startNodeId);
  }

  const startNode = game.gameNodes.startNode();

  if (!startNode) {
    return [game.errorMessage('No start node found')];
  }

  return game.execute(startNode.id);
};
