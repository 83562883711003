import React, { useMemo } from 'react';
import { TickMark } from './TickMark';

type Props = {
  size: number;
  percentage: number;
  activeColor: string;
  defaultColor: string;
  tickLength?: number;
  tickCount?: number;
  tickThickness?: number;
  clockwise?: boolean;
};

const toPointOnCircle = (
  centerX: number,
  centerY: number,
  radius: number,
  angle: number,
) => {
  const x = centerX + radius * Math.cos(angle);
  const y = centerY + radius * Math.sin(angle);

  return { x, y };
};

const createPaths = (
  size: number,
  tickCount: number,
  tickLength: number,
  clockwise: boolean,
) => {
  const radius = size / 2;
  const centerX = size / 2;
  const centerY = size / 2;

  const tickMarks = [];

  for (let i = 0; i < tickCount; i++) {
    const angleBetweenTicks = 360 / tickCount;
    /**
     * Rotating the angle 90° to start from the top and go clockwise unless flipped horizontally
     * subtracting the angleBetweenTicks from (-)90° so key values like 50% are straight down
     * and 25% and 75% are straight right and left
     */
    const angleDegrees =
      (i * angleBetweenTicks - (clockwise ? 90 : -90 - angleBetweenTicks)) *
      (clockwise ? 1 : -1);
    const angleRadians = (angleDegrees * Math.PI) / 180;

    const start = toPointOnCircle(centerX, centerY, radius, angleRadians);
    const end = toPointOnCircle(
      centerX,
      centerY,
      radius - tickLength,
      angleRadians,
    );

    tickMarks.push(`M${start.x} ${start.y} L${end.x} ${end.y}`);
  }

  return tickMarks;
};

export const ProbabilityCircle: React.FC<Props> = ({
  size,
  percentage,
  activeColor,
  defaultColor,
  tickLength = 20,
  tickCount = 144,
  tickThickness = 1,
  clockwise = false,
}) => {
  const paths = useMemo(
    () => createPaths(size, tickCount, tickLength, clockwise),
    [size, tickCount, tickLength, clockwise],
  );

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      {paths.map((path, index) => (
        <TickMark
          key={index}
          {...{
            index,
            path,
            percentage,
            activeColor,
            defaultColor,
            tickCount,
            tickThickness,
          }}
        />
      ))}
    </svg>
  );
};
