import { create } from 'zustand';
import { SuggestionsSection } from '../../components/suggestions/suggestion.types';

type SuggestionsStore = {
  suggestions: SuggestionsSection[];
  setSuggestions: (suggestions: SuggestionsSection[]) => void;
};

export const useSuggestionsStore = create<SuggestionsStore>()((set) => ({
  suggestions: [],
  setSuggestions: (suggestions) => set((state) => ({ ...state, suggestions })),
}));
