import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { ImageInput, TextInput, Textarea } from '@maestro/components';
import React from 'react';
import { Hint } from '../../../../components/Hint';
import { CreateWorldFields } from '../../types';

type Props = {
  submitPressed: boolean;
  world: CreateWorldFields;
  onChange: (world: CreateWorldFields) => void;
};

export const WorldBaseFieldsStep: React.FC<Props> = ({
  world,
  submitPressed,
  onChange,
}) => {
  return (
    <>
      <Hint>
        Create a world bible to establish the universe for your story. The more
        detail you add, the more rich your story will be.
      </Hint>
      <FormControl mb="3" isInvalid={submitPressed && !world.title}>
        <FormLabel>Title</FormLabel>
        <TextInput
          maxCharacters={50}
          value={world.title}
          onChange={(event) =>
            onChange({ ...world, title: event.target.value })
          }
        />
        <FormErrorMessage>
          {submitPressed && !world.title && 'This field is required'}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={submitPressed && !world.story}>
        <FormLabel>World overview</FormLabel>
        <Textarea
          value={world.story}
          onChange={(event) =>
            onChange({ ...world, story: event.target.value })
          }
          placeholder="Paste your story here. Make sure you include locations, protraits, plot, and any other details vital to create your world bible."
        />
        <FormErrorMessage>
          {submitPressed && !world.story && 'This field is required'}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={submitPressed && !world.coverImage}>
        <FormLabel>World Image</FormLabel>
        <ImageInput
          value={world.coverImage}
          uploadPath="world"
          onChange={(image) => onChange({ ...world, coverImage: image?.path })}
          isInvalid={submitPressed && !world.coverImage}
        />
        <FormErrorMessage>
          {submitPressed && !world.coverImage && 'This field is required'}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};
