import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{
  text?: string;
  maxCharacters?: number;
  showExpandButton?: boolean;
}>;

export const ExpandableText: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const max = props.maxCharacters || 200;
  const shouldExpand =
    props.showExpandButton || (props.text?.length && props.text.length > max);

  return (
    <Container>
      <Text>{expanded ? props.text : `${props.text?.slice(0, max)}...`}</Text>
      {!shouldExpand || (expanded && props.children)}
      {shouldExpand && (
        <ToggleButton onClick={() => setExpanded(!expanded)}>
          <Icon
            name={expanded ? 'chevron-up' : 'chevron-down'}
            size={rawDimensions.size16}
          />
          {expanded ? 'View less' : 'View more'}
        </ToggleButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  white-space: pre-line;
`;

const ToggleButton = styled.div`
  ${textStyles.label.lb14sb}
  display: flex;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${dimensions.size16};
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.header};
  }
`;
