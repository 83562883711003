import { Button, ModalBody, ModalFooter, Switch } from '@chakra-ui/react';
import {
  DataType,
  RpgConfig,
  RpgConfigHudElement,
  RpgConfigProperty,
  Value,
  ValueType,
  hudIcons,
} from '@common/studio-types';
import { Field, StackDialog, TextInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Item } from '../../../hooks/useItems';
import { SmallHint } from '../../Hint';
import { ValueInput } from '../../value/ValueInputV2';
import { IconInput } from './IconInput';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  items: Item[];
  onItemCreated: (item: Item) => void;
  properties: RpgConfigProperty[];
  hudElement?: RpgConfigHudElement;
  onChange: (hudElement: RpgConfigHudElement) => void;
  onRemove: () => void;
};

// We only support support HUD items with numeric values
const onlyNumericValue: Value = {
  dataType: DataType.Number,
  type: ValueType.StaticValue,
  value: 0,
};

export const HudElementForm: React.FC<Props> = (props) => {
  const { properties, onClose, isOpen } = props;
  const [hudElement, setHudElement] = useState(
    props.hudElement ? { ...props.hudElement } : undefined,
  );
  const hudIcon = hudIcons.find((icon) => icon.ref === hudElement?.icon);
  const property = useMemo(() => {
    if (hudElement?.value.type === ValueType.Property) {
      const ref = hudElement.value.ref;

      return properties.find((p) => p.id === ref);
    }

    return undefined;
  }, [properties, hudElement]);
  const isMaxValueEnabled =
    property &&
    property.config.dataType === DataType.Number &&
    property.config.maxValue;

  useEffect(() => setHudElement(props.hudElement), [props.hudElement]);

  const onSave = () => {
    props.onChange(hudElement!);
    props.onClose();
  };

  const onRemoveClick = () => {
    props.onRemove();
    props.onClose();
  };

  const rpgConfig: RpgConfig = useMemo(
    () => ({
      version: 2,
      properties,
      customItems: [], // we don't need them in value input
      hud: [], // we don't need them in value input
    }),
    [properties],
  );

  const isInvalidValue =
    (hudElement?.value.type == ValueType.Property && !hudElement.value.ref) ||
    (hudElement?.value.type == ValueType.Item && !hudElement.value.ref);
  const isInvalid =
    !hudElement || !hudElement.icon || !hudElement.value || isInvalidValue;

  return (
    <StackDialog title="Player menu detail" isOpen={isOpen} onClose={onClose}>
      {hudElement && (
        <ModalBody>
          <DetailsContainer>
            <IconInput
              icon={hudIcon?.ref}
              onChange={(icon) => setHudElement({ ...hudElement, icon })}
            />
            <ValueInput
              referenceValue={onlyNumericValue}
              updateOperation
              rpgConfig={rpgConfig}
              disabledTypes={[ValueType.Input, ValueType.StaticValue]}
              value={hudElement.value}
              onChange={(value) => setHudElement({ ...hudElement, value })}
            />
          </DetailsContainer>

          <StyledField label="Display name">
            <TextInput
              placeholder="Enter display name. Leave it blank for Property or Item name"
              hint="This is the name that will be displayed on the player menu."
              maxCharacters={20}
              value={hudElement.displayName}
              onChange={(e) =>
                setHudElement({ ...hudElement, displayName: e.target.value })
              }
            />
          </StyledField>

          {isMaxValueEnabled && (
            <StyledField
              label="Display max value"
              rightButton={
                <Switch
                  isChecked={hudElement.displayMaxValue}
                  onChange={(e) =>
                    setHudElement({
                      ...hudElement,
                      displayMaxValue: e.target.checked,
                    })
                  }
                  size="lg"
                />
              }
            >
              <SmallHint>
                Selecting this will display the max value of the selected
                property.
              </SmallHint>
            </StyledField>
          )}
        </ModalBody>
      )}
      <ModalFooter>
        <Button variant="default" onClick={onRemoveClick}>
          Delete
        </Button>
        <Divider />
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} isDisabled={isInvalid}>
          Save
        </Button>
      </ModalFooter>
    </StackDialog>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: stretch;
  width: 100%;
  margin-bottom: ${dimensions.size24};
`;

const Divider = styled.div`
  flex: 1;
`;

const StyledField = styled(Field)`
  width: 100%;
`;
