import { Button } from '@chakra-ui/react';
import {
  DataType,
  EpisodeSetupNodeData,
  PropertyScope,
  RpgConfigProperty,
} from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { AddPropertyButton } from '../../components/propertyForm/AddPropertyButton';
import { PropertyForm } from '../../components/propertyForm/PropertyForm';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { StudioNodeFormComponent } from '../Node.types';

export const EpisodeSetupForm: StudioNodeFormComponent<
  EpisodeSetupNodeData
> = ({ data, onChange }) => {
  const { rpgConfig } = useStudioRpgConfig();
  const addNewProperty = (newProperty: RpgConfigProperty) => {
    onChange({
      ...data,
      properties: [...data.properties, newProperty],
    });
  };

  const removeProperty = (propertyId: string) => {
    onChange({
      ...data,
      properties: data.properties.filter(
        (property) => property.id !== propertyId,
      ),
    });
  };

  return (
    <Container>
      <Hint>
        Configure whatever episode level properties you want to add to make your
        episode more dynamic and interactive.
      </Hint>

      <Field label="Episode Properties">
        <PropertiesContainer>
          {data.properties.map((property, index) => (
            <PropertyForm
              rpgConfig={rpgConfig}
              key={property.id}
              data={property}
              onChange={(newProp) => {
                const newProperties = data.properties.map((opt, i) =>
                  i === index ? newProp : opt,
                );
                onChange({ ...data, properties: newProperties });
              }}
              onRemove={() => removeProperty(property.id)}
            />
          ))}
        </PropertiesContainer>
        <AddPropertyButton
          disabledTypes={[DataType.Enum]}
          onAddProperty={addNewProperty}
          scope={PropertyScope.Episode}
        >
          <Button
            variant="default"
            leftIcon={<Icon name="plus" size={16} />}
            width={'100%'}
          >
            Add property
          </Button>
        </AddPropertyButton>
      </Field>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  border-radius: ${dimensions.size4};
`;
