import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Icon, TextInput, Textarea } from '@maestro/components';
import { FC } from 'react';
import { WorldLocation } from '../../types';
import {
  GeneratorFormContainer,
  GeneratorFormHeader,
  GeneratorItemBody,
} from './GeneratorComponents';

type Props = {
  submitPressed?: boolean;
  locations: WorldLocation[];
  onDelete: () => void;
  location: WorldLocation;
  onChange: (location: WorldLocation) => void;
  index: number;
};

export const LocationForm: FC<Props> = ({
  location,
  submitPressed,
  onDelete,
  onChange,
  index,
}) => {
  return (
    <GeneratorFormContainer>
      <GeneratorFormHeader>
        <Text fontWeight={'semibold'} fontSize={18}>
          Location {index + 1}
        </Text>
        <ButtonGroup>
          <Button
            onClick={onDelete}
            variant="ghost"
            size="sm"
            leftIcon={<Icon name="trash" size={16} />}
          >
            Delete
          </Button>
        </ButtonGroup>
      </GeneratorFormHeader>
      <GeneratorItemBody>
        <FormControl mb="3" isInvalid={submitPressed && !location.name}>
          <FormLabel>Name of Location</FormLabel>
          <TextInput
            maxCharacters={100}
            value={location.name}
            onChange={(e) => onChange({ ...location, name: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !location.name && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !location.description}>
          <FormLabel>
            Describe the location. Include information like weather, building
            materials, appearance, layout, streets or sidewalks and density of
            people
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={location.description}
            onChange={(e) =>
              onChange({ ...location, description: e.target.value })
            }
          />
          <FormErrorMessage>
            {submitPressed && !location.description && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !location.npcs}>
          <FormLabel>
            Name the NPCs who spend time at this location and what they do
            there?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={location.npcs}
            onChange={(e) => onChange({ ...location, npcs: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !location.npcs && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
      </GeneratorItemBody>
    </GeneratorFormContainer>
  );
};
