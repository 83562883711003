import { useInfiniteMaestroEpisodesQuery } from '@maestro/graphql';
import { useEffect, useState } from 'react';

type Args = {
  seriesId?: string;
  draftsFirst?: boolean;
};

const PAGE_SIZE = 10;

export const useEpisodes = (args: Args) => {
  const [hasMore, setHasMore] = useState(false);

  const { data, isLoading, fetchNextPage, refetch } =
    useInfiniteMaestroEpisodesQuery(
      {
        input: {
          seriesId: args.seriesId,
          draftsFirst: args.draftsFirst,
          offset: 0,
          limit: PAGE_SIZE,
        },
      },
      {
        getNextPageParam: (lastPage) => ({
          input: {
            seriesId: args.seriesId,
            draftsFirst: args.draftsFirst,
            offset: lastPage.maestroEpisodes?.metadata?.nextCursor?.offset,
            limit: lastPage.maestroEpisodes?.metadata?.nextCursor?.limit,
          },
        }),
      },
    );

  const fetchMore = () => {
    fetchNextPage().catch(() => void 0);
  };

  const refetchAll = () => {
    refetch().catch(() => void 0);
  };

  useEffect(() => {
    setHasMore(
      data?.pages[data?.pages.length - 1]?.maestroEpisodes?.metadata?.hasMore ??
        false,
    );
  }, [data?.pages]);

  return {
    episodes:
      data?.pages.flatMap((page) => page.maestroEpisodes?.episodes ?? []) ?? [],
    isLoading,
    hasMore,
    refetch: refetchAll,
    fetchMore,
  };
};
