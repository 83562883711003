import { StudioFlowState } from '@common/studio-types';
import { dimensions } from '@maestro/styles';
import { useEffect, useMemo, useRef } from 'react';
import { useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useCallbackRef } from '../../hooks/useCallbackRef';
import { Item } from '../../hooks/useItems';
import { MaestroGameMessage } from './gameMessages/MaestroGameMessage';
import { useGameSimulator } from './hooks/useGameSimulator';
import { CollapsedPlayerMenu } from './playerMenu/CollapsedPlayerMenu';
import { SimulatorPropertiesForm } from './SimulatorPropertiesForm';

type Props = {
  isPropertiesOpen: boolean;
  onPropertiesClose: () => void;
  allItems: Item[];
};

export const PlayEpisodeSimulator: React.FC<Props> = (props) => {
  const flowInstance = useReactFlow();
  const onNodeSelect = useCallbackRef((nodeId: string) => {
    const node = flowInstance.getNode(nodeId);

    if (node) {
      const x = node.width ? node.position.x + node.width / 2 : node.position.x;
      const y = node.height
        ? node.position.y + node.height / 2
        : node.position.y;

      flowInstance.setCenter(x + 200, y, { duration: 300, zoom: 1.2 });
    }
  });

  const flowState = useMemo(() => {
    return flowInstance.toObject() as unknown as StudioFlowState;
  }, []);
  const game = useGameSimulator({ ...props, onNodeSelect, flowState });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.parentElement) {
      const element = ref.current.lastElementChild as HTMLDivElement | null;

      if (element) {
        const drawerHeight = ref.current.parentElement.offsetHeight;
        const addedElementHeight = element.offsetHeight;
        const minimumScroll = 88;

        ref.current.parentElement.scrollTo({
          behavior: 'smooth',
          top:
            element.offsetTop -
            (addedElementHeight > drawerHeight
              ? minimumScroll
              : Math.min(300, drawerHeight - addedElementHeight)),
        });
      }
    }
  }, [game.messages.length]);

  return (
    <Container>
      <SimulatorPropertiesForm
        game={game}
        isOpen={props.isPropertiesOpen}
        onClose={props.onPropertiesClose}
      />
      <ScrollContainer>
        <GameFeed ref={ref}>
          {game.messages.map((message, index) => (
            <div key={index}>
              <MaestroGameMessage
                message={message}
                game={game}
                isLastMessage={index + 1 === game.messages.length}
              />
            </div>
          ))}
        </GameFeed>
      </ScrollContainer>
      <HudContainer>
        <CollapsedPlayerMenu game={game} />
      </HudContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const HudContainer = styled.div`
  width: 100%;
`;

const GameFeed = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
  padding: ${dimensions.size24};
  margin-bottom: calc(100dvh - 300px);
`;
