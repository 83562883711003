import React, { memo } from 'react';
import { CoinTossResultMessage } from '@enigma-engine/core';
import { ActionFail, ActionSuccessful } from './coinToss/coinTossMessages';
import { MaestroCoinTossComponent } from './MaestroCoinTossComponent';

type Props = {
  message: CoinTossResultMessage;
};

const Component: React.FC<Props> = (props) => {
  const { message } = props;
  const messageContainer = message.successful ? (
    <ActionSuccessful />
  ) : (
    <ActionFail />
  );

  return (
    <MaestroCoinTossComponent
      messageContainer={messageContainer}
      successRate={message.successRate}
      successful={message.successful}
      selectedOption={message.choice}
    />
  );
};

export const MaestroGameMessageCoinTossResult = memo(Component);
