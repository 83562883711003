import { Button, useDisclosure } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import {
  DeleteModal,
  Icon,
  IconMenuItem,
  ImageWrapper,
  Menu,
  MenuButton,
  MenuList,
  useMaestroToast,
} from '@maestro/components';
import { ImageFallback } from '@maestro/components/ImageFallback';
import { CopyEpisodeShareLinkMenuItem } from '@maestro/components/ShareButton/CopyEpisodeShareLinkMenuItem';
import { PlayOnAppMenuItem } from '@maestro/components/ShareButton/PlayOnAppMenuItem';
import { StudioEpisode } from '@maestro/graphql';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Stat } from '../../../components/Stat';
import { EpisodeBuilderButton } from './EpisodeBuilderButton';
import { EpisodePublishButton } from './EpisodePublishButton';
import { EpisodeStatus } from './EpisodeStatus';

type Props = {
  episode: StudioEpisode;
  seriesId: string;
  refetch: () => void;
  order: number;
  referer?: string;
};

export const EpisodeRow: React.FC<Props> = (props) => {
  const { episode, order, refetch } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const toast = useMaestroToast();

  const hasNewVersion = Boolean(
    !!episode.draftVersion.updatedAt &&
      !!episode?.publishedVersion?.publishedAt,
  );
  const isDraft = !episode?.publishedVersion;
  const isPublished = !!episode?.publishedVersion?.publishedAt;
  const publishedAt = episode?.publishedVersion?.publishedAt ?? undefined;

  const onEdit = () => {
    navigate(
      `${pathname}?edit=episode&episodeId=${episode.id}${props?.referer ? `&referer=${props.referer}` : ''}`,
    );
  };

  const onDelete = async () => {
    // TODO [supabase-to-graphql]
    await toSupabaseClient().from('episode').delete().eq('id', episode.id);

    toast({
      status: 'success',
      title: 'Episode deleted',
    });

    refetch();
  };

  const deleteModal = useDisclosure();
  const showPublishButton = (!isPublished && isDraft) || hasNewVersion;

  return (
    <Container>
      <Rows>
        <EpisodeStatus
          hasNewVersion={hasNewVersion}
          isDraft={isDraft}
          isPublished={isPublished}
          publishedAt={publishedAt}
        />
        <MobileContainer>
          {!isDraft && episode.playsCount > 0 && (
            <Stat
              iconName={'play'}
              count={episode.playsCount}
              label={'Plays'}
            />
          )}
        </MobileContainer>
      </Rows>

      <EpisodeContainer>
        <EpisodeOrder>{order}</EpisodeOrder>
        <ImageWrapper borderRadius={rawDimensions.size8}>
          {episode?.media?.main ? (
            <EpisodeImage
              src={toOptimizedImageUri(episode.media.main, {
                width: rawDimensions.size120,
                height: rawDimensions.size80,
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
            />
          ) : (
            <ImageFallback
              width={120}
              height={80}
              iconSize={rawDimensions.size24}
            />
          )}
        </ImageWrapper>
        <EpisodeDetails>
          <EpisodeTitle>{episode.title}</EpisodeTitle>
          <EpisodeDescription>{episode.description}</EpisodeDescription>
        </EpisodeDetails>
      </EpisodeContainer>

      <DeleteModal
        variant="danger"
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        onDelete={onDelete}
        promptTitle="Delete episode"
        promptMessage={
          !!episode.publishedVersion
            ? `This will delete ${episode.title} from your studio. This episode is already published and users won't be able to play it anymore.`
            : 'Are you sure you want to delete this episode?'
        }
      />

      <ButtonGroup>
        <DesktopContainer>
          {!isDraft && episode.playsCount > 0 && (
            <Stat
              iconName={'play'}
              count={episode.playsCount}
              label={'Plays'}
            />
          )}
        </DesktopContainer>
        <Menu>
          <MenuButton>
            <Button
              variant="default"
              width={dimensions.size40}
              height={dimensions.size40}
            >
              <Icon name="ellipsis" size={rawDimensions.size16} />
            </Button>
          </MenuButton>
          <MenuList>
            <IconMenuItem icon="edit" name="Edit details" onClick={onEdit} />
            <PlayOnAppMenuItem episodeId={episode.id} />
            <CopyEpisodeShareLinkMenuItem
              episodeId={episode.id}
              label="Copy link"
            />
            <IconMenuItem
              icon="trash"
              name="Delete"
              onClick={deleteModal.onOpen}
            />

            {showPublishButton && (
              <EpisodePublishButton episodeId={episode.id} />
            )}
          </MenuList>
        </Menu>
        <EpisodeBuilderButton isDraft={isDraft} episode={episode} />
        {showPublishButton && (
          <EpisodePublishButton episodeId={episode.id} variant="button" />
        )}
      </ButtonGroup>
    </Container>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  margin-left: auto;

  @media ${breakpoints.tablet} {
    zoom: 0.9;
  }
`;

const Container = styled.div<{ $isLast?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size16};
  align-items: flex-start;
`;

const Rows = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size16};
  align-items: center;
`;

const EpisodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
`;

const EpisodeOrder = styled.div`
  ${textStyles.label.lb16sb}
  width: ${dimensions.size20};
  color: ${({ theme }) => theme.colors.text.header};
`;

const EpisodeImage = styled.img`
  width: ${dimensions.size120};
  height: ${dimensions.size80};
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const EpisodeDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: center;
`;

const EpisodeTitle = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const EpisodeDescription = styled.div`
  ${textStyles.body.b14m}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const DesktopContainer = styled.div`
  display: block;

  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media ${breakpoints.mobile} {
    display: block;
  }
`;
