import { dimensions } from '@maestro/styles';
import React, { memo, useState } from 'react';
import styled from 'styled-components';
import {
  CoinTossChoice,
  CoinTossInteractionMessage,
  CoinTossSide,
} from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import {
  AddingModifier,
  ChooseSide,
  FlippingCoin,
} from './coinToss/coinTossMessages';
import { MaestroCoinTossComponent } from './MaestroCoinTossComponent';
import { MaestroPlayerChoiceInteraction } from './MaestroPlayerChoiceInteraction';

type Props = {
  message: CoinTossInteractionMessage;
  game: GameSimulator;
  isLastMessage: boolean;
};

const Component: React.FC<Props> = (props) => {
  const { message, isLastMessage, game } = props;
  const [initiated, setInitiated] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    CoinTossSide | undefined
  >();
  const option = { id: message.id, text: 'Flip a coin', icon: 'coin-flip' };
  const [successRate, setSuccessRate] = useState<number>(50);
  const [messageContainer, setMessageContainer] = useState(<ChooseSide />);

  const selectOption = (side: CoinTossSide) => {
    setSelectedOption(side);

    if (message.attribute) {
      setSuccessRate(message.successRate);
      setMessageContainer(<AddingModifier attr={message.attribute.name} />);
    } else {
      setMessageContainer(<FlippingCoin />);
    }

    game.coinToss(
      message.id,
      side === CoinTossSide.Heads ? CoinTossChoice.Heads : CoinTossChoice.Tails,
    );
  };

  if (!isLastMessage) {
    return (
      <MaestroPlayerChoiceInteraction
        options={[]}
        prompt={message.prompt}
        showChoices={false}
      />
    );
  }

  return (
    <Container>
      <MaestroPlayerChoiceInteraction
        onSelect={() => setInitiated(true)}
        options={[option]}
        prompt={message.prompt}
        showChoices={isLastMessage && !initiated}
      />
      {initiated && (
        <MaestroCoinTossComponent
          messageContainer={messageContainer}
          successRate={successRate}
          successful
          onSelectOption={selectOption}
          selectedOption={selectedOption}
        />
      )}
    </Container>
  );
};

export const MaestroGameMessageCoinTossInteraction = memo(Component);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
`;
