import {
  DiscoverySectionFragment,
  useCreateDiscoverySectionSeriesMutation,
} from '@maestro/graphql';

export const useCreateSection = () => {
  const { isLoading, mutateAsync } = useCreateDiscoverySectionSeriesMutation();

  const createSection = async (
    name: string,
  ): Promise<DiscoverySectionFragment | undefined> => {
    const { discoverySection } = await mutateAsync({ input: { name } });

    return discoverySection.createSectionSeries.section ?? undefined;
  };

  return { createSection, isSaving: isLoading };
};
