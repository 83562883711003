import React, { memo } from 'react';
import { PlayerAttributeMessage, Role, TextMessage } from '@enigma-engine/core';
import { MaestroGameMessageNarratorText } from './MaestroGameMessageNarratorText';

type Props = {
  message: PlayerAttributeMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  const textMessage: TextMessage = {
    type: 'text',
    nodeId: message.nodeId,
    name: 'Narrator',
    role: Role.Narrator,
    message:
      message.delta < 0
        ? `Your ${message.attribute.name} decreases by ${Math.abs(message.delta)}`
        : `Your ${message.attribute.name} increases by ${message.delta}`,
  };

  return <MaestroGameMessageNarratorText message={textMessage} />;
};

export const MaestroGameMessagePlayerAttribute = memo(Component);
