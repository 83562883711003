import { ButtonGroup, Tooltip, useDisclosure } from '@chakra-ui/react';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { FC } from 'react';
import styled from 'styled-components';
import { GameSimulator } from '../hooks/useGameSimulator';
import { MaestroContinueButton } from './MaestroContinueButton';
import { MaestroReplayButton } from './MaestroReplayButton';
import { PlayerMenu } from './PlayerMenu';
import { PlayerMenuStats } from './PlayerMenuStats';

type Props = {
  game: GameSimulator;
};

export const CollapsedPlayerMenu: FC<Props> = ({ game }) => {
  const stats = game.hud.slice(0, 4);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shouldShowHud = stats.length > 0 || game.inventoryItems.length > 0;

  return (
    <>
      <PlayerMenu isOpen={isOpen} onClose={onClose} game={game} />
      <Container $centerContent={stats.length < 2}>
        {shouldShowHud && (
          <Tooltip label="Player menu" aria-label="Player menu" placement="top">
            <StatsButton onClick={onOpen}>
              {stats.length > 0 ? (
                <PlayerMenuStats stats={stats} />
              ) : (
                <OpenInventoryLabel>Open inventory</OpenInventoryLabel>
              )}
            </StatsButton>
          </Tooltip>
        )}
        <ButtonGroup>
          <MaestroReplayButton game={game} />
          <MaestroContinueButton game={game} />
        </ButtonGroup>
      </Container>
    </>
  );
};

const Container = styled.div<{ $centerContent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  justify-content: ${({ $centerContent }) =>
    $centerContent ? 'center' : 'space-between'};
  width: 100%;
  min-height: ${rawDimensions.size48 + rawDimensions.size4}px;
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.base.dark['1000']};
  border-top-color: ${({ theme }) => theme.colors.border.default['100']};
  border-top-width: ${dimensions.size1};
`;

const StatsButton = styled.button`
  background: ${({ theme }) => theme.colors.background.shade};
  padding: ${dimensions.size12};
  border-radius: ${dimensions.size8};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.base.light[100]};
  }
`;

const OpenInventoryLabel = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.body};
`;
