import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
} from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { SuggestionsSection } from './suggestion.types';
import { SuggestionItem } from './SuggestionItem';

type Props = {
  section: SuggestionsSection;
};

export const SuggestionSection: React.FC<Props> = ({ section }) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <TitleContainer>
          <AccordionTitle>
            {section.suggestions.length > 0 && (
              <Badge variant="circle">{section.suggestions.length}</Badge>
            )}
            {section.title}
            {!!section?.required && (
              <RequiredIndicator>Required</RequiredIndicator>
            )}
          </AccordionTitle>
          <AccordionIcon />
        </TitleContainer>
      </AccordionButton>
      <AccordionPanel padding={0}>
        {section.suggestions.map((suggestion) => (
          <SuggestionItem key={suggestion.id} suggestion={suggestion} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const AccordionTitle = styled.div`
  ${textStyles.body.b12sb}
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.body};
`;

const RequiredIndicator = styled.label`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.base.yellow[1000]};
  margin-left: auto;
`;
