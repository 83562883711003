import { StudioNodeType, type DiceRollNodeData } from '@common/studio-types';
import {
  createDiceRollResultMessage,
  getModifier,
  getResult,
  rollDices,
} from '../diceRoll/diceRoll.utils';
import type { Message } from '../game/messages.types';
import type { StatefulGame } from './statefulGame';

type Args = {
  interactionId: string;
};

export const handleDiceRollInteraction = async (
  game: StatefulGame,
  interactionArgs: Args,
): Promise<Message[]> => {
  const { interactionId } = interactionArgs;
  const interaction = game.gameNodes.nodeById<DiceRollNodeData>(interactionId);
  const state = game.state();

  if (
    !interaction ||
    state.currentInteraction !== interactionId ||
    interaction?.type !== StudioNodeType.DiceRoll
  ) {
    return [game.errorMessage('Invalid interaction')];
  }

  const diceRollResult = rollDices(interaction.dices);
  const modifier = await getModifier(
    interaction,
    state,
    game.config,
    game.gameData.playerData,
  );
  const result = await getResult(
    diceRollResult,
    interaction,
    state,
    game.config,
    game.gameData.playerData,
  );
  const outputName = interaction.outputName || 'default';

  game.setOutput({ [outputName]: result });

  /* This needs to run BEFORE the game execution, otherwise
   * it'll override the interaction that will be set during execution
   */
  game.setGameState({ currentInteraction: undefined });

  const messages = await game.execute(interaction.nextNodeId!);
  const diceRollResultMessage = createDiceRollResultMessage(
    interaction,
    diceRollResult,
    modifier,
  );

  if (messages.length === 0) {
    // setting back to the current interaction
    game.setGameState({ currentInteraction: interaction.id });

    return [diceRollResultMessage];
  }

  return [diceRollResultMessage, ...messages];
};
