import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { ExpandableText } from './ExpandableText';

type Props = {
  icon: string;
  name: string;
  sections: { name: string; content: string }[];
};

export const WorldElement: React.FC<Props> = (props) => {
  const [firstSection, ...otherSections] = props.sections;

  return (
    <Container>
      <TitleContainer>
        <IconContainer>
          <Icon name={props.icon} size={rawDimensions.size24} />
        </IconContainer>
        {props.name}
      </TitleContainer>
      {firstSection && (
        <Section>
          <SectionTitle>{firstSection.name}</SectionTitle>
          <SectionContent>
            <ExpandableText
              text={firstSection.content}
              showExpandButton={otherSections.length > 0}
            >
              {otherSections.map((section, index) => (
                <StyledSection key={index}>
                  <SectionTitle>{section.name}</SectionTitle>
                  <SectionContent>{section.content}</SectionContent>
                </StyledSection>
              ))}
            </ExpandableText>
          </SectionContent>
        </Section>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size16};
  border: 1px solid ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
`;

const TitleContainer = styled.div`
  ${textStyles.label.lb16b}
  display: flex;
  gap: ${dimensions.size12};
  align-items: center;
  color: ${({ theme }) => theme.colors.text.header};
`;

const IconContainer = styled.div`
  padding: ${dimensions.size12} ${dimensions.size12} ${dimensions.size8}
    ${dimensions.size12};
  color: ${({ theme }) => theme.colors.base.accent[1000]};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const StyledSection = styled(Section)`
  margin-top: ${dimensions.size16};
`;

const SectionTitle = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const SectionContent = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.body};
  white-space: pre-line;
`;
