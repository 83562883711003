import { dimensions, rawDimensions } from '@maestro/styles';
import styled from 'styled-components';

export const FullWidthIcon = () => (
  <Container>
    <Image />
    <TitleContainer>
      <Title />
      <Subtitle />
    </TitleContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  width: ${rawDimensions.size80 + rawDimensions.size12}px;
  padding: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const Image = styled.div`
  width: ${rawDimensions.size80 + rawDimensions.size4}px;
  height: ${dimensions.size80};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Title = styled.div`
  width: ${dimensions.size24};
  height: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const Subtitle = styled.div`
  width: 100%;
  height: ${dimensions.size4};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;
