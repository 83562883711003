import {
  CoinTossNodeData,
  ConditionCheckNodeData,
  EpisodeSetupNodeData,
  ObjectiveCompleteNodeData,
  SingleSelectNodeData,
  StartNodeData,
  StudioFlowState,
  StudioNodeData,
  StudioNodeType,
} from '@common/studio-types';
import type { GameNode } from '../studioGameCreator.types';

export type GameNodes = {
  nodesMap(): Record<string, GameNode<StudioNodeData>>;
  setupNode: () => EpisodeSetupNodeData | undefined;
  nodeById<TData extends StudioNodeData = StudioNodeData>(
    id: string,
  ): GameNode<TData> | undefined;
  startNode(): GameNode<StartNodeData>;
  refresh: (state: StudioFlowState) => void;
};

export const isStartNode = (
  nodeData: GameNode,
): nodeData is GameNode<StartNodeData> => {
  return nodeData.type === StudioNodeType.Start;
};

export const isObjectiveCompleteNode = (
  nodeData: GameNode,
): nodeData is GameNode<ObjectiveCompleteNodeData> => {
  return nodeData.type === StudioNodeType.ObjectiveComplete;
};

export const isSingleSelectNode = (
  nodeData: GameNode,
): nodeData is GameNode<SingleSelectNodeData> => {
  return nodeData.type === StudioNodeType.SingleSelect;
};

export const isEpisodeSetupNode = (
  nodeData: GameNode,
): nodeData is GameNode<EpisodeSetupNodeData> => {
  return nodeData.type === StudioNodeType.EpisodeSetup;
};

export const isCoinTossNode = (
  nodeData: GameNode,
): nodeData is GameNode<CoinTossNodeData> => {
  return nodeData.type === StudioNodeType.CoinToss;
};

export const isConditionCheckNode = (
  nodeData: GameNode,
): nodeData is GameNode<ConditionCheckNodeData> => {
  return nodeData.type === StudioNodeType.ConditionCheck;
};

export const getGameNodes = (argState: StudioFlowState): GameNodes => {
  let sourceByTarget: Record<string, string> = {};
  let targetBySource: Record<string, string> = {};
  let map: Record<string, GameNode<StudioNodeData>> = {};
  let episodeSetupNode: GameNode<EpisodeSetupNodeData> | undefined;
  let startNode: GameNode<StartNodeData> | undefined;

  const refresh = (state: StudioFlowState) => {
    map = {};
    sourceByTarget = {};
    targetBySource = {};

    state.edges.forEach((edge) => {
      const isMultiplexer = edge.sourceHandle?.startsWith('studio-');

      if (edge.sourceHandle && isMultiplexer) {
        // for connections to the left of single-select nodes
        const sourceHandle = edge.sourceHandle.replace('-left', '');

        sourceByTarget[edge.target] = sourceHandle;
        targetBySource[sourceHandle] = edge.target;
      } else {
        sourceByTarget[edge.target] = edge.source;
        targetBySource[edge.source] = edge.target;
      }
    });

    state.nodes.forEach((node) => {
      const gameNode: GameNode = {
        ...node.data,
        nextNodeId: targetBySource[node.id],
      };

      if (isSingleSelectNode(gameNode)) {
        gameNode.options = gameNode.options.map((option) => ({
          ...option,
          nextNodeId: targetBySource[option.id],
        }));
        gameNode.nextNodeId = undefined; // only options have nextNodeId
        gameNode.nodeAfterOtherOptionsId = targetBySource[gameNode.id];
      } else if (isCoinTossNode(gameNode)) {
        gameNode.nextNodeId = undefined;
        gameNode.failNodeId = targetBySource[`${gameNode.id}-fail`];
        gameNode.successNodeId = targetBySource[`${gameNode.id}-success`];
      } else if (isConditionCheckNode(gameNode)) {
        gameNode.nextNodeId = undefined;
        gameNode.trueNodeId = targetBySource[`${gameNode.id}-true`];
        gameNode.falseNodeId = targetBySource[`${gameNode.id}-false`];
      }

      map[gameNode.id] = gameNode;

      if (isStartNode(gameNode)) {
        startNode = gameNode;
      } else if (isEpisodeSetupNode(gameNode)) {
        episodeSetupNode = gameNode;
      }
    });

    if (!startNode) {
      startNode = state.nodes.find(
        (node) => !sourceByTarget[node.id],
      ) as GameNode<StartNodeData>;
    }
  };

  refresh(argState);

  return {
    nodesMap: () => map,
    setupNode: () => episodeSetupNode,
    nodeById: <TData extends StudioNodeData = StudioNodeData>(id: string) => {
      return map[id] as GameNode<TData> | undefined;
    },
    startNode: () => startNode!,
    refresh: (state) => refresh(state),
  };
};
