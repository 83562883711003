import { dimensions } from '@maestro/styles';
import styled from 'styled-components';

export const NoResultContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${dimensions.size40} 0;
  text-align: center;
`;

export const NoResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  align-items: center;
  max-width: ${dimensions.size600};
  text-align: center;
`;
