import {
  PlayerInputNodeData,
  PropertyValue,
  ValueType,
} from '@common/studio-types';
import { NumericInput } from '@maestro/components';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { ValueInput } from '../../components/value/ValueInput';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { StudioNodeFormComponent } from '../Node.types';

export const PlayerInputForm: StudioNodeFormComponent<PlayerInputNodeData> = ({
  data,
  onChange,
}) => {
  const { rpgConfig } = useStudioRpgConfig();

  return (
    <Container>
      <Hint>
        Player inputs are a way to request text input from the player.
      </Hint>
      <Label>Player input</Label>
      <Textarea
        placeholder="What's your name?"
        maxCharacters={100}
        autoFocus={!data.prompt}
        value={data.prompt}
        onChange={(e) => onChange({ ...data, prompt: e.target.value })}
      />
      <Field label="Property to assign">
        <ValueInput
          rpgConfig={rpgConfig}
          referenceValue={data.property}
          value={data.property}
          updateOperation
          disabledTypes={[
            ValueType.Input,
            ValueType.StaticValue,
            ValueType.Item,
          ]}
          onChange={(property) =>
            onChange({ ...data, property: property as PropertyValue })
          }
        />
      </Field>
      <Field label="Max length">
        <NumericInput
          onChange={(maxLength) => onChange({ ...data, maxLength })}
          value={data.maxLength}
          // NOTE: if this is updated make sure to update the DEFAULT_PLAYER_INPUT_MAX_LENGTH
          // constant from the @enigma-api/episode/constants.ts file
          defaultValue={30}
        />
      </Field>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
