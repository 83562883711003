import { useGetUserId } from '@maestro/components';
import { useMaestroSeriesQuery } from '@maestro/graphql';
import { useEffect } from 'react';

export const useGetSeries = (seriesId: string) => {
  const userId = useGetUserId();
  const { data, isLoading, refetch } = useMaestroSeriesQuery(
    { input: { seriesId } },
    { cacheTime: 0, staleTime: 0, retry: false },
  );

  useEffect(() => {
    refetch().catch(() => void 0);
  }, [userId]);

  return {
    series: data?.maestroSeries?.series,
    isLoading,
    refetch,
  };
};
