import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { createTheme, THEME_ID } from '@mui/material/styles';
import React, { PropsWithChildren, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeMode } from '../types/theme';
import { baseFont } from './baseFont';
import { chakraTheme } from './chakraTheme';
import { getTheme } from './theme';

export const MaestroThemeProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const mode = ThemeMode.Dark;
  const customTheme = useMemo(() => getTheme(mode), []);

  const muiTheme = createTheme({
    shadows: Array(25).fill('none') as never,
    palette: {
      mode,
      primary: {
        main: customTheme.colors.text.accent,
      },
      secondary: {
        main: customTheme.colors.text.highlight,
      },
    },
    typography: {
      fontFamily: baseFont.family,
    },
  });

  // Why? Check here https://github.com/mui/material-ui/issues/25852#issuecomment-1489172516
  const combinedThemes = { ...chakraTheme, [THEME_ID]: muiTheme };

  return (
    <ThemeProvider theme={customTheme}>
      <ColorModeScript initialColorMode={mode} />
      <ChakraProvider theme={combinedThemes}>{children}</ChakraProvider>
    </ThemeProvider>
  );
};
