import { ColorTheme, RGBA } from '../types/theme';
import { baseColorMap } from './baseColors';

export type BackgroundColorDesignSystem = {
  default: RGBA;
  shade: RGBA;
  accent: RGBA;
  danger: RGBA;
  success: RGBA;
};

export const backgroundColorMap: ColorTheme<BackgroundColorDesignSystem> = {
  dark: {
    default: baseColorMap.dark.dark['1000'],
    shade: baseColorMap.dark.light['50'],
    accent: baseColorMap.dark.accent['1000'],
    danger: baseColorMap.dark.red['200'],
    success: baseColorMap.dark.accent['200'],
  },
  light: {
    default: baseColorMap.light.light['1000'],
    shade: baseColorMap.light.dark['50'],
    accent: baseColorMap.light.accent['1000'],
    danger: baseColorMap.dark.red['200'],
    success: baseColorMap.dark.accent['200'],
  },
};
