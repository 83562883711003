import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import {
  RpgConfig,
  RpgConfigAiGeneration,
  RpgConfigProperty,
} from '@common/studio-types';
import { Field, StackDialog, TextInput } from '@maestro/components';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PropertyInput } from '../../../value/PropertyInputV2';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  properties: RpgConfigProperty[];
  aiGeneration?: RpgConfigAiGeneration;
  onChange: (aiGeneration: RpgConfigAiGeneration) => void;
  onRemove: () => void;
};

export const AiGenerationForm: React.FC<Props> = (props) => {
  const { properties, onClose, isOpen } = props;
  const [aiGeneration, setAiGeneration] = useState(
    props.aiGeneration ? { ...props.aiGeneration } : undefined,
  );

  useEffect(() => setAiGeneration(props.aiGeneration), [props.aiGeneration]);

  const onSave = () => {
    props.onChange(aiGeneration!);
    props.onClose();
  };

  const onRemoveClick = () => {
    props.onRemove();
    props.onClose();
  };

  const rpgConfig: RpgConfig = useMemo(
    () => ({
      version: 2,
      properties,
      customItems: [], // we don't need them in value input
      hud: [], // we don't need them in value input
    }),
    [properties],
  );

  const isInvalid =
    !aiGeneration || !aiGeneration.value || !aiGeneration.displayName;

  return (
    <StackDialog title="AI Generation Form" isOpen={isOpen} onClose={onClose}>
      {aiGeneration && (
        <ModalBody>
          <StyledField label="Property">
            <PropertyInput
              rpgConfig={rpgConfig}
              value={aiGeneration.value}
              onChange={(value) => setAiGeneration({ ...aiGeneration, value })}
            />
          </StyledField>

          <StyledField label="Field name">
            <TextInput
              placeholder="Enter display name"
              hint="This is the name that will be displayed on the player menu."
              maxCharacters={20}
              value={aiGeneration.displayName}
              isInvalid={!aiGeneration.displayName}
              onChange={(e) =>
                setAiGeneration({
                  ...aiGeneration,
                  displayName: e.target.value,
                })
              }
            />
          </StyledField>

          <StyledField label="Description">
            <TextInput
              placeholder="Enter the description"
              hint="This is the name that will be displayed on the player menu."
              maxCharacters={20}
              value={aiGeneration.description}
              onChange={(e) =>
                setAiGeneration({
                  ...aiGeneration,
                  description: e.target.value,
                })
              }
            />
          </StyledField>
        </ModalBody>
      )}
      <ModalFooter>
        <Button variant="default" onClick={onRemoveClick}>
          Delete
        </Button>
        <Divider />
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} isDisabled={isInvalid}>
          Save
        </Button>
      </ModalFooter>
    </StackDialog>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const StyledField = styled(Field)`
  width: 100%;
`;
