import { useMaestroToast } from '@maestro/components';
import { env } from '@maestro/env';
import { useCallback } from 'react';

export const useGenerateNarrationWithAi = () => {
  const toast = useMaestroToast();

  return useCallback(async (prompt: string) => {
    try {
      const body = JSON.stringify({ prompt });
      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/studio/generate-narration`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body,
        },
      );

      if (!response.ok) {
        toast({
          status: 'warning',
          title: 'AI Generation failed',
        });

        throw new Error('Failed to generate world bible');
      }

      const { narration } = await response.json();

      return narration;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);
};
