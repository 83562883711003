import {
  DataType,
  GetValueNodeData,
  Value,
  ValueType,
} from '@common/studio-types';
import { TextInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { ValueInput } from '../../components/value/ValueInput';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { StudioNodeFormComponent } from '../Node.types';

// We don't support outputs with different types yet
const onlyNumericValue: Value = {
  dataType: DataType.Number,
  type: ValueType.StaticValue,
  value: 0,
};

export const GetValueForm: StudioNodeFormComponent<GetValueNodeData> = ({
  data,
  onChange,
}) => {
  const { rpgConfig } = useStudioRpgConfig();

  return (
    <Container>
      <Hint>
        Use this node to get value from state or character and pass to other
        nodes
      </Hint>
      <ValueInput
        referenceValue={onlyNumericValue}
        rpgConfig={rpgConfig}
        disabledTypes={[ValueType.Input, ValueType.StaticValue]}
        value={data.value}
        onChange={(value) => onChange({ ...data, value })}
      />
      <Field label="Output name">
        <Hint>
          Use this to name the output to be able to use different outputs names
          in other nodes
        </Hint>
        <TextInput
          value={data.outputName}
          onChange={(e) => onChange({ ...data, outputName: e.target.value })}
        />
      </Field>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
