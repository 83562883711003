import type { NarratorTextNodeData } from '@common/studio-types';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { Warning } from '../../components/Warning';
import { StudioNodeFormComponent } from '../Node.types';

const maxCharacters = 1000;

export const NarratorTextForm: StudioNodeFormComponent<
  NarratorTextNodeData
> = ({ data, onChange }) => {
  return (
    <Container>
      <Hint>
        The narrator response is the literary voice that conveys the story to
        your players
      </Hint>
      <Label>Narrator response</Label>
      <Textarea
        placeholder="Write a narrator response here"
        maxCharacters={maxCharacters}
        autoFocus={!data.text}
        value={data.text}
        onChange={(e) => onChange({ ...data, text: e.target.value })}
      />
      {data.text.length > maxCharacters && (
        <Warning>
          We recommend less than {maxCharacters} characters for each narrator
          response. Try breaking it up by adding a player choice, image or
          adding another narrator response to add more characters.
        </Warning>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
