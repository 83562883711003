import { RpgConfig, StartNodeData, StudioNodeType } from '@common/studio-types';
import { migrateStudioFlowState } from '@common/studio-types/migration';
import { DefaultEdgeOptions, Node } from 'reactflow';
import { MaestroStudioFlowState } from '../types/studio-flow';

export const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'default',
};

export const defaultStartNode: Node<StartNodeData> = {
  id: 'start',
  deletable: false,
  position: { x: 0, y: 0 },
  type: StudioNodeType.Start,
  data: { id: 'start', type: StudioNodeType.Start },
};

export const cleanFlowState = (
  flowState: MaestroStudioFlowState,
  config: RpgConfig,
): MaestroStudioFlowState => {
  migrateStudioFlowState(flowState, config);

  const startNode = flowState.nodes.find(
    (node) => node.type === StudioNodeType.Start,
  ) as MaestroStudioFlowState['nodes'][0];

  return {
    ...flowState,
    nodes: [
      startNode ?? defaultStartNode,
      ...flowState.nodes.map((node) => ({ ...node, selected: false })),
    ],
    edges: flowState.edges.map((edge) => ({
      ...edge,
      ...defaultEdgeOptions,
    })),
  };
};
