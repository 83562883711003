import { useMaestroToast } from './useMaestroToast';

export const useCopyToClipboard = () => {
  const toast = useMaestroToast();

  const copyToClipboard = (
    linkToCopy: string,
    {
      onSuccessMessage = 'Link copied to clipboard',
      onSuccessDescription,
      onErrorMessage = 'Oops! there was an error trying to copy the link to your clipboard',
    }: {
      onSuccessMessage?: string;
      onSuccessDescription?: string;
      onErrorMessage?: string;
    },
  ) => {
    try {
      const successful = navigator.clipboard
        .writeText(linkToCopy)
        .then(() => true)
        .catch(() => false);

      if (!successful) {
        throw Error('Failed to copy link to clipboard');
      }
      toast({
        title: onSuccessMessage,
        description: onSuccessDescription,
        status: 'success',
        duration: 2000,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Oops, unable to copy', err);
      toast({
        title: onErrorMessage,
        status: 'warning',
        duration: 2000,
      });
    }
  };

  return { copyToClipboard };
};
