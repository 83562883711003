import { Checkbox } from '@chakra-ui/react';
import { textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  onChange: () => void;
  checked: boolean;
};

export const NeverShowAgainCheckbox: React.FC<Props> = ({
  onChange,
  checked,
}) => {
  return (
    <Container>
      <Checkbox
        iconColor="white"
        isChecked={checked}
        onChange={onChange}
        colorScheme="primary"
      >
        <Label>Do not show this again</Label>
      </Checkbox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Label = styled.span`
  ${textStyles.label.lb16sb};
  color: ${({ theme }) => theme.colors.text.title};
`;
