import { useEffect, useState } from 'react';
import { KeyCode, useKeyPress } from 'reactflow';

const keyPressOpts = {
  actInsideInputWithModifier: false,
};

export const useShortcut = (keyCode: KeyCode, callback: VoidFunction): void => {
  const [didRun, setDidRun] = useState(false);
  const shouldRun = useKeyPress(keyCode, keyPressOpts);

  useEffect(() => {
    if (shouldRun && !didRun) {
      callback();
      setDidRun(true);
    } else {
      setDidRun(shouldRun);
    }
  }, [shouldRun, didRun, callback]);
};
