import { Button } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { Icon, TextInput } from '@maestro/components';
import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@maestro/components/menu/Menu';
import { SeriesShortFragment } from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

type Props = {
  series: SeriesShortFragment[];
  onSelect: (series: SeriesShortFragment) => void;
};

export const SeriesDropdown: React.FC<Props> = (props) => {
  const { onSelect, series } = props;
  const [search, setSearch] = useState('');
  const filteredSeries = useMemo(() => {
    if (!search) return series;

    return series.filter((series) =>
      series.title.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, series]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onClear = (e: React.MouseEvent) => {
    setSearch('');
    e.stopPropagation();
  };

  return (
    <Menu>
      <MenuButton>
        <Button
          variant="default"
          size="sm"
          rightIcon={<Icon name="caret-down" size={rawDimensions.size16} />}
        >
          Add series
        </Button>
      </MenuButton>
      <MenuList>
        <Searcher>
          <TextInput
            value={search}
            showRemoveButton
            onRemove={onClear}
            placeholder="Search"
            onClick={(e) => e.stopPropagation()}
            onChange={onChangeSearch}
          />
        </Searcher>

        {filteredSeries.map((series) => (
          <MenuItem key={series.id} onClick={() => onSelect(series)}>
            <IconContainer>
              <img
                src={toOptimizedImageUri(series.media.main, {
                  width: rawDimensions.size24,
                  height: rawDimensions.size32,
                  dpr: window.devicePixelRatio,
                  resize: 'cover',
                })}
              />
              {series.title}
            </IconContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const Searcher = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  padding: 0 ${dimensions.size8};
  margin-bottom: ${dimensions.size8};
`;

const IconContainer = styled.div`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  border-radius: ${dimensions.size4};

  img {
    width: ${dimensions.size24};
    height: ${dimensions.size32};
    object-fit: cover;
    border-radius: ${dimensions.size4};
  }
`;
