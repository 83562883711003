import { toOptimizedImageUri } from '@common/image';
import { hudIcons } from '@common/studio-types';
import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  onChange: (icon: string) => void;
  icon?: string;
};

export const IconInput: React.FC<Props> = ({ onChange, icon }) => {
  const selected = hudIcons.find(({ ref }) => ref === icon);

  return (
    <Menu>
      <MenuDropdownButton>
        {selected && (
          <IconContainer>
            <img
              src={toOptimizedImageUri(selected.icon, {
                width: rawDimensions.size16,
                height: rawDimensions.size16,
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
            />
            {selected.name}
          </IconContainer>
        )}
      </MenuDropdownButton>
      <MenuList>
        {hudIcons.map((icon) => (
          <MenuItem key={icon.ref} onClick={() => onChange(icon.ref)}>
            <IconContainer>
              <img
                src={toOptimizedImageUri(icon.icon, {
                  width: rawDimensions.size16,
                  height: rawDimensions.size16,
                  dpr: window.devicePixelRatio,
                  resize: 'cover',
                })}
              />
              {icon.name}
            </IconContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const IconContainer = styled.div`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  border-radius: ${dimensions.size4};

  img {
    width: ${dimensions.size16};
    height: ${dimensions.size16};
    object-fit: cover;
  }
`;
