import React, { PropsWithChildren } from 'react';
import { useIsAdminUser } from '../useIsAdminUser';

export const AdminGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const isAdminUser = useIsAdminUser();

  if (!isAdminUser) {
    return null;
  }

  return children;
};
