export type StatBlockField = {
  name: string;
  value: string;
};

/**
 * @deprecated
 * @see StatBlockSectionLayout
 */
export enum StatBlockDirection {
  Row = 'row',
  Column = 'column',
}

export enum StatBlockSectionLayout {
  GridList = 'GridList',
  Description = 'Description',
}

export type StatBlockSection = {
  title?: string;
  fields: StatBlockField[];
  layout: StatBlockSectionLayout;
};

export enum StatBlockLayout {
  // A full width image is shown on top of the stat block
  FullWidth = 'FullWidth',
  // A small image is shown on the side of the title/subtitle container
  Compact = 'Compact',
}

export type StatBlockImage = {
  url: string;
  width: number;
  height: number;
};

export type StatBlockData = {
  name: string; // title
  subtitle: string;
  description: string;
  sections: StatBlockSection[];
  layout?: StatBlockLayout;
  image?: StatBlockImage;
};
